// ----------------------------------------------------------------------

const sizes = {
    heights :{
        contentContainer: { xs: "calc(100vh - 168px)", sm: "calc(100vh - 76px)", lg: "calc(100vh - 100px)" },
        contentItemsContainer: { xs: "calc(100vh - 148px)", sm: "calc(100vh - 150px)", lg: "calc(100vh - 100px)" },
       // postContainer:
    },
    widths : {
        contentTabLeftContainer: { xs: "110px", sm: "175px" },
        contentContainerWithMenu: {
            xs: "calc(100vh - 112px)",
            sm: "calc(100vh - 120px)",
            lg: "calc(100vh - 64px)"
        }

    },
}

export default sizes;
