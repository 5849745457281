import React, { Component } from 'react';

//Material UI
import Toolbar from '@mui/material/Toolbar'; 
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CircularProgress from '@mui/material/CircularProgress';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
//react-pdf
import { Document, Page } from 'react-pdf'



export class DocumentViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageScale: this.props.scale ?? 1,
            numPages: null,
            pageNumber: 1,
            loaded: false,
            rotate: 0
        }
    }

    download = () => {
        const linkSource = `data:application/pdf;base64,${this.props.document}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        const ext = this.props.fileName.slice(-4);
        downloadLink.download = ext === '.pdf' ? this.props.fileName : `${this.props.fileName}.pdf`;
        downloadLink.click();
    }

    changePage = (offset) => {
        this.setState({ pageNumber: this.state.pageNumber + offset });
    }
    nextPage = () => {
        this.changePage(1);
    }
    previousPage = () => {
        this.changePage(-1);
    }
    zoomIn = () => {
        this.setState({ pageScale: this.state.pageScale * 1.2 })
    }
    zoomOut = () => {
        this.setState({ pageScale: this.state.pageScale / 1.2 })
    }
    rotatePage = () => {
        if (this.state.rotate === 270) {
            this.setState({ rotate: 0 });
        }
        else {
            this.setState({ rotate: this.state.rotate + 90 });
        }
    }
    onDocumentLoadSuccess = ({ numPages }) => {

        if (!this.state.loaded) {
            this.setState({
                numPages: numPages,
                loaded: true
            });
        }
    }

    //render funcs
    title = () => {
        return (
            <Toolbar disableGutters variant="dense" >
                <Box sx={{ display: { xs: 'flex' } , width:"100%"}} >
                    <Typography
                        variant="h6"
                        sx={{ pl:2, color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack, my:"auto" }}
                    >
                        {this.props.fileName ?? "Document Viewer"}
                    </Typography>
    
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'flex' } }} >
                        <IconButton aria-label="zoomin" onClick={this.zoomIn} sx={{ margin: "0px 0px", color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack}} disabled={!this.state.loaded}>
                            <ZoomInIcon />
                        </IconButton>
                        <IconButton aria-label="zoomout" onClick={this.zoomOut} sx={{ margin: "0px 0px", color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }} disabled={!this.state.loaded}>
                            <ZoomOutIcon />
                        </IconButton>
                        <Tooltip title="Rotate 90 degress clockwise" >
                            <IconButton aria-label="rotate" onClick={this.rotatePage} sx={{ margin: "0px 0px", color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }} disabled={!this.state.loaded}>
                                <RotateRightIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Download" >
                            <IconButton aria-label="download" onClick={this.download} sx={{ margin: "0px 0px", color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }} disabled={!this.state.loaded}>
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Close Document" >
                            <IconButton aria-label="close" onClick={this.props.handleClose && this.props.handleClose} sx={{ margin: "0px 0px", marginLeft: "16px", color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }} disabled={!this.state.loaded}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>

                    </Box>

                </Box>
            </Toolbar>

        )
    }
    loading = () => {
        return (
        <Box sx={{ display: "flex", width: "100%", height: "calc(100vh - 120px)" }}>
            <CircularProgress color="secondary" sx={{ margin: "auto" }} />
        </Box>
        )

    }
    contents = () => {
        return (
            <Box
                sx={{
                    textAlign: 'center',
                    color: "#ccc",
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                    mx: "auto",
                }}
            >
                {this.title()}
                <Paper  sx={{
                    height: "100%",
                    padding: "10px",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    overflowY: "auto",
                    overflowX: "auto",
                    background: this.props.dark ? "#111" : "#666",
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxHeight: this.props.fullHeight ? { xs: "calc(100vh - 104px)", lg: "calc(100vh - 48px)" }: "calc(100vh - 200px)",
                    borderRadius:0
                }}
                    elevation={0}
                    className="prettyScroll"
                >
                    {this.props.document &&
                        <Document
                        file={'data:application/pdf;base64,' + this.props.document}
                        loading={this.loading()}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        noData="No file selected"
                        options={{ workerSrc: "/pdf.worker.js" }}
                    >
                            {this.state.numPages &&
                                Array.apply(null, Array(this.state.numPages))
                                    .map((x, i) => i + 1)
                            .map(page => <Page key={page} pageNumber={page} scale={this.state.pageScale} rotate={this.state.rotate} className="ls-react-pdf"  />)
                            }
                        </Document>
                    }
                </Paper>
            </Box>
            )
    }

    render() {
        return this.contents();
    }
}
