// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
              backgroundColor: theme.palette.grey[800],
            padding:"8px",
        },
        arrow: {
          color: theme.palette.grey[800]
        }
      }
    }
  };
}
