import linkProperies from './Links';
import videoProperies from './Videos';
import photoProperies from './Photos';
import noteProperies from './Notes';
import documentProperies from './Documents';
import listProperies from './Lists';
import { faMusic, faImage, faVideo, faFilePdf, faLink, faStickyNote, faList, faStore, faBlog, faHome, faCalendar, faUtensils } from '@fortawesome/free-solid-svg-icons'

const contentTypes = [
    { label: 'Content Home', value: null,  },
    { label: 'Photos', value: 1, contentType: "photos", desc: 'Store and organize photos', icon: faImage, folders: "photoLibraries", postType: 2},
    //{ label: 'Videos', value: 4, contentType: "videos", desc: 'Upload and share videos', icon: faVideo, folders: "videoLibraries", postType: 3},
    //{ label: 'Music', value: 15, contentType: "music", desc: 'Upload your music, create playlists, and more', icon: faMusic, postType: 5, disabled: true },
    { label: 'Files', value: 0, contentType: "documents", desc: 'Store personal files and view files shared with you', icon: faFilePdf, folders: "documentFolders", postType: 6},
    //{ label: 'Links', value: 14, contentType: "links", desc: 'Save links to frequently accessed sites', icon: faLink, folders: "linkFolders", postType: 1},
    { label: 'Notes', value: 2, contentType: "notes", desc: 'Create and organize notes in one convenient location', icon: faStickyNote, folders: "notebooks", postType: null},
    { label: 'Lists', value: 3, contentType: "lists", desc: 'Create todo and other types of lists', icon: faList, folders: "lists", postType: null},
    //{ label: 'Market', value: 13, contentType: "photos", desc: 'Create your own online store to post items for sale', icon: faStore, color: '#ccc' },
    //{ label: 'Recipies', value: 16, contentType: "photos", desc: "Create and share your favorite recipies", icon: faUtensils, color: '#ccc'},
    //{ label: 'Blogs', value: 5, contentType: "photos", desc:"Create and manage your own Blog" },
    //{ label: 'Events', value: 6, contentType: "photos", desc: "Create and manage both public and private events", icon: faCalendar, folders: "events", postType: 8, disabled: true},
]

export const getContentProps = (type) => {
    switch (type) {
        case 'links': return linkProperies;
        case 'photos': return photoProperies;
        case 'videos': return videoProperies;
        case 'notes': return noteProperies;
        case 'documents': return documentProperies;
        case 'lists': return listProperies;
        default: return null;
    }
}

export const getContentTypes = () => {
    return contentTypes;
}

//enum vals postcontenttype
// 0   Text,
// 1   Link,
// 2  Photo,
// 3  Video,
// 4   Blog,
// 5   Audio,
// 6   File,
// 7   Custom,
// 8   Event,
// 9   Partner,
// 10   Article,
// 11   Post,
// 12   Market