import React, { Component } from 'react';

//Custom Components
import { UserSiteAbout } from './UserSiteAbout';
import { UserSitePhotos } from './UserSitePhotos';
import { UserSiteVideos } from './UserSiteVideos';
import { UserSiteMusic } from './UserSiteMusic';
import { UserSiteFiles } from './UserSiteFiles';
import { UserSiteHome } from './UserSiteHome';

//Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { getSiteContent, getUserSiteProfile, getProfileImage } from '../../DataServices/UserService'
import { getPhotoThumb, getPhoto, getSiteLibrary } from '../../DataServices/PhotoService'
import { getVideoLibrary } from '../../DataServices/VideoService'
import { getSong } from '../../DataServices/MusicService'
import { getFolder } from '../../DataServices/DocumentService'
import { getPostsByUser } from '../../DataServices/PostService'

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ minWidth: "130px" }}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export class UserSite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            tabs: ['Home', 'About Me'],
            loadingHome: true,
            loadingProfile: false,
            loadingContent: false,
            loadingPhotos: true,
            loadingVideos: true,
            loadingFiles: true,
            loadingSongs: true,
            coverPhoto: this.props.site.coverPhoto ?? null,
            profilePhoto: "",
            refreshing: false,
            loadingPosts: false,
            loadedPosts: false,
            newestPostID: "",

            posts: [],
            loadIndex: 0,
            loadCount: 6,
            width: 0,
            height: 0,
        }
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getProfilePic();
        this.getPosts();
        this.getSiteContent();
        this.getUserSiteProfile();
        this.getCoverImage();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    //Data Getters
    getProfilePic = async () => {
        const photo = await getProfileImage(this.props.site.userID);
        if (photo) {
            this.setState({ profilePhoto: photo.thumb, loadingHome: false });
            this.forceUpdate();
        }
    }
    getSiteContent = async () => {

        this.setState({ loadingContent: true });
        const siteContent = await getSiteContent(this.props.site.userID);
        const tabs = [...this.state.tabs];
        if (siteContent) {
            if (siteContent.photoAlbums && siteContent.photoAlbums.length > 0) {
                tabs.push("Photos");

            }
            if (siteContent.videoLibraries && siteContent.videoLibraries.length > 0) {
                tabs.push("Videos");
            }
            if (siteContent.blogs && siteContent.blogs.length > 0) {
                tabs.push("Blog");
            }
            if (siteContent.music && (
                (siteContent.music.songs && siteContent.music.songs.length > 0) ||
                (siteContent.music.artists && siteContent.music.artists.length > 0) ||
                (siteContent.music.albums && siteContent.music.albums.length > 0) ||
                (siteContent.music.playlists && siteContent.music.playlists.length > 0)
            )) {
                tabs.push("Music");
            }
            if (siteContent.events && siteContent.events.length > 0) {
                tabs.push("Events");
            }
            if (siteContent.documentFolders && siteContent.documentFolders.length > 0) {
                tabs.push("Files");
            }
            //if (siteContent.notebooks && siteContent.notebooks.length > 0) {
            //    tabs.push("Notes");
            //}
            //if (siteContent.lists && siteContent.lists.length > 0) {
            //    tabs.push("Lists");
            //}
            this.setState({ siteContent, loadingContent: false, tabs });
            setTimeout(this.loadContentLibs, 200);

        }



    }
    loadContentLibs = async () => {
        await this.loadVideos();
        await this.loadMusic();
        await this.loadDocuments();
        this.loadPhotos();
    }
    loadPhotos = async () => {
        this.setState({ loadingPhotos: true});
        const siteContent = this.state.siteContent;
        if (siteContent.photoAlbums && siteContent.photoAlbums.length > 0) {
            for (var i = 0; i < siteContent.photoAlbums.length; i++) {
                const album = await getSiteLibrary(siteContent.photoAlbums[i].photoAlbumID);
                if (album) {
                    siteContent.photoAlbums[i] = album;
                    this.setState({ siteContent });
                }
            }
            this.setState({ loadingPhotos: false });
            for (var i = 0; i < siteContent.photoAlbums.length; i++) {
                for (var j = 0; j < siteContent.photoAlbums[i].photos.length; j++) {
                    var photo = siteContent.photoAlbums[i].photos[j];
                    if (photo.photoID) {
                        const thumb = await getPhotoThumb(photo.photoID);
                        if (thumb) {
                            siteContent.photoAlbums[i].photos[j].thumb = thumb;
                            this.setState({ siteContent });
                        }
                    }
                }

            }
        }
    }
    loadVideos = async () => {
        this.setState({ loadingVideos: true });
        const siteContent = this.state.siteContent;
        if (siteContent.videoLibraries && siteContent.videoLibraries.length > 0) {
            for (var i = 0; i < siteContent.videoLibraries.length; i++) {
                const lib = await getVideoLibrary(siteContent.videoLibraries[i].id);
                if (lib) {
                    siteContent.videoLibraries[i] = lib;
                    this.setState({ siteContent, loadingVideos: false });
                    this.forceUpdate();
                }
            }
        }
    }
    loadDocuments = async () => {
        this.setState({ loadingFiles: true });
        const siteContent = this.state.siteContent;
        if (siteContent.documentFolders && siteContent.documentFolders.length > 0) {
            for (var i = 0; i < siteContent.documentFolders.length; i++) {
                const folder = await getFolder(siteContent.documentFolders[i].id);
                if (folder) {
                    siteContent.documentFolders[i] = folder;
                    this.setState({ siteContent, loadingFiles: false });
                    this.forceUpdate();
                }
            }
        }
    }
    loadMusic = async () => {
        await this.getSongs();
    }
    getSongs = async () => {
        this.setState({ loadingSongs: true });
        const siteContent = this.state.siteContent;
        if (siteContent.music && siteContent.music.songs && siteContent.music.songs.length > 0) {
            for (var i = 0; i < siteContent.music.songs.length; i++) {
                const song = await getSong(siteContent.music.songs[i].id);
                if (song) {
                    siteContent.music.songs[i] = song;
                    this.setState({ siteContent, loadingSongs: false });
                    this.forceUpdate();
                }
            }
        }
    }
    getUserSiteProfile = async () => {
        this.setState({ loadingProfile: true });

        const userSiteProfile = await getUserSiteProfile(this.props.site.userID);
        if (userSiteProfile) {
            this.setState({ siteProfile: userSiteProfile, loadingProfile: false });
        }
    }
    getCoverImage = async () => {
        if (this.props.site && this.props.site.coverPhotoID) {
            const coverPhoto = await getPhoto(this.props.site.coverPhotoID);
            if (coverPhoto) {
                this.setState({ coverPhoto: coverPhoto.fullPhoto });
            }
        }
    }
    getPosts = async () => {
        if (!this.state.refreshing) {
            this.setState({ refreshing: true });
            const postInfo = await this.getPostsByUser(-1, this.state.loadIndex, this.state.loadCount);
            if (postInfo) {
                this.setState({
                    posts: postInfo.posts,
                    loadIndex: postInfo.loadIndex,
                    newestPostID: postInfo.newestPostID,
                    loadingPosts: false,
                    loadedPosts: true,
                    refreshing: false,
                });
            }
            else {
                this.setState({
                    loadingPosts: false,
                    loadedPosts: true,
                    refreshing: false,
                });
            }
            this.forceUpdate();
        }
    }
    getPostsByUser = async (channel, loadIndex, loadCount) => {
        const data = await getPostsByUser(this.props.site.userID, loadIndex, loadCount, channel ?? -1, this.props.appIdentifier ?? "LS"); //fetch posts

        //if unauthorized, invoke callback to kill token and logout
        if (data === 401 || data === 400) {
            this.props.setToken('');
            return;
        }

        //if other error just set status to normal
        if (data === 'error') {
            return;
        }

        let newestPostID;
        if (data.posts && data.posts.length > 0) {
            newestPostID = data.posts[0].id;
        }
        const postInfo = { posts: data.posts, newestPostID: newestPostID, loadIndex: loadIndex + data.posts.length, loading: false, user: data.user, loaded: true, refreshing: false };
        return postInfo;
    }
    getMorePosts = async () => {
        if (!this.state.refreshing && !this.state.loadingMore) {
            this.setState({
                loadingMore: true,
                refreshing: true
            });
            const result = await this.getMorePostsByUser();

            if (result) {
                let posts = this.state.posts;
                posts = [...posts, ...result.posts];
                this.setState({
                    posts,
                    loadIndex: this.state.loadIndex + result.loadIndex,
                    loading: false,
                    loadingMore: false,
                    refreshing: false,
                });
            }
            else{
                this.setState({
                    loading: false,
                    loadingMore: false,
                    refreshing: false,
                });
            }
            this.forceUpdate();
        }
    }
    getMorePostsByUser = async () => {

        const data = await getPostsByUser(this.props.site.userID, this.state.loadIndex, this.state.loadCount, -1, this.props.appIdentifier ?? "LS");

        //if unauthorized, invoke callback to kill token and logout
        if (data === 401) {
            this.props.setToken('');
            return;
        }

        //if other error just set status to normal
        if (data === 'error') {
            return null;
        }

        if (data && data.posts && data.posts.length > 0) {
            const postInfo = { posts: data.posts, loadIndex: data.posts.length, loading: false, loaded: true, refreshing: false, loadingMore: false };
            return postInfo;
        }
        else {
            return null;
        }
    }
    updatePostContent = async (channel, idx, contentIdx, content) => {
        if (content) {
            if (content.type == 4) {
                content = await this.loadBlogSpecificContent(content);
            }
            if (content.type == 8) {
                content = await this.loadEventSpecificContent(content);
            }
            if (content.type == 12) {
                content = await this.loadMarketSpecificContent(content);
            }
            if (idx >= 0) {
                const posts = this.state.posts;
                if (posts[idx] && posts[idx].content)
                    if (content) {
                        posts[idx].content[contentIdx] = content;
                    }
                    else {
                        posts.splice(idx, 1);
                    }
                this.setState({ posts });
                this.forceUpdate();
            }
            else {

                let post = this.props.selectedPost;
                if (post) {
                    post.content[contentIdx] = content;
                    this.props.setSelectedPost(post);
                }
            }
        }
    }
    updateRepostContent = async (postIdx, postContentIdx, contentIdx, content) => {
        if (content && content) {
            if (content.type == 4) {
                content = await this.loadBlogSpecificContent(content);
            }
            if (content.type == 8) {
                content = await this.loadEventSpecificContent(content);
            }
            const posts = [...this.state.posts];
            if (posts[postIdx] && posts[postIdx].content && posts[postIdx].content.length > 0 && posts[postIdx].content[postContentIdx].post && posts[postIdx].content[postContentIdx].post.content)
                if (content) {
                    posts[postIdx].content[postContentIdx].post.content[contentIdx] = content;
                }
                else {
                    posts[postIdx].content[postContentIdx].post.content[contentIdx].splice(contentIdx, 1);
                }

            this.setState({ posts });
        }
    }
    loadBlogSpecificContent = async (content) => {
        //get header image
        if (content.blog && content.blog.header && content.blog.header.headerImage && content.blog.header.headerImage.photoID) {
            const hthumb = await getPhotoThumb(content.blog.header.headerImage.photoID);
            if (hthumb) {
                content.blog.header.headerImage.thumb = hthumb;
            }
        }
        //get cover image
        if (content.blog && content.blog.header && content.blog.header.coverImage && content.blog.header.coverImage.photoID) {
            const cthumb = await getPhotoThumb(content.blog.header.coverImage.photoID);
            if (cthumb) {
                content.blog.header.coverImage.thumb = cthumb;
            }
        }
        //get author image
        if (content.blog && content.blog.header && content.blog.header.authorImage && content.blog.header.authorImage.photoID) {
            const athumb = await getPhotoThumb(content.blog.header.authorImage.photoID);
            if (athumb) {
                content.blog.header.authorImage.thumb = athumb;
            }
        }
        //load other content
        if (content.blog && content.blog.bodyRows && content.blog.bodyRows.length > 0) {
            for (var i = 0; i < content.blog.bodyRows.length; i++) {
                let row = content.blog.bodyRows[i];
                if (row.rowContent && row.rowContent.length > 0) {
                    for (var j = 0; j < row.rowContent.length; j++) {
                        let rcontent = row.rowContent[j];
                        if (rcontent.contentType == 1 && rcontent.photo && rcontent.photo.photoID) {
                            const contentThumb = await getPhotoThumb(rcontent.photo.photoID);
                            if (contentThumb) {
                                content.blog.bodyRows[i].rowContent[j].photo.thumb = contentThumb;
                            }

                        }
                    }
                }
            }
        }
        return content;
    }
    loadEventSpecificContent = async (content) => {
        //get header image
        if (content.event && content.event.coverPhoto && content.event.coverPhoto.photoID) {
            const hthumb = await getPhotoThumb(content.event.coverPhoto.photoID);
            if (hthumb) {
                content.event.coverPhoto.thumb = hthumb;
            }
        }

        //load other content
        if (content.event && content.event.bodyRows && content.event.bodyRows.length > 0) {
            for (var i = 0; i < content.event.bodyRows.length; i++) {
                let row = content.event.bodyRows[i];
                if (row.rowContent && row.rowContent.length > 0) {
                    for (var j = 0; j < row.rowContent.length; j++) {
                        let rcontent = row.rowContent[j];
                        if (rcontent.contentType == 1 && rcontent.photo && rcontent.photo.photoID) {
                            const contentThumb = await getPhotoThumb(rcontent.photo.photoID);
                            if (contentThumb) {
                                content.event.bodyRows[i].rowContent[j].photo.thumb = contentThumb;
                            }

                        }
                    }
                }
            }
        }
        return content;
    }
    loadMarketSpecificContent = async (content) => {
        //get header image
        if (content.marketItem && content.marketItem.primaryPhoto && content.marketItem.primaryPhoto.photoID) {
            const hthumb = await getPhotoThumb(content.marketItem.primaryPhoto.photoID);
            if (hthumb) {
                content.marketItem.primaryPhoto.thumb = hthumb;
            }
        }

        //load other content
        if (content.marketItem && content.marketItem.photos && content.marketItem.photos.length > 0) {
            for (var i = 0; i < content.marketItem.photos.length; i++) {
                if (content.marketItem.photos[i].photo && content.marketItem.photos[i].photo.photoID) {
                    const contentThumb = await getPhotoThumb(content.marketItem.photos[i].photo.photoID);
                    if (contentThumb) {
                        content.marketItem.photos[i].photo.thumb = contentThumb;
                    }
                }
            }
        }
        return content;
    }
    updatePosts = (channel, post, idx) => {
        switch (channel) {
            case -1:
                const posts = this.state.posts;
                posts[idx] = post;
                this.setState({ posts });
                break;
            default:
                break;
        }
        this.forceUpdate();
    }
    updateLastPost = (id) => {
        this.setState({ newestPostID: id });
    }

    //Event Handlers
    handleSubscribe = () => { }
    setTabValue = (newValue) => {
        this.setState({ tabValue: newValue });
    }
    handleScroll = (e) => {
   
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        console.log(bottom);
        if (bottom) {
            this.getMorePosts();
        }
    }

    //Render funcs
    avatar = () => {
        return (
            <Avatar
                className="avatarImageMassive"
                src={"data:image/png;base64, " + this.state.profilePhoto}
                imgProps={{ style: { cursor: "pointer", borderRadius: "8px" } }}
                variant="square"
            />
        )
    }
    header = () => {
        if (this.props.site) {
            return (
                <Box
                    sx={{
                        p: { xs: 1, sm: 1, md: 3 },
                        textAlign: "left",
                        backgroundImage: this.state.coverPhoto && "linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('data:image/png;base64, " + this.state.coverPhoto + "')",
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        background: (theme) => !this.state.coverPhoto && (this.props.dark ? theme.palette.primary.main : "white"),
                        boxShadow: this.props.dark ? 0 : 8
                    }}>
                    <Stack sx={{ textAlign: "center", justifyContent: "left" }}>
                        <Box display="flex">
                            {/*<Box sx={{ display: { xs: "none", sm: "flex" }, width: "150px" }} />*/}
                            {this.state.width > 600 && this.avatar() }
                            <Box sx={{ flexGrow: 1 }} />
                            <Stack sx={{ p: 3, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.white, py: this.state.width <= 600 ? "5px" : undefined }}>
                                <Typography variant="h3" sx={{ color: theme=> (!this.state.coverPhoto && !this.props.dark) && theme.palette.common.dimblack }}>
                                    {this.props.site.user.displayName ? this.props.site.user.displayName : this.props.site.user.firstName + " " + this.props.site.user.lastName}
                                </Typography>
                                {(this.state.siteProfile && this.state.siteProfile.addresses && this.state.siteProfile.addresses.length > 0) &&
                                    <Typography variant="subtitle1" sx={{ color: theme => (!this.state.coverPhoto && !this.props.dark) && theme.palette.common.dimblack }}>
                                        {this.state.siteProfile.addresses[0].city + (this.state.siteProfile.addresses[0].state ? ", " + this.state.siteProfile.addresses[0].state : "")}
                                    </Typography>
                                }
                                {(this.state.width > 600 && this.state.siteProfile && this.state.siteProfile.personalHeadline) &&
                                    <Typography variant="subtitle2" sx={{ fontStyle: 'italic', mt: 1, fontWeight: 500, color: theme => (!this.state.coverPhoto && !this.props.dark) && theme.palette.common.dimblack }}>
                                        {'"' + this.state.siteProfile.personalHeadline + '"'}
                                    </Typography>
                                }
                            </Stack>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box display="flex" sx={{ m: "auto" }} >
                                <Button variant="outlined" size="medium" color="secondary" sx={{ color: theme => theme.palette.common.dimwhite }}>{this.props.site.user.following ? "Unfollow" : "Follow" + (this.props.site.user &&  " " + this.props.site.user.firstName)}</Button>
               {/*                 <Button size="small"  color="secondary">Message</Button>*/}
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            )
        }
        return null;
    }
    folderLabel = (label) => {
        return (
            <Box display="flex" sx={{ width: "100%", textAlign: "left" }}>
                <Typography variant="body1" sx={{ fontSize: ".9rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                    {label}
                </Typography>
            </Box>
        )
    }
    tabs = () => {
        return (
            <Box sx={{ width: '100%', height: "100%", minHeight: "400px", background: this.props.dark ? "#050505" : "#eee" }} className={this.props.dark && "dark"} >
                <Box sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: theme => this.props.dark ? "#0f0f0f" : theme.palette.secondary.main, height: "100%" }} >
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{ minHeight: "36px", maxWidth: "fit-content", mx: "auto" }}
                        allowScrollButtonsMobile
                        indicatorColor="secondary"
                        value={this.state.tabValue}
                        onChange={(e, newValue) => this.setTabValue(newValue)}
                        aria-label="content tabs"
                    >
                        {this.state.tabs.map((item, index) => {
                            return (
                                <Tab
                                    key={item}
                                    label={item}
                                    sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }}
                                />
                            )
                        })
                        }
                    </Tabs>
                    {this.state.tabs.map((item, index) => {
                        return (
                            <TabPanel key={item + 'tabpanel'} style={{ display: "contents", width: "100%",height:"100%" }} value={this.state.tabValue} index={index}>
                                <Box
                                    onScroll={this.handleScroll}
                                    sx={{
                                        width: "100%", p: { xs: 0, sm: 1 },
                                        height: { xs: "calc(100% - 92px)", lg: this.props.fullScreen ? "calc(100% - 24px)" : "calc(100% - 92px)" },
                                        background: (theme) => this.props.dark ? theme.palette.backgroundMain.dark : theme.palette.backgroundMain.light,
                                    }} className="prettyScroll">
                                    {this.renderTab(item)}
                                </Box>
                            </TabPanel>
                        )
                    })}
                </Box>
            </Box>
        )
    }
    home = () => {
        return (
            <UserSiteHome
                siteOwner={this.props.site && this.props.site.user}
                dark={this.props.dark}
                loading={this.state.loadingHome}
                photo={this.state.profilePhoto}
                bio={this.props.site.bio}
                posts={this.state.posts}
                postsloaded={this.state.loadedPosts}
                user={this.props.user}
                updatePostContent={this.updatePostContent}
                updateRepostContent={this.updateRepostContent}
                appIdentifier="LS"
                updateLastPost={this.updateLastPost}
                updatePosts={this.updatePosts}
                getMorePosts={this.getMorePosts}
                width={this.state.width}

            />
        )
    }
    profile = () => {
        if (this.state.siteProfile) {
            return (<UserSiteAbout dark={this.props.dark} loading={this.state.loadingProfile} siteProfile={this.state.siteProfile} />)
        }
        return null;
    }
    videos = () => {
        if (this.state.siteContent && this.state.siteContent.videoLibraries && this.state.siteContent.videoLibraries.length > 0) {
            return (
                <UserSiteVideos dark={this.props.dark} loading={this.state.loadingVideos} videoLibraries={this.state.siteContent.videoLibraries} width={this.state.width} />
                )
        }
        return null;
    }
    photos = () => {
        if (this.state.siteContent && this.state.siteContent.photoAlbums && this.state.siteContent.photoAlbums.length > 0) {
            return (<UserSitePhotos dark={this.props.dark} loading={this.state.loadingPhotos} siteContent={this.state.siteContent} width={this.state.width} fullScreen={this.state.width <= 600 ? true : undefined} />)
        }
        return null;
    }
    music = () => {
        if (this.state.siteContent && this.state.siteContent.music && this.state.siteContent.music.songs && this.state.siteContent.music.songs.length > 0) {
            return (<UserSiteMusic dark={this.props.dark} loading={this.state.loadingSongs} music={this.state.siteContent.music} />)
        }
        return null;
    }
    files = () => {
        if (this.state.siteContent && this.state.siteContent.documentFolders && this.state.siteContent.documentFolders.length > 0) {
            return (<UserSiteFiles dark={this.props.dark} loading={this.state.loadingFiles} folders={this.state.siteContent.documentFolders} />)
        }
        return null;
    }
    renderTab = (item) => {
        switch (item) {
            case "Home":
                return this.home();
            case "About Me":
                return this.profile();
            case "Photos":
                return this.photos();
            case "Videos":
                return this.videos();
            case "Music":
                return this.music();
            case "Files":
                return this.files();
            default:
                return (item);
        }
    }
    site = () => {
        return (
            <Box sx={{ minWidth: "360px", marginLeft: "auto", marginRight: "auto", paddingBottom: "0px", height: { xs: "calc(100vh - 51px)" } }} >
                {this.props.site &&
                    <Stack sx={{ height: "100%"}}>
                        {this.header()}
                        {this.tabs()}
                    </Stack>
                }
            </Box>
        )
    }

    //Main component render
    render() {
        return this.site();
    }
}

