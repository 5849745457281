
export async function getVerbiage(app) {
   
    if (!app) app = "";
    let url = 'constants/getverbiage?app=' + app;
    const response = await fetch(url);
    if(response.status !== 200)
    return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function setVerbiage(app, welcome, datausage, freespeech) {
    const req = { App: app, Welcome: welcome, DataUsage: datausage, FreeSpeech: freespeech };
    const response = await fetch('constants/setverbiage', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return null;
}




