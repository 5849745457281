import React, { Component } from 'react';

//Custom Components
import { PhotoViewer } from './PhotoViewer';

//Material UI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Carousel from 'react-material-ui-carousel';

//Data Services
export class PhotoGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPhotoViewer: false,
            selectedPhoto: null,
        };
    }

    //Event handlers
    handleClickPhoto = (e, photo, index) => {
        let target = e.target;
        target.style.cursor = 'wait';
        document.body.style.cursor = 'wait';
        this.setState({
            selectedPhoto: photo,
            selectedPhotoID: photo.photoID,
            selectedPhotoIDX: index,
            openPhotoViewer: true,
        });
        target.style.cursor = 'default';
        document.body.style.cursor = 'default';
    }

    //Render funcs
    content = () => {
        const photos = this.props.photos;
        if (photos && photos.length > 1) {
            return (
                <Carousel
                    autoPlay={false}
                    animation="slide"
                    className={this.props.dark ? "dark photoslider" : "photoslider"}
                    indicatorIconButtonProps={{
                        style: {
                            color: this.props.dark ? "#666" : "#afafaf"
                        }
                    }}
                    activeIndicatorIconButtonProps={{ style: { color: "#32478C" } }}
                >
                    {
                        photos.map((photo, index) => {
                            if (photo && photo.thumb) {
                                const imageUrl = "data:image/png;base64, " + photo.thumb;
                                return (
                                    <Box key={"photofor" + photo.photoID} sx={{ p: { xs: 0, sm: 1 }, backgroundColor: this.props.dark ? "transparent" : "transparent", width: "100%", height: { xs: this.props.width, sm: "fit-content" }, textAlign: "center", display: "inline-block", }}>
                                        <Paper
                                            component="img"
                                            elevation={this.props.dark ? 0 : 8}
                                            key={"pc-" + index}
                                            id={"image_" + photo.photoID + "_" + index}
                                            className="postimage"
                                            src={imageUrl}
                                            onClick={(e) => this.handleClickPhoto(e, photo, index)}
                                            sx={{
                                                backgroundColor: this.props.dark ? "black" : "#f7f7f7",
                                                height: { xs: this.props.width, md: this.props.width },
                                                objectFit: "cover",
                                                cursor: "pointer",
                                                borderRadius: "0px",
                                                maxHeight: { xs: this.props.width, md: 500 },
                                                maxWidth: { xs: '100%', md: 500 },
                                                width: { xs: "100%" },
                                                my: "auto"

                                            }}
                                        />
                                    </Box>

                                )
                            }
                            return null;
                        })
                    }
                </Carousel>

            )
        }
        else {
            let photo = photos[0];
            if (photo && photo.thumb) {
                const imageUrl = "data:image/png;base64, " + photo.thumb;
                return (
                    <Box key={"photofor" + photo.photoID} sx={{ p: { xs: 0, sm: 1 }, backgroundColor: this.props.dark ? "transparent" : "transparent", width: "100%", height: { xs: this.props.width, sm: "fit-content" }, textAlign: "center", display: "inline-block", }}>
                        <Paper
                            component="img"
                            elevation={this.props.dark ? 0 : 4}
                            key={"pc-" + 0}
                            id={"image_" + photo.photoID + "_" + 0}
                            className="postimage"
                            src={imageUrl}
                            onClick={(e) => this.handleClickPhoto(e, photo, 0)}
                            sx={{
                                backgroundColor: this.props.dark ? "black" : "#f7f7f7",
                                height: { xs: this.props.width, md: this.props.width },
                                objectFit: "cover",
                                cursor: "pointer",
                                borderRadius: "0px",
                                maxHeight: { xs: this.props.width, md: 500 },
                                maxWidth: { xs: '100%', md: 500 },
                                width: { xs: "100%" },
                                my: "auto"

                            }}
                        />
                    </Box>

                )
            }
            return null;
        }

    }

    photoViewer = () => {
        if (this.state.openPhotoViewer) {
            return (
                <PhotoViewer
                    dark={this.props.dark}
                    open={this.state.openPhotoViewer}
                    photoID={this.state.selectedPhotoID}
                    photo={this.state.selectedPhoto}
                    handleClose={() => this.setState({ openPhotoViewer: false })}
                    index={this.state.selectedPhotoIDX}
                    user={this.props.user}
                    updateCommentCount={this.updatePhotoCommentCount}
                    appIdentifier={this.props.appIdentifier}
                    fullScreen={this.props.width <= 600}
                />
            )
        }
        return null;
    }

    //main component render
    render() {
        return (
            <>
                {this.content()}
                {this.photoViewer()}
            </>
        )
    }
}
