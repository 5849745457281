import React, { PureComponent } from 'react';

//Custom Components
import { Section } from '../Utilities/Section';
import { Spin } from '../Utilities/Spin';
import { VideoPlayer } from '../Utilities/VideoPlayer';

//Material UI
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export class UserSiteVideos extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            streamBase: "https://mylivesphere.com/api/videostream/",
        }
    }
  
    videoLibraries = () => {
        if (this.props.loading) {
            return (
                <Box sx={{ height: "100%", width: "100%", display: 'flex', justifyContent: "center" }}>
                    <Spin sub />
                </Box>
            )
        }
        if (this.props.videoLibraries && this.props.videoLibraries.length > 0) {
            return (
                <Stack>
                    {this.props.videoLibraries.map((item) => {
                        if (item.videos && item.videos.length > 0) {
                            return (
                                <Section key={item.id + "k"} dark={this.props.dark} title={item.name} padBottom noPad={this.props.width <= 600 ? true : false }>
                                    <Box sx={{ textAlign: "center" }}>
                                        {this.videos(item)}
                                    </Box>
                  
                                </Section>
                            )
                        }
                        return null;
                    })}
                </Stack>
                )
        }
        return null;
    }
    videos = (item) => {
        return (
            <Stack sx={{ width: "100%", display: "inherit" }}>
                {(item.videos && item.videos.length > 0) && item.videos.map((video) => {
                    return (
                        <Card
                            sx={{
                                m: 1,
                                background: (theme) => this.props.dark && "#2a2a2a", color: (theme) => this.props.dark && theme.palette.common.dimwhite,
                                maxWidth: 800,
                                m: "auto",
                                mb:2
                            }}
                            raised
                            elevation={this.props.dark ? 1 : 4}
                        >
                            <VideoPlayer video={video} />
                            <Box display="flex" sx={{ p: 1, justifyContent:"center" }}>
                                <Typography align="center" variant="subtitle1" sx={{ fontSize: { xs: ".7rem", sm: ".9rem" } }}>
                                {video.title}
                                </Typography>
                            </Box>
                        </Card>
                    )
                })
                }
            </Stack>
        )
    }

    render() {
        return this.videoLibraries();
    }
}

