import React, { PureComponent } from 'react';

//Custom Components
import { TextInput } from '../Utilities/TextInput';
import { Spin } from '../Utilities/Spin'
import { Comment } from './Comment';

//Material UI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

//Database Services
import { createNotification } from '../../DataServices/NotificationService'
import { getComments } from '../../DataServices/CommentService'
import { getPhotoThumb } from '../../DataServices/PhotoService'

export class Comments extends PureComponent {

  constructor(props) {
      super(props);
      this.state = {
          targetID: this.props.targetID,
          targetType: this.props.targetType,
          commentCount: this.props.commentCount,
          loadIndex: 0,
          loadCount: 10,
          comments: [],
          showComments: false,
          loading: false,
          show: false,
          loaded: false,
          avatarPhotos: [this.props.user.avatar ? { userID: this.props.user.userID, avatar: this.props.user.avatar } : { UserID:"", avatar:""}],
      };
  }
  
    componentDidUpdate() {
        if (!this.state.loaded && this.state.showComments) {
            this.getItems();
        }
    }

    getItems = async () => {
        this.setState({ loading: true });
        const data = await getComments(this.state.targetID, this.state.loadIndex, this.state.loadCount  );
        if (data === 'error') {
            return;
        }
        if (data) {
            this.setState({
                comments: data,
                loading: false,
                loaded: true
            });
            this.loadAvatars();
        }
    }
    loadAvatars = async () => {
        if (this.state.comments && this.state.comments.length > 0) {
            for (var i = 0; i < this.state.comments.length; i++) {
                let cuser = this.state.comments[i].user;
                if (!cuser.avatarB64) {
                    const euser = this.state.avatarPhotos.find(x => x.userID === cuser.userID);
                    if (euser) {
                        const eavatar = euser.avatar;
                        if (eavatar) {
                            const comments = this.state.comments;
                            comments[i].user.avatarB64 = eavatar;
                            this.setState({ comments });
                            this.forceUpdate();
                        }
                        else {
                            const avatar = await getPhotoThumb(cuser.userID);
                            const avatarPhotos = this.state.avatarPhotos;
                            avatarPhotos.push({ userID: euser.userID, avatar: avatar });
                            const comments = this.state.comments;
                            comments[i].user.avatarB64 = avatar;
                            this.setState({ comments, avatarPhotos });
                            this.forceUpdate();
                        }
                    }
                }
            }
        }
    }
    updateComments(comments) {
        this.setState({ comments: comments });
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handlePostComment();
        }
    }
    handlePostComment = async () =>  {
        const msg = document.getElementById("newComment" + this.state.targetID).value;
        if (msg) {
            document.getElementById("newComment" + this.state.targetID).value = "";
            const req = { TargetType: this.props.targetType, TargetID: this.state.targetID, CommentText: msg };
            //save data and get new id as response
            const response = await fetch('comment/addcomment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(req)
            })
            const user = this.props.user;
            const itemToInsert = await response.json().then(function (text) {
                var newItem = {
                    id: text,
                    fromMe: true,
                    commentText: msg,
                    commentDate: new Date(),
                    likeCount: 0,
                    loveCount: 0,
                    commentCount:0,
                    myReaction: -1,
                    comments:[],
                    whenPosted: "just now",
                    user: user ?? { firstName: "Me", lastName: "" }
                };
                return newItem;
            });

            const comments = [...this.state.comments];
            comments.push(itemToInsert);
            this.setState({ comments: comments });

            if (this.props.updateCommentCount) {
                this.props.updateCommentCount(this.state.commentCount + 1);
            }
            this.setState({ commentCount: this.state.commentCount + 1 });
            this.setState({ show: true });
        }
        let message = this.props.user.displayName + " commented on your " + this.props.targetTypeDesc;
        if (this.props.refUser.userID === this.props.user.userID) {
            message = "You commented on your own " + this.props.targetTypeDesc;
        }
        await createNotification(this.props.refUser.userID, 3, this.state.targetID, this.props.user.userID, message, this.props.appIdentifier);
    }
    handleEditComment = async (id, idx, commentText) => {
        this.setState(
            prevState => {
                let state = { ...prevState };
                state.comments[idx].commentText = commentText;
                return { state };
            }
        );
        const req = { CommentID: id, CommentText: commentText };
        await fetch('comment/editcomment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
    }
    handleDeleteComment = async (id, idx) => {

        const comments = [...this.state.comments];
        comments.splice(idx, 1);
        this.setState({ comments});

        if (this.props.updateCommentCount) {
            this.props.updateCommentCount(this.state.commentCount - 1);
        }
        this.props.updateCommentCount(this.state.commentCount - 1);
        this.setState({ commentCount: this.state.commentCount - 1 });

        const req = { CommentID: id };
        await fetch('comment/deletecomment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
    }
    handleAddReply = async (id, idx, reply) => {

        const req = { TargetID: id, CommentText: reply };
        //save data and get new id as response
        const response = await fetch('comment/addcomment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        let user = this.props.user;
        if (user) {
            user.avatarB64 = user.avatar;
        }
        const itemToInsert = await response.json().then(function (text) {
     
            var newItem = {
                id: text,
                fromMe: true,
                commentText: reply,
                commentDate: new Date(),
                likeCount: 0,
                loveCount: 0,
                myReaction: -1,
                whenPosted: "just now",
                user: user ?? { firstName: "Me", lastName: "" }
            };
            return newItem;
        });

 
        this.setState(
            prevState => {
                let state = { ...prevState };
                if (!state.comments[idx].comments) {
                    state.comments[idx].comments = [];
                }
                state.comments[idx].comments.push(itemToInsert);
                state.comments[idx].commentCount++;
                return { state };
            }
        );
    }
    handleDeleteReply = async (id, idx, replyidx) =>  {

        var newList = this.state.comments[idx].comments.splice(replyidx, 1);

        this.setState(
            prevState => {
                let state = { ...prevState };
                state.comments[idx].comments = newList;
                return { state };
            }
        );
        this.setState(
            prevState => {
                let state = { ...prevState };
                state.comments[idx].commentCount = this.state.comments[idx].commentCount - 1;
                return { state };
            }
        );

        const req = { CommentID: id };
        await fetch('comment/deletecomment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
    }
    handleEditReply = async (id, idx, editidx, reply) => {

        this.setState(
            prevState => {
                let state = { ...prevState };
                state.comments[idx].comments[editidx].commentText = reply;
                return { state };
            }
        );
        const req = { CommentID: id, CommentText: reply};
        await fetch('comment/editcomment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
    }
    handleGetReplies = async (id, idx, loadindex, loadcount) => {
        const response = await fetch('comment/getchildcomments?commentid=' + id + "&index=" + loadindex + "&count=" + loadcount);
        if (response.status === 200) {
            const data = await response.json();
            if (data && data.length > 0) {
                this.setState(
                    prevState => {
                        let state = { ...prevState };
                        if (state.comments[idx].comments === null) {
                            state.comments[idx].comments = [];
                        }
                        state.comments[idx].comments = [];
                        var i;
                        for (i = 0; i < data.length; i++) {
                            state.comments[idx].comments.push(data[i]);
                        }
                        return { state };
                    }
                );
            }
        }
    }
    updateReplyCount = (idx, count) => {
        this.setState(
            prevState => {
                let state = { ...prevState };
                state.comments[idx].commentCount = count;
                return { state };
            }
        );
    }

    //redner funcs
    composer = () => {
        return (
            <Box sx={{ maxWidth: "500px", width: "100%" }}>
                <TextInput
                    dark={this.props.dark}
                    cKey="CNewComment"
                    cid="CnewComment"
                    id={"newComment" + this.state.targetID}
                    label="New Comment"
                    defaultValue=""
                    onKeyPress={this.handleKeyPress}
                />
            </Box>

        )
    }
    items = () => {
        return  (
            <Stack>
                {this.state.comments.map((comment, index) => (
                    <Comment
                        dark={this.props.dark}
                        key={comment.id}
                        message={comment}
                        idx={index}
                        cbEdit={this.handleEditComment}
                        handleDelete={this.handleDeleteComment}
                        cbAddReply={this.handleAddReply}
                        cbEditReply={this.handleEditReply}
                        cbDeleteReply={this.handleDeleteReply}
                        updateReplyCount={this.updateReplyCount}
                        user={this.props.user}
                        noIcon={this.props.noIcon ?? false}
                        appIdentifier={this.props.appIdentifier}
                    />
                ))}
            </Stack>
        )
    } 
    commentButton = () => {
        var commentLinkLabel = "Comments";
        if (this.state.commentCount === 0) commentLinkLabel = "No Comments Yet";
        if (this.state.commentCount === 1) commentLinkLabel = "1 Comment";
        if (this.state.commentCount > 1) commentLinkLabel = this.state.commentCount + " Comments";
        return (
            <>
                <Button
                    sx={{ p:0, color: this.props.dark ? "#ddd" : "#333", fontWeight: 500, fontSize:".8rem" }}
                    onClick={() => this.setState({ showComments: !this.state.showComments })}
                >{commentLinkLabel}
                </Button>
                {this.state.loading &&
                    <Box sx={{ px:1, my:"auto" }}>
                        <Spin open={this.state.loading} sub={true} tiny={true} />
                    </Box>
                }
            </>
        )
    } 
    comments = () => {
        return (
            <div className="inline">
                <Accordion sx={{ backgroundColor: "transparent", color: this.props.dark ? "#ddd" : "#333", boxShadow: "none", padding: "0px 0px", opacity: .9 }}>
                    <AccordionSummary
                        expandIcon={!this.props.noIcon && <ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => this.setState({ showComments: !this.state.showComments })}
                    >
                        {this.state.commentCount > 0 ? this.commentButton() : this.composer()}
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: { xs: 0, sm: undefined } }}>
                        {(!this.state.loading && this.state.commentCount > 0) &&
                            <>
                                {this.items()}
                                {this.composer()}
                            </>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
            
            )
    }

    //main component render
    render() {
        return this.comments();
    }
}
