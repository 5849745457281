import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../Utilities/ModalDialog';

//Material UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export class ItemDeleter extends Component {

    content = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                user={this.props.user}
                show={this.props.show}
                title="Confirm Deletion"
                handleClose={this.props.handleClose}
                handleSave={this.props.handleDelete}
                fullWidth={false}
                maxWidth="sm"
                saveLabel="Delete"
            >
                <Box sx={{ display: "flex", p: 2, width: "100%" }}>
                    <Typography sx={{ m: "auto", p: 1, fontWeight: 500, fontSize: { xs: ".75rem", sm: "1rem" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                        {this.props.itemLabel + " will be deleted.  Proceed?"}
                    </Typography>
                </Box>
            </ModalDialog>
            )
    }
    render() {
        return this.content();

    }
}
