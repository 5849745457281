export async function setForgottenPassword(id, pwd) {
    const req = { RequestID: id, NewPassword: pwd };
    const response = await fetch('home/resetforgottenpassword', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function setPassword(old, newp) {
    const req = { OldPassword: old, NewPassword: newp };
    const response = await fetch('home/resetpassword', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function getPasswordResetRequest(id) {
    let url = 'home/getpasswordresetrequest?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function createPasswordResetRequest(email) {
    const req = { Email: email};
    const response = await fetch('home/createpasswordresetrequest', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function disableAccount(id) {
    const req = { UserID: id };
    const response = await fetch('home/disableaccount', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function deleteAccount(id) {
    const req = { UserID: id };
    const response = await fetch('home/deleteaccount', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function setPublic(attr) {
    const req = { Attribute: attr};
    const response = await fetch('home/setpublic', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function removePublic(attr) {
    const req = { Attribute: attr };
    const response = await fetch('home/removepublic', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

export async function isOnline(userid) {
    let url = 'home/isonline?userid=' + userid;
    const response = await fetch(url);
    if (response.status !== 200)
        return false;
    const data = await response.json();
    if (data)
        return data;
    return false;
}
export async function getUserProfile(appid) {
    let url = 'home/getuserprofile?appid=' + appid;
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getPublicAttributes() {
    let url = 'home/getpublicattributes';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getUserInfo(appid) {
    let url = 'home/getuserinfo?appid=' + appid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getUserAvatar(userid) {
    let url = 'home/getuseravatar?userID=' + userid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getUserTheme() {
    let url = 'home/gettheme';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function setUserTheme(theme) {
    const response = await fetch('home/settheme', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(theme)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function getProfileImage(userid) {
    let url = 'home/getuserprofileimage';
    if (userid) {
        url += "?userID=" + userid;
    }

    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function removeStrength(id) {
    const response = await fetch('home/removestrength?id=' + id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function addStrength(strength) {
    const response = await fetch('home/addstrength?strength=' + strength + '&impacts=&ranking=0', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function removeWeakness(id) {
    const response = await fetch('home/removeweakness?id=' + id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function addWeakness(strength) {
    const response = await fetch('home/addweakness?weakness=' + strength + '&impacts=&ranking=0', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function saveName(first, middle, last) {
    const response = await fetch('home/savename?first=' + first + '&middle=' + middle + '&last=' + last, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function saveHeadline(headline) {
    const response = await fetch('home/saveheadline?headline=' + headline, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function saveImportantDate(type, date) {
    const response = await fetch('home/setimportantdate?type=' + type + '&date=' + date, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function saveTrait(type, val) {
    const response = await fetch('home/savetrait?trait=' + type + '&value=' + val, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function addAddress(type, street, city, state, zip, country, primary) {
    const response = await fetch('home/addaddress?type=' + type + '&street=' + street + '&city=' + city + '&state=' + state + '&zip=' + zip + '&country=' + country + '&primary=' + primary, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function editAddress(id, type, street, city, state, zip, country, primary) {
    const response = await fetch('home/editaddress?id=' + id + '&type=' + type + '&street=' + street + '&city=' + city + '&state=' + state + '&zip=' + zip + '&country=' + country + '&primary=' + primary, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function deleteEmail(id) {
    const response = await fetch('home/removeemail?id=' + id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function deletePhone(id) {
    const response = await fetch('home/removephone?id=' + id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function deleteAddress(id) {
    const response = await fetch('home/removeaddress?id=' + id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function deleteFamily(id) {
    const response = await fetch('home/removefamily?id=' + id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function addEmail(type, email, primary) {
    const response = await fetch('home/addemail?type=' + type + '&email=' + email + '&primary=' + primary, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function editEmail(id, type, email, primary) {
    const response = await fetch('home/editemail?id=' + id + '&type=' + type + '&email=' + email + '&primary=' + primary, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function addPhone(type, number, primary) {
    const response = await fetch('home/addphone?type=' + type + '&number=' + number + '&primary=' + primary, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function editPhone(id, type, number, primary) {
    const response = await fetch('home/editphone?id=' + id + '&type=' + type + '&number=' + number + '&primary=' + primary, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function addFamily(first, middle, last, gender, type, dob) {
    const response = await fetch('home/addfamily?first=' + first + '&middle=' + middle + '&last=' + last + '&gender=' + gender + '&type=' + type + '&dob=' + dob, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function editFamily(id, first, middle, last, gender, type, dob) {
    const response = await fetch('home/editfamily?id=' + id + '&type=' + type + '&first=' + first + '&middle=' + middle + '&last=' + last + '&gender=' + gender + '&dob=' + dob, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function setSiteOptions(userid, photoid, showfollowbtn, showaboutbtn, showabout, showcontent, showposts, showfeatured, featuredtitle, featureddesc, featuredid, featuredtype, style, bio) {
    const req = { UserID: userid, PhotoID: photoid, ShowFollowBtn: showfollowbtn, ShowAboutBtn: showaboutbtn, ShowAbout: showabout, ShowContent: showcontent, ShowPosts: showposts, ShowFeatured: showfeatured, FeaturedTitle: featuredtitle, FeaturedDesc: featureddesc, FeaturedContentID: featuredid, FeaturedContentType: featuredtype, SiteStyle: style, Bio: bio };
    const response = await fetch('home/setsiteoptions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function getSiteOptions(id) {
    let url = 'home/getsiteoptions?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getSiteContent(id) {
    let url = 'home/getusersite?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getUserSiteProfile(id) {
    let url = 'home/getusersiteprofile?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getUserList() {
    let url = 'home/getuserlist';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function followUser(userid, targetid) {
    const req = { UserID: userid, TargetID: targetid };
    const response = await fetch('home/followuser', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function unfollowUser(userid, targetid) {
    const req = { UserID: userid, TargetID: targetid };
    const response = await fetch('home/unfollowuser', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function getUserFollowers() {
    let url = 'home/getuserfollowers';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getUsersFollowing() {
    let url = 'home/getusersfollowing';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function setFeedPreferences(userid, politics, profanity, adult) {
    const req = { UserID: userid, Profanity: profanity, Politics: politics, AdultContent: adult };
    const response = await fetch('home/setfeedpreferences', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function getFeedPreferences(id) {
    let url = 'home/getfeedpreferences?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}