
export async function sendEmail(toEmail, toName, subject, textContent, htmlContent, appid) {
    const req = { RecipientEmail: toEmail, RecipientName: toName, Subject: subject, TextContent: textContent, HTMLContent: htmlContent, AppID: appid};
    console.log(req);
    const response = await fetch('email/sendemail', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const res = await response.json();
        return res;
    }
    return null;
}




