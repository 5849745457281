import React, { Component } from 'react';

import { Song } from '../Common/Content/Music/Song';

import { Box,  Typography, List, ListItem, ListSubheader } from '@mui/material'

//React-slick
import Slider  from "react-slick";
import Linkify from 'react-linkify';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//constants
const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true
};

export class SliderComponent extends Component {
    constructor(props) {
        super(props);
    }
    spacer = (y) => {
        return (<Box sx={{ height: { xs: 0, sm: y } }} />)
    }
    sliderContent = (item, idx) => {
        return (
            <div key={"slider" + idx} style={{ textAlign: "center", width: "100%", }}>
                <Box
                    sx={{
                        maxWidth: "900px",
                        mx: "auto",
                        p: { xs: 2, sm: this.props.slider.padding ?? this.props.slider.size === "compact" ? 3 : 5, lg: this.props.slider.size === "compact" ? 4 : 5, }
                    }}>
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key} style={{ color: "white" }}> {decoratedText} </a>)}>
                        {item.primaryText &&
                            <Typography variant={this.props.slider.size === "compact" ? "h5" : "h3"} align="center" sx={{ fontFamily:this.props.fontFamily, fontWeight: this.props.slider.size === "compact" && 500, mb: this.props.slider.size === "compact" ? 3 : 5, color: this.props.slider.darkText ? theme => theme.palette.common.black : theme => theme.palette.common.white }}>
                                {item.primaryText}
                            </Typography>
                        }
                        {item.secondaryText &&
                            item.object ?
                            <>
                                {
                                    item.lists ?
                                    <Box display="flex">
                                        {item.lists.map(list => {
                                            return (
                                                <Box sx={{ mx: 1, flexGrow:1 }}>
                                                <List
                                                    dense
                                                    key={list.title}
                                                    subheader={
                                                        <ListSubheader sx={{ backgroundColor: "transparent" }}>
                                                            <Typography sx={{ fontFamily: this.props.fontFamily, color: theme => this.props.slider.darkText ? theme.palette.common.black : theme.palette.common.white, fontWeight: 600, fontSize: { xs: "1rem", md: "1.1rem" }  }}>
                                                                {list.title}
                                                            </Typography>
                                                        </ListSubheader>}
                                                >
                                                    {list.items.map(item => {
                                                        return (
                                                            <ListItem key={item} disablePadding>
                                                                <Typography sx={{ pl: 2, fontFamily: this.props.fontFamily, color: theme => this.props.slider.darkText ? theme.palette.common.black : theme.palette.common.white, fontWeight: 500, fontSize: { xs: ".9rem", md: ".8rem" } }}>
                                                                    {"- " + item}
                                                                </Typography>
                                                            </ListItem>
                                                        )
                                                    })}
                                                </List>
                                                    </Box>
                                            )
                                        })}
                                        </Box>
               
           
                                        :
                                        <>

                                            <Typography variant="h6" align="center" sx={{ fontFamily: this.props.fontFamily, color: theme => this.props.slider.darkText ? theme.palette.common.black : theme.palette.common.white, fontWeight: 500, fontSize: "1.2rem" }}>
                                                {item.secondaryText.title}
                                            </Typography>


                                            {item.secondaryText.items.map(x => {
                                                return (<Typography key={x} align="center" sx={{ fontFamily: this.props.fontFamily, color: theme => this.props.slider.darkText ? theme.palette.common.black : theme.palette.common.white, fontWeight: 500, fontSize: "1.2rem" }}>
                                                    {x}
                                                </Typography>)
                                            })}
                                        </>
                                }
                                {item.bottomText &&

                                    <Typography variant="h6" align="center" sx={{ pt:2, fontFamily: this.props.fontFamily, color: theme => this.props.slider.darkText ? theme.palette.common.black : theme.palette.common.white, fontWeight: 500, fontSize: "1.2rem" }}>
                                        {item.bottomText}
                                    </Typography>

                                }

                            </>
                            :
    
                            <Typography align="center" sx={{ fontFamily: this.props.fontFamily, color: theme => this.props.slider.darkText ? theme.palette.common.black : theme.palette.common.white, fontWeight: 500, fontSize: { xs: "1rem", sm: item.primaryText ? "1.2rem" : "1.4rem" } }}>
                                {item.secondaryText}
                            </Typography>
        



                        }
                        {item.songID &&
                            <Song dark={true} id={item.songID} compact noImage transparent />
                        }

                        {item.link &&
                            <Box sx={{ mt: 4, width: "100%" }}>
                                <a style={{ marginTop: "16px" }} href={item.link.href} target="_blank">
                                    <img src={item.link.image} style={{ maxHeight: "80px", width: "100%", height: "auto", objectFit: "contain", margin: "auto" }} />
                                </a>
                            </Box>
                        }
                    </Linkify>

                </Box>

            </div>

        )
    }
    content = () => {
        return (
            <Box
                sx={{
                    border: this.props.slider.border ?? undefined,
                    borderRadius: this.props.slider.size === "compact" ? "24px" : "36px",
                    backgroundColor: this.props.slider.background,
                    maxWidth: this.props.slider.size === "compact" ? "500px" : "800px",
                    mx: { sm: "auto" },
                    width:"-webkit-fill-available",
                }}
                className={this.props.slider.darkDots === true ? "darkDots" : undefined}
            >
                <Slider {...settings}>
                    {this.props.slider.items.map((item, index) => {
                        return this.sliderContent(item, index);
                    })}
                </Slider>
            </Box>
        )
    }
 

    render() {
        return this.content();
    }
}

