import React, { Component } from 'react';

//Custom Components
import { ContentTabsItemContainer } from './ContentTabsItemContainer';
import { ContentTabsRightTitle } from './ContentTabsRightTitle';
import { ContentLoader } from './ContentLoader';
import { ContentCard } from './ContentCard';
import { ListItemContainer } from '../Content/Lists/ListItemContainer';
import { ItemCreator } from './ItemCreator';
import { PhotoUploader } from '../Content/Photos/PhotoUploader';
import { VideoUploader } from '../Content/Videos/VideoUploader';
import { DocumentUploader } from '../Content/Documents/DocumentUploader';
import { capitalize,  } from '../../DataServices/Utility';
//Material UI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { Droppable, Draggable } from 'react-beautiful-dnd';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ minWidth: "130px" }}
            {...other}
        >
            {value === index && children}
        </div>
    );
}
const getItemStyle = (isDragging, draggableStyle, width) => ({
    userSelect: "none",
    ...draggableStyle,
    opacity: isDragging ? .5 : 1,
    display: "inline-block",
    marginLeft: "auto",
    marginRight: "auto",
    maxHeight: 360
});

const genericContent = (originalFolder, type) => {
    switch (type) {
        case 'documents':
            var folder = { id: originalFolder.id, name: originalFolder.name, loading: originalFolder.loading, items: [] };
            if (originalFolder.documents) {
                for (var j = 0; j < originalFolder.documents.length; j++) {  
                    let item = originalFolder.documents[j];
                    item.id = item.documentID;
                    item.name = item.documentName;
                    folder.items.push(item);
                }
            }
            return folder;
        case 'videos':
            var folder = { id: originalFolder.id, name: originalFolder.name, loading: originalFolder.loading, items: [] };
            if (originalFolder.videos) {
                for (var j = 0; j < originalFolder.videos.length; j++) {
                    let item = originalFolder.videos[j];
                    item.name = item.title;
                    folder.items.push(item);
                }
            }
            return folder;
        case 'photos':
            var folder = { id: originalFolder.id, name: originalFolder.name, loading: originalFolder.loading, items: []};
            if (originalFolder.photos) {
                for (var j = 0; j < originalFolder.photos.length; j++) {
                    let item = originalFolder.photos[j];
                    item.id = item.photoID;
                    item.name = item.photoName;
                    folder.items.push(item);
                }
            }
            return folder;
        case 'notes':
            var folder = { id: originalFolder.id, name: originalFolder.name, loading: originalFolder.loading, items: [] };
            if (originalFolder.notes) {
                for (var j = 0; j < originalFolder.notes.length; j++) {
                    let item = originalFolder.notes[j];
                    item.name = item.title;
                    folder.items.push(item);
                }
            }
            return folder;
        case 'links':
            var folder = { id: originalFolder.id, name: originalFolder.name, loading: originalFolder.loading, items: [] };
            if (originalFolder.links) {
                for (var j = 0; j < originalFolder.links.length; j++) {
                    let item = originalFolder.links[j];
                    item.name = item.title;
                    folder.items.push(item);
                }
            }
            return folder;
        default:
            return originalFolder;
    }
}
export class ContentTabPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewItem: false
        }
    }
    handleClickNewItem = (id) => {
        if (this.props.contentType === "lists") {
            return null;
        }
        this.setState({ folderID: id });
        if (this.props.contentType === "notes") {
            this.createNote(id);
        }
        else {
            this.setState({ showNewItem: true })
        }

    }
    createNote = async (folderID) => {
        const id = await this.props.functions.createItem("", folderID);

        if (id) {
            const folders = [...this.props.folders];
            let item = { id: id, title: "", noteContent: "", previewText: "", createDate: new Date(), modifyDate: new Date() };

            console.log(folders[this.props.index]);
            folders[this.props.index][this.props.contentType].push(item);
            this.props.updateData(this.props.contentType, folders);
        }
    }
    handleNewItem = async (values) => {
        if (values && values.length > 0) {
            this.setState({ showNewItem: false });
            const id = await this.props.functions.createItem(...values, this.state.folderID);
            if (id) {
                const folders = [...this.props.folders];
                let item = { id: id };
                for (var i = 0; i < this.props.itemFields.length; i++) {
                    item[this.props.itemFields[i].fieldName] = values[i];
                }
                folders[this.props.index][this.props.contentType].push(item);
                this.props.updateData(this.props.contentType, folders);
            }
        }
    }
    updateParent = (item) => {
        const folders = [...this.props.folders];
        folders[this.props.index][this.props.contentType].push(item);
        this.props.updateData(this.props.contentType, folders);
    }

    //Render functions
    droppableContainer = (folder) => {
        return (
            <Droppable key={"folder_drop" + this.props.index} droppableId={`${this.props.contentType}_droppable`} isDropDisabled>
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ width: "100%", display: "inherit", px: 1 }} >
                            {(folder.items && folder.items.length > 0) && folder.items.map((item, idx) => {
                                if (this.props.isUserFolder) {
                                    return (
                                        <Draggable key={item.id} draggableId={item.id} index={idx} >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, this.props.width)}
                                                >
                                                    {this.itemCard(item, idx, true, provided)}

                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                }
                                else {
                                    return (
                                        <Box sx={{ display: "inline-block", mx: "auto", maxHeight: 360 }}>
                                            {this.itemCard(item, idx, false)}
                                        </Box>
                                    )
                                }
                            })}
                        </Stack>
                    </div>
                )}
            </Droppable>
            )
    }
    listContainer = (folder) => {
        return (
            <ListItemContainer
                {...this.props}
                sub={false}
                list={folder}
                parentID={folder.id}
            />
        )
    }
    content = () => {
        const folder = genericContent(this.props.folder,this.props.contentType);
        return (
            <TabPanel key={folder.id + 'tabpanel'} style={{ display: "contents" }} value={this.props.tabValue} index={this.props.index + 1}>
                {folder.loading ?
                    <ContentLoader {...this.props} />
                    :
                    <Box sx={{ width: "100%", position: "relative" }}>
                        <ContentTabsRightTitle
                            {...this.props}
                            allowPublic={this.props.isUserFolder}
                            publicAttr={`${this.props.contentType}-${folder.id}`}
                            allowAdd={this.props.contentType !== "lists" && this.props.isUserFolder}
                            onClick={() => this.handleClickNewItem(folder.id)}
                            name={folder.name}
                            showName={this.props.contentType === "lists"}

                        />
                        <ContentTabsItemContainer>
                            {this.props.contentType !== "lists" ?
                                this.droppableContainer(folder)
                                :
                                this.listContainer(folder)
                            }

                        </ContentTabsItemContainer>
                    </Box>
                }
            </TabPanel>
        )
    }
    itemCard = (item, idx, showOption, provided) => {
        return (
            <ContentCard
                {...this.props }
                item={item}
                itemID={item.id}
                itemIDX={idx}
                itemName={item.name}
                allowEdit={showOption}
                dragOptions={showOption ? { drag: provided !== null, dragProvided: provided } : false}
            />

        )
    }
    itemCreator = () => {
        if (this.state.showNewItem) {
            switch (this.props.contentType) {
                case "photos":
                    return (
                        <PhotoUploader
                            {...this.props}
                            multi={true}
                            open={this.state.showNewItem}
                            library={this.props.folders[this.props.tabValue - 1]}
                            updateParent={this.updateParent}
                            handleClose={() => this.setState({ showNewItem: false })}
                        />
                    )
                case "videos":
                    return (
                        <VideoUploader
                            {...this.props}
                            multi={false}
                            open={this.state.showNewItem}
                            library={this.props.folders[this.props.tabValue - 1]}
                            updateParent={this.updateParent}
                            handleClose={() => this.setState({ showNewItem: false })}
                        />
                    )
                case "documents":
                    return (
                        <DocumentUploader
                            {...this.props}
                            multi={true}
                            open={this.state.showNewItem}
                            library={this.props.folders[this.props.tabValue - 1]}
                            handleClose={() => this.setState({ showNewItem: false })}
                            updateParent={this.updateParent}
                        />
                    )
                case "notes":
                    return null; //no ui, in case code inadvertently goes into this func
                default:
                    return (
                        <ItemCreator
                            {...this.props}
                            fields={this.props.itemFields}
                            show={this.state.showNewItem}
                            item={capitalize(this.props.contentType).slice(0, -1)}
                            handleClose={() => this.setState({ showNewItem: false })}
                            handleSave={this.handleNewItem}
                        />
                    )
            }

        }
        return null;
    }

    render() {
        return (
            <>
                {this.content()}
                {this.itemCreator()}
            </>
        )

    }
}
