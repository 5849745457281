import React, { PureComponent } from 'react';

//custom components
import { TextInput } from '../Utilities/TextInput';
import { Spin } from '../Utilities/Spin'
import { Reply } from './Reply';

//materialui
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

//data functions
import { getPhotoThumb } from '../../DataServices/PhotoService'
import { createNotification } from '../../DataServices/NotificationService'

export class Replies extends PureComponent {
  constructor(props) {
      super(props);
      this.state = {
          loadIndex: 0,
          loadCount: 10,
          comments: [],
          loading: false,
          loaded: false,
          showComments: false,
          avatarPhotos: [this.props.user.avatar && { userID: this.props.user.userID, avatar: this.props.user.avatar }],
      };
  }
  
    componentDidUpdate() {
        if (!this.state.loading && !this.state.loaded && this.state.showComments) {
            this.getReplies();
        }
    }
    getReplies = async () => {
        this.setState({ loading: true });
        const response = await fetch('comment/getchildcomments?commentid=' + this.props.targetID + "&index=" + this.state.loadIndex + "&count=" + this.state.loadCount);
        if (response.status === 200) {
            const data = await response.json();
            if (data && data.length > 0) {
                this.setState(
                    prevState => {
                        let state = { ...prevState };
                        if (state.comments === null) {
                            state.comments = [];
                        }
                        data.map((comment, index) => {
                            state.comments.push(comment);
                        });
                        return { state };
                    }
                );
                this.setState({ loading: false, loaded: true });
                this.loadAvatars();
            }
            else {
                this.setState({ loading: false, loaded: true });

            }
        }
    }
    loadAvatars = async () => {
        if (this.state.comments && this.state.comments.length > 0) {
            for (var i = 0; i < this.state.comments.length; i++) {
                let cuser = this.state.comments[i].user;
                if (!cuser.avatarB64) {
                    const euser = this.state.avatarPhotos.find(x => x.userID === cuser.userID);
                    if (euser) {
                        const eavatar = euser.avatar;
                        if (eavatar) {
                            const comments = this.state.comments;
                            comments[i].user.avatarB64 = eavatar;
                            this.setState({ comments });
                            this.forceUpdate();
                        }
                        else {
                            const avatar = await getPhotoThumb(cuser.userID);
                            const avatarPhotos = this.state.avatarPhotos;
                            avatarPhotos.push({ userID: euser.userID, avatar: avatar });
                            const comments = this.state.comments;
                            comments[i].user.avatarB64 = avatar;
                            this.setState({ comments, avatarPhotos });
                            this.forceUpdate();
                        }
                    }
                }
            }
        }
    }
    updateComments(comments) {
        this.setState({ comments: comments });
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handlePostComment();
        }
    }
    handlePostComment = async () =>  {
        const msg = document.getElementById("newReply" + this.props.targetID).value;
        if (msg) {
            document.getElementById("newReply" + this.props.targetID).value = "";
            const req = { TargetType: this.props.targetType, TargetID: this.props.targetID, CommentText: msg };
            //save data and get new id as response
            const response = await fetch('comment/addcomment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(req)
            })
            let user = this.props.user;
            if (user) {
                user.avatarB64 = user.avatar;
            }
            const itemToInsert = await response.json().then(function (text) {
                var newItem = {
                    id: text,
                    fromMe: true,
                    commentText: msg,
                    commentDate: new Date,
                    likeCount: 0,
                    loveCount: 0,
                    commentCount:0,
                    myReaction: -1,
                    whenPosted: "just now",
                    user: user ?? { firstName: "Me", lastName: "" }
                };
                return newItem;
            });

            const comments = [...this.state.comments];
            comments.push(itemToInsert);
            this.setState({ comments: comments });
            this.props.updateReplyCount(this.props.commentCount + 1);
            this.setState({ show: true });

            let message = this.props.user.displayName + " replied to your comment";
            if (this.props.refUser.userID === this.props.user.userID) {
                message = "You replied to your own comment";
            }
            await createNotification(this.props.refUser.userID, 3, this.state.targetID, this.props.user.userID, message, this.props.appIdentifier);
        }

    }
    handleEditComment = async (id, idx, commentText) => {
        this.setState(
            prevState => {
                let state = { ...prevState };
                state.comments[idx].commentText = commentText;
                return { state };
            }
        );
        const req = { CommentID: id, CommentText: commentText };
        await fetch('comment/editcomment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
    }
    handleDeleteComment = async (id, idx) => {
        const comments = [...this.state.comments];
        comments.splice(idx, 1);
        this.setState({ comments });
        if (this.state.comments.length < 1) {
            this.setState({ show: false });
        }
        this.props.updateReplyCount(this.props.commentCount - 1);
        const req = { CommentID: id };
        await fetch('comment/deletecomment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
    }

    //render funcs
    items = () => {
        return (
            <Stack>
                {this.state.comments.map((comment, index) => (
                    <Reply
                        key={comment.id}
                        dark={this.props.dark}
                        key={comment.id}
                        message={comment}
                        idx={index}
                        handleEdit={this.handleEditComment}
                        handleDelete={this.handleDeleteComment}
                        user={this.props.user}
                        appIdentifier={this.props.appIdentifier}
                    />
                ))}
            </Stack>
        )
    }
    composer = () => {
        return (
            <Box sx={{ maxWidth: "500px", width: "100%" }}>
                <TextInput
                    dark={this.props.dark}
                    cKey="CNew Reply"
                    cid="CnewReply"
                    id={"newReply" + this.props.targetID}
                    label="Reply"
                    defaultValue=""
                    onKeyPress={this.handleKeyPress}
                />
            </Box>
        )
    }
    commentButton = () => {
        let commentLinkLabel = "Replies";
        if (this.props.commentCount === 0) commentLinkLabel = "No Replies Yet";
        if (this.props.commentCount === 1) commentLinkLabel = "1 Reply";
        if (this.props.commentCount > 1) commentLinkLabel = this.props.commentCount + " Replies";
        return (
            <>
                <Button
                    sx={{ color: this.props.dark ? "#ccc" : "#444", fontWeight: 400, fontSize:".7rem" }}
                    onClick={() => this.setState({ showComments: !this.state.showComments })}
                >{commentLinkLabel}
                </Button>
                {this.state.loading &&
                    <Box sx={{ padding: "6px 6px", marginTop: "auto", marginBottom: "auto", marginTop: "4px" }}>
                        <Spin open={this.state.loading} sub={true} tiny={true} />
                    </Box>
                }
            </>
        )
    }
    replies = () => {
        return (
            <div style={{ marginRight: "-30px" }}>
                <Accordion sx={{ backgroundColor: "transparent", color: this.props.dark ? "#ccc" : "#444", boxShadow: "none", padding: "0px 0px", marginTop: "0px", marginBottom: "16px" }}>
                    <AccordionSummary
                        expandIcon={!this.props.noIcon && <ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ margin: "0px" }}
                        onClick={() => this.setState({ showComments: !this.state.showComments })}
                    >
                        {this.props.commentCount > 0 ? this.commentButton() : this.composer()}
                    </AccordionSummary>
                    <AccordionDetails>
                        {!this.state.loading &&
                            <>
                                {this.items()}
                                {this.props.commentCount > 0 && this.composer()}
                            </>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
            )
    }

    //main render func
    render() {
        return  this.replies();
    }
}
