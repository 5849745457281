import React, { Component } from 'react';
//custom components
import { PublicAttribute } from './PublicAttribute';
//material ui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';

export class ContentTabsRightTitle extends Component {

    //main component render
    render() {
        return (
            <Box
                display="flex"
                sx={{
                    p: 1,
                    py: { xs: 0.5, sm: 1 },
                    position: "fixed",
                    background: this.props.dark ? "rgba(17,17,17,.7)" : "rgba(242,243,247,.7)",
                    width: "-webkit-fill-available",
                    zIndex: 100
                }}>

                {this.props.allowPublic && <PublicAttribute {...this.props} attr={this.props.publicAttr} />}
                {this.props.showName &&          
                    <Typography underline sx={{pl:3, m:"auto", color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.black, fontSize: { xs: ".8rem", sm: "1rem" }, fontWeight: { xs: 600, sm: 600 } }}>
                        {this.props.name}
                    </Typography>
                }
                <Box sx={{ flexGrow: 1 }} />
                {(this.props.onClick && this.props.allowAdd) &&
                    <>
                        <Box display={{ xs: "none", sm: "block" }}>
                            <Fab
                                size="small"
                                color="secondary"
                                aria-label="newcontent"
                                onClick={this.props.onClick}
                                sx={{
                                    boxShadow: this.props.dark && "none",
                                    color: "white",
                                    zIndex: "1000"
                                }}
                            >
                                <AddIcon />
                            </Fab>
                        </Box>
                        <Box display={{ xs: "block", sm: "none" }}>
                            <IconButton
                                size="small"
                                key="newdoc"
                                color="secondary"
                                aria-label="newcontent"
                                onClick={this.props.onClick}
                                sx={{ boxShadow: this.props.dark && "none", }}
                            >
                                <AddIcon sx={{ color: this.props.dark && "white" }} />
                            </IconButton>
                        </Box>
                    </>
                }
            </Box>
        )
    }
}
