import React, { Component } from 'react';

//material ui
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
export class ContentTabs extends Component {

    horizontal = () => {
        return (     
            <Box sx={{ borderBottom: this.props.dark && "1px solid", background: theme => this.props.dark ? "#0d0d0d" : "white", boxShadow: this.props.dark ? 0 : 1, }} elevation={this.props.dark ? 0 : 1} >
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                indicatorColor="secondary"
                aria-label="content tabs"
                sx={{
                    minHeight: "36px",
                    maxWidth: "fit-content",

                }}
                value={this.props.tabValue}
                onChange={this.props.onChange}         
            >
                {this.props.children}
                </Tabs>
            </Box>
        )
    }

    vertical = () => {
        return (
            <Tabs
                indicatorColor={this.props.indicatorColor ?? "secondary"}
                value={this.props.tabValue}
                onChange={this.props.onChange}
                aria-label="content tabs"
                orientation="vertical"
                variant="scrollable"
                className="prettyScroll"
                sx={{
                    boxShadow: this.props.dark ? 0 : 8,
                    width: theme => theme.sizes.widths.contentTabLeftContainer,
                    height: theme => theme.sizes.heights.contentContainer,
                    borderRight: 1,
                    borderColor: 'divider',
                    background: this.props.dark ? "unset" : "#f9f9f9",
                }}
            >
                {this.props.children}
            </Tabs>
        )
    }
    //main component render
    render() {
        return this.horizontal();

    }
}
