import React, { Component } from 'react';
import Picker from 'emoji-picker-react';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';

export class EmojiPicker extends Component {
    constructor(props) {
        super(props);
    }

    onClick = (e, emoji) => {
        this.props.handleSelectEmoji(emoji);
    }

    render() {

        return (
            <Box className={this.props.dark ? "dark" : "light"}>
                <Picker
                    onEmojiClick={this.onClick}
                    pickerStyle={{color:this.props.dark ? "#ccc" : "#444"}}
                />
            </Box>

        );
    }
}
