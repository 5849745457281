import React, { PureComponent } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { removePublic, setPublic } from '../../DataServices/UserService';
import { removeFromArray } from '../../DataServices/Utility';

export class PublicAttribute extends PureComponent {

    //event handlers
    handleSetPublic = async (attr, val) => {
        if (val === true) {
            const profile = this.props.profile;
            profile.publicAttributes.push(attr);
            this.props.updateData("profile", profile);
            await setPublic(attr);
            this.forceUpdate();
        }
        else {
            const profile = this.props.profile;
            removeFromArray(profile.publicAttributes, attr);
            this.props.updateData("profile", profile);
            await removePublic(attr);
            this.forceUpdate();
        }
    }

    //render funcs
    content = () => {
        if (this.props.appIdentifier === "LS" && this.props.profile && this.props.profile.publicAttributes) {
            let val = false;
            if (this.props.profile.publicAttributes.find((x) => x === this.props.attr)) {
                val = true;
            }

            return (
                <Tooltip title="Check this box to allow this content to be seen by visitors of your public site" enterDelay={300}>
                    <FormControlLabel
                        key={this.props.attr + "ctl"}
                        sx={{
                            paddingLeft: "16px", marginTop: "auto", marginBottom: "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black
                        }}
                        control={<Switch edge="start" checked={val} onChange={(e) => this.handleSetPublic(this.props.attr, e.target.checked)} color="secondary" size="small" />}
                        label={
                            <Typography sx={{ fontSize: ".7rem" }}>
                                {val ? "Public" : "Private"}
                            </Typography>
                        }
                    />
                </Tooltip>
            )
        }
        return null;
    }

    //main component render
    render() {
        return this.content();
    }
}
