import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212121',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
    lighter: '#E2E2E2',
    light: '#505050',
    main: '#212121',
    dark: '#000000',
    darker: '#005249',
    contrastText: '#fff'
};
const SECONDARY = {
    lighter: '#97acd2',
    light: 'rgb(84, 100, 148)',
    main: '#32478C',
    main: '#384370',
    dark: '#00205e',
  darker: '#091A7A',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#4f83cc',
    main: '#64b5f6',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
    main: '#2c7216',
  dark: '#229A16',
  darker: '#08660D',
    contrastText: '#fff'
};
const WARNING = {
  lighter: '#FFF7CD',
    light: '#534bae',
    main: '#1a237e',
    dark: '#000051',
  darker: '#7A4F01',
    contrastText: '#fff'
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const palette = {
    maintoolbar: { dark: '#222', light: '#222' },
    maintoolbarHover: { dark: 'rgba(51, 71, 140, .7)', light: 'rgba(255,255,255,.9)' },
    subtoolbar: { dark: 'transparent', light: 'transparent' },
    subtoolbarHover: { dark: '#111', light: '#bbb'},
    subtoolbar2: { dark: '#0d0d0d', light: 'transparent' },
    backgroundMain: { dark: '#111', light: '#f2f3f7' },
    backgroundSecondary: { dark: '#050505', light: '#fff' },
    backgroundSecondaryAlt: { dark: '#080808', light: '#f3f5f7' },
    backgroundModal: { dark: '#222', light: 'rgba(255, 255, 255, 1)' },
    backgroundMenu: { dark: '#111', light: '#fff' },
    backgroundInput: { dark: '#101010', light: '#fff' },
    borderMenu: { dark: '1px solid #252525', light: 'none' },
    component: { dark: '#191919', light: '#f9f9f9' },
    component2: { dark: '#191919', light: '#ddd' },
    component3: { dark: '#151515', light: '#fafafa' },
    component4: { dark: '#202020', light: '#ddd' },
    component5: { dark: '#202020', light: '#f3f3f3' },
    component6: { dark: '#202020', light: '#eee' },
    adder: { dark: '#1a1a1a', light: '#f5f5f5' },
    titlebg: { dark: '#222', light: '#ddd' },
    docbg: { dark: '#222', light: '#fafafa' },
    item: { dark: '#151515', light: '#fff' },
    post: { dark: "#222", light: '#f9f9f9' }, 
    repost: { dark: "#2e2e2e", light: '#fff' },
    card: { dark: "#222", light: 'rgba(255,255,255,.6)' },
    note: { dark: '#333', light: '#fafafa' },
    doc: { dark: '#333', light: '#fafafa' },
    comment: { dark: '#353535', light: '#e5e5e5' },
    reply: { dark: '#333', light: '#d5d5d5' },
    boxBorder: { dark: "none", light:"solid 1px #ddd"},
    reactionContainer: { dark: 'transparent', light: 'transparent' },
    myMessage: { dark: '#0d47a1', light: '#ccc' },
    otherMessage: { dark: '#333', light: '#eee' },
    myMessageAlt: { dark: '#0d47a1', light: '#ccc' },
    otherMessageAlt: { dark: '#333', light: '#eee' },
    selectedItem: { dark: '#444', light: '#ccc' },

    emoji: { dark: 'orange', light: 'orange' },
    purple: { dark: '#6200ea', light: '#6200ea' }, 
    green: { dark: '#005005', light: '#2e7d32' },
    common: { black: '#000', white: '#eee', dimblack: '#444', dimwhite: '#bbb', dimmerblack: '#555', dimmerwhite: '#999', dimmestblack: '#777', dimmestwhite: '#666', disabled: '#999' },
    //photo: '#2962ff',
    //video: '#3949ab',
    //music: '#388e3c',
    //files: '#607d8b',
    //links: '#e64a19',
    //notes: '#fdd835',
    //lists: '#aa00ff',
    //market: '#009688',
    //contentHome: '#2196f3',
    photo: 'rgb(50,71,140)',
    video: 'rgb(50,71,140)',
    music: 'rgb(50,71,140)',
    files: 'rgb(50,71,140)',
    links: 'rgb(50,71,140)',
    notes: '#f9f9f9',
    lists: 'rgb(50,71,140)',
    market: 'rgb(50,71,140)',
    contentHome: 'rgb(50,71,140)',
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
    chart: CHART_COLORS,
  cardHover: GREY[100],
  divider: GREY[500_24],
    text: { primary: GREY[800], secondary: '#21558c', disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
  clear: 'transparent',
  action: {
  /*    active: GREY[600],*/
      active: '#21558c',
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default palette;
