import React, { Component} from 'react';

//Custom Components
import { Content } from '../Content/Content'
import { Posts } from '../Posts/Posts'

//Material UI
import Box from '@mui/material/Box';

//Database Services
import { getPosts, getNewPosts, getPostsByIdRange } from '../../DataServices/PostService'
import { getNotebooks } from '../../DataServices/NoteService'
import { getLists } from '../../DataServices/ListService'
import { getDocumentFolders } from '../../DataServices/DocumentService'
import { getLinkFolders } from '../../DataServices/LinkService'
import { getPhotoLibraries, getPhotoList, getPhotoThumb } from '../../DataServices/PhotoService'
import { getAlbums, getPlaylists, getArtists, getSongs, getGenres } from '../../DataServices/MusicService'
import { getUserBlogChannels } from '../../DataServices/BlogService'
import { getVideoLibraries } from '../../DataServices/VideoService'
import { getPage, getContactFormData, getPageUsers, getPageAdmins } from '../../DataServices/WebPageService'
import { getMyEvents, getMyPastEvents } from '../../DataServices/EventService'
import { getEnums } from '../../DataServices/Utility'
import { getPublicAttributes, getUserList, getFeedPreferences, setFeedPreferences } from '../../DataServices/UserService'
import { getMyStore } from '../../DataServices/MarketService'
import { getContentTypes } from '../../ObjectDefinitions/objectProperties'

const fixNotebookProps = (folders) => {
    let notebooks = [];
    if (folders && folders.length > 0) {
        for (var i = 0; i < folders.length; i++) {
            var item = folders[i];
            item.name = item.title;
            notebooks.push(item);
        }
    }
    return notebooks;
}
export class Home extends Component {

    constructor(props) {
        super(props);
        this.props.setToken.bind(this);
        this.state = {
            topic: "posts",
            contentTabPanelState: {main:0, notes: 0, lists: 0, documents: 0, photos: 0, blogs: 0, videos: 0, featuredContent: 0, events: 0, market: 0, links: 0, music: 1 },
            contentTabValue: 0,
            thoughts: { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: 0},
            photos: { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: 0 },
            videos: { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: 0 },
            songs: { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: 0 },
            events: { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: 0 },
            market: { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: 0 },
            blogs: { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: 0 },
            searchResults: { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false },
            blogChannels: [],
            userLists: [],
            music: { albums : [], playlists: [], songs: [], artists: [], loaded: false},
            notebooks: [],
            documentFolders: [],
            linkFolders: [],
            photoLibraries: [],
            videoLibraries: [],
            userevents: [],
            pageUsers: [],
            webPage: null,
            dailyNoteContent: null,
            contextMenuNotebook: null,
            postChannel: 0,
            fetchNewPostsInterval: 30000,
            autoFetchNew: true,
            contentUser: false,
            contentLoaded: false,
            dropDownOptions: [],
            selectedContentType: null,
            selectedContentProps: null,
            contentUser: true,
            pauseRefreshes: false,
            bottomMenuValue: 0,
            contentLoading: false,
            height: 0,
            width: 0,
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getData();
        this.getContentTypes();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.clearIntervals();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchTerm !== this.props.searchTerm) {
            this.clearIntervals();
            this.handleSearch();
        }
        if (this.props.topic === "posts" && this.state.topic !== "posts") {
            this.setState({ topic: "posts" });
        }
        else {
            if (this.props.topic === "content" && this.state.topic !== "content") {
                this.setState({ topic: "content" });
            }
        }
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    clearIntervals = () => {
        //clear autofetch interval if it exists
        if (this.intervalThoughts)
            clearInterval(this.intervalThoughts);
        if (this.intervalPhotos)
            clearInterval(this.intervalPhotos);
        if (this.intervalVideos)
            clearInterval(this.intervalVideos);
        if (this.intervalSongs)
            clearInterval(this.intervalSongs);
        if (this.intervalSongs)
            clearInterval(this.intervalSongs);
        if (this.intervalEvents)
            clearInterval(this.intervalEvents);
        if (this.intervalMarket)
            clearInterval(this.intervalMarket);
    }

    //Data Fetching
    getData = async () => {
        //Get posts first
        this.getInitialPostData(this.state.postChannel);
        this.getUserList();
        this.getFeedPrefs();
        this.getDropdownOptions();
    }
    getDropdownOptions = async () => {
        const data = await getEnums();
        this.setState({ dropDownOptions: data });

    }
    getUserList = async () => {
        const data = await getUserList();
        const userList = [];

        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                userList.push({ id: data[i].userID, display: data[i].firstName + ' ' + data[i].lastName });
            }
        }
        this.setState({ userList });
    }

    //Posts***************************
    changePostChannel = async (channel) => {
        await this.setState({
            postChannel: channel,
        });
        
        this.getInitialPostData(channel);
        this.forceUpdate();
    }
    getInitialPostData = async (channel) => {
        switch (channel) {
            case 0:
                if (this.state.thoughts.posts.length < 1) {
                    await this.getThoughtPosts();
                    //wait 1 minute then set auto-fetcher for new posts
                    if (this.state.autoFetchNew && !this.intervalThoughts) {
                        setTimeout(
                            this.intervalThoughts = setInterval(() => {
                                this.getNewThoughtPosts();
                            }, this.state.fetchNewPostsInterval),
                            60000);
                    }
                }
                break;
            case 2:
                if (this.state.photos.posts.length < 1) {
                    await this.getPhotoPosts();
                    //wait 1 minute then set auto-fetcher for new posts
                    if (this.state.autoFetchNew && !this.intervalPhotos) {
                        setTimeout(
                            this.intervalPhotos = setInterval(() => {
                                this.getNewPhotoPosts();
                            }, this.state.fetchNewPostsInterval),
                            60000);
                    }
                }
                break;
            case 3:
                if (this.state.videos.posts.length < 1) {
                    await this.getVideoPosts();
                    //wait 1 minute then set auto-fetcher for new posts
                    if (this.state.autoFetchNew && !this.intervalVideos) {
                        setTimeout(
                            this.intervalVideos= setInterval(() => {
                                this.getNewVideoPosts();
                            }, this.state.fetchNewPostsInterval),
                            60000);
                    }
                }
                break;
            case 5:
                if (this.state.songs.posts.length < 1) {
                    await this.getMusicPosts();
                    //wait 1 minute then set auto-fetcher for new posts
                    if (this.state.autoFetchNew && !this.intervalSongs) {
                        setTimeout(
                            this.intervalSongs = setInterval(() => {
                                this.getNewMusicPosts();
                            }, this.state.fetchNewPostsInterval),
                            60000);
                    }
                }
                break;
            case 8:
                if (this.state.events.posts.length < 1) {
                    await this.getEventPosts();
                    //wait 1 minute then set auto-fetcher for new posts
                    if (this.state.autoFetchNew && !this.intervalEvents) {
                        setTimeout(
                            this.intervalEvents = setInterval(() => {
                                this.getNewEventPosts();
                            }, this.state.fetchNewPostsInterval),
                            60000);
                    }
                }
                break;
            case 12:
                if (this.state.market.posts.length < 1) {
                    await this.getMarketPosts();
                    //wait 1 minute then set auto-fetcher for new posts
                    if (this.state.autoFetchNew && !this.intervalEvents) {
                        setTimeout(
                            this.intervalMarket = setInterval(() => {
                                this.getNewMarketPosts();
                            }, this.state.fetchNewPostsInterval),
                            60000);
                    }
                }
                break;
            default:

                break;
        }
    }
    getFeedPrefs= async () => {
        const feedPrefs = await getFeedPreferences(this.props.user.userID);
        if (feedPrefs) {
            this.updateData("feedPrefs", feedPrefs);
        }
        else {
            this.updateData("feedPrefs", { UserID: this.props.userID, politics: 2, profanity: true, adultContent: false });
        }

    }
    setFeedPrefs = async (feedPrefs) => {
        if (feedPrefs) {
            this.updateData("feedPrefs", feedPrefs);
            await setFeedPreferences(this.props.user.userID, feedPrefs.politics, feedPrefs.profanity, feedPrefs.adultContent);
        }
    }

    getThoughtPosts = async () => {
        if (!this.state.thoughts.refreshing) {
            const thoughts = this.state.thoughts;
            thoughts.refreshing = true;
            this.setState({ thoughts });
            const postInfo = await this.getPosts(0, this.state.thoughts.loadIndex, this.state.thoughts.loadCount, this.state.thoughts.filter);
            if (postInfo) {
                thoughts.posts = postInfo.posts;
                thoughts.loadIndex = postInfo.loadIndex;
                thoughts.newestPostID = postInfo.newestPostID;
            }
            thoughts.loading = false;
            thoughts.loaded = true;
            thoughts.refreshing = false;
            this.setState({ thoughts });
            this.forceUpdate();
        }
    }
    getPhotoPosts = async () => {
        if (!this.state.photos.refreshing) {
            const photos = this.state.photos;
            photos.refreshing = true;
            this.setState({ photos });
            const postInfo = await this.getPosts(2, this.state.photos.loadIndex, this.state.photos.loadCount, this.state.photos.filter);
            if (postInfo) {
                photos.posts = postInfo.posts;
                photos.loadIndex = postInfo.loadIndex;
                photos.newestPostID = postInfo.newestPostID;
            }
            photos.loading = false;
            photos.loaded = true;
            photos.refreshing = false;
            this.setState({ photos });
            this.forceUpdate();
        }
    }
    getVideoPosts = async () => {
        if (!this.state.videos.refreshing) {
            const videos = this.state.videos;
            videos.refreshing = true;
            this.setState({ videos });
            const postInfo = await this.getPosts(3, this.state.videos.loadIndex, this.state.videos.loadCount, this.state.videos.filter);
            if (postInfo) {
                videos.posts = postInfo.posts;
                videos.loadIndex = postInfo.loadIndex;
                videos.newestPostID = postInfo.newestPostID;
            }
            videos.loading = false;
            videos.loaded = true;
            videos.refreshing = false;
            this.setState({ videos });
            this.forceUpdate();
        }
    }
    getMusicPosts = async () => {
        if (!this.state.songs.refreshing) {
            const songs = this.state.songs;
            songs.refreshing = true;
            this.setState({ songs });
            const postInfo = await this.getPosts(5, this.state.songs.loadIndex, this.state.songs.loadCount, this.state.songs.filter);
            if (postInfo) {
                songs.posts = postInfo.posts;
                songs.loadIndex = postInfo.loadIndex;
                songs.newestPostID = postInfo.newestPostID;
            }
            songs.loading = false;
            songs.loaded = true;
            songs.refreshing = false;
            this.setState({ songs });
            this.forceUpdate();
        }
    }
    getEventPosts = async () => {
        if (!this.state.events.refreshing) {
            const events = this.state.events;
            events.refreshing = true;
            this.setState({ events });
            const postInfo = await this.getPosts(8, this.state.events.loadIndex, this.state.events.loadCount, this.state.events.filter);
            if (postInfo) {
                events.posts = postInfo.posts;
                events.loadIndex = postInfo.loadIndex;
                events.newestPostID = postInfo.newestPostID;
            }
            events.loading = false;
            events.loaded = true;
            events.refreshing = false;
            this.setState({ events });
            this.forceUpdate();
        }
    }
    getMarketPosts = async () => {
        if (!this.state.market.refreshing) {
            const market = this.state.market;
            market.refreshing = true;
            this.setState({ market });
            const postInfo = await this.getPosts(12, this.state.market.loadIndex, this.state.market.loadCount, this.state.market.filter);
            if (postInfo) {
                market.posts = postInfo.posts;
                market.loadIndex = postInfo.loadIndex;
                market.newestPostID = postInfo.newestPostID;
            }
            market.loading = false;
            market.loaded = true;
            market.refreshing = false;
            this.setState({ market });
            this.forceUpdate();
        }
    }

    getNewThoughtPosts = async () => {
        if (!this.state.thoughts.refreshing && !this.state.thoughts.loadingMore && !this.state.pauseRefreshes) {
            const thoughts = this.state.thoughts;
            thoughts.refreshing = true;
            thoughts.loadingMore= true;
            this.setState({ thoughts });
            const result = await this.getNewPosts(0, thoughts.newestPostID, this.props.appIdentifier, thoughts.filter);
            if (result) {
                if (result.posts && result.posts.length > 0) {
                    for (var i = 0; i < result.posts.length; i++) {
                        let id = result.posts[i].id;
                        for (var j = 0; j < thoughts.posts.length; j++) {
                            if (thoughts.posts[j].id === id) {
                                thoughts.posts.splice(j, 1);
                            }
                        }
                    }
                }
                thoughts.posts = [...result.posts, ...thoughts.posts];
                thoughts.loadIndex += result.loadIndex;
                thoughts.newestPostID = result.newestPostID;
            }
            thoughts.loading = false;
            thoughts.refreshing = false;
            thoughts.loadingMore = false;
            this.setState({ thoughts });
            this.forceUpdate();
        }
    }
    getNewVideoPosts = async () => {
        if (!this.state.videos.refreshing && !this.state.videos.loadingMore && !this.state.pauseRefreshes) {
            const videos = this.state.videos;
            videos.refreshing = true;
            videos.loadingMore = true;
            this.setState({ videos });
            const result = await this.getNewPosts(3, videos.newestPostID, this.props.appIdentifier, videos.filter);
            if (result) {
                if (result.posts && result.posts.length > 0) {
                    for (var i = 0; i < result.posts.length; i++) {
                        let id = result.posts[i].id;
                        for (var j = 0; j < videos.posts.length; j++) {
                            if (videos.posts[j].id === id) {
                                videos.posts.splice(j, 1);
                            }
                        }
                    }
                }
                videos.posts = [...result.posts, ...videos.posts];
                videos.loadIndex += result.loadIndex;
                videos.newestPostID = result.newestPostID;
            }
            videos.loading = false;
            videos.refreshing = false;
            videos.loadingMore = false;
            this.setState({ videos });
            this.forceUpdate();
        }
    }
    getNewPhotoPosts = async () => {
        if (!this.state.photos.refreshing && !this.state.photos.loadingMore && !this.state.pauseRefreshes) {
            const photos = this.state.photos;
            photos.refreshing = true;
            photos.loadingMore = true;
            this.setState({ photos });
            const result = await this.getNewPosts(2, photos.newestPostID, this.props.appIdentifier, photos.filter);
            if (result) {
                if (result.posts && result.posts.length > 0) {
                    for (var i = 0; i < result.posts.length; i++) {
                        let id = result.posts[i].id;
                        for (var j = 0; j < photos.posts.length; j++) {
                            if (photos.posts[j].id === id) {
                                photos.posts.splice(j, 1);
                            }
                        }
                    }
                }
                photos.posts = [...result.posts, ...photos.posts];
                photos.loadIndex += result.loadIndex;
                photos.newestPostID = result.newestPostID;
            }
            photos.loading = false;
            photos.refreshing = false;
            photos.loadingMore = false;

            this.setState({ photos });
            this.forceUpdate();
        }
    }
    getNewMusicPosts = async () => {
        if (!this.state.songs.refreshing && !this.state.songs.loadingMore && !this.state.pauseRefreshes) {
            const songs = this.state.songs;
            songs.refreshing = true;
            songs.loadingMore = true;
            this.setState({ songs });
            const result = await this.getNewPosts(5, songs.newestPostID, this.props.appIdentifier, songs.filter);
            if (result) {
                if (result.posts && result.posts.length > 0) {
                    for (var i = 0; i < result.posts.length; i++) {
                        let id = result.posts[i].id;
                        for (var j = 0; j < songs.posts.length; j++) {
                            if (songs.posts[j].id === id) {
                                songs.posts.splice(j, 1);
                            }
                        }
                    }
                }
                songs.posts = [...result.posts, ...songs.posts];
                songs.loadIndex += result.loadIndex;
                songs.newestPostID = result.newestPostID;
            }
            songs.loading = false;
            songs.refreshing = false;
            songs.loadingMore = false;
            this.setState({ songs });
            this.forceUpdate();
        }
    }
    getNewEventPosts = async () => {
        if (!this.state.events.refreshing && !this.state.events.loadingMore && !this.state.pauseRefreshes) {
            const events = this.state.events;
            events.refreshing = true;
            events.loadingMore = true;
            this.setState({ events });
            const result = await this.getNewPosts(8, events.newestPostID, this.props.appIdentifier, events.filter);
            if (result) {
                if (result.posts && result.posts.length > 0) {
                    for (var i = 0; i < result.posts.length; i++) {
                        let id = result.posts[i].id;
                        for (var j = 0; j < events.posts.length; j++) {
                            if (events.posts[j].id === id) {
                                events.posts.splice(j, 1);
                            }
                        }
                    }
                }
                events.posts = [...result.posts, ...events.posts];
                events.loadIndex += result.loadIndex;
                events.newestPostID = result.newestPostID;
            }
            events.loading = false;
            events.refreshing = false;
            events.loadingMore = false;
            this.setState({ events });
            this.forceUpdate();
        }
    }
    getNewMarketPosts = async () => {
        if (!this.state.market.refreshing && !this.state.market.loadingMore && !this.state.pauseRefreshes) {
            const market = this.state.market;
            market.refreshing = true;
            market.loadingMore = true;
            this.setState({ market });
            const result = await this.getNewPosts(12, market.newestPostID, this.props.appIdentifier, market.filter);
            if (result) {
                if (result.posts && result.posts.length > 0) {
                    for (var i = 0; i < result.posts.length; i++) {
                        let id = result.posts[i].id;
                        for (var j = 0; j < market.posts.length; j++) {
                            if (market.posts[j].id === id) {
                                market.posts.splice(j, 1);
                            }
                        }
                    }
                }
                market.posts = [...result.posts, ...market.posts];
                market.loadIndex += result.loadIndex;
                market.newestPostID = result.newestPostID;
            }
            market.loading = false;
            market.refreshing = false;
            market.loadingMore = false;
            this.setState({ market });
            this.forceUpdate();
        }
    }

    getMoreThoughtPosts = async () => {
        if (!this.state.thoughts.refreshing && !this.state.thoughts.loadingMore) {
            const thoughts = this.state.thoughts;
            thoughts.refreshing = true;
            thoughts.loadingMore = true;
            this.setState({ thoughts });
            const result = await this.getMorePosts(0, thoughts.loadIndex, thoughts.loadCount, thoughts.filter);
            if (result) {
                thoughts.posts = [...thoughts.posts,...result.posts ];
                thoughts.loadIndex += result.loadIndex;
            }
            thoughts.loading = false;
            thoughts.refreshing = false;
            thoughts.loadingMore = false;
            this.setState({ thoughts });
            this.forceUpdate();
        }
    }
    getMoreVideoPosts = async () => {
        if (!this.state.videos.refreshing && !this.state.videos.loadingMore) {
            const videos = this.state.videos;
            videos.refreshing = true;
            videos.loadingMore = true;
            this.setState({ videos });
            const result = await this.getMorePosts(3, videos.loadIndex, videos.loadCount, videos.filter);
            if (result) {
                videos.posts = [...videos.posts, ...result.posts];
                videos.loadIndex += result.loadIndex;
            }
            videos.loading = false;
            videos.refreshing = false;
            videos.loadingMore = false;
            this.setState({ videos });
            this.forceUpdate();
        }
    }
    getMorePhotoPosts = async () => {
        if (!this.state.photos.refreshing && !this.state.photos.loadingMore) {
            const photos = this.state.photos;
            photos.refreshing = true;
            photos.loadingMore = true;
            this.setState({ photos });
            const result = await this.getMorePosts(2, photos.loadIndex, photos.loadCount, photos.filter);
            if (result) {
                photos.posts = [...photos.posts, ...result.posts];
                photos.loadIndex += result.loadIndex;
            }
            photos.loading = false;
            photos.refreshing = false;
            photos.loadingMore = false;
            this.setState({ photos });
            this.forceUpdate();
        }
    }
    getMoreMusicPosts = async () => {
        if (!this.state.songs.refreshing && !this.state.songs.loadingMore) {
            const songs = this.state.songs;
            songs.refreshing = true;
            songs.loadingMore = true;
            this.setState({ songs });
            const result = await this.getMorePosts(5, songs.loadIndex, songs.loadCount, songs.filter);
            if (result) {
                songs.posts = [...songs.posts, ...result.posts];
                songs.loadIndex += result.loadIndex;
            }
            songs.loading = false;
            songs.refreshing = false;
            songs.loadingMore = false;
            this.setState({ songs });
            this.forceUpdate();
        }
    }
    getMoreEventPosts = async () => {
        if (!this.state.events.refreshing && !this.state.events.loadingMore) {
            const events = this.state.events;
            events.refreshing = true;
            events.loadingMore = true;
            this.setState({ events });
            const result = await this.getMorePosts(8, events.loadIndex, events.loadCount, events.filter);
            if (result) {
                events.posts = [...events.posts, ...result.posts];
                events.loadIndex += result.loadIndex;
            }
            events.loading = false;
            events.refreshing = false;
            events.loadingMore = false;
            this.setState({ events });
            this.forceUpdate();
        }
    }
    getMoreMarketPosts = async () => {
        if (!this.state.market.refreshing && !this.state.market.loadingMore) {
            const market = this.state.market;
            market.refreshing = true;
            market.loadingMore = true;
            this.setState({ market });
            const result = await this.getMorePosts(8, market.loadIndex, market.loadCount, market.filter);
            if (result) {
                market.posts = [...market.posts, ...result.posts];
                market.loadIndex += result.loadIndex;
            }
            market.loading = false;
            market.refreshing = false;
            market.loadingMore = false;
            this.setState({ market });
            this.forceUpdate();
        }
    }

    getPosts = async (channel, loadIndex, loadCount, filter) => {
        if (channel === 100) {
            this.handleSearch();
        }
        else {
            const data = await getPosts(loadIndex, loadCount, channel ?? -1, this.props.appIdentifier, filter); //fetch posts

            //if unauthorized, invoke callback to kill token and logout
            if (data === 401 || data === 400) {
                this.props.setToken('');
                return;
            }

            //if other error just set status to normal
            if (data === 'error') {
                return;
            }

            let newestPostID;
            if (data.posts && data.posts.length > 0) {
                newestPostID = data.posts[0].id;
            }
            const postInfo = { posts: data.posts, newestPostID: newestPostID, loadIndex: loadIndex + data.posts.length, loading: false, user: data.user, loaded: true, refreshing: false };
            return postInfo;
        }
    }
    getNewPosts = async(channel, newestPostID, appID, filter) => {

        const data = await getNewPosts(channel, newestPostID, this.props.appIdentifier, filter);

        //if unauthorized, invoke callback to kill token and logout
        if (data === 401) {
            this.props.setToken('');
            return;
        }

        //if other error just set status to normal
        if (data === 'error') {
            return null;
        }

        if (data && data.length > 0) {
            const newestPostID = data[0].id
            const postInfo = { posts: data, newestPostID: newestPostID, loadIndex:  data.length, loading: false,  loaded: true, refreshing: false, loadingMore: false };
            return postInfo;
        }
        else {
            return null;
        }
        
    }
    getMorePosts = async (channel, loadIndex, loadCount, filter) => {

        const data = await getPosts(loadIndex, loadCount, channel, this.props.appIdentifier, filter);

        //if unauthorized, invoke callback to kill token and logout
        if (data === 401) {
            this.props.setToken('');
            return;
        }

        //if other error just set status to normal
        if (data === 'error') {
            return null;
        }

        if (data && data.posts && data.posts.length > 0) {
            const postInfo = { posts: data.posts, loadIndex: data.posts.length, loading: false, loaded: true, refreshing: false, loadingMore: false };
            return postInfo;
        }
        else {
            return null;
        }
    }

    updatePostContent = async(channel,  idx, contentIdx, content) => {
        if (this.state.topic === "posts") {
            if (content && content) {
                if (content.type == 4) {
                    content = await this.loadBlogSpecificContent(content);
                }
                if (content.type == 8) {
                    content = await this.loadEventSpecificContent(content);
                }
                if (content.type == 12) {
                    content = await this.loadMarketSpecificContent(content);
                }
                if (idx >= 0) {
                    switch (channel) {
                        case 0:
                            const thoughts = this.state.thoughts;
                            if (thoughts.posts[idx] && thoughts.posts[idx].content)
                                if (content) {
                                    thoughts.posts[idx].content[contentIdx] = content;
                                }
                                else {
                                    thoughts.posts.splice(idx, 1);
                                }

                            this.setState({ thoughts });
                            break;
                        case 2:
                            const photos = this.state.photos;
                            if (photos.posts[idx] && photos.posts[idx].content)
                                if (content) {
                                    photos.posts[idx].content[contentIdx] = content;
                                }
                                else {
                                    photos.posts.splice(idx, 1);
                                }

                            this.setState({ photos });
                            break;
                        case 3:
                            const videos = this.state.videos;
                            if (videos.posts[idx] && videos.posts[idx].content)
                                if (content) {
                                    videos.posts[idx].content[contentIdx] = content;
                                }
                                else {
                                    videos.posts.splice(idx, 1);
                                }

                            this.setState({ videos });
                            break;
                        case 5:
                            const songs = this.state.songs;
                            if (songs.posts[idx] && songs.posts[idx].content)
                                if (content) {
                                    songs.posts[idx].content[contentIdx] = content;
                                }
                                else {
                                    songs.posts.splice(idx, 1);
                                }

                            this.setState({ songs });
                            break;
                        case 8:
                            const events = this.state.events;
                            if (events.posts[idx] && events.posts[idx].content)
                                if (content) {
                                    events.posts[idx].content[contentIdx] = content;
                                }
                                else {
                                    events.posts.splice(idx, 1);
                                }

                            this.setState({ events });
                            break;
                        case 12:
                            const market = this.state.market;
                            if (market.posts[idx] && market.posts[idx].content)
                                if (content) {
                                    market.posts[idx].content[contentIdx] = content;
                                }
                                else {
                                    market.posts.splice(idx, 1);
                                }

                            this.setState({ market });
                            break;
                        case 100:
                            const searchResults = this.state.searchResults;
                            if (searchResults.posts[idx] && searchResults.posts[idx].content)
                                if (content) {
                                    searchResults.posts[idx].content[contentIdx] = content;
                                }
                                else {
                                    searchResults.posts.splice(idx, 1);
                                }

                            this.setState({ searchResults });
                            break;
                        default:
                            break;
                    }
                    this.forceUpdate();
                }
                else {

                    let post = this.props.selectedPost;
                    if (post) {
                        post.content[contentIdx] = content;
                        this.props.setSelectedPost(post);
                    }


                }

            }
        }
    }
    updateRepostContent = async (postIdx, postContentIdx, contentIdx, content) => {
        if (this.state.topic === "posts") {
            if (content && content) {
                if (content.type == 4) {
                    content = await this.loadBlogSpecificContent(content);
                }
                if (content.type == 8) {
                    content = await this.loadEventSpecificContent(content);
                }
                const posts = [...this.state.posts];
                if (posts[postIdx] && posts[postIdx].content && posts[postIdx].content.length > 0 && posts[postIdx].content[postContentIdx].post && posts[postIdx].content[postContentIdx].post.content)
                    if (content) {
                        posts[postIdx].content[postContentIdx].post.content[contentIdx] = content;
                    }
                    else {
                        posts[postIdx].content[postContentIdx].post.content[contentIdx].splice(contentIdx, 1);
                    }

                this.setState({ posts });

            }
        }
    }

    loadBlogSpecificContent = async (content) => {
        //get header image
        if (content.blog && content.blog.header && content.blog.header.headerImage && content.blog.header.headerImage.photoID) {
            const hthumb = await getPhotoThumb(content.blog.header.headerImage.photoID);
            if (hthumb) {
                content.blog.header.headerImage.thumb = hthumb;
            }
        }
        //get cover image
        if (content.blog && content.blog.header && content.blog.header.coverImage && content.blog.header.coverImage.photoID) {
            const cthumb = await getPhotoThumb(content.blog.header.coverImage.photoID);
            if (cthumb) {
                content.blog.header.coverImage.thumb = cthumb;
            }
        }
        //get author image
        if (content.blog && content.blog.header && content.blog.header.authorImage && content.blog.header.authorImage.photoID) {
            const athumb = await getPhotoThumb(content.blog.header.authorImage.photoID);
            if (athumb) {
                content.blog.header.authorImage.thumb = athumb;
            }
        }
        //load other content
        if (content.blog && content.blog.bodyRows && content.blog.bodyRows.length > 0) {
            for (var i = 0; i < content.blog.bodyRows.length; i++) {
                let row = content.blog.bodyRows[i];
                if (row.rowContent && row.rowContent.length > 0) {
                    for (var j = 0; j < row.rowContent.length; j++) {
                        let rcontent = row.rowContent[j];
                        if (rcontent.contentType == 1 && rcontent.photo && rcontent.photo.photoID) {
                            const contentThumb = await getPhotoThumb(rcontent.photo.photoID);
                            if (contentThumb) {
                                content.blog.bodyRows[i].rowContent[j].photo.thumb = contentThumb;
                            }

                        }
                    }
                }
            }
        }
        return content;
    }
    loadEventSpecificContent = async (content) => {
        //get header image
        if (content.event && content.event.coverPhoto && content.event.coverPhoto.photoID) {
            const hthumb = await getPhotoThumb(content.event.coverPhoto.photoID);
            if (hthumb) {
                content.event.coverPhoto.thumb = hthumb;
            }
        }

        //load other content
        if (content.event && content.event.bodyRows && content.event.bodyRows.length > 0) {
            for (var i = 0; i < content.event.bodyRows.length; i++) {
                let row = content.event.bodyRows[i];
                if (row.rowContent && row.rowContent.length > 0) {
                    for (var j = 0; j < row.rowContent.length; j++) {
                        let rcontent = row.rowContent[j];
                        if (rcontent.contentType == 1 && rcontent.photo && rcontent.photo.photoID) {
                            const contentThumb = await getPhotoThumb(rcontent.photo.photoID);
                            if (contentThumb) {
                                content.event.bodyRows[i].rowContent[j].photo.thumb = contentThumb;
                            }

                        }
                    }
                }
            }
        }
        return content;
    }
    loadMarketSpecificContent = async (content) => {
        //get header image
        if (content.marketItem && content.marketItem.primaryPhoto && content.marketItem.primaryPhoto.photoID) {
            const hthumb = await getPhotoThumb(content.marketItem.primaryPhoto.photoID);
            if (hthumb) {
                content.marketItem.primaryPhoto.thumb = hthumb;
            }
        }

        //load other content
        if (content.marketItem && content.marketItem.photos && content.marketItem.photos.length > 0) {
            for (var i = 0; i < content.marketItem.photos.length; i++) {
                if (content.marketItem.photos[i].photo && content.marketItem.photos[i].photo.photoID) {
                    const contentThumb = await getPhotoThumb(content.marketItem.photos[i].photo.photoID);
                    if (contentThumb) {
                        content.marketItem.photos[i].photo.thumb = contentThumb;
                    }
                }
            }
        }
        return content;
    }
    updatePosts = (channel, post, idx) => {
        switch (channel) {
            case 0:
                const thoughts = this.state.thoughts;
                thoughts.posts[idx] = post;
                this.setState({ thoughts });
                break;
            case 2:
                const photos = this.state.photos;
                photos.posts[idx] = post;
                this.setState({ photos });
                break;
            case 3:
                const videos = this.state.videos;
                videos.posts[idx] = post;
                this.setState({ videos });
                break;
            case 5:
                const songs = this.state.songs;
                songs.posts[idx] = post;
                this.setState({ songs });
                break;
            case 8:
                const events = this.state.events;
                events.posts[idx] = post;
                this.setState({ events });
                break;
            default:
                break;
        }
        this.forceUpdate();
    }
    updateLastPost = (id, channel) => {
        switch (channel) {
            case 0:
                const thoughts = this.state.thoughts;
                thoughts.newestPostID = id;
                this.setState({ thoughts });
                break;
            case 2:
                const photos = this.state.photos;
                photos.newestPostID = id;
                this.setState({ photos });
                break;
            case 3:
                const videos = this.state.videos;
                videos.newestPostID = id;
                this.setState({ videos });
                break;
            case 5:
                const songs = this.state.songs;
                songs.newestPostID = id;
                this.setState({ songs });
                break;
            case 8:
                const events = this.state.events;
                events.newestPostID = id;
                this.setState({ events });
                break;
            default:
                break;
        }
    }

    //Search 
    getSearchPosts = async (ids) => {
         //fetch posts
        const data = await getPostsByIdRange(ids, 0, this.state.searchResults.loadCount);

        //if unauthorized, invoke callback to kill token and logout
        if (data === 401 || data === 400) {
            this.props.setToken('');
            return;
        }

        //if other error just set status to normal
        if (data === 'error' || data === null) {
            return;
        }
        //console.log(data);
        let newestPostID;
        if (data.posts && data.posts.length > 0) {
            newestPostID = data.posts[0].id;
        }
        const postInfo = { posts: data.posts, newestPostID: newestPostID, loadIndex: this.state.searchResults.loadIndex + data.posts.length, loading: false, user: data.user, loaded: true, refreshing: false };
        return postInfo;

    }
    getMoreSearchPosts = async () => {
        if (!this.state.refreshing && !this.state.loadingMore) {

            this.setState({ refreshing: true, loadingMore: true });
            const data = await getPostsByIdRange(this.state.searchResultIDs, this.state.searchResults.loadIndex, this.state.searchResults.loadCount); //fetch posts

            //if unauthorized, invoke callback to kill token and logout
            if (data === 401 || data === 400) {
                this.props.setToken('');
                return;
            }

            //if other error just set status to normal
            if (data === 'error') {
                return;
            }

            let newestPostID;
            if (data.posts && data.posts.length > 0) {
                newestPostID = data.posts[0].id;
            }
            const postInfo = { posts: data.posts, newestPostID: newestPostID, loadIndex: this.state.searchResults.loadIndex + data.posts.length, loading: false, user: data.user, loaded: true, refreshing: false };
            return postInfo;
        }
    }
    handleSearch = async () => {
        this.setState({ postChannel: 100, searching: true, showSearch: true });
        const term = this.props.searchTerm;
        if (term && term.length > 0) {
            const req = { Tags: [term], AppID: this.props.appIdentifier };
            const response = await fetch('search/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(req)
            });

            if (response.status === 200) {
                const data = await response.json();
                let ids = [];
                let results = [];
                if (data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        ids.push(data[i].itemID);
                        results.push(data[i]);
                    }
                }

                this.setState({ topic:"posts", searchResultIDs: ids });
                const searchResults = this.state.searchResults;
                const postInfo = await this.getSearchPosts(ids);
                if (postInfo) {
                    searchResults.posts = postInfo.posts;
                    searchResults.loadIndex = postInfo.loadIndex;
                    searchResults.newestPostID = postInfo.newestPostID;
                }
                searchResults.loading = false;
                searchResults.loaded = true;
                searchResults.refreshing = false;
                this.setState({ searchResults, postChannel: 100, showSearch: true, searching: false, hasResults: searchResults.posts.length > 0 });
                this.forceUpdate();
            }
            else {
                console.log(response);
            }
        }
    }
    handleSearchMore = async () => {
        if (!this.state.searchResults.refreshing && !this.state.searchResults.loadingMore) {
            const searchResults = this.state.searchResults;
            searchResults.refreshing = true;
            searchResults.loadingMore = true;
            this.setState({ searchResults });

            const result = await this.getMoreSearchPosts();
            if (result) {
                searchResults.posts = [...searchResults.posts, ...result.posts];
                searchResults.loadIndex += result.loadIndex;
            }

            searchResults.loading = false;
            searchResults.loaded = true;
            searchResults.refreshing = false;
            this.setState({ searchResults, postChannel: 100, showSearch: true, searching: false, hasResults: searchResults.posts.length > 0 });
            this.forceUpdate();
        }
  
    }

    //Content
    getContentTypes = () => {
        const contentTypes = getContentTypes();
        if (contentTypes) {
            this.setState({ contentTypes });
        }
    }
    getContent = (tabIndex) => {
        switch (tabIndex) {
            case 1:
                this.getPhotoLibraries();
                break;
            case 4:
                this.getVideoLibraries();
                break;
            case 15:
                this.getMusic();
                break;
            case 0:
                this.getDocuments();
                break;
            case 14:
                this.getLinks();
                break;
            case 2:
                this.getNotebooks();
                break
            case 3:
                this.getLists();
                break;
            case 13:
                this.getMarket();
                break;
            case 6:
                this.getEvents();
                break;
            case 5:
                this.getBlogChannels();
                break;
            default:
                break;
        }
        this.getPublicAttributes();

    }
    getEvents = async () => {
        const events = [];

        const future = await this.getFutureEvents();
        if (future) {
            events.push({ id: 'futureevents', name: 'Upcoming Events', events: future });
        }
        else {
            events.push({ id: 'futureevents', name: 'Upcoming Events', events: [] });
        }

        const past = await this.getPastEvents();
        if (past) {
            events.push({ id: 'pastevents', name: 'Past Events', events: past });
        }
        else {
            events.push({ id: 'pastevents', name: 'Past Events', events: [] });
        }

        let contentTabPanelState = this.state.contentTabPanelState;
        contentTabPanelState.events = 1;
        this.setState({ contentTabPanelState: contentTabPanelState })

        for (var i = 0; i < events.length; i++) {
            for (var j = 0; j < events[i].events.length; j++) {
                if (events[i].events[j].coverPhoto && events[i].events[j].coverPhoto.photoID) {
                    const thumb = await getPhotoThumb(events[i].events[j].coverPhoto.photoID);
                    if (thumb) {
                        events[i].events[j].coverPhoto.thumb = thumb;
                    }
                }

            }
        }
        this.updateData("events", events);
        
    }
    getPastEvents = async () => {
        const data = await getMyPastEvents(this.props.appIdentifier);
        if (data === 'error') {
            return;
        }
        return data;
    }
    getFutureEvents = async () => {
        const data = await getMyEvents(this.props.appIdentifier);
        if (data === 'error') {
            return;
        }
        return data;
    }
    getNotebooks = async () => {

        if (this.state.notebooks.length < 1) {
            this.setState({ contentLoading: true });
            const data = await getNotebooks();
            if (data === 'error') {
                return;
            }
            if (data) {
                if (data.length > 0) {
                    let contentTabPanelState = this.state.contentTabPanelState;
                    contentTabPanelState.notes = 1;
                    this.setState({ contentTabPanelState: contentTabPanelState })
                }
                this.updateData("notes", data);
            }
            this.setState({ contentLoading: false });
        }

    }
    getLists = async () => {
        if (this.state.userLists.length < 1) {
            const data = await getLists();
            if (data === 'error') {
                return;
            }

            if (data) {
                if (data && data.lists && data.lists.length > 0) {
                    let contentTabPanelState = this.state.contentTabPanelState;
                    contentTabPanelState.lists = 1;
                    this.setState({ contentTabPanelState: contentTabPanelState })
                }
                this.updateData("lists", data.lists);
            }
        }
    }
    getDocuments = async () => {
        if (this.state.documentFolders.length < 1) {
            const parentID = this.props.parentID ?? "";
            const data = await getDocumentFolders(parentID, this.props.appIdentifier);
            if (data === 'error') {
                return;
            }
            if (data) {
                if (data.length > 0) {
                    let contentTabPanelState = this.state.contentTabPanelState;
                    contentTabPanelState.documents = 1;
                    this.setState({ contentTabPanelState: contentTabPanelState })
                }
                this.updateData("documents", data);
            }
        }
    }
    getLinks = async () => {
        if (this.state.linkFolders.length < 1) {
            this.setState({ contentLoading: true });
            const data = await getLinkFolders("", this.props.appIdentifier);
            if (data === 'error') {
                return;
            }
            if (data) {
                if (data.length > 0) {
                    let contentTabPanelState = this.state.contentTabPanelState;
                    contentTabPanelState.links = 1;
                    this.setState({ contentTabPanelState: contentTabPanelState })
                }
                this.updateData("links", data);
            }
            this.setState({ contentLoading: false });
        }
    }
    getMusic = async () => {
        if (!this.state.music.loaded) {
            const artists = await getArtists();
            const songs = await getSongs();
            const albums = await getAlbums();
            const playlists = await getPlaylists();
            const genres = await getGenres();

            const music = { loaded: true, artists: artists ? artists : [], songs: songs ? songs : [], albums: albums ? albums : [], playlists: playlists ? playlists : [], genres: genres ? this.convertGenres(genres) : [] }
            this.updateData("music", music);

            if (artists && artists.length > 0) {
                for (var i = 0; i < artists.length; i++) {
                    const photo = await getPhotoThumb(artists[i].image.photoID);
                    if (photo) {
                        artists[i].image.thumb = photo;
                        music.artists = artists;
                        this.updateData("music", music);
                        this.forceUpdate();
                    }
                }

            }
            if (songs && songs.length > 0) {
                for (var i = 0; i < songs.length; i++) {
                    const photo = await getPhotoThumb(songs[i].image.photoID);
                    if (photo) {
                        songs[i].image.thumb = photo;
                        music.songs = songs;
                        this.updateData("music", music);
                        this.forceUpdate();
                    }
                }

            }
            if (playlists && playlists.length > 0) {
                for (var i = 0; i < playlists.length; i++) {
                    const photo = await getPhotoThumb(playlists[i].image.photoID);
                    if (photo) {
                        playlists[i].image.thumb = photo;
                        music.playlists = playlists;
                        this.updateData("music", music);
                        this.forceUpdate();
                    }
                }

            }
            if (albums && albums.length > 0) {
                for (var i = 0; i < albums.length; i++) {
                    const photo = await getPhotoThumb(albums[i].image.photoID);
                    if (photo) {
                        albums[i].image.thumb = photo;
                        music.albums = albums;
                        this.updateData("music", music);
                        this.forceUpdate();
                    }
                }

            }
        }

    }
    convertGenres = (g) => {
        var genres = [];
        if (g && g.length > 0) {
            g.map((item, index) => {
                genres.push({ value: index, label: item.name, id: item.id });
            });
        }
        return genres;
    }
    getPhotoLibraries = async () => {
        if (this.state.photoLibraries.length < 1) {
            const data = await getPhotoLibraries("", this.props.appIdentifier);
            if (data === 'error') {
                this.props.setToken('');
                return;
            }
            if (data) {
                if (data.length > 0) {
                    let contentTabPanelState = this.state.contentTabPanelState;
                    contentTabPanelState.photos = 1;
                    this.setState({ contentTabPanelState: contentTabPanelState })
                }
                this.updateData("photos", data);
                if (data.length > 0) { 
                    const photoLibraries = data;
                    photoLibraries[0].loading = true;
                    this.updateData("photos", photoLibraries);
                    const photos = await getPhotoList(photoLibraries[0].id, this.props.appIdentifier);
                    if (photos) {
                        photoLibraries[0].photos = photos;
                        photoLibraries[0].loading = false;
                        this.updateData("photos", photoLibraries);
                    }
                    else {
                        photoLibraries[0].loading = false;
                        this.updateData("photos", photoLibraries);
                    }
                    this.forceUpdate();
                }
            }
        }

    }
    getVideoLibraries = async () => {
        if (this.state.videoLibraries.length < 1) {
            this.setState({ contentLoading: true });
            const data = await getVideoLibraries(this.props.appIdentifier);
            if (data === 'error') {
                this.props.setToken('');
                return;
            }
            if (data) {
                if (data.length > 0) {
                    let contentTabPanelState = this.state.contentTabPanelState;
                    contentTabPanelState.videos = 1;
                    this.setState({ contentTabPanelState: contentTabPanelState })
                }
                this.updateData("videos", data);
            }
            this.setState({ contentLoading: false });
        }

    }
    getPhotos = async (libIdx) => {
        if (this.state.photoLibraries && this.state.photoLibraries.length >= libIdx + 1) {

            const photoLibraries = [...this.state.photoLibraries];
            photoLibraries[libIdx].loading = true;
            this.updateData("photos", photoLibraries);

            const photos = await getPhotoList(this.state.photoLibraries[libIdx].id, this.props.appIdentifier);

            if (photos) {
                photoLibraries[libIdx].photos = photos;
                photoLibraries[libIdx].loading = false;
                this.updateData("photos", photoLibraries);
            }
            else {
                photoLibraries[libIdx].loading = false;
                this.updateData("photos", photoLibraries);
            }
            this.forceUpdate();
        }
    }
    getPublicAttributes = async () => {
        const data = await getPublicAttributes();
        if (data === 'error') {
            return;
        }
        if (data) {
            const profile = { publicAttributes: data };
            this.setState({ profile });

        }
    }
    //getBlogChannels = async () => {
    //    if (!this.props.blogChannels) {
    //        const data = await getUserBlogChannels(this.props.appIdentifier);
    //        if (data === 'error') {
    //            return;
    //        }
    //        if (data) {
    //            if (data.length > 0) {
    //                let contentTabPanelState = this.state.contentTabPanelState;
    //                contentTabPanelState.blogs = 1;
    //                this.setState({ contentTabPanelState: contentTabPanelState })
    //            }
    //            this.updateData("blogs", data);
    //        }

    //        if (data && data.length > 0) {
    //            const channels = [...data];
    //            for (var i = 0; i < data.length; i++) {
    //                if (data[i].blogs && channels[i].blogs.length > 0) {
    //                    for (var j = 0; j < channels[i].blogs.length; j++) {
    //                        let blog = channels[i].blogs[j];
    //                        if (blog.header && blog.header.coverImage && blog.header.coverImage.photoID) {
    //                            const thumb = await getPhotoThumb(blog.header.coverImage.photoID);
    //                            if (thumb) {
    //                                blog.header.coverImage.thumb = thumb;
    //                                channels[i].blogs[j] = blog;
    //                                this.updateData("blogs", channels);
    //                            }
    //                        }
    //                        //Load author image if exists
    //                        if (blog.header && blog.header.authorImage && blog.header.authorImage.photoID) {
    //                            const athumb = await getPhotoThumb(blog.header.authorImage.photoID);
    //                            if (athumb) {
    //                                blog.header.authorImage.thumb = athumb;
    //                                channels[i].blogs[j] = blog;
    //                                this.updateData("blogs", channels);
    //                            }
    //                        }
    //                    }
    //                }
    //            }
    //        }

    //    }
    //}
    //getWebPage = async () => {
    //    const data = await getPage(this.props.webPageID);
    //    if (data === 'error') {
    //        this.props.setToken('');
    //        return;
    //    }
    //    if (data) {
    //        this.updateData("webPage", data);
    //    }
    //}
    //getMarket = async () => {
    //    const data = await getMyStore();
    //    if (data === 'error') {
    //        //this.props.setToken('');
    //        return;
    //    }
    //    if (data) {
    //        let contentTabPanelState = this.state.contentTabPanelState;
    //        contentTabPanelState.market = 1;
    //        this.setState({ contentTabPanelState: contentTabPanelState })
    //        this.updateData("usermarket", data);

    //        if (data.items && data.items.length > 0) {
    //            for (var i = 0; i < data.items.length; i++) {
    //                if (data.items[i].primaryPhoto && data.items[i].primaryPhoto.photoID && !data.items[i].primaryPhoto.thumb) {
    //                    const thumb = await getPhotoThumb(data.items[i].primaryPhoto.photoID);
    //                    if (thumb) {
    //                        data.items[i].primaryPhoto.thumb = thumb;
    //                        this.updateData("usermarket", data);
    //                    }
    //                }
    //            }
    //        }
    //        if (data.services && data.services.length > 0) {
    //            for (var i = 0; i < data.services.length; i++) {
    //                if (data.services[i].primaryPhoto && data.services[i].primaryPhoto.photoID && !data.services[i].primaryPhoto.thumb) {
    //                    const thumb = await getPhotoThumb(data.services[i].primaryPhoto.photoID);
    //                    if (thumb) {
    //                        data.services[i].primaryPhoto.thumb = thumb;
    //                        this.updateData("usermarket", data);
    //                    }
    //                }
    //            }
    //        }
    //    }
    //}
    getContactFormData = async () => {
        const data = await getContactFormData(this.props.webPageID);
        if (data === 'error') {
            //this.props.setToken('');
            return;
        }
        if (data) {
            this.updateData("contactFormData", data);
        }
    }
    getAllPageUsers = async () => {
        const users = [];

        const all = await this.getPageUsers();
        if (all) {
            users.push({ id: 'allusers', name: 'All Users', users: all });
        }
        else {
            users.push({ id: 'allusers', name: 'All Users', users: [] });
        }

        const admins = await this.getPageAdmins();
        if (admins) {
            users.push({ id: 'adminusers', name: 'Admin Users', users: admins });
        }
        else {
            users.push({ id: 'adminusers', name: 'Admin Users', users: [] });
        }
        let contentTabPanelState = this.state.contentTabPanelState;
        contentTabPanelState.pageUsers = 1;
        this.setState({ contentTabPanelState: contentTabPanelState })

        this.updateData("pageUsers", users);
    }
    getPageUsers = async () => {
        const data = await getPageUsers(this.props.webPageID);
        if (data === 'error') {
            //this.props.setToken('');
            return;
        }
        if (data) {
            return data;
        }
    }
    getPageAdmins = async () => {
        const data = await getPageAdmins(this.props.webPageID);
        if (data === 'error') {
            //this.props.setToken('');
            return;
        }
        if (data) {
            return data;
        }
    }

    //Callbacks
    updateData = (type, data) => {
         //Callback function for updating lists mutated by child components
        switch (type) {
            case "feedPrefs":
                if (data) {
                    this.setState({
                        feedPrefs: data,
                    });
                }
                break;
            case "blogs":
                if (data) {
                    this.setState({
                        blogChannels: data,
                    });
                }
                break;
            case "lists":
                if (data) {
                    this.setState({
                        lists: data,
                    });
                }
                break;
            case "notes":
                if (data) {
                    this.setState({
                        notebooks: data
                    });
                }
                break;
            case "docs":
            case "documents":
                if (data) {
                    this.setState({ documentFolders: data });
                }
                break;
            case "photos":
                if (data) {
                    this.setState({ photoLibraries: data });
                }
                break;
            case "thoughtposts":
                if (data) {
                    const thoughts = this.state.thoughts;
                    thoughts.posts = data;
                    this.setState({ thoughts});
                }
                break;
            case "photoposts":
                if (data) {
                    const photos = this.state.photos;
                    photos.posts = data;
                    this.setState({ photos });
                }
                break;
            case "videoposts":
                if (data) {
                    const videos = this.state.videos;
                    videos.posts = data;
                    this.setState({ videos });
                }
                break;
            case "songposts":
                if (data) {
                    const songs = this.state.songs;
                    songs.posts = data;
                    this.setState({ songs });
                }
                break;
            case "eventposts":
                if (data) {
                    const events = this.state.events;
                    events.posts = data;
                    this.setState({ events });
                }
                break;
            case "videos":
                if (data) {
                    this.setState({ videoLibraries: data });
                }
                break;
            case "events":
                if (data) {
                    this.setState({ userevents: data });
                }
                break;
            case "webPage":
                if (data) {
                    this.setState({ webPage: data });
                }
                break;
            case "contactFormData":
                if (data) {
                    this.setState({ contactFormData: data });
                }
                break;
            case "pageUsers":
                if (data) {
                    this.setState({ pageUsers: data });
                }
                break;
            case "market":
                if (data) {
                    this.setState({ market: data });
                }
                break;
            case "usermarket":
                if (data) {
                    this.setState({ userMarket: data });
                }
                break;
            case "links":
                if (data) {
                    this.setState({ linkFolders: data });
                }
                break;
            case "music":
                if (data) {
                    this.setState({ music: data });
                }
                break;
            case "profile":
                if (data) {
                    this.setState({ profile: data });
                    this.forceUpdate();
                }
                break;
            default:
                break;
        }
        this.forceUpdate();
    }
    updateContentTabPanelState = (type, val) => {

        let state = this.state.contentTabPanelState;
        switch (type) {
            case 'main':
                state.main = val;
                break;
            case 'notes':
                state.notes = val;
                break;
            case 'lists':
                state.lists = val;
                break;
            case 'docs':
            case 'documents':
                state.documents = val;
                break;
            case 'photos':
                state.photos = val;
                break;
            case 'videos':
                state.videos = val;
                break;
            case 'blogs':
                state.blogs = val;
                break;
            case 'events':
                state.events = val;
                break;
            case 'webPage':
                state.featuredContent = val;
                break;
            case 'pageUsers':
                state.pageUsers = val;
                break;
            case 'market':
                state.market = val;
                break;
            case 'links':
                state.links = val;
                break;
            case 'music':
                state.music = val;
                break;
            default:
                break;
        }
        this.setState({ contentTabPanelState: state });
        this.forceUpdate();
    }
    setContentTabValue = (newValue) => {
        this.setState({ contentTabValue: newValue });    
    }
    setSelectedContentType = (value) => {
        this.setState({ selectedContentType: value });
       
        this.getContent(value);
        this.forceUpdate();
    }
    changeFilter = async  (filter) => {
        switch (this.state.postChannel) {
            case 0:
                const thoughts = { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: filter };
                await this.setState({ thoughts });
                await this.getThoughtPosts();
                break;
            case 2:
                const photos = { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: filter };
                await this.setState({ photos });
                await this.getPhotoPosts();
                break;
            case 3:
                const videos = { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: filter };
                await this.setState({ videos });
                await this.getVideoPosts();
                break;
            case 5:
                const songs = { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: filter };
                await this.setState({ songs });
                await this.getMusicPosts();
                break;
            case 8:
                const events = { posts: [], loadIndex: 0, loadCount: 8, newestPostID: "", loading: false, loaded: false, refreshing: false, loadingMore: false, filter: filter };
                await this.setState({ events });
                await this.getEventPosts();
                break;
            default:
                break;
        }
  
        
    }
    posts = () => {
        return (
            <Posts
                {...this.props}
                thoughts={this.state.thoughts}
                photos={this.state.photos}
                videos={this.state.videos}
                songs={this.state.songs}
                events={this.state.events}
                market={this.state.market}
                searchResults={this.state.searchResults}
                getMoreThoughtPosts={this.getMoreThoughtPosts}
                getMoreVideoPosts={this.getMoreVideoPosts}
                getMorePhotoPosts={this.getMorePhotoPosts}
                getMoreMusicPosts={this.getMoreMusicPosts}
                updatePostContent={this.updatePostContent}
                getMoreEventPosts={this.getMoreEventPosts}
                getMoreMarketPosts={this.getMoreMarketPosts}
                getMoreSearchPosts={this.handleSearchMore}
                updateRepostContent={this.updateRepostContent}
                updateData={this.updateData}
                loaded={this.state.loaded}
                postChannel={this.state.postChannel}
                changePostChannel={this.changePostChannel}
                hasResults={this.state.hasResults}
                updateLastPost={this.updateLastPost}
                userList={this.state.userList}
                updatePosts={this.updatePosts}
                selectedPost={this.props.selectedPost}
                selectedRepost={this.state.selectedRepost}
                setSelectedPost={this.props.setSelectedPost}
                toggleFeature={this.toggleFeature}
                feedPrefs={this.state.feedPrefs}
                setFeedPrefs={this.setFeedPrefs}
                showSearch={this.state.showSearch}
                searching={this.state.searching}
                pauseRefreshes={(pause) => this.setState({ pauseRefreshes: pause })}
                width={this.state.width}
                height={this.state.height}
                changeFilter={this.changeFilter}
            />
        )
    }
    content = () => {
        return (
            <Content
                dark={this.props.dark}
                user={this.props.user}
                appIdentifier={this.props.appIdentifier}
                content={{
                    notebooks: fixNotebookProps(this.state.notebooks),
                    lists: this.state.lists,
                    blogChannels: this.state.blogChannels,
                    documentFolders: this.state.documentFolders,
                    linkFolders: this.state.linkFolders,
                    photoLibraries: this.state.photoLibraries,
                    videoLibraries: this.state.videoLibraries,
                    music: this.state.music,
                    events: this.state.userevents,
                    webPage: this.state.webPage,
                    contactFormData: this.state.contactFormData,
                    pageUsers: this.state.pageUsers,
                    market: this.state.userMarket
                }}
                getPhotos={this.getPhotos}
                updateData={this.updateData}
                tabPanelState={this.state.contentTabPanelState}
                updateTabPanelState={this.updateContentTabPanelState}
                webPageID={this.props.webPageID}
                dropDownOptions={this.state.dropDownOptions}
                profile={this.state.profile}
                userList={this.state.userList}
                toggleFeature={this.toggleFeature}
                selectedContentType={this.state.selectedContentType}
                contentTypes={this.state.contentTypes}
                setSelectedContentType={this.setSelectedContentType}
                contentLoading={this.state.contentLoading}
                width={this.state.width}
                height={this.state.height}
            />
        )
    }

    render() {
        return (
            <Box sx={{ pt: { xs: 7, sm: 8 } }} className={this.props.dark && "dark"} >
                {this.state.topic == 'posts'
                    ?
                    this.posts()
                    :
                    this.content()
                }
            </Box>
        )
    }
}
