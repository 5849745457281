import React, { Component } from 'react';

//Custom Components
import { getPhoto } from '../../../DataServices/PhotoService'
import { PhotoViewer } from '../Photos/PhotoViewer';

//Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Rating from '@mui/material/Rating';


export class Product extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            openPhotoViewer: false,
        }
    }
    handleClickPhoto = async (e, photo, idx) => {
        var ele = e.target;
        ele.style.cursor = 'wait';
        document.body.style.cursor = 'wait';
        if (!photo.fullPhoto) {
            photo = await getPhoto(photo.photoID);
            //this.updatePhoto(photo, idx);

        }

        this.setState({
            selectedPhoto: photo,
            selectedPhotoIDX: idx,
            openPhotoViewer: true
        })

        document.body.style.cursor = 'default';
        ele.style.cursor = 'default';

    }

    //Render funcs
    header = () => {
        if (this.props.item) {
            return (
                <Box
                    style={{
                        padding: "60px",
                        textAlign: "left",
                        backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('data:image/png;base64, " + this.props.item.primaryPhoto.thumb + "')",
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        minHeight: this.props.item.primaryPhoto.thumb && "250px",
                        maxHeight: this.props.preview && "250px",
                        backgroundColor: this.props.dark ? "#111" : "#fff"

                    }}>
                    <Stack spacing={1} sx={{ maxWidth: "700px", margin:"auto", textAlign:"center" }}>
                        {this.props.item.name &&
                            <Typography variant="h3" sx={{ fontWeight: 500, color: "white" }}>
                            {this.props.item.name}
                            </Typography>
                        }
                        {this.props.item.desc &&
                            <Typography variant="h6" sx={{ fontWeight: 500, color: "white" }}>
                            {this.props.item.desc + (this.props.preview ? " $" + this.props.item.price : "")}
                            </Typography>
                        }
                    </Stack>
                </Box>
            )
        }
        return null;
    }

    body = () => {
        return (
            <Grid container sx={{ marginTop: "0px !important" }}>
 
                <Grid key="extdec" item xs={12} lg={6}>
                    <Stack sx={{ padding: "0px 24px", paddingTop: "8px" }}>
                        <Typography variant="h6" sx={{ fontWeight: 550, color: "black", marginBottom: "24px", marginTop:"8px" }}>
                                Product Information
                        </Typography>
                        <Box sx={{ padding: "0px 16px" }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "black" }}>
                                <b>Name: </b>{this.props.item.name}
                            </Typography>
                            {this.props.item.desc &&
                                <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "black" }}>
                                    <b>Description: </b>{this.props.item.desc}
                                </Typography>
                            }
                            <Typography variant="subtitle2" sx={{ fontWeight: 500, color: "black" }}>
                                <b>Asking Price: </b>${this.props.item.price}
                            </Typography>
                        </Box>
                        <br/>
                        {this.props.item.longDesc &&
                            <Accordion defaultExpanded disableGutters elevation={0} sx={{  }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="itemacc"
                                >
                                <Typography sx={{ fontWeight: 550, color: (theme) => this.props.dark ? theme.palette.common.dimblack : theme.palette.common.dimblack }}>More Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ padding: "0px 16px" }}>
                                        <div dangerouslySetInnerHTML={{ __html: this.props.item.longDesc }} style={{ width: "100%" }} />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        }
     
                        <Accordion defaultExpanded disableGutters elevation={0} sx={{}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="itemspecs"
                            >
                                <Typography sx={{ fontWeight: 550, color: (theme) => this.props.dark ? theme.palette.common.dimblack : theme.palette.common.dimblack }}>Product Specs</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ padding: "0px 16px" }}>
                                    Attributes will go here
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <br />

                        {this.rating()}
                    </Stack>
                </Grid>
                
                <Grid key="images" item xs={12} lg={6} sx={{ background:"#f2f2f2"}}>
                    <Stack sx={{ padding: "0px 24px", paddingTop: "8px" }}>
                        <Typography variant="h6" sx={{ fontWeight: 550, color: "black", marginBottom: "24px", marginTop: "8px"  }}>
                                Product Images
                            </Typography>
                            <Box sx={{width:"100%"}}>
                                <ImageList cols={2} sx={{ padding: "24px" }} gap={12}>
                                    {this.props.item.photos.map((item, index) => (
                                        <ImageListItem key={item.photo.id + item.id + index} sx={{
                                            transition: "transform .2s",
                                            "&:hover": { transform: "scale(1.05)" },padding:1
                                        }}>
                                            <img
                                                src={"data:image/png;base64, " + item.photo.thumb}
                                                alt={item.title}
                                                loading="lazy"
                                                style={{  background: "white",  }}
                                                onClick={(e) => this.handleClickPhoto(e, item.photo, index)}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                        </Stack>
                </Grid>

                <Grid key="reviews" item xs={12} lg={6}>
                    <Stack sx={{ padding: "0px 24px", paddingTop: "8px" }}>
                        <Typography variant="h6" sx={{ fontWeight: 550, color: "black", marginBottom: "24px", marginTop: "8px" }}>
                            Seller Reviews
                        </Typography>
                        <Box sx={{ width: "100%" }}>
                           
                        </Box>
                    </Stack>
                </Grid>


            </Grid>


            )
    }

    rating = () => {
        return (
            <Box sx={{ padding:"0px 16px"}}>
                <Typography sx={{ fontWeight: 550, color: (theme) => this.props.dark ? theme.palette.common.dimblack : theme.palette.common.dimblack }}>Seller Rating</Typography>
                <Rating
                    name="Seller Rating"
                    value={this.props.sellerRating ?? 3}
                    onChange={(event, newValue) => {
                        this.setRatingValue(newValue);
                    }}
                />
            </Box>
            )
    }
    photoViewer = () => {
        if (this.state.selectedPhoto) {
            return (
                <PhotoViewer
                    dark={this.props.dark}
                    open={this.state.openPhotoViewer}
                    photoID={this.state.selectedPhoto && this.state.selectedPhoto.photoID}
                    photo={this.state.selectedPhoto}
                    handleClose={() => this.setState({ openPhotoViewer: false })}
                    index={this.state.selectedPhotoIDX}
                    allowNext={false}
                    allowPrevious={false}
                    hideInteractions
                />
            )
        }
        else {
            return null;
        }

    }
    render() {
        return (
            <Box
                elevation={this.props.dark ? 0 : 8}
                sx={{
       /*             width: this.props.post ? "100%" : { xs: "360px", sm: "450px", md: "672px", lg: "960px", xl: "1176px" },*/
                    width: this.props.post ? "100%" :"100%",
                    maxWidth: "1176px",
                    minWidth: "360px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingBottom:"0px",
                    background:"white"

                }}
                className="prettyScroll"
            >
                <Stack spacing={3}>
                    {
                        this.props.item &&
                        <>
                            {this.header()}
                            {this.body()}
                            {this.photoViewer()}
                        </>

                    }
                </Stack>
            </Box>
        );
    }
}

