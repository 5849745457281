
export async function getInvitations() {

    const response = await fetch('org/getuserinvitations');

    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

export async function confirmInvitation(id) {
    const req = { InvitationID: id };
    const response = await fetch("org/confirmmember", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function declineInvitation(id) {
    const req = { InvitationID: id };
    const response = await fetch("org/declinemember", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

export async function removeInvitation(id) {
    const req = { InvitationID: id };
    const response = await fetch("org/removeinvitation", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

