import React, { Component } from 'react';

//Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


export class Blog extends Component {

    //Event Handlers
    handleSubscribe = () => { }

    //Render funcs
    header = () => {
        if (this.props.blog) {
            return (
                <Box
                    style={{
                        padding: "60px",
                        textAlign: "left",
                        backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('data:image/png;base64, " + this.props.blog.header.coverImage.thumb + "')",
                        backgroundSize: 'cover',
                        backgroundPositionY:'center'
                    }}>
                    <Stack spacing={1} sx={{ maxWidth: "500px", marginLeft: "auto", marginRight: "auto" }}>
                        {(this.props.blog.header && this.props.blog.header.title) &&
                            <Typography variant="h3" sx={{ fontWeight: 500, color: "white" }}>
                                {this.props.blog.header.title}
                            </Typography>
                        }
                        {(this.props.blog.header && this.props.blog.header.subTitle) &&
                            <Typography variant="h6" sx={{ fontWeight: 500, color: "white" }}>
                                {this.props.blog.header.subTitle}
                            </Typography>
                        }
                        {this.author()}
                    </Stack>
                </Box>
            )
        }
        return null;
    }
    author = () => {
        if (this.props.blog.header.authorName) {
            return (
                <Box display="flex">
                    {/*<Avatar*/}
                    {/*    className="avatarImageMedium"*/}
                    {/*    alt={this.props.blog.author && this.props.blog.author.firstName + " " + this.props.blog.author.lastName}*/}
                    {/*    src={(this.props.blog.author && this.props.blog.author.avatarB64) && "data:image/png;base64, " + this.props.blog.author.avatarB64}*/}
                    {/*    imgProps={{ style: { cursor: "pointer" } }}*/}
                    {/*/>*/}
                    <Stack>
                        <Typography variant="body1" sx={{ fontWeight: 500, fontSize: ".9rem", color: (theme) => theme.palette.common.white }} >
                            {"by " + this.props.blog.header.authorName ? this.props.blog.header.authorName :  this.props.blog.author.firstName + " " + this.props.blog.author.lastName}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: ".8rem", color: (theme) => theme.palette.common.dimwhite }} >
                            {"Posted on " + this.props.pubDate ?? ""}
                        </Typography>
                    </Stack>
                    {this.social(false)}
                </Box>

            )
        }
    }
    social = (dark) => {
        return (
            <Box display="flex" sx={{ padding: "0px 16px" }}>
                {this.props.blog.header.authorFB &&
                    <IconButton
                        target="_blank"
                        href={this.props.blog.header.authorFB}
                        key="tdyf"
                        sx={{ color: (theme) => dark ? theme.palette.secondary.main : (this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white) }}>
                        <FacebookIcon />
                    </IconButton>
                }
                {this.props.blog.header.authorLI &&
                    <IconButton
                        target="_blank"
                        href={this.props.blog.header.authorLI}
                        key="tdyf"
                        sx={{ color: (theme) => dark ? theme.palette.secondary.main : (this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white) }}>
                        <LinkedInIcon />
                    </IconButton>
                }
                {this.props.blog.header.authorTW &&
                    <IconButton
                    target="_blank"
                    href={this.props.blog.header.authorTW}
                    key="tdy"
                    sx={{ color: (theme) => dark ? theme.palette.secondary.main : (this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white) }}

                    >
                        <TwitterIcon />
                    </IconButton>
                }


            </Box>


        )
    }
    aboutAuthor = () => {
        if (this.props.blog.header.authorName) {
            return (
                <Stack spacing={2} sx={{ padding: "12px 48px", borderTop: "4px solid #ddd" }}>

                    <Box display="flex">
                        <Avatar
                            className="avatarImageLarge"
                            alt={this.props.blog.header.authorName && this.props.blog.header.authorName}
                            src={(this.props.blog.header.authorImage && this.props.blog.header.authorImage.thumb) && "data:image/png;base64, " + this.props.blog.header.authorImage.thumb}
                            imgProps={{ style: { cursor: "pointer" } }}
                        />
                        <Stack>
                            <Typography sx={{ paddingLeft: "24px", fontWeight: 500, fontSize: "1rem", color: (theme) => theme.palette.common.black, marginTop: "0px", marginBottom: "0px" }} >
                                {this.props.blog.header.authorName ? this.props.blog.header.authorName : this.props.blog.author.firstName + " " + this.props.blog.author.lastName}
                            </Typography>
                            {this.props.blog.header.authorDesc &&
                                <Typography sx={{ paddingLeft: "24px", fontWeight: 500, fontSize: ".9rem", color: (theme) => theme.palette.common.dimblack, marginTop: "0px", marginBottom: "0px" }} >
                                {this.props.blog.header.authorDesc}
                                </Typography>
                            }
                        </Stack>

                        {this.social(true)}
                    </Box>
                </Stack>


            )
        }
    }
    bodyrows = () => {
        if (this.props.blog.bodyRows && this.props.blog.bodyRows.length > 0) {
            return (
                <Stack spacing={2} sx={{ marginBottom: "24px", marginTop: "16px", fontSize: ".9rem", padding: "0px 32px", textAlign: "left" }}>
                    {
                        this.props.blog.bodyRows.map((item, index) => {

                            if (item.numCols === 1) {
                                return (
                                    <Box key={item.id} display="flex" sx={{ padding: "0px 16px", color: (theme) => theme.palette.common.dimblack }}>
                                        {(item.rowContent && item.rowContent.length > 0 && item.rowContent[0].contentType === 0) &&

                                            <div dangerouslySetInnerHTML={{ __html: item.rowContent[0].textContent }} style={{ width:"100%"}} />

                                        }
                                        {(item.rowContent && item.rowContent.length > 0 && item.rowContent[0].contentType === 1 && item.rowContent[0].photo && item.rowContent[0].photo.thumb) &&
                                            <Stack>
                                                <Paper
                                                    component="img"
                                                    elevation={this.props.dark ? 0 : 8}
                                                    src={"data:image/png;base64, " + item.rowContent[0].photo.thumb}
                                                    style={{ maxHeight: "120px", maxWidth: "200px", height: "auto", width: "100%", objectFit: "cover", borderRadius: "5px", cursor: "pointer" }}
                                                />
                                                {item.rowContent[0].textContent &&
                                                    <Box sx={{ width: "100%", textAlign: "center" }}>
                                                        <Typography variant="body2" style={{ paddingTop: "6px", fontSize: ".7rem" }}>
                                                            {item.rowContent[0].textContent}
                                                        </Typography>
                                                    </Box>
                                                }
                                            </Stack>
                                        }
                                    </Box>
                                )
                            }
                            else {
                                return (
                                    <Box key={item.id} display="flex">
                                        <Box sx={{ width: "50%", padding: "0px 16px", color: (theme) => theme.palette.common.dimblack }}>
                                            {(item.rowContent && item.rowContent.length > 0 && item.rowContent[0].contentType === 0) &&
                                                <div dangerouslySetInnerHTML={{ __html: item.rowContent[0].textContent }} style={{ width: "100%" }}  />
                                            }
                                            {(item.rowContent && item.rowContent.length > 0 && item.rowContent[0].contentType === 1 && item.rowContent[0].photo && item.rowContent[0].photo.thumb) &&
                                                <Stack>
                                                    <Paper
                                                        component="img"
                                                        elevation={this.props.dark ? 0 : 8}
                                                        src={"data:image/png;base64, " + item.rowContent[0].photo.thumb}
                                                        style={{ marginTop: "8px", marginBottom: "auto", maxHeight: "100%", maxWidth: "100%", height: "auto", width: "100%", objectFit: "cover", borderRadius: "5px", cursor: "pointer" }}
                                                    />
                                                    {item.rowContent[0].textContent &&
                                                        <Box sx={{ width: "100%", textAlign: "center" }}>
                                                            <Typography variant="body2" style={{ paddingTop: "6px", fontSize: ".7rem" }}>
                                                                {item.rowContent[0].textContent}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                </Stack>
                                            }
                                        </Box>
                                        <Box sx={{ width: "50%", padding: "0px 16px", color: (theme) => theme.palette.common.dimblack }}>
                                            {(item.rowContent && item.rowContent.length > 1 && item.rowContent[1].contentType === 0) &&
                                                <div dangerouslySetInnerHTML={{ __html: item.rowContent[1].textContent }} style={{ width: "100%" }} />
                                            }
                                            {(item.rowContent && item.rowContent.length > 1 && item.rowContent[1].contentType === 1 && item.rowContent[1].photo && item.rowContent[1].photo.thumb) &&
                                                <Stack>
                                                    <Paper
                                                        component="img"
                                                        elevation={this.props.dark ? 0 : 8}
                                                        src={"data:image/png;base64, " + item.rowContent[1].photo.thumb}
                                                        style={{ marginTop: "8px", marginBottom: "auto", maxHeight: "100%", maxWidth: "100%", height: "auto", width: "100%", objectFit: "cover", borderRadius: "5px", cursor: "pointer" }}
                                                    />
                                                    {item.rowContent[1].textContent &&
                                                        <Box sx={{ width: "100%", textAlign: "center" }}>
                                                            <Typography variant="body2" style={{ paddingTop: "6px", fontSize: ".7rem" }}>
                                                                {item.rowContent[1].textContent}
                                                            </Typography>
                                                        </Box>
                                                    }

                                                </Stack>
                                            }
                                        </Box>

                                    </Box>
                                )
                            }
                        })
                    }
                </Stack>
            )
        }
        return null;
    }
    footer = (show) => {
        if (show) {
            return (
                <Box sx={{ height: "48px", background: (theme) => theme.palette.secondary.main }}>
                    <Box display="flex" sx={{ height: "100%", padding: "0px 16px" }}>

                        <Button size="small" key="newblogchannel" aria-label="newblogchannel" onClick={this.handleSubscribe} sx={{ color: (theme) => theme.palette.common.white, fontSize: ".8rem", borderRadius: "6px", margin: "6px 0px" }}>
                            {"Subscribe to " + (this.props.blog.blogChannel && "to " + this.props.blog.blogChannel.name)}
                        </Button>

                    </Box>
                </Box>
            )
        }
        return null;
    }

    render() {
        return (
            <Box
                elevation={this.props.dark ? 0 : 8}
                sx={{
                    maxWidth: "1176px",
                    minWidth: "360px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingBottom:"0px",
                    background: "white",

                }}
            >
                <Stack spacing={3}>
                    {
                        this.props.blog &&
                        <>
                            {this.header()}
                            {this.bodyrows()}
                            {this.aboutAuthor()}
                            {this.footer(false)}
                        </>

                    }
                </Stack>
            </Box>
        );
    }
}

