
export async function getLists() {
    let url = 'list/getalllists';
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function moveItem(id, idx) {
    const req = { ItemID: id, Idx: idx };
    const response = await fetch('list/movelistitem', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}
export async function checkOffItem(id) {
    const req = { ItemID: id };
    const response = await fetch('list/checkoffitem', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function uncheckOffItem(id) {
    const req = { ItemID: id };
    const response = await fetch('list/uncheckoffitem', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function newItem(id, item) {
    const req = { ListID: id, Item: item };
    const response = await fetch('list/addlistitem', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function editItem(id, item) {
    const req = { Item: item, ItemId: id };
    const response = await fetch('list/editlistitem', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function deleteItem(id) {
    const req = { ItemID: id };
    const response = await fetch('list/removelistitem', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function newList(name, type) {
    const req = { Name: name, Type: type };
    const response = await fetch('list/createlist', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function editList(id, name) {
    const req = { Name: name, ListId: id };
    const response = await fetch('list/editlist', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function deleteList(id) {
    const req = {ListID: id };
    const response = await fetch("list/removelist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}
export async function shareList(id, ownerid, userid, isFolder, appid, canedit, hasexpiration, expiration) {
    const req = {
        ListID: id,
        OwnerID: ownerid,
        UserID: userid,
        IsFolder: isFolder,
        AppID: appid,
        CanEdit: canedit,
        HasExpiration: hasexpiration,
        Expiration: expiration
    };
    const response = await fetch("list/sharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function unShareList(id, ownerid, userid, appid) {
    const req = {
        ListID: id,
        OwnerID: ownerid,
        UserID: userid,
        AppID: appid
    };
    const response = await fetch("list/unsharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function hideSharedList(id, ownerid, userid, appid) {
    const req = {
        ListID: id,
        OwnerID: ownerid,
        UserID: userid,
        AppID: appid
    };
    const response = await fetch("list/hidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function unhideSharedList(id, ownerid, userid, appid) {
    const req = {
        ListID: id,
        OwnerID: ownerid,
        UserID: userid,
        AppID: appid
    };
    const response = await fetch("list/unhidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
