import React, { Component } from 'react';


//Database Services
import { getSong } from '../../../DataServices/MusicService';

//Material UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

//import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export class Song extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        if (!this.state.song) {
            this.getSong();
        }
    }

    getSong = async () => {

        document.body.style.cursor = 'wait';
        const song = await getSong(this.props.id);
        if (song) {
            this.setState({song})
        }
        document.body.style.cursor = 'default';
    }

    //Render functions
    song = () => {
        if (this.state.song) {
            return (
                <Card
                    sx={{ backgroundColor: "transparent" ,borderRadius: "8px", display: "inline-block", m: 1, width: { xs: "-webkit-fill-available", sm: "-webkit-fill-available" }, }}
                    raised
                    elevation={this.props.dark ? 0 : 4}
                    className="song"
                >
                    <CardMedia
                        component="image"
                        height="auto"
                        image={"data:image/png;base64, " + this.state.song.image.thumb}
                        sx={{
                            width: "-webkit-fill-available",backgroundColor:"transparent" }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', py: 1, width: "-webkit-fill-available", background: this.props.dark ? "rgba(0,0,0,.65)" : "rgba(0,0,0,.65)", borderRadius: "8px" }} className={this.props.dark && "dark" }>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box sx={{ textAlign: "center", justifyContent: "center" }} >
                                    <Typography variant="h5" sx={{ p: 0.5, paddingBottom: "0px", textAlign: "center", fontWeight: 500, marginTop: "auto", marginBottom: "auto", fontSize: "1.0rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.white }}>
                                        {this.state.song.title}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ p: 0.5, paddingBottom: "0px", textAlign: "center", fontWeight: 500, marginTop: "auto", marginBottom: "auto", fontSize: "0.9rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.white }}>
                                        {this.state.song.artist ? this.state.song.artist.name : "Unspecified Artist"}
                                    </Typography>
                                </Box>
                                <Box sx={{  p: 1,}}>
                                    <AudioPlayer
                                        src={"https://mylivesphere.com/api/musicstream/" + this.state.song.id}
                                        preload="metadata"
                                    />
                                </Box>
                                <Box sx={{ flexGrow: 1 }} />
                            </Box>
                            <Box sx={{ flexGrow: 1 }} />
                        </Box>
                    </CardMedia>
                </Card>
            )
        }
        return (<Skeleton height={180} variant="rectangular"  />)
    }




    render() {
        return this.song();
    }
}
