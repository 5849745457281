import React, { PureComponent } from 'react';
import {  b64toBlob } from '../../DataServices/Utility';
//material ui
import Box from '@mui/material/Box';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
//React PLayer (video/audio player)
import ReactPlayer from 'react-player'

export class VideoPlayer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            streamBase: "https://mylivesphere.com/api/videostream/",
        }
    }

    //render funcs
    content = () => {
        let blob;
        if (this.props.video.cover && this.props.video.cover.thumb) {
            blob = b64toBlob(this.props.video.cover.thumb);
        }
        return (
            <Box className="player-wrapper" sx={{ display: "flex", marginTop: { xs: 0, }, justifyContent: "center", }}>
                <ReactPlayer
                    className='react-player'
                    url={this.state.streamBase + this.props.video.id}
                    controls={true}
                    light={blob ? URL.createObjectURL(blob) : true}
                    width='100%'
                    height='100%'
                    playIcon={<PlayCircleIcon sx={{ fontSize: 72, color: "#999" }} />}
                />
            </Box>
        )
    }

    //main component render
    render() {
        return this.content();
    }
}
