import React, { PureComponent } from 'react';

//Custom Components
import { Section } from '../Utilities/Section';
import { Spin } from '../Utilities/Spin';
import { Song } from '../Content/Music/Song';
//Material UI
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

//import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export class UserSiteMusic extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            streamBase: "https://mylivesphere.com/api/musicstream/",
        }
    }
  
    music = () => {
        if (this.props.loading) {
            return (
                <Box sx={{ height: "100%", width: "100%", display: 'flex', justifyContent: "center" }}>
                    <Spin sub />
                </Box>
            )
        }
        if (this.props.music && this.props.music.songs && this.props.music.songs.length > 0) {
            return (
                <Stack>
                    <Section key={"songsk"} dark={this.props.dark} title="Songs" padBottom >
                        {this.props.music.songs.map((item) => {
                            return this.songCard(item);
                        })}
                    </Section>           
                </Stack>
                )
        }
        return null;
    }
    songCard = (item) => {
        if (item.hasAudio) {
            return (
                //<Box>
                //    <Card
                //        sx={{ m: 2, marginLeft:"auto", marginRight:"auto",  display: "flex", minWidth: "200px", maxWidth:"800px", maxHeight: "200px", background: (theme) => this.props.dark && theme.palette.card.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }}
                //        raised
                //        elevation={this.props.dark ? 1 : 4}
                //    >
                //        {item.image && item.image.thumb &&
                //            <CardMedia
                //                component="img"
                //                height="190"
                //                image={item.image && "data:image/png;base64, " + item.image.thumb}
                //                sx={{ width: "190px" }}
                //            />
                //    }
                //    <Box sx={{ flexGrow: 1 }} />
                //        <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                //            <Box sx={{ textAlign: "center", justifyContent: "center" }} >
                //                <Typography variant="h6" sx={{ p: 0.5, paddingBottom: "0px", textAlign: "center", fontWeight: 500, marginTop: "auto", marginBottom: "auto", fontSize: "1.0rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                //                    {item.title}
                //                </Typography>
                //                <Typography variant="subtitle2" sx={{ p: 0.5, paddingBottom: "0px", textAlign: "center", fontWeight: 500, marginTop: "auto", marginBottom: "auto", fontSize: "0.9rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                //                    {item.artist ? item.artist.name : "Unspecified Artist"}
                //                </Typography>
                //            </Box>
                //            <Box sx={{ p: 1 }}>

                //                <AudioPlayer
                //                    src={this.state.streamBase + item.id}
                //                    preload="metadata"
                //                />
                //            </Box>
                //    </Box>
                //    <Box sx={{ flexGrow: 1 }} />
                //    </Card>
                //</Box>
                <Box sx={{ width:"100%", maxWidth:"800px", m:"auto"}}>
                    <Song
                        key={item.id}
                        dark={this.props.dark}
                        id={item.id}
                        user={this.props.user}
                    />
                </Box>
            )
        }
        return null;
    }

    render() {
        return this.music();
    }
}

