import React, { Component } from 'react';
import { Reactions } from '../Reactions/Reactions';

//Custom Components
import { Replies } from './Replies';
import { ModalDialog } from '../Utilities/ModalDialog'
import { UserAvatar } from '../Utilities/UserAvatar'

//Material UI
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Typography from '@mui/material/Typography';

export class Comment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            showReplies: false,
            showConfirmDelete: false
        };
    }

    //event handlers
    handleClose = () => {
        this.setState({  anchorEl: null });
    };
    handleDelete = (id, idx) => {
        this.setState({
            anchorEl: null,
            showConfirmDelete: true,
            selectedMessageID: id,
            selectedMessageIDX: idx
        });

    }
    handleConfirmDelete = () => {
        this.setState({ showConfirmDelete: false });
        this.props.handleDelete(this.state.selectedMessageID, this.state.selectedMessageIDX);
    }
    handleEdit = (id, idx) => {
        this.handleClose();
        this.props.handleEdit(id, idx);
    }
    handleAddReply = () => {
        const val = document.getElementById("newReply" + this.props.message.id).value;
        if (val) {
            this.props.cbAddReply(this.props.message.id, this.props.idx, val);
            document.getElementById("newReply" + this.props.message.id).value = "";
        }
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleAddReply();
        }
    }
    updateReplyCount = (count) => {
        this.props.updateReplyCount(this.props.idx, count);
    }

    //Render funcs
    avatar = () => {
        return (
            <UserAvatar
                id={this.props.message.id}
                user={this.props.message.user}
                dark={this.props.dark}
                loaded={true}
                activeUser={this.props.user}
                appIdentifier={this.props.appIdentifier}
                hideName
                small
            />
        )
    }
    editButton = () => {
        return (
            <Box sx={{ display: "flex" }}>
                <IconButton
                    aria-label="Options"
                    size="small"
                    onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                    sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}
                >
                    <MoreHorizIcon />
                </IconButton>
            </Box>
        )
    }
    msgMenu = () => {
        return (
            <Menu
                id="msg-menu"
                anchorEl={this.state.anchorEl}
                open={this.state.anchorEl !== null}
                onClose={() => this.setState({ anchorEl: null })}
                PaperProps={{ elevation: 4, sx: { backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundModal.dark : theme.palette.backgroundModal.light, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack } }}
                MenuListProps={{ sx: { backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundModal.dark : theme.palette.backgroundModal.light, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack } }}
            >
                <MenuItem onClick={() => this.handleDelete(this.props.message.id, this.props.index)}>Delete</MenuItem>
            </Menu>
        )
    }
    deleteConfirmation = () => {
        return (
            <ModalDialog dark={this.props.dark} show={this.state.showConfirmDelete}
                title="Confirm Deletion"
                subTitle={"Comment will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDelete: false })}
                handleSave={this.handleConfirmDelete}
                fullWidth={false}
                maxWidth="sm"
                saveLabel="Delete"
            />
        )
    }
    userAvatar = (me) => {
        return (
            <ListItemIcon sx={{ color: this.props.dark ? "#ccc" : "#444", marginRight: "16px", marginTop: "8px" }}>
                {this.avatar()}
            </ListItemIcon>
        )
    }
    replies = () => {
        return (
            <Replies
                targetID={this.props.message.id}
                targetType={3}
                dark={this.props.dark}
                commentCount={this.props.message.commentCount}
                updateReplyCount={this.updateReplyCount}
                user={this.props.user}
                noIcon={this.props.noIcon ?? false}
                appIdentifier={this.props.appIdentifier}
                refUser={this.props.message.user}
            />)
    } 
    reactions = () => {
        return (
            <Box sx={{ marginTop: "auto", marginBottom: "auto" }}>
                <Reactions
                    dark={true}
                    myReaction={this.props.message.myReaction}
                    likeCount={this.props.message.likeCount}
                    loveCount={this.props.message.loveCount}
                    targetID={this.props.message.id}
                    idx={this.props.index}
                    targetType={3}
                    targetTypeDesc="comment"
                    inline={true}
                    noBack={true}
                    noColor={true}
                    refUser={this.props.message.user}
                    user={this.props.user}
                />
            </Box>
        )
    }
    msg = () => {
        return (
            <ListItem
                key={this.props.message.id}
                sx={{ justifyContent: this.props.message.fromMe ? "end" : "start", paddingTop: "0px", paddingBottom: "0px", alignItems: "start" }}
            >
                {this.userAvatar(this.props.message.fromMe)}
                <Box sx={{ width: "100%"}}>
                    <Paper
                        elevation={this.props.dark ? 0 : 4}
                        sx={{ borderRadius: "12px", backgroundColor: theme => this.props.dark ? theme.palette.comment.dark : theme.palette.comment.light, padding: "8px" }}
                    >
                        <Box display="flex">
                            {this.props.message.fromMe && this.editButton()}
                            <Box sx={{ flexGrow: 1 }} />
                            <Stack>
                                <Box sx={{ display: "block", textAlign: this.props.message.fromMe ? "right" : "right", padding: "0px 8px" }}>
                                    <Typography variant="body1" sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack, fontSize: ".9rem" }}>
                                        {this.props.message.commentText}
                                    </Typography>
                                    <Box display="flex">
                                        <Box sx={{ flexGrow: 1 }} />
                                        {!this.props.message.fromMe && this.reactions()}
                                        <Typography variant="subtitle2" sx={{ marginTop: "auto", marginBottom: "auto", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack, fontSize: ".7rem" }}>
                                            {this.props.message.whenPosted ? this.props.message.whenPosted : new Date(this.props.message.commentDate).toLocaleDateString() + " " + new Date(this.props.message.commentDate).toLocaleTimeString()}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Stack>
                        </Box>
                    </Paper>
                    {this.replies()}
                </Box>
            </ListItem>
            )
    }
    content = () => {
        return (
            <>
                {this.msg()}
                {this.deleteConfirmation()}
                {this.msgMenu()}
            </>
        );
    }

    //main component render
    render() {
        return this.content();
    }
}

