import React, { Component } from 'react';

//material ui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Badge from '@mui/material/Badge';

export class BottomNav extends Component {
    constructor(props) {
        super(props);
    }

    //render funcs
    bottomNav = () => {
        return (
            <Box sx={{ display: { xs: "block", lg: "none" }, position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 10000 }} className={this.props.dark ? "dark bottomNav" : "bottomNav"}>
                <BottomNavigation
                    showLabels
                    value={this.props.index}
                    onChange={(event, newValue) => { this.props.setIndex(newValue) }}
                    sx={{ backgroundColor: theme => this.props.dark ? "#000" : "#000" }}
                >
                    <BottomNavigationAction label={<Typography sx={{ fontSize: ".6rem" }}>Feed</Typography>} icon={<HomeIcon sx={{ fontSize: "1.3rem" }} />} />
                    <BottomNavigationAction label={<Typography sx={{ fontSize: ".6rem" }}>Content</Typography>} icon={<WidgetsIcon sx={{ fontSize: "1.3rem" }} />} />
                    <BottomNavigationAction label={<Typography sx={{ fontSize: ".6rem" }}>Chat</Typography>} icon={<Badge invisible={!this.props.hasNewMessages} color="info" variant="dot"><ChatBubbleIcon sx={{ fontSize: "1.3rem" }} /></Badge>} />
                    <BottomNavigationAction label={<Typography sx={{ fontSize: ".6rem" }}>Notifications</Typography>} icon={<Badge badgeContent={this.props.notificationCount ?? null} color="error"><NotificationsIcon sx={{ fontSize: "1.3rem" }} /></Badge>} />
                    <BottomNavigationAction label={<Typography sx={{ fontSize: ".6rem" }}>Profile</Typography>} icon={<AccountCircleIcon sx={{ fontSize: "1.3rem" }} />} />
                </BottomNavigation>
            </Box>
        )
    }

    //main component render
    render() {
        return this.bottomNav();
    }
}
