
export async function getBusinesses() {
    let url = 'business/getuserbusinesses';
    const response = await fetch(url);
    if (response.status !== 200) 
        return null;
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getBusinessesByAdminUser() {
    let url = 'business/getbusinessesbyadminuser';
    const response = await fetch(url);
    if (response.status !== 200)
        return null;
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getBusinessTypes() {
    let url = 'business/getbusinesstypelist';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getStateList() {
    let url = 'business/getstatelist';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function newBusiness(name, desc, type, street, city, state, zip, phone, website) {
    const req = {
        Name: name,
        Desc: desc,
        Type: type,
        Street: street,
        City: city,
        State: state,
        Zip: zip,
        Phone: phone,
        Website: website
    };
    const response = await fetch('business/createbusiness', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const newBiz = await response.json();
        return newBiz;
    }
    return null;
}

export async function editBusiness(id, name, desc, type, street, city, state, zip, phone, website) {
    const req = {
        BusinessID: id,
        Name: name,
        Desc: desc,
        Type: type,
        Street: street,
        City: city,
        State: state,
        Zip: zip,
        Phone: phone,
        Website: website
    };
    const response = await fetch('business/editbusiness', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function deleteBusiness(id) {
    const req = {
        BusinessID: id,
    };
    const response = await fetch('business/deletebusiness', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function getBusiness(id) {
    let url = 'business/getbusiness?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getClients(id) {
    let url = 'business/getclients?businessid=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getClientUsers(id) {
    let url = 'business/getclientusers?clientid=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return null;
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getAllClientDocuments(businessid,appid) {
    let url = 'business/getallclientdocuments?businessid=' + businessid + '&appid=' + appid;
    const response = await fetch(url);
    if (response.status !== 200)
        return null;
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getClientUserInvites(id) {
    let url = 'org/getgroupinvitedmembers?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return null;
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function newClient(id, name, altid) {
    const req = { Name: name, BusinessID: id, AltID: altid };
    const response = await fetch('business/createclient', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const newData = await response.json();
        return newData;
    }
    return null;
}

export async function editClient(id, name, altid) {
    const req = { Name: name, ClientID: id, AltID: altid, Status: 2 };
    const response = await fetch('business/editclient', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return null;
}

export async function deleteClient(id) {
    const req = { ClientID: id };
    const response = await fetch('business/deleteclient', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return null;
}

export async function getUserAffiliations() {
    let url = 'business/getuseraffiliations'
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getAdminUsers(id) {
    let url = 'business/getadminusers?businessid=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return null;
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getAdminUserInvites(id) {
    let url = 'org/getgroupinvitedmembers?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return null;
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function deleteAdminUser(id) {
    const req = { ID: id };
    const response = await fetch('business/deleteadminuser', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return null;
}