import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../Common/Utilities/ModalDialog';
import { TextInput } from '../Common/Utilities/TextInput';
import { SectionFieldGroup } from '../Common/Utilities/SectionFieldGroup';

/*import Carousel from 'react-bootstrap/Carousel';*/
import { Box} from '@mui/material'
import Typography from '@mui/material/Typography';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';

//Database Services
import { addContactFormSubmission} from '../DataServices/WebPageService'
import { validateEmail } from '../DataServices/Utility'
import { sendEmail } from '../DataServices/EmailService'
const getIP = async () => {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        return data.IPv4;
}

export class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactFormSubTitle: "Enter your info below and we will contact you shortly.",
            contactFormTitle: "Thanks for reaching out!",
            requestDesc: "Provide a short message...",
            contactStatus: 0,
            disableContactButtons: false
        }
    }

    //Event Handlers
    validateRequiredFields = () => {
        let valid = true;
        if (!document.getElementById("newFirst").value) {
            this.setState({ firstNameError: true });
            valid = false;
        }
        else {
            this.setState({ contactFirst: document.getElementById("newFirst").value });
        }
  
        if (!document.getElementById("newLast").value) {
            this.setState({ lastNameError: true });
            valid = false;
        }
        else {
            this.setState({ contactLast: document.getElementById("newLast").value });
        }
         
        if (!document.getElementById("newEmail").value) {
            this.setState({ emailError: true });
            valid = false;
        }
        else {
            this.setState({ contactEmail: document.getElementById("newEmail").value });
        }
        return valid;
    }
    handleSubmitContact = async () => {
        const requiredFields = await this.validateRequiredFields();
        if (!requiredFields || this.state.badEmail) {
            return;
        }
        this.setState({
            contactStatus: 1,
            contactFormSubTitle: null,
            disableContactButtons: true
        });

        setTimeout(this.saveContact(), 2000);
    }
    handleCommentCompose = (e) => {
        var val = e.target.value;
        this.setState({ newCommentValue: val });

    }
    handleValidateEmail = () => {
        this.setState({ emailError: false });
        var email = document.getElementById("newEmail").value;
        if (!validateEmail(email)) {
            this.setState({ badEmail: true })
        }
        else {
            this.setState({ badEmail: false })
        }

    }
    saveContact = async () => {
        var comment = this.state.newCommentValue;
        var first = this.state.contactFirst;
        var last = this.state.contactLast;
        var email = this.state.contactEmail;

        var ip;
        try {
            ip = await getIP();       
        }
        catch (err) {
            console.log(err);
            ip = "Unable to retreive IP Address";
        }
        const contactID = await addContactFormSubmission(this.props.webPageID, first, last, email, ip, comment);

        if (contactID) {
            this.setState({
                contactStatus: 2,
                contactFormSubTitle: null,
                disableContactButtons: true,
                contactFormTitle:
                    <>
                        Contact Received
                        <DoneIcon style={{ marginLeft:"8px", color: "green" }} />
                    </>,
            });

            if (this.props.notify) {
                const now = new Date();
                let h = "<div style='background:#f0f0f0;padding:12px;'>";
                h += "<h2 style='text-align:center;'>New Message From " + this.props.site + " Website</h2>";
                h += "<div style='padding-left:24px;padding-right:24px;'>";
                h += "<span><b>Sent:</b> " + now.toLocaleDateString() + " " + now.toLocaleTimeString() + "</span><br/>";
                h += "<span><b>Name:</b> " + this.state.contactFirst + " " + this.state.contactLast + "</span><br/>";
                h += "<span><b>Email:</b> " + this.state.contactEmail + "</span><br/>";
                if (ip) {
                    h += "<span><b>IP Address:</b> " + ip + "</span><br/>";
                }
                h += "<br/><span><b>Message:</b> " + this.state.newCommentValue + "</span><br/>";
                h += "<div style='padding-top:48px;width:100%;text-align:center;line-height:48px;'>";
                h += "<span style='padding-bottom:6px;font-weight:500;vertical-align:middle;margin-right:8px;font-size:1rem;color:#444;line-height:48px;'>Email provided by LiveSphere</span>";
                h += "<a href='https://mylivesphere.com' target='_blank'><img src='https://mylivesphere.com/Images/lscircle.png' height='48' width='48' style='vertical-align:bottom;max-height:48px;max-width:48px;' title='LiveSphere'></a>";
                h += "</div>";
                h += "</div>";
                const response = await sendEmail(this.props.notificationEmail, this.props.notificationName ?? "", 'New Website Message', h, '', "LS");
                console.log(response);
            }

        }
        else {
            this.setState({ contactStatus: 3 });
        }
    }

    //Render funcs
    contactForm = () => {
        return (
            <ModalDialog
                {...this.props}
                show={this.props.show}
                title={this.props.title ?? this.state.contactFormTitle}
                subTitle={this.state.contactFormSubTitle}
                handleClose={this.props.hide}
                handleSave={this.handleSubmitContact}
                fullWidth={true}
                saveLabel="Submit"
                maxWidth="sm"
                disableButtons={this.state.disableContactButtons}
                containedButton
            >
                {this.state.contactStatus === 0 &&
                    <Box sx={{ padding:"8px 0px"}}>
                    <SectionFieldGroup dark={this.props.dark} noShadow>
                        {this.state.firstNameError && <Alert severity="error">First name is required</Alert>}
                        <TextInput
                            dark={this.props.dark}
                            cKey="newFirst"
                            cid="newFirst"
                            id="newFirst"
                            label="First Name"
                            defaultValue=""
                            onBlur={() => this.setState({ firstNameError: false })}
                            inputProps={{ maxLength: 100 }}
                        />
                        {this.state.lastNameError && <Alert severity="error">Last name is required</Alert>}
                        <TextInput
                            dark={this.props.dark}
                            cKey="newLast"
                            cid="newLast"
                            id="newLast"
                            label="Last Name"
                            defaultValue=""
                            onBlur={() => this.setState({ lastNameError: false })}
                            inputProps={{ maxLength: 100 }}
                        />
                        {this.state.badEmail && <Alert severity="error">Invalid email address</Alert>}
                        {this.state.emailError && <Alert severity="error">Email is required</Alert>}
                        <TextInput
                            dark={this.props.dark}
                            cKey="email"
                            cid="emailAddressF"
                            id="newEmail"
                            label="Email Address"
                            onBlur={this.handleValidateEmail}
                            defaultValue={this.state.emailValue}
                            inputProps={{ maxLength: 250 }}
                        />
                        <InputGroup style={{ padding: "6px", paddingTop: "12px" }}>
                            <FormControl
                                as="textarea"
                                aria-label="With textarea"
                                id="newComment"
                                placeholder={this.state.requestDesc}
                                className="postText prettyScroll"
                                rows={5}
                                maxLength={500}
                                defaultValue=""
                                onInput={this.handleCommentCompose}
                                style={{
                                    background: this.props.dark ? '#050505' : '#fff',
                                    color: this.props.dark ? "#ccc" : "#444",
                                    borderColor: this.props.dark ? "#111" : "#ccc"
                                }}
                            />
                        </InputGroup>
                    </SectionFieldGroup>
                    </Box>
                }
                {this.state.contactStatus === 1 &&
                    <Box sx={{ textAlign: "center", padding: "24px" }}>
                        <Typography variant="subtitle1" sx={{ marginLeft: "auto", marginRight: "auto", color: (theme) => theme.palette.common.dimblack, textAlign: "center", marginTop: "24px" }}>
                            Submitting
                        </Typography>
                        <CircularProgress sx={{ color: (theme) => this.props.color ?? theme.palette.secondary.main }} />
                    </Box>
                }
                {this.state.contactStatus === 2 &&
                    <Box sx={{ textAlign: "center", padding: "24px" }}>
                        <Typography variant="subtitle2" sx={{ marginLeft: "auto", marginRight: "auto", maxWidth: "1200px", color: (theme) => theme.palette.common.dimblack, textAlign: "center", marginBottom: "48px" }}>
                            {this.state.contactFirst
                                ?
                                "Thanks for reaching out, " + this.state.contactFirst + ". We have received your message and will be back in touch shortly!"
                                :
                                "Thanks for reaching out. We have received your message and will be back in touch shortly!"
                            }
                        </Typography>
                    </Box>
                }
                {this.state.contactStatus === 3 &&
                    <Box sx={{ textAlign: "center", padding: "24px" }}>
                        <Typography variant="subtitle1" sx={{ marginLeft: "auto", marginRight: "auto", maxWidth: "1200px", color: (theme) => theme.palette.common.dimblack, textAlign: "center", marginBottom: "48px" }}>
                            There was a problem with your submission.  Please try again later.
                        </Typography>
                    </Box>
                }

            </ModalDialog>
        )
    } 

    render() {
        return this.contactForm();
    }
}

