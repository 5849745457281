import React, { Component } from 'react';

//custom components
import { UserSelector } from './UserSelector';
import { ModalDialog } from './ModalDialog';
import { CheckBoxControl } from './CheckBoxControl';
import { DateSelector } from './DateSelector';

//material ui
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';


//Database Services
import { createNotification } from '../../DataServices/NotificationService'
import { sharePhoto } from '../../DataServices/PhotoService'
import { shareDocument } from '../../DataServices/DocumentService'
import { shareNote } from '../../DataServices/NoteService'
import { shareList } from '../../DataServices/ListService'
import { shareLink } from '../../DataServices/LinkService'

export class ContentShareComposer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUsers: [],
            selectedEmails: [],
            subTitle: "Find users to share content by entering their name or email address.",
            sendNotification: true,
            canEdit: false,
            hasExpiration: false,
            expiration: new Date()
        };  
    } 


    handleShareContent = async () => {
        try {
            for (var i = 0; i < this.state.selectedUsers.length; i++) {
                switch (this.props.contentType) {
                    case "photo":
                        await sharePhoto(this.props.contentID, this.props.user.userID, this.state.selectedUsers[i].userID, false, this.props.appIdentifier ?? "LS", this.state.canEdit, this.state.hasExpiration, this.state.expiration);
                        break;
                    case "document":
                        await shareDocument(this.props.contentID, this.props.user.userID, this.state.selectedUsers[i].userID, false, this.props.appIdentifier ?? "LS", this.state.canEdit, this.state.hasExpiration, this.state.expiration);
                        break;
                    case "note":
                        await shareNote(this.props.contentID, this.props.user.userID, this.state.selectedUsers[i].userID, false, this.props.appIdentifier ?? "LS", this.state.canEdit, this.state.hasExpiration, this.state.expiration);
                        break;
                    case "list":
                        await shareList(this.props.contentID, this.props.user.userID, this.state.selectedUsers[i].userID, false, this.props.appIdentifier ?? "LS", this.state.canEdit, this.state.hasExpiration, this.state.expiration);
                        break;
                    case "link":
                        await shareLink(this.props.contentID, this.props.user.userID, this.state.selectedUsers[i].userID, false, this.props.appIdentifier ?? "LS", this.state.canEdit, this.state.hasExpiration, this.state.expiration);
                        break;
                    default:
                        break;
                }

                if (this.props.notify && this.state.sendNotification) {
                    await this.sendNotification(this.state.selectedUsers[i].userID, this.props.contentType ? "a " + this.props.contentType : "content", this.props.contentID);
                }
            }
            this.props.handleComplete(true);
        }
        catch (err) {
            this.props.handleComplete(false);
        }

   
        this.props.hide();
    }
    sendNotification = async (user, content, refID) => {    
        await createNotification(user, 7, refID, this.props.user.userID, this.props.user.firstName + ' ' + this.props.user.lastName + ' has shared ' + content + ' with you.', this.props.appIdentifier);
    }
    content = () => {
        if (this.props.contentID) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.props.show}
                    title={this.props.title ?? "Share Content"}
                    subTitle={this.state.subTitle}
                    fullWidth={true}
                    maxWidth="sm"
                    fullHeight
                    hideScroll
                    disableButtons={true}
                >
                    <Stack spacing={1} sx={{ p: 1 }}>
                        <UserSelector
                            dark={this.props.dark}
                            selectedUsers={this.state.selectedUsers}
                            selectedEmails={this.state.selectedEmails}
                            setSelectedUsers={(users) => this.setState({ selectedUsers: users })}
                            setSelectedEmails={(emails) => this.setState({ selectedEmails: emails })}
                            allowEmails

                        />
                        <Box sx={{ display: "flex", textAlign: "right" }}>
                            <Stack>
                                <CheckBoxControl dark={this.props.dark} value={this.state.sendNotification} handleCheck={(val) => this.setState({ sendNotification: val })} tooltip="Notify users of shared content?" label="Notify?" pushDown />
                                <CheckBoxControl dark={this.props.dark} value={this.state.canEdit} handleCheck={(val) => this.setState({ canEdit: val })} tooltip="Allow users to edit shared content" label="Allow Edit" pushDown />
                                <Box display="flex">
                                    <CheckBoxControl dark={this.props.dark} value={this.state.hasExpiration} handleCheck={(val) => this.setState({ hasExpiration: val })} tooltip="Set expiration for shared content" label="Set Expiration" pushDown />
                                    {this.state.hasExpiration && <DateSelector dark={this.props.dark} value={this.state.expiration} handleChange={(val) => this.setState({ expiration: new Date(val) })} label="Expiration Date" minValue={new Date()} narrow />}
                                </Box>

                            </Stack>

                            <Box sx={{ flexGrow: 1 }} />
                            <Box display="flex">
                                <Stack>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <Box display="flex">
                                        <Button onClick={this.props.handleCancel} sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}>
                                            Cancel
                                        </Button>
                                        {(this.state.selectedUsers.length > 0 || this.state.selectedEmails.length > 0) &&
                                            <Button onClick={this.handleShareContent} sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}  >Share </Button>
                                        }
                                    </Box>

                                </Stack>

                            </Box>
                        </Box>
                    </Stack>
                </ModalDialog>

            );
        }
        return null;
    }


    render() {
        return this.content();
    }
}
