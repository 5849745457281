
export async function getMyEvents(appid) {
    var url = "event/getmyevents?appid=" + appid;
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getMyPastEvents(appid) {
    var url = "event/getmypastevents?appid=" + appid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getEventsImAttending(appid) {
    var url = "event/geteventsimattending?appid=" + appid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getAllEventsIAttended(appid) {
    var url = "event/getalleventsiattended?appid=" + appid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getEventInfo(id) {
    var url = "event/getevent?id=" + id;
    const response = await fetch(url);

    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
  
        return data;
    return null;
}
export async function getAttendees(id) {
    var url = "event/getattendees?id=" + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function createEvent(title, desc, type, ownerid, ownertype, start, end, website, email, phone, hostedby, locationdesc, street, city, state, zip, ispublic, admission, appid) {
    const req = { OwnerID: ownerid, OwnerType: ownertype, Title: title, Desc: desc, Type: type, Start: start, End: end, Website: website, Email: email, Phone: phone, HostedBy: hostedby, LocationDesc: locationdesc, Street: street, City: city, State: state, Zip: zip, Public: ispublic, Admission: admission, AppID: appid };

    const response = await fetch("event/createevent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function editEvent(id, title, desc, type, start, end, website, email, phone, hostedby, locationdesc, street, city, state, zip, ispublic, admission) {
    const req = { EventID: id, Title: title, Desc: desc, Type: type, Start: start, End: end, Website: website, Email: email, Phone: phone, HostedBy: hostedby, LocationDesc: locationdesc, Street: street, City: city, State: state, Zip: zip, Public: ispublic, Admission: admission };
    console.log(req);
    const response = await fetch("event/editevent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function deleteEvent(id) {
    const req = { EventID: id };
    const response = await fetch("event/deleteevent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function addAttendee(eventid, userid) {
    const req = { EventID: eventid, UserID: userid };
    const response = await fetch("event/addattendee", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function removeAttendee(id) {
    const req = { AttendeeID: id };
    const response = await fetch("event/removeattendee", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function addCoverPhoto(eventid, photoid) {
    const req = { EventID: eventid, PhotoID: photoid };
    const response = await fetch("event/addcoverphoto", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function addSchedule(eventid, title, desc, ispublic) {
    const req = { EventID: eventid, Title: title, Desc: desc, Public: ispublic };
    const response = await fetch("event/addschedule", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function editSchedule(id, title, desc, ispublic) {
    const req = { ScheduleID: id, Title: title, Desc: desc, Public: ispublic };
    const response = await fetch("event/editschedule", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function removeSchedule(id) {
    const req = { ScheduleID: id };
    const response = await fetch("event/removeschedule", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function addScheduleItem(scheduleid, title, desc, start, end) {
    const req = { ScheduleID: scheduleid, Title: title, Desc: desc,Start: start, End: end };
    const response = await fetch("event/addscheduleitem", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function editScheduleItem(itemid, title, desc, start, end) {
    const req = { ItemID: itemid, Title: title, Desc: desc, Start: start, End: end };
    const response = await fetch("event/editscheduleitem", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function removeScheduleItem(itemid) {
    const req = { ItemID: itemid };
    const response = await fetch("event/removescheduleitem", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function createBodyRow(id, numcols) {
    const req = { EventID: id, NumCols: numcols };
    const response = await fetch("event/createbodyrow", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function moveBodyRow(id, rowid, idx) {
    const req = { EventID: id, RowID: rowid, Idx: idx };
    const response = await fetch("event/movebodyrow", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function updateRowColCount(rowid, numcols) {
    const req = { RowID: rowid, NumCols: numcols };
    const response = await fetch("event/updaterowcolcount", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function deleteRow(rowid) {
    const req = { RowID: rowid };
    const response = await fetch("event/deleterow", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function addContent(id, rowid, idx, contenttype, textcontent, photoid) {
    const req = { EventID: id, RowID: rowid, Idx: idx, ContentType: contenttype, TextContent: textcontent, PhotoID: photoid };
    const response = await fetch("event/addcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function editTextContent(id, textcontent) {
    const req = { ID: id, TextContent: textcontent };
    const response = await fetch("event/edittextcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function updateContentPhotoID(id, photoid) {
    const req = { ID: id, PhotoID: photoid };
    const response = await fetch("event/updatecontentphotoid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function moveContent(id, idx) {
    const req = { ID: id, Idx: idx };
    const response = await fetch("event/movecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function deleteContent(id) {
    const req = { ID: id };
    const response = await fetch("event/deletecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}