import React, { Component } from 'react';
//Custom
import { ContentContainer } from '../Utilities/ContentContainer';
//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faHome } from '@fortawesome/free-solid-svg-icons'
//Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { CardActionArea } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export class Content extends Component {

    contentHome = () => {
        //returns home view for contnet, showing clickable
        //boxes for each available content type 
        if (this.props.contentTypes) {
            return (
                <Box
                    className="prettyScroll"
                    sx={{
                        height: { xs: "calc(100vh - 112px)", sm: "calc(100vh - 120px)", lg: "calc(100vh - 64px)" },
                        display: 'flex',
                        flexGrow: 1,
                        background: (theme) => this.props.dark ? "#050505" : theme.palette.backgroundMain.light,
                    }}
                >
                    <Stack
                        sx={{
                            width: "100%",
                            pb: { xs: 7, sm: "unset" },
                        }}
                    >
                        <Box
                            sx={{
                                boxShadow: this.props.dark ? 0 : 0,
                                pt: 1,
                                mt: 0,
                                backgroundColor: theme => this.props.dark ? "transparent" : "transparent",
                                color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black
                            }}>
                            <Typography align="center" variant="h4" sx={{ fontWeight: { xs: 500, sm: 700 }, fontSize: { xs: "1rem", sm: "2rem" }, py:.5}}>
                                My Content Library
                            </Typography>
                        </Box>
                        <Typography
                            align="center"
                            variant="h6"
                            sx={{
                                display: { xs: "none", md: "block" },
                                px: 1,
                                mt: { xs: "8px!important", sm: "16px!important" },
                                fontSize: { xs: ".8rem", sm: "1.2rem" },
                                fontWeight: 600,
                                color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack
                            }}
                        >
                            Create, upload and stash all of your stuff.  Use it as your own private library or create content to share.
                        </Typography>
                        <Grid
                            container
                            spacing={{ xs: 2, sm: 2, lg: 3, xl: 3 }}
                            columns={{ xs: 2, sm: 2, md: 4 }}
                            sx={{
                                maxWidth: "1440px",
                                width: "100%!important",
                                mt: { xs: "0px!important", sm: 2 },
                                mx: "auto!important",
                                pr: { xs: 1, sm: 3 },
                                pb: 0
                            }}
                        >
                            {this.props.contentTypes.map((item, index) => {
                                if (item.value !== null)
                                    return (
                                        <Grid
                                            item
                                            key={"ct" + item.value}
                                            xs={1} sm={1} md={1} lg={1} xl={1}
                                            sx={{
                                                pl: { xs: "8px!important", sm: "16px!important", lg: "24px!important", xl: "32px!important" },
                                                pt: { xs: "8px!important", sm: "16px!important", lg: "24px!important", xl: "32px!important" },
                                                transition: "transform .2s",
                                                "&:hover": {
                                                    transform: {
                                                        xs: "scale(1.01)", sm: "scale(1.02)"
                                                    }
                                                }
                                            }}
                                        >
                                            {this.contentCard(item)}
                                        </Grid>
                                    )
                                return null;
                            })
                            }
                        </Grid>
                    </Stack>

                </Box>
            )
        }
        return null;
    }
    buttonStack = () => {
        //return vertical buttons on left side
        if (this.props.contentTypes && this.props.selectedContentType !== null) {
            return (
                <Stack
                    sx={{
                        width: "40px",
                        background: theme=>  this.props.dark ?  "black" : theme.palette.secondary.main,
                        borderBottom: this.props.dark ? "1px solid #151515" : undefined,
                    }}
                >
                    <Tooltip key="contenthome" title="Content Home" placement="right">
                        <IconButton onClick={(e) => this.props.setSelectedContentType(null)}>
                            <FontAwesomeIcon icon={faHome} style={{ fontSize: this.props.width < 600 ? "1.1rem" : "1.3rem", color: '#eee', marginBottom: "8px" }} />
                        </IconButton>
                    </Tooltip>
                    {this.props.contentTypes.map((item, index) => {
                        if (index > 0) {
                            const selected = this.props.selectedContentType === item.value;
                            return (
                                <Tooltip key={item.label} title={item.label} placement="right">
                                    <IconButton
                                        sx={{
                                            color: "#eee",
                                            backgroundColor: selected ? "#222" : "unset",
                                            borderRadius: "0px",
                                            borderRight: theme => selected ? `solid 3px ${theme.palette.secondary.main}` : "none"
                                        }} onClick={(e) => this.props.setSelectedContentType(item.value)}>
                                        <FontAwesomeIcon icon={item.icon} style={{ fontSize: this.props.width < 600 ? "1rem" : "1.2rem", color: "inherit", marginBottom: "8px" }} />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                        return null;
                    })
                    }
                </Stack>
            )
        }
        return null;
    }
    contentCard = (item) => {
        return (
            <Card
                sx={{
                 //  height: { xs: "calc(((100vh - 152px)/4) - 8px)", sm: "200px", md: this.props.contentTypes.length > 5 ? "250px" : "300px" },
                    height: { xs: "100px", sm: "200px", md: this.props.contentTypes.length > 5 ? "250px" : "300px" },
                    background: (theme) => this.props.dark ? theme.palette.card.dark : theme.palette.card.light,
                }}
                raised
                elevation={this.props.dark ? 1 : 8}
            >
                <CardActionArea onClick={(e) => !item.disabled && this.props.setSelectedContentType(item.value)} sx={{ height:"100%"}}>
                    <CardContent sx={{ textAlign: "center", p: { xs: 1.5, sm: 2 }, color: theme => theme.palette.secondary.main }}>
                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                            <FontAwesomeIcon icon={item.icon} style={{ fontSize: "2.5rem", color: "inherit", marginBottom: "8px" }} />
                        </Box>
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                            <FontAwesomeIcon icon={item.icon} style={{ fontSize: "4rem", color: "inherit", marginBottom: "8px" }} />
                        </Box>
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        <Box display="flex" sx={{ width: "100%", justifyContent: "center" }}>
                            <Typography gutterBottom variant="h6" component="div" sx={{ mb: { xs: 0, sm: 1 }, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}>
                                {item.label}
                            </Typography>
                        </Box>
                        </Box>
                        <Box sx={{ display: { xs: "block", sm: "none" } }}>
                            <Box display="flex" sx={{ width: "100%", justifyContent: "center" }}>
                                <Typography component="div" sx={{ fontWeight:600, fontSize:".9rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                                    {item.label}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography variant="body2" sx={{ display: { xs: "none", sm: "block" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            {item.desc}
                            </Typography>
       

                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
    content = () => {
      //  if (this.props.appIdentifier === "LS") {
            if (this.props.selectedContentType === null) {
                return this.contentHome();
            }
            const contentProps = this.props.contentTypes.find(x => x.value === this.props.selectedContentType);
            return (
                <ContentContainer
                    {...this.props}
                    tabValue={this.props.tabPanelState[contentProps.contentType]}
                    setTabValue={this.props.updateTabPanelState}
                    contentType={contentProps.contentType}
                    postContentType={contentProps.postType}
                    height={this.props.height}
                    width={this.props.width}
                    folders={this.props.content[contentProps.folders]}
                />
            )   
       // }
    }

    render() {
        return (
            <Box display="flex">
                {this.buttonStack()}
                {this.content()}
            </Box>
        )
    }
}
