import {  newList, editList, deleteList,} from '../DataServices/ListService'
const itemFields = [];

const folderFields = [
    {
        type: "text",
        label: "List Name",
        fieldName: "name",
        value: "",
        maxLength: 100,
        required: true
    },
]

const functions = {
    createItem: null,
    editItem: null,
    deleteItem: null,
    moveItem: null,
    createFolder: newList,
    editFolder: editList,
    deleteFolder: deleteList,
}
const options = {
    notify: true,
    noItemClick: true,
    edit: false,
    delete: true,
    share: true,
    post: false,
    copy: true
}
const listProperties = {
    itemFields: itemFields,
    folderFields: folderFields,
    functions: functions,
    options: options
}

export default listProperties;

