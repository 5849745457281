import React, { PureComponent } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export class CheckBoxControl extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Tooltip title={this.props.tooltip && this.props.tooltip} enterDelay={300}>
                <FormControlLabel
                    key={this.props.attr + "ctl"}
                    sx={{
                        paddingLeft: "16px", marginTop: "auto", marginBottom: !this.props.pushDown && "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black
                    }}
                    control={<Checkbox defaultChecked={this.props.value} onChange={(e) => this.props.handleCheck(e.target.checked)} color="secondary" size="small" />}
                    label={
                        <Typography sx={{ fontSize: ".7rem" }}>
                            {this.props.label && this.props.label}
                        </Typography>
                    }
                />
            </Tooltip>
        )

    }
}
