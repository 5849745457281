import React, { Component } from 'react';

//Custom components
import { DropUploader } from '../../Utilities/DropUploader'
import { ModalDialog } from '../../Utilities/ModalDialog'

//Material UI
import { Typography, Stack } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

//Data services
import { savePhoto } from '../../../DataServices/PhotoService'


export class PhotoUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            library: this.props.library,
            itemCount: 0,
            processing:false
        }
    }

    updateParent = (photo, file) => {       
        var reader = new FileReader();
        reader.onload = e => {
            const { result } = e.target;
            var base64result = result.split(',')[1];
            this.props.updateParent(photo, base64result);
            this.updateStateForNewPhoto(base64result);
        }
        reader.readAsDataURL(file);
    }
    handleSavePhoto = async (file) => {
        const newPhoto = await savePhoto(file, this.state.library.id, this.props.appIdentifier);
        if (newPhoto) {
            this.updateStateForNewPhoto(newPhoto.thumb);
            this.props.updateParent(newPhoto);
        }
    }
    updateStateForNewPhoto = (base64result) => {
        //Update State to display new photo
        const itemCount = this.state.itemCount + 1;
        this.setState({
            currentUpload: base64result,
            itemCount: itemCount
        });
    }
    handleClose = () => {
        this.setState({
            currentUpload: "",
            itemCount: 0
        })
        this.props.handleClose();
    }
    toggleProcessing = (status) => {
        if (status != null) {
            this.setState({ processing: status });
        }
        else {
            this.setState({ processing: !this.state.processing });
        }  
    }

    summaryText = (count) => {
        if (count === 1) {
            return "Uploaded 1 photo";
        }
        else {
            return "Uploaded " + count + " photos";
        }
    }
    uploadingMessage = () => {
        return (
            <Box sx={{ display: "flex", width: "100%",  my: "auto", minHeight: { md: 300 }, minWidth: { sm: 500 }, textAlign: "center", background: "transparent" }}>
                <Box sx={{ m: "auto" }}>
                    <Typography sx={{ color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, mb: 2, fontWeight: 600 }}>
                        Uploading
                    </Typography>
                    <CircularProgress color="secondary" />
                </Box>
            </Box>
        )
    }
    uploader = () => {
        return (
            <DropUploader
                dark={this.props.dark}
                type={0}
                multi={this.props.multi}
                label="Project Profile Image"
                fileType="photo"
                onDrop={this.updateStateForNewPhoto}
                handleSave={this.handleSavePhoto}
                photoB64={this.state.currentUpload ? this.state.currentUpload : ""}
                height={250}
                width={this.props.width}
                toggleProcessing={this.toggleProcessing}
            />
            )
    }
    alerts = () => {
        return (
            <Box sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                mt: this.state.itemCount > 0 || this.state.processing ? 2 : 0,
                px:this.props.width < 600 ? 1 : 2,
            }}>
                <Stack>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", }}>
                        {(!this.state.processing && this.state.itemCount > 0) &&
                            <Typography sx={{ display: "inline-block", mr:2, color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                {this.summaryText(this.state.itemCount)}
                            </Typography>}
                        {(!this.state.processing && this.state.itemCount > 0) && <CheckCircleOutlineIcon color="success" />}
                        {this.state.processing && 
                            <Box sx={{ m: "auto", display:"flex" }}>
                                <Typography sx={{  mr: 2, my:"auto", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                    Uploading
                                </Typography>
                                <CircularProgress color="secondary"/>
                            </Box>
                        }
     
                    </Box>
                    {(!this.state.processing && this.state.itemCount > 0) && 
                        <Typography sx={{ mt: 2, fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            You can crop, rotate, and apply filters by clicking on the photo from your content library.
                        </Typography>
                    }
                </Stack>
            </Box>
            )
    }

    render() {
        return (
            <ModalDialog
                dark={this.props.dark}
                fullWidth={true}
                maxWidth={"xs"}
                fullScreen={this.props.width < 600}
                closeLabel="Close"
                handleClose={this.handleClose}
                show={this.props.open}
                title="Upload Photos"
            >
                <Stack sx={{ pt: { sm: 1 }, pb: { sm: 2 } }} >
                    {this.uploader()}
                    {this.alerts()}
                </Stack>

            </ModalDialog>

        )
    }
}

