import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

export class SelectionChip extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        let color = "#ccc";
        if (!this.props.selected && !this.props.dark) {
            color =  "#444";
        }
        return (
            <Box sx={{ padding: this.props.padding ?? "8px" }} key={this.props.value}>
                <Chip
                    key={'chip' + this.props.label}
                    variant={this.props.variant ?? "outlined"}
                    label={this.props.label}
                    color={"secondary"}
                    size="medium"
                    clickable
                    onClick={this.props.onClick}
                    sx={{ color: color, height: { xs: "28px", sm: "32px" }, fontSize: { xs: ".775rem", sm: ".8125rem" } }}
                />
            </Box>
        );
    }
}
