import React, { Component } from 'react';

import { UserAvatar } from '../Utilities/UserAvatar'
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
import { TextInput } from '../Utilities/TextInput';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

//Database Services
import { createNotification } from '../../DataServices/NotificationService'

export class Invitation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
            newMessageRecipients: [],
            newEmailRecipients: [],
            emailError: "",
            customMessage: "You have been invited to join this group"
        };  
    } 

    deleteNewEmailRecipientHandler = (idx) => {
        this.setState(state => {
            const newEmailRecipients = state.newEmailRecipients.filter((item, j) => idx !== j);
            return { newEmailRecipients, };
        });
    }

    deleteNewRecipientHandler = (idx) => {
        this.setState(state => {
            const newMessageRecipients = state.newMessageRecipients.filter((item, j) => idx !== j);
            return { newMessageRecipients, };
        });
    }

    handleSelectMessageRecipient = async (idx) => {
        const user = this.state.searchResults[idx];
        this.addExistingUser(user);
    }

    handleSelectEmailRecipient = async (email) => {
        this.setState(
            state => {
                const newEmailRecipients = state.newEmailRecipients.concat(email);;
                return { newEmailRecipients };
            }
        );
        document.getElementById("newThreadMember").value = "";
        document.getElementById("newThreadMember").focus();
    }

    addExistingUser = (user) => {
        this.setState(
            state => {
                const newMessageRecipients = state.newMessageRecipients.concat(user);
                return { newMessageRecipients, searchResults: [] };
            }
        );
        document.getElementById("newThreadMember").value = "";
        document.getElementById("newThreadMember").focus();
    }

    handleKeyPress = async (event) => {
        if (event.keyCode === 9) {
            event.preventDefault();
        }
        if (event.key === 'Enter' || event.keyCode === 9) {
            const name = event.target.value;
            if (this.validateEmail(name)) {
                const user = await this.checkIfUserExists(name);
                if (user) {
                    this.addExistingUser(user);
                }
                else {
                    this.handleSelectEmailRecipient(name);
                }

            }
        }
    }

    validateEmail = (email) => {
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!email || reg.test(email) === false) {
            this.setState({ emailError: "Invalid email" });
            return false;
        }
        this.setState({ emailError: "" });
        return true;
    }

    validateMember = async (name) => {
        if (!this.state.validating) {
            this.setState({ validating: true });
            if (name) {
                const response = await fetch('messenger/searchusersbyname?name=' + name)
                const searchResults = await response.json();
                if (searchResults && searchResults.length > 0) {
                    this.setState({ searchResults });
                    //for (var i = 0; i < searchResults.length; i++) {
                    //    let avatar = await this.getUserInfo(searchResults[i].userID);
                    //    if (avatar) {
                    //        searchResults[i].avatarB64 = avatar;
                    //        this.setState({ searchResults });
                    //    }

                    //}
                }
                else {
                    this.setState({ searchResults: [] });
                }
            }
            else {
                this.setState({ searchResults: [] });
            }
            this.setState({ validating: false });
        }

    }

    checkIfUserExists = async (email) => {
        if (email) {
            const response = await fetch('messenger/getuserinfobyemail?email=' + email);
            return await response.json();
        }
    }

    getUserInfo = async (id) => {
        if (id) {
            const response = await fetch('home/getuseravatar?userid=' + id);
            const userinfo = await response.json();
            if (userinfo) {
                return userinfo.avatarB64;
            }
        }
        return null;
    }

    handleSaveInvitation = async () => {
        switch (this.props.inviteType) {
            case "share":
                break;
            default:
                let groupid = this.props.groupID;
                let groupName = this.props.groupName;
                let groupType;
                switch (this.props.groupType) {
                    case "Family":
                        groupType = 1;
                        break;
                    case "Client":
                        groupType = 3;
                        break;
                    case "Team":
                        groupType = 2;
                        break;
                    case "Employee":
                        groupType = 4;
                        break;
                    case "Attendee":
                        groupType = 5;
                        break;
                    case "Admin":
                        groupType = 6;
                        break;
                    default:
                        groupType = 0;
                        break;
                }

                var message = this.props.inviteMessage ?? "You have been invited to join the " + this.props.groupName;
    
                const users = [];
                if (this.state.newMessageRecipients && this.state.newMessageRecipients.length > 0) {
                    for (var i = 0; i < this.state.newMessageRecipients.length; i++) {
                        const req = { GroupID: groupid, GroupType: groupType, GroupName: groupName, NewMemberUserID: this.state.newMessageRecipients[i].userID, CustomMessage: message };
                        const responseUID = await fetch('org/addmember', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(req)
                        });
                        if (responseUID.status === 200) {
                            const usr = await responseUID.json()
                            users.push(usr);
                        }


                        //if family, go ahead and add the user.
                        if (this.props.groupType === "Family") {
                            const req2 = { FamilyID: groupid, UserID: this.state.newMessageRecipients[i].userID, Role: 1, IsAdmin: false, IsPrimary: false };
                            await fetch('family/createfamilymember', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(req2)
                            });
                        }
                        //if client user, go ahead and add the user.
                        if (this.props.groupType === "Client" && this.props.businessID) {
                            const req3 = { ClientID: groupid, BusinessID: this.props.businessID, UserID: this.state.newMessageRecipients[i].userID, Role: 1, IsAdmin: false, IsPrimary: false };
                            const invite = await fetch('business/createclientuser', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(req3)
                            });
                            let message = (this.props.user.displayName ? this.props.user.displayName : "A user") + " has invited you to join the " + this.props.groupName + " group as a client of " + this.props.parentName;
                            this.sendNotification(this.state.newMessageRecipients[i].userID, message, invite.id);
                        }
                        //if admin user, go ahead and add the user.
                        if (this.props.groupType === "Admin" && this.props.businessID) {
                            const req5 = { BusinessID: this.props.businessID, UserID: this.state.newMessageRecipients[i].userID };
                            const invite = await fetch('business/createadminuser', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(req5)
                            });
                            let message = (this.props.user.displayName ? this.props.user.displayName : "A user") + " has invited you to join the " + this.props.groupName + " group as an admin user";
                            this.sendNotification(this.state.newMessageRecipients[i].userID, message, invite.id);
                        }

                    }
                }
                if (this.state.newEmailRecipients && this.state.newEmailRecipients.length > 0) {

                    for (var i = 0; i < this.state.newEmailRecipients.length; i++) {

                        const req = { GroupID: groupid, NewMemberEmail: this.state.newEmailRecipients[i], CustomMessage: message, GroupName: groupName, GroupType: groupType };
                        const responseEmail = await fetch('org/addmember', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(req)
                        })
                        if (responseEmail.status === 200) {
                            const eml = await responseEmail.json();
                            users.push(eml);
                        }

                        //if client user, go ahead and add the user.
                        if (this.props.groupType === "Client" && this.props.businessID) {
                            const req4 = { ClientID: groupid, BusinessID: this.props.businessID, UserID: this.state.newEmailRecipients[i], Role: 1, IsAdmin: false, IsPrimary: false };
                            const invite2 = await fetch('business/createclientuser', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(req4)
                            });
                        }
                        //if admin user, go ahead and add the user.
                        if (this.props.groupType === "Admin" && this.props.businessID) {
                            const req7 = { BusinessID: this.props.businessID, UserID: this.state.newEmailRecipients[i] };
                            const invite = await fetch('business/createadminuser', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(req7)
                            });
                        }
                    }
                }
                console.log(users);
                if (this.props.updateInviteList) this.props.updateInviteList(users);

                this.setState({
                    newMessageRecipients: [],
                    newEmailRecipients: []
                })

                break;

        }
        this.props.handleCancel();
    }

    sendNotification = async (user, message, refID) => {
      
        await createNotification(user, 7, refID, this.props.user.userID, message, this.props.appIdentifier);
    }

    render() {

        const searchResults = ( 
            <>
                {(this.state.searchResults && this.state.searchResults.length > 0) &&
                    <Box sx={{
                        background: (theme) => this.props.dark ? theme.palette.component.dark : theme.palette.component.light,
                        color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                        padding: "6px, 16px",
                        maxHeight: "300px"
                    }}
                        className="prettyScroll" >
                        <List>
                            {this.state.searchResults.map((user, index) => {
                                return (
                                    <ListItem
                                        key={user.userID}
                                    >
                                        <ListItemButton onClick={() => this.handleSelectMessageRecipient(index)} >
                                            <ListItemAvatar>
                                                <UserAvatar
                                                    id={user.userID}
                                                    user={user}
                                                    dark={this.props.dark}
                                                    loaded={true}
                                                    activeUser={this.props.user}
                                                    appIdentifier={this.props.appIdentifier}
                                                    hideName={true}
                                                    small
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={user.firstName + " " + user.lastName} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                }
            </>
            )

        const invitee = (user, idx) => {
            return (
                <Chip
                    color="secondary"
                    label={user.firstName + " " + user.lastName}
                    onDelete={() => this.deleteNewRecipientHandler(idx)}
                    avatar={
                        <UserAvatar
                            id={user.userID}
                            user={user}
                            dark={this.props.dark}
                            loaded={true}
                            activeUser={this.props.user}
                            appIdentifier={this.props.appIdentifier}
                            hideName={true}
                            tiny
                        />
                    }
                />
                )
        }

        const emailInvitee = (email, idx) => {
            return (
                <Chip
                    color="warning"
                    label={email}
                    onDelete={() => this.deleteNewEmailRecipientHandler(idx)}
                />
            )
        }

        const inviteeList = ( <Box>
                <Typography variant="body1" color={(theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black} sx={{ padding: "6px", fontSize: ".9rem" }}>
                    Invite by Livesphere Accout
                </Typography>
                <Stack direction="row" spacing={1}>
                    {this.state.newMessageRecipients.map((user, index) => {
                        return invitee(user, index)
                    })}
                </Stack>
            </Box>)

        const emailInviteeList = (  <Box>
                <Typography variant="body1" color={(theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black} sx={{ padding: "6px", fontSize: ".9rem" }}>
                    Invite by email
                </Typography>
                <Stack direction="row" spacing={1}>
                    {this.state.newEmailRecipients.map((email, index) => {
                        return emailInvitee(email, index)
                    })}
                </Stack>
            </Box> )

        const input = ( <TextInput {...this.props}
            cKey="CnewThreadMember"
            cid="CnewThreadMember"
            id="newThreadMember"
            label="Start Typing Name"
            defaultValue=""
            onChange={(e) => this.validateMember(e.target.value)}
            onKeyPress={this.handleKeyPress} />)

        const cancelButton = (<Button
            onClick={this.props.handleCancel}
            sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
        >
            Cancel
        </Button>)

        const sendButton = (
            <Button
                sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
                    onClick={this.handleSaveInvitation}
            >
                Save
            </Button>)

        return (
            <Stack spacing={1}>
                <SectionFieldGroup dark={this.props.dark}>
                    {input}
                    {this.state.searchResults && searchResults}
                </SectionFieldGroup>

                {(this.state.newMessageRecipients && this.state.newMessageRecipients.length > 0) && inviteeList}
                {(this.state.newEmailRecipients && this.state.newEmailRecipients.length > 0) && emailInviteeList}

                <Box sx={{ paddingTop: "16px", textAlign: "right" }}>
                <Stack direction="row" spacing={2} sx={{ justifyContent:"end"}}>
                    {cancelButton}
                    {((this.state.newMessageRecipients && this.state.newMessageRecipients.length > 0) || (this.state.newEmailRecipients && this.state.newEmailRecipients.length > 0)) && sendButton }
                    </Stack>
                </Box>
            </Stack>

        );
    }
}
