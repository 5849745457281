import React, { Component } from 'react';
import { ContextMenu } from '../../Utilities/ContextMenu'
import { ItemDeleter } from '../../Utilities/ItemDeleter'
import { ItemCreator } from '../../Utilities/ItemCreator';
import { TextInput } from '../../Utilities/TextInput'
import { moveItem, newItem, editItem, deleteItem, checkOffItem, uncheckOffItem } from '../../../DataServices/ListService'
import { moveInArray} from '../../../DataServices/Utility'
//Material-UI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

//Drag and Drop
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const getListStyle = isDraggingOver => ({
    opacity: isDraggingOver ? .8 : 1,
});
const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
});

export class ListItemContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contextMenu: null,
            showEditItem: false,
            selectedItem: null,
            contextMenuListItems: [
                {
                    callBack: this.handleShowEditItem,
                    label: "Edit"
                },
                {
                    callBack: this.handleShowConfirmDeleteItem,
                    label: "Delete"
                },
            ],
            showConfirmDeleteItem: false,
        };
    }
    //handlers
    handleShowConfirmDeleteItem = () => {
        this.setState({
            contextMenu: null,
            showConfirmDeleteItem: true
        });
    }
    handleShowEditItem = () => {
        this.setState({
            showEditItem: true,
            contextMenu: null
        })
    }
    handleContextMenu = (e, idx) => {
        e.preventDefault();
        this.setState({
            selectedItem: this.props.list.listItems[idx],
            selectedItemIDX: idx,
        });
        if (this.state.contextMenu === null) {
            this.setState({
                contextMenu: {
                    mouseX: e.clientX - 2,
                    mouseY: e.clientY - 4,
                },
            })
        }
        else {
            this.setState({ contextMenu: null,  });
        }
    }
    handleKeyPress = (event, value, ele) => {
        if (event.key === 'Enter') {
            this.handleAddItem(value, ele);
        }
    }
    handleUpdateItems = (items) => {
        const folders = this.props.folders;
        folders[this.props.tabValue - 1].listItems = items;
        this.props.updateData(this.props.contentType, folders);
    }
    handleMoveItem = async (id, idx, oldIdx) => {
        //Update UI
        var items = [...this.props.list.listItems];
        moveInArray(items, oldIdx, idx);
        this.handleUpdateItems(items);

        //Update DB
        await moveItem(id, idx);
    }
    handleAddItem = async (value) => {
        const id = await newItem(this.props.list.id, value);
        var newIdx;
        if (this.props.list.listItems) {
            newIdx = this.props.list.listItems.length;
            const newItem = { id: id, value: value, idx: newIdx };
            const items = this.props.list.listItems;
            items.push(newItem);
            this.handleUpdateItems(items);
        }
        else {
            const firstItem = { id: id, value: value, idx: 0 };
            const itms = [firstItem];
            this.handleUpdateItems(itms);
        }
        this.forceUpdate();

        const ele = document.getElementById("newItemC");
        if (ele) {
            ele.value = "";
            ele.focus();
        }


    }
    handleEditItem = async (values) => {
        if (values && values.length > 0) {
            this.setState({ showEditItem: false });
            const newValue = values[0];
            const items = this.props.list.listItems;
            items[this.state.selectedItemIDX].value = newValue;
            this.handleUpdateItems(items);
            await editItem(this.state.selectedItem.id, newValue);
        }
    }
    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        this.handleMoveItem(result.draggableId, result.destination.index, result.source.index);
    }
    handleCheck = async  (id, checked, idx) => {
        if (checked) {
            const items = this.props.list.listItems;
            items[idx].status = 2;
            items[idx].itemStatus = "Checked";
            this.handleUpdateItems(items);
            await checkOffItem(id);
        }
        else {
            const items = this.props.list.listItems;
            items[idx].status = 1;
            items[idx].itemStatus = "";
            this.handleUpdateItems(items);
            await uncheckOffItem(id);
        }
    }
    handleDeleteItem = async () => {
        this.setState({ showConfirmDeleteItem: false });
        const items = this.props.list.listItems;
        items.splice(this.state.selectedItemIDX, 1);
        this.handleUpdateItems(items);
        await deleteItem(this.state.selectedItem.id);
    }

    //render functions
    newItem = () => {
        return (
            <Box
                sx={{
                    background: this.props.dark ? "#191919" : "#f9f9f9",
                    px: { xs: 0.5, sm: 1 },
                }}
            >
                <TextInput
                    dark={this.props.dark}
                    cKey="CnewItemC"
                    cid="CnewItemC"
                    id="newItemC"
                    label="Add Item"
                    defaultValue=""
                    //onBlur={this.handleAddItem}
                    onKeyPress={(e) => this.handleKeyPress(e, e.target.value, e.target)}
                    autoFocus={true}
                />
            </Box>
        )
    } 
    itemList = () => {
        if (this.props.list && this.props.list.listItems) {
            return this.props.list.listItems.map((item, idx) => (
                    <Draggable key={item.id} draggableId={item.id} index={idx} >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={
                                    getItemStyle(snapshot.isDragging, provided.draggableProps.style)
                                }
                            >
                                <ListItem
                                    key={item.id}
                                    disablePadding
                                    sx={{ background: snapshot.isDragging && (this.props.dark ? "#333" : "#BBB"), borderBottom: this.props.dark ? "solid 1px #222" : "solid 1px #bbb" }}
                                >
                                    <ListItemButton
                                        role={undefined}
                                        dense
                                        onContextMenu={(e) => this.handleContextMenu(e, idx)}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                size="small"
                                                color="secondary"
                                                checked={(item.itemStatus === 'checked' || item.itemStatus === 'Checked')}
                                                tabIndex={-1}
                                                inputProps={{ 'aria-labelledby': item.id }}
                                                onChange={(e) => this.handleCheck(item.id, e.target.checked, idx)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={item.id}
                                            primary={item.value}
                                            primaryTypographyProps={{
                                                sx: {
                                                    fontSize: ".8rem",
                                                    color: (theme) => this.props.dark ? ((item.itemStatus === 'checked' || item.itemStatus === 'Checked') ? "#888" : theme.palette.common.white) : ((item.itemStatus === 'checked' || item.itemStatus === 'Checked') ? "#aaa" : theme.palette.common.black),
                                                    textDecoration: (item.itemStatus === 'checked' || item.itemStatus === 'Checked') && "line-through"
                                                }
                                            }} />
                                    </ListItemButton>
                                </ListItem>
                            </div>
                        )}
                    </Draggable>
             
            ))
        }
        else {
            return null;
        }
    }
    itemDeleter = () => {
        return (
            <ItemDeleter
                dark={this.props.dark}
                user={this.props.user}
                handleClose={() => this.setState({ showConfirmDeleteItem: false })}
                handleDelete={this.handleDeleteItem}
                itemLabel="Item"
                show={this.state.showConfirmDeleteItem}
            />
        )
    }
    itemEditor = () => {
        if (this.state.showEditItem) {
            return (
                <ItemCreator
                    dark={this.props.dark}
                    show={this.state.showEditItem}
                    width={this.props.width}
                    user={this.props.user}
                    item="List Item"
                    handleClose={() => this.setState({ showEditItem: false })}
                    handleSave={this.handleEditItem}
                    fields={[
                        {
                            type: "text",
                            label: "List Item",
                            fieldName: "value",
                            value: this.state.selectedItem.value,
                            maxLength: 200,
                            required: true
                        },
                    ]}
                    edit
                />
            )
        }
        return null;
    }
    contextMenu = () => {
        return (
            <ContextMenu
                show={this.state.contextMenu !== null}
                dark={this.props.dark}
                onClose={() => this.setState({ contextMenu: null })}
                contextMenu={this.state.contextMenu}
                menuItems={this.state.contextMenuListItems}
                id="ListItemctx"
            />
            )
    }
    content = () => {
        return (
            <Box
                sx={{
                    mr: "auto",
                    maxWidth: "1200px",
                    px: { xs: 1, sm: 2 },
                    mt:1
                }}
            >
                {(this.props.list && this.props.list.id !== "root") &&  this.newItem() }
                {(this.props.list && this.props.list.listItems) &&
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable key={this.props.list.id + "_drop"} droppableId={this.props.list.id + "_droppable"}>
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    <Box sx={{ height: this.props.sub ? "260px" : "calc(100% - 50px)", }} className="prettyScroll">
                                        <List sx={{ width: '100%' }}>
                                            {this.itemList()}
                                        </List>
                                        {provided.placeholder}
                                    </Box>


                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                }
                {this.itemDeleter()}
                {this.itemEditor()}
                {this.contextMenu()}
            </Box>
        );
    }
    render() {
        return this.content();
    }
}
