import React, { Component } from 'react';

//Custom
import { ModalDialog } from '../Common/Utilities/ModalDialog';
import { SectionItem } from '../Common/Utilities/SectionItem';
import { ProfileImageUploader } from '../Common/Profile/ProfileImageUploader';
import { Spin } from '../Common/Utilities/Spin';
import { UserAvatar } from '../Common/Utilities/UserAvatar'

//MUI
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Switch from '@mui/material/Switch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

//Database Services
import { getVerbiage } from '../DataServices/ConstantsService'
import { newFolder } from '../DataServices/DocumentService'
import { newLibrary } from '../DataServices/PhotoService'
import { newList } from '../DataServices/ListService'
import { newNotebook } from '../DataServices/NoteService'
import { newLibrary as newVideoLibrary } from '../DataServices/VideoService'
import { createFolder } from '../DataServices/LinkService'
import { confirmInvitation, declineInvitation } from '../DataServices/OrganizationService'

export class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPhotoUploader: false,
            wizardPage: 0,
            policyAccepted: this.props.user.privacyPolicy,
            addFiles: true,
            addPhotos: true,
            welcome: [],
            dataUsage: [],
            freeSpeech: []
        };
    }

    componentDidMount() {
        this.getVerbiage();
        this.getInvitations();
    }

    //data fetchers
    getInvitations = async () => {
        const response = await fetch('org/getuserinvitations');
        if (response.status === 200) {
            const data = await response.json();
            this.setState({ invitations: data });
        }
    }
    getVerbiage = async () => {
        const verbiage = await getVerbiage();


        if (verbiage) {
            let du = "";
            let fs = "";
            if (verbiage.dataUsage) {
                for (var i = 0; i < verbiage.dataUsage.length; i++) {
                    du += verbiage.dataUsage[i];
                }
            }
            if (verbiage.freeSpeech) {
                for (var j = 0; j < verbiage.freeSpeech.length; j++) {
                    fs += verbiage.freeSpeech[j];
                }
            }

            this.setState({
                welcome: verbiage.welcome,
                dataUsage: du,
                freeSpeech: fs
            })
        }
    }

    //event handlers
    handleAcceptPrivacyPolicy = async (e) => {
        let userInfo = this.props.user;
        userInfo.privacyPolicy = e.target.checked;

        this.props.setUserInfo(userInfo);
        this.setState({ policyAccepted: true });

        const req = { Privacy: e.target.checked, AppID: this.props.appIdentifier };
        await fetch('home/toggleprivacypolicy', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })
    }
    handleComplete = async () => {
        this.setState({ wizardPage: 5, saving: true });

        const req = { Hide: true, AppID: this.props.appIdentifier  };
        let name = "User";
        if (this.props.user && this.props.user.firstName) {
            name = this.props.user.firstName;
        }

        await fetch('home/togglehidewelcome', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        });

        if (this.props.appIdentifier === "LS") {
            await newFolder(name + "'s Documents", this.props.user.userID, this.props.appIdentifier);
            await newLibrary(name + "'s Photos", this.props.user.userID, this.props.appIdentifier);
            await newList(name + "'s List", 0);
            await newNotebook(name + "'s Notebook", 0);
            await newVideoLibrary(name + "'s Videos", this.props.user.userID, this.props.appIdentifier);
            await createFolder(name + "'s Links", this.props.user.userID, this.props.appIdentifier);
        }
   
        let userInfo = this.props.user;
        userInfo.hideWelcome = true;
        this.props.setUserInfo(userInfo);
        this.setState({ saving: false });
        this.props.hide();
    }
    handleUpdateProfile = (b64) => {
        let user = this.props.user;
        user.avatar = b64;
        this.props.setUserInfo(user);
        this.setState({ showPhotoUploader: false });
    }
    handleAcceptInvite = async (id, index) => {
        const invitations = [...this.state.invitations];
        invitations.splice(index, 1);
        this.setState({ invitations });
        await confirmInvitation(id);
    }
    handleDeclineInvite = async (id, index) => {
        const invitations = [...this.state.invitations];
        invitations.splice(index, 1);
        this.setState({ invitations });
        await declineInvitation(id);
    }
    //render funcs
    profileImageUploader = () => {
        return (
            <ProfileImageUploader
                {...this.props}
                open={this.state.showPhotoUploader}
                handleSave={this.handleUpdateProfile}
                handleClose={() => this.setState({ showPhotoUploader: false })}
            />
        )
    }
    profilePicture = () => {
        return (
            <Stack spacing={3} >
                <Typography variant="subtitle2" style={{ marginTop: "auto", marginBottom: "auto" }} sx={{ fontWeight: 400, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}>
                    Next, lets set your profile picture.  Ideally, this is a "headshot" photo, but doesn't have to be.  Take or choose a photo that will represent you by clicking in the box below...
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                    <Box sx={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto" }}>
                        {this.props.user.avatar ?
                            <Paper
                                component="img"
                                elevation={4}
                                src={"data:image/png;base64, " + this.props.user.avatar}
                                style={{ height: "200px", width: "200px", objectFit: "cover", borderRadius: "8px", cursor: "pointer" }}
                                onClick={() => this.setState({ showPhotoUploader: true })}
                            />
                            :
                            <Button variant="contained" style={{ height: "200px", width: "100%", backgroundColor: this.props.dark ? "#444" : "#bbb", fontSize: "76px" }} onClick={() => this.setState({ showPhotoUploader: true })}>
                                <InsertPhotoIcon sx={{ color: (theme) => this.props.dark ? "#bbb" : "#444" }} style={{ fontSize: "76px" }} />
                            </Button>
                        }
                    </Box>
                </Box>
            </Stack>
        )
    }
    preferences = () => {
        return (
            <Stack spacing={2}>
                <Typography variant="subtitle2" style={{ marginTop: "auto", marginBottom: "auto" }} sx={{ fontWeight: 400, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}>
                    Preferences (you can change these at any time in your profile settings)
                </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.props.dark}
                            color="secondary"
                            onChange={() => this.props.setDark(!this.props.dark)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Dark Mode"
                />
            </Stack>
        )
    } 
    invitation = (item, index) => {
        return (
            <SectionItem
                itemID={item.id}
                index={index}
                dark={this.props.dark}
                chipClickEdit={false}
            >
                <Box display="flex" sx={{ width: "100%", p:1 }}>
                    {item.fromUser &&
                        <Box display="flex">
                            <UserAvatar
                                id={item.fromUser.userID}
                                user={item.fromUser}
                                dark={this.props.dark}
                                loaded={true}
                                activeUser={this.props.user}
                                appIdentifier={this.props.appIdentifier}
                                hideName={true}
                                small
                            />
                            <Box sx={{ paddingLeft: "16px" }}>
                                <Typography variant="body1" color={(theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black} sx={{ padding: "6px", fontSize: ".9rem" }}>
                                    {item.fromUser.firstName + " " + item.fromUser.lastName + " invited you to join the " + item.groupType.toLowerCase() + " group " + item.groupName + " on " + new Date(item.dateSent).toLocaleDateString()}
                                </Typography>
                            </Box>
                            <Box sx={{ flexGrow: 1 }} />
                        </Box>
                    }
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ textAlign: "right" }}>
                        <Tooltip title="Accept Invitation">
                            <IconButton onClick={() => this.handleAcceptInvite(item.id, index)} size="small">
                                <ThumbUpOffAltIcon sx={{ color: (theme) => theme.palette.secondary.main }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Decline Invitation">
                            <IconButton onClick={() => this.handleDeclineInvite(item.id, index)} size="small">
                                <ThumbDownOffAltIcon sx={{ color: (theme) => theme.palette.secondary.main }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </SectionItem>
        )
    }
    invitations = () => {
        return (
            <Stack spacing={2}>
                {this.state.invitations && this.state.invitations.length > 0 ?
                    <Typography variant="subtitle2" style={{ marginTop: "auto", marginBottom: "auto" }} sx={{ fontWeight: 400, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}>
                        It looks like other users have invited you to join existing groups.  Please review the invitations and click 'thumbs up' to accept, 'thumbs down' to reject, or simply ignore for now.
                    </Typography>
                    :
                    <Typography variant="subtitle2" style={{ marginTop: "auto", marginBottom: "auto" }} sx={{ fontWeight: 400, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}>
                        You don't have any pending invitations at this time.
                    </Typography>
                }

                <Box sx={{ textAlign: "center", paddingLeft: "16px", paddingRight: "16px" }}>
                    <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
                        {this.state.invitations && this.state.invitations.map((item, index) => {
                            return this.invitation(item, index);
                        })}
                    </Box>
                </Box>
            </Stack>)
    } 
    privacyPolicy = () => {
        return (
            <Stack spacing={2}>
                <Box sx={{  maxHeight: "400px", paddingLeft: "24px", paddingRight: "24px", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} className="prettyScroll">
                    <Typography variant="subtitle2" style={{ fontWeight: 400, }}>
                        First, please review and accept the LiveSpere data usage and privacy policy. (Scroll down if necessary)
                    </Typography>
                    <br />
                    <Typography variant="body2" style={{ fontSize: ".8rem" }}>
                        {this.state.dataUsage}
                    </Typography>
                    <br />
                    <Typography variant="body2" style={{ fontSize: ".8rem" }}>
                        {this.state.freeSpeech}
                    </Typography>
                    <br />
                    <FormControlLabel
                        sx={{ fontSize: ".9rem" }}
                        label="I have read and understand the Livesphere Privacy Policy"
                        control={<Checkbox defaultChecked={this.props.user.privacyPolicy} onChange={this.handleAcceptPrivacyPolicy} size="small" color="secondary" />}
                    />
                </Box>
            </Stack>
        )
    } 
    welcomeMessage = () => {
        return (
            <Stack spacing={2}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{ maxHeight: "400px", marginTop: "auto", marginBottom: "auto", padding: "8px 24px", verticalAlign: "middle", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} className="prettyScroll">
                            {this.state.welcome && this.state.welcome.map((item) => {
                                return (
                                    <>
                                        <Typography variant="body2" style={{ fontSize: ".9rem" }}>
                                            {item}
                                        </Typography>
                                        <br />
                                    </>
                                )
                            })}
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        )
    } 
    backButton = () => {
        switch (this.state.wizardPage) {
            case 0:
                return null;
            case 1:
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<ArrowBackIcon />}
                        style={{ minWidth: "85px", marginTop: "auto", marginBottom: "auto", marginRight: "32px", paddingLeft: "8px", paddingRight: "8px" }}
                        onClick={() => this.setState({ wizardPage: 0 })}>
                        Back
                    </Button>
                )
            case 2:
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<ArrowBackIcon />}
                        style={{ minWidth: "85px", marginTop: "auto", marginBottom: "auto", marginRight: "32px", paddingLeft: "8px", paddingRight: "8px" }}
                        onClick={() => this.setState({ wizardPage: 1 })}>
                        Back
                    </Button>
                )
            case 3:
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<ArrowBackIcon />}
                        style={{ minWidth: "85px", marginTop: "auto", marginBottom: "auto", marginRight: "32px", paddingLeft: "8px", paddingRight: "8px" }}
                        onClick={() => this.setState({ wizardPage: 2 })}>
                        Back
                    </Button>
                )
            case 4:
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<ArrowBackIcon />}
                        style={{ minWidth: "85px", marginTop: "auto", marginBottom: "auto", marginRight: "32px", paddingLeft: "8px", paddingRight: "8px" }}
                        onClick={() => this.setState({ wizardPage: 3 })}>
                        Back
                    </Button>
                )
            default:
                return null;
        }
    }
    nextButton = () => {
        switch (this.state.wizardPage) {
            case 0:
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                        onClick={() => this.setState({ wizardPage: 1 })}
                    >
                        Get Started
                    </Button>
                );

            case 1:
                if (this.state.policyAccepted) {
                    return (
                        <Button
                            variant="contained"
                            color="secondary"
                            endIcon={<ArrowForwardIcon />}
                            style={{ marginTop: "auto", marginBottom: "auto" }}
                            onClick={() => this.setState({ wizardPage: 2 })}
                        >
                            Next
                        </Button>
                    );
                }
                else {
                    return null;
                }

            case 2:
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                        onClick={() => this.setState({ wizardPage: 3 })}
                    >
                        Next
                    </Button>
                )
            case 3:
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                        onClick={() => this.setState({ wizardPage: 4 })}
                    >
                        Next
                    </Button>
                )
            case 4:
                return (
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                        onClick={this.handleComplete}
                    >
                        Start Using LiveSphere
                    </Button>
                );
            default:
                return null;
        }
    }
    footer = () => {
        return (
            <Box display="flex" >
                <Box>
                    {this.backButton()}
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                    {this.nextButton()}
                </Box>
            </Box>
        )
    } 
    setupWizard = () => {
        switch (this.state.wizardPage) {
            case 0:
                return this.welcomeMessage();
            case 1:
                return this.privacyPolicy();
            case 2:
                return this.profilePicture();
            case 3:
                return this.preferences();
            case 4:
                return this.invitations();
            default:
                return (<></>);
        }
    }
    title = () => {
        switch (this.state.wizardPage) {
            case 0:
                return "Welcome to Livesphere!";
            case 1:
                return "Data Usage and Privacy Policy";
            case 2:
                return "Profile Picture";
            case 3:
                return "General Prefences";
            case 4:
                return "Prior Invitations";
            case 5:
                return "Setting Up Profile...";
            default:
                return (<></>);
        }
    }

    contents = () => {
        return (
            <ModalDialog
                {...this.props}
                show={this.props.show}
                title={this.title()}
                fullWidth={true}
                maxWidth="lg"
                hideScroll={true}
                noFooter
            >
                {this.state.saving ?
                    <Spin dark={this.props.dark} sub />
                    :
                    <Stack spacing={3} sx={{ padding: "16px" }}>
                        <Box sx={{ background: this.props.dark ? "#111" : "#fff", padding: "16px", borderRadius: "6px" }} >
                            {this.setupWizard()}
                        </Box>
                        {this.footer()}
                    </Stack>
                }
                {this.profileImageUploader()}
            </ModalDialog>
            )
    }

    render() {
        return this.contents()
    }
}
