import React, { Component } from 'react';

//material ui
import Box from '@mui/material/Box';

export class ContentTabsItemContainer extends Component {

    //main component render
    render() {
        return (
            <Box
                sx={{ height: theme => theme.sizes.heights.contentItemsContainer, pt: { xs: "34px", sm: "56px" }  }}
                className="prettyScroll"
            >
                {this.props.children}
            </Box>
        )

    }
}
