import React, { Component } from 'react';

//Custom components
import { ModalDialog } from '../../Utilities/ModalDialog'

//Material UI
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
//Data services
import { savePhoto } from '../../../DataServices/PhotoService'
import { getPhoto } from '../../../DataServices/PhotoService'
const  pasteFromClickBoard = async () => {

    const photoID = await navigator.clipboard.readText();
    if (photoID) {
        const photo = await getPhoto(photoID);
        console.log(photo);
        if (photo && photo.fullPhoto) {
            // console.log(photo.fullPhoto);
            //var bs = atob(photo.fullPhoto);
            //var buffer = new ArrayBuffer(bs.length);
            //var ba = new Uint8Array(buffer);
            //for (var i = 0; i < bs.length; i++) {
            //    ba[i] = bs.charCodeAt(i);
            //}
            //var file = new Blob([ba], { type: "image/png" });
            //if (file) {
            //    console.log(file);
            //    return file;
            //}
            return "data:image/png;base64, " + photo.fullPhoto;
        }
        else {
            alert("Photo (ID: " + photoID + " not found");
        }
    }



}

export class ComponentImageUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            selectedFile: null,
            imgScale: 1.0,
            imgRotation: 0,

        }
    }
    setEditorRef = (editor) => (this.editor = editor);
    handleSavePhoto = async () => {

        document.body.style.cursor = 'wait';
        const canvas = await this.editor.getImage().toDataURL();
        const b64 = await fetch(canvas);
        const blob= await b64.blob();
        const file = new File([blob], this.state.selectedFile.name);

        const photo = await savePhoto(file, this.props.parentID, this.props.appIdentifier)
        if (photo) {

            this.props.handleSave(photo);
        }
        this.setState({
            processing: false,
            selectedFile: null,
            imgScale: 1.0,
            imgRotation: 0,
            itemCount: 0
        });

        document.body.style.cursor = 'default';
    }




    updateStateForNewPhoto = (base64result) => {
        //Update State to display new photo
        const itemCount = this.state.itemCount + 1;
        this.setState({
            currentUpload: base64result,
            itemCount: itemCount
        });
    }

    handleClose = () => {
        this.setState({
            currentUpload: "",
            itemCount: 0
        })
        this.props.handleClose();
    }

    toggleProcessing = (status) => {
        if (status != null) {
            this.setState({ processing: status });
        }
        else {
            this.setState({ processing: !this.state.processing });
        }  
    }
    handleDragOverDropZone = (e) => {
        e.currentTarget.classList.add("hoverdropzone");
    }
    handleDragLeaveDropZone = (e) => {
        e.currentTarget.classList.remove("hoverdropzone");
    }
    handleRotateImage = () => {
        var rot = parseInt(this.state.imgRotation) + 90;
        if (rot === 450) {
            rot = 0;
        }
        this.setState({ imgRotation: rot });
    }
    handleScaleUpImage = () => {
        var scale = parseFloat(this.state.imgScale);
        scale = scale + .1;
        this.setState({ imgScale: scale });
    }
    handleScaleDownImage = () => {
        var scale = parseFloat(this.state.imgScale);
        if (scale > .2) {
            scale = scale - .1;
        }
        this.setState({ imgScale: scale });
    }
    handleDrop = async (files) => {
        this.toggleProcessing(true);
        if (files) {
            var numAllowed = this.props.numAllowed ? this.props.numAllowed : 1;
            let max = numAllowed;
            if (files.length < numAllowed) {
                max = files.length;
            }
            for (var i = 0; i < max; i++) {
                var file = files[i];
                var extension = file.type;
                if (extension === "image/jpeg" || extension === "image/jpg" || extension === "image/png" || extension === "image/bmp") {
                    this.setState({ selectedFile: file });
                    console.log(file);
                }
            }
        }

        //clean up iu from any progress indicators, etc.
        var ele = document.getElementById("dropZone");
        if (ele) ele.classList.remove("hoverdropzone");
        this.toggleProcessing(false);
    }

    handleExistingPhoto = async () => {
        const url = await pasteFromClickBoard();
        this.setState({ selectedFile: url });
    }
    render() {
        const inst = () => {
            if (this.state.selectedFile) {
                return (
                     <Box display="flex">
                        <Typography variant="body1" sx={{ marginTop: "auto", marginBottom: "auto", fontSize: ".8rem" }} component="div">
                     {"Edit the photo using the zoom and rotate buttons below..."}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Tooltip title="Start Over">
                            <IconButton sx={{ color: (theme) => theme.palette.secondary.main }} onClick={() => this.setState({ selectedFile: null})} >
                                <UndoIcon sx={{ fontSize: "18px" }} />
                            </IconButton>
                        </Tooltip>

                    </Box>

                    )

            }
            else {
                return (
                    <Box display="flex">
                        <Typography variant="body1" sx={{ marginTop: "auto", marginBottom: "auto", fontSize: ".8rem" }} component="div">
                            {"Drag photos below, or click for file browser..."}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Tooltip title="Paste ID from clipboard">
                            <IconButton sx={{ color: (theme) => theme.palette.secondary.main }} onClick={this.handleExistingPhoto} >
                                <ContentPasteIcon sx={{ fontSize: "18px" }} />
                            </IconButton>
                        </Tooltip>

                    </Box>
                    
                    )
             /*   return "Drag photos below, or click for file browser...";*/
            }
        }

        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.props.open}
                handleClose={this.props.handleClose}
                fullWidth={false}
                maxWidth="lg"
                closeLabel="Close"
                hideScroll={true}
                draggable={true}
                title={this.props.numAllowed ? (this.props.numAllowed > 1 ? "Upload Images" : "Upload Image") : "Upload Image"}
                subTitleContent={inst()}
                handleSave={this.state.selectedFile ? this.handleSavePhoto : null}
            >
                <Box sx={{margin:"8px 0px"}}>
                    {this.state.selectedFile ?
                        <>
                        <AvatarEditor
                            className="avatareditor"
                            ref={this.setEditorRef}
                            image={this.state.selectedFile}
                                width={this.props.cropWidth ? this.props.cropWidth : 800}
                                height={this.props.cropHeight ? this.props.cropHeight : 400}
                            border={50}
                            color={[51, 51, 51, 0.6]} // RGBA
                            scale={this.state.imgScale}
                            rotate={this.state.imgRotation}
                            />
                            <Box display="flex" sx={{ textAlign: "center" }}>
                                <IconButton >
                                    <ZoomInIcon sx={{ color:(theme) => theme.palette.secondary.main}} onClick={this.handleScaleUpImage} />
                                </IconButton>
                                <IconButton >
                                    <ZoomOutIcon sx={{ color: (theme) => theme.palette.secondary.main }} onClick={this.handleScaleDownImage} />
                                </IconButton>
                                <IconButton >
                                    <RotateRightIcon sx={{ color: (theme) => theme.palette.secondary.main }} onClick={this.handleRotateImage} />
                                </IconButton>
                            </Box>
                        </>
                        :
                        <Dropzone
                            onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}
                            onDragOver={this.handleDragOverDropZone}
                            onDragLeave={this.handleDragLeaveDropZone}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}
                                    id={"dropZone"}
                                    style={{ backgroundColor: this.props.dark ?  "#444"  :"#fff" , height: this.props.height ?? 400, width: this.props.width ?? 400, position: "relative", borderRadius: 4 }}
                                    className="hoverPointer"
                                >
                                    <input {...getInputProps()} accept=".jpg, .jpeg, .png" />
                                    <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translateY(-50%) translateX(-50%)" }}>
                                        <InsertPhotoIcon sx={{ color: (theme) => this.props.dark ? "#bbb" : "#444" }} style={{ fontSize: "76px" }} />
                                    </div>                        
                                </div>
                            )}
                            </Dropzone>
                    }
                </Box>
            </ModalDialog>
        );
    }
}

