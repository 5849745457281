import React, { PureComponent } from 'react';

//Custom Modules
import { ComponentImageUploader } from '../Content/Photos/ComponentImageUploader';
import { ModalDialog } from '../Utilities/ModalDialog';
import { Section } from '../Utilities/Section';
import { SectionGrid } from '../Utilities/SectionGrid';
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
import { SelectList } from '../Utilities/SelectList';
import { TextInput } from '../Utilities/TextInput';
import { UserSite } from '../UserSites/UserSite'
import { RichTextEditor } from '../Utilities/RichTextEditor'


//Material UI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

//Data Services
import { setSiteOptions } from '../../DataServices/UserService'

const contentTypeOptions = [
    { label: '', value: -1 },
    { label: 'Text', value: 0 },
    { label: 'Link', value: 1 },
    { label: 'Photo', value: 2 },
    { label: 'Video', value: 3 },
    { label: 'Blog', value: 4 },
    { label: 'Audio', value: 5 },
    { label: 'PDF', value: 6 },
    { label: 'Custom', value: 7 },
    { label: 'Event', value: 8 },
    { label: 'Partner', value: 9 },
    { label: 'Article', value: 10 },
];

export class ProfileSiteOptions extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            newContentType: this.props.siteOptions.featuredContentType ?? 0,
            showPhotoUploader: false,
            showPreview: false,
            featuredDesc: this.props.siteOptions.featuredDesc,
            photoID: this.props.siteOptions.coverPhotoID,
            content: this.props.siteOptions.bio,
        }
    }
    handleUpdateCover = (photo) => {

        this.setState({ photoID: photo.photoID, showPhotoUploader: false });

        const siteOptions = this.props.siteOptions;
        siteOptions.coverPhoto = photo.thumb;
        this.props.updateSiteOptions(siteOptions);

        setTimeout(this.handleSetSiteOptions, 1000);
    }
    handleUpdateFeaturedDesc = (text, content) => {
        this.setState({ featuredDesc: content });
        setTimeout(this.handleSetSiteOptions, 1000);
    }
    handleTypeChange = (e) => {
        this.setState({ newContentType: e.target.value });
        const siteOptions = this.props.siteOptions;
        siteOptions.featuredContentType = e.target.value;
        this.props.updateSiteOptions(siteOptions);
        setTimeout(this.handleSetSiteOptions, 1000);
    }
    handleSetSiteOptions = async () => {
        const siteid = this.props.siteOptions.userID;
        const photoID = this.state.photoID;
        const showAboutBtn = document.getElementById("showAboutBTN").checked;
        const showFollowBtn = document.getElementById("showFollowBTN").checked;
        const showAbout = document.getElementById("showAbout").checked;
        const showContent = document.getElementById("showContent").checked;
        const showPosts = document.getElementById("showPosts").checked;
        const showFeatured = document.getElementById("showFeatured").checked;
        const featuredTitle = document.getElementById("featuredTitle").value;
        const featuredDesc = document.getElementById("featuredDesc").value;
        const featuredContentType = this.state.newContentType;
        const contentRefID = document.getElementById("newContentRefID").value;
        const style = "";
        const bio = this.state.siteOptions.bio ?? "";
        await setSiteOptions(siteid, photoID, showFollowBtn, showAboutBtn, showAbout, showContent, showPosts, showFeatured, featuredTitle, featuredDesc, contentRefID, featuredContentType, style, bio);
    }
    handleSaveBio = async (content, text) => {

        const siteid = this.props.siteOptions.userID;
        const photoID = this.state.photoID;
        const showAboutBtn = document.getElementById("showAboutBTN").checked;
        const showFollowBtn = document.getElementById("showFollowBTN").checked;
        const showAbout = document.getElementById("showAbout").checked;
        const showContent = document.getElementById("showContent").checked;
        const showPosts = document.getElementById("showPosts").checked;
        const showFeatured = document.getElementById("showFeatured").checked;
        const featuredTitle = document.getElementById("featuredTitle").value;
        const featuredDesc = document.getElementById("featuredDesc").value;
        const featuredContentType = this.state.newContentType;
        const contentRefID = document.getElementById("newContentRefID").value;
        const style = "";
     
        await setSiteOptions(siteid, photoID, showFollowBtn, showAboutBtn, showAbout, showContent, showPosts, showFeatured, featuredTitle, featuredDesc, contentRefID, featuredContentType, style, content);

    }

    coverPic = () => {
        return (
            <Section dark={this.props.dark} title="Cover Photo" >
                <div style={{ textAlign: "center" }}>
                    <Tooltip title="Click to add/update site cover photo">
                        {this.props.siteOptions.coverPhoto ?
                            <Paper
                                component="img"
                                elevation={this.props.dark ? 0 : 4}
                                src={"data:image/png;base64, " + this.props.siteOptions.coverPhoto}
                                sx={{ height: "auto", width: { xs: "100%", sm: "500px"} , objectFit: "cover", borderRadius: "6px", cursor: "pointer" }}
                                onClick={() => this.setState({ showPhotoUploader: true })}
                            />
                            :
                            <Button variant="contained" style={{ height: "230px", width: "230px", backgroundColor: this.props.dark ? "#444" : "#bbb", fontSize: "76px" }} onClick={() => this.setState({ showPhotoUploader: true })}>
                                <InsertPhotoIcon sx={{ color: (theme) => this.props.dark ? "#bbb" : "#444" }} style={{ fontSize: "76px" }} />
                            </Button>
                        }
                    </Tooltip>

                </div>
            </Section>)
    }
    headerOptions = () => {
        return (
            <Section dark={this.props.dark} title="Header Options" >
                <SectionFieldGroup dark={this.props.dark}>
                    <Button onClick={() => this.setState({ showPreview: true })} variant="contained" color="secondary">
                        Preview
                    </Button>
                     <FormControlLabel
                        key={"fbctl"}
                        sx={{ paddingLeft: "16px", marginTop: "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
                        control={<Checkbox id="showFollowBTN" defaultChecked={this.props.siteOptions.showFollowBtn} onChange={this.handleSetSiteOptions} color="secondary" size="small" />}
                        label={
                            <Typography sx={{ fontSize: ".8rem" }}>
                                Show Follow Button
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        key={"abctl"}
                        sx={{ paddingLeft: "16px", marginTop: "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
                        control={<Checkbox id="showAboutBTN" defaultChecked={this.props.siteOptions.showAboutBtn} onChange={this.handleSetSiteOptions} color="secondary" size="small" />}
                        label={
                            <Typography sx={{ fontSize: ".8rem" }}>
                                Show About Button
                            </Typography>
                        }
                    />
                </SectionFieldGroup>
            </Section>)
    }
    bio = () => {
        return (
            <Section dark={this.props.dark} title="Bio" >
                <Box sx={{ textAlign: "center", border: this.props.dark ? "none" : "1px solid #ccc" }}>
                    <Box className={this.props.dark ? "dark prettyScroll" : "prettyScroll"} sx={{ minHeight: "200px", backgroundColor: this.props.dark ? "#000" : "white", height: "100%", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black, }}>
                        <RichTextEditor
                            initialContent={this.state.content}
                            updateContent={this.handleSaveBio}
                        />
                    </Box>

                </Box>
            </Section>)
    }
    contentOptions = () => {
        return (
            <Section dark={this.props.dark} title="Site Sections" >
                <SectionFieldGroup dark={this.props.dark}>
                    <FormControlLabel
                        key={"pcctl"}
                        sx={{ paddingLeft: "16px", marginTop: "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
                        control={<Checkbox id="showAbout" defaultChecked={this.props.siteOptions.showAbout} onChange={this.handleSetSiteOptions} color="secondary" size="small" />}
                        label={
                            <Typography sx={{ fontSize: ".8rem" }}>
                                Show About Me Section
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        key={"pcctl1"}
                        sx={{ paddingLeft: "16px", marginTop: "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
                        control={<Checkbox id="showContent" defaultChecked={this.props.siteOptions.showContent} onChange={this.handleSetSiteOptions} color="secondary" size="small" />}
                        label={
                            <Typography sx={{ fontSize: ".8rem" }}>
                                Show Public Content Sections
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        key={"pcctl2"}
                        sx={{ paddingLeft: "16px", marginTop: "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
                        control={<Checkbox id="showPosts" defaultChecked={this.props.siteOptions.showPosts} onChange={this.handleSetSiteOptions} color="secondary" size="small" />}
                        label={
                            <Typography sx={{ fontSize: ".8rem" }}>
                                Show My Posts
                            </Typography>
                        }
                    />


                </SectionFieldGroup>
            </Section>)
    }
    featuredOptions = () => {
        return (
            <Section dark={this.props.dark} title="Featured Options" >
                <SectionFieldGroup dark={this.props.dark}>
                    <FormControlLabel
                        key={"pcctl"}
                        sx={{ paddingLeft: "16px", marginTop: "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
                        control={<Checkbox id="showFeatured" defaultChecked={this.props.siteOptions.showFeatured} onChange={this.handleSetSiteOptions} color="secondary" size="small" />}
                        label={
                            <Typography sx={{ fontSize: ".8rem" }}>
                                Show Feautured Content Section
                            </Typography>
                        }
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="featuredTitle"
                        cid="featuredTitle"
                        id="featuredTitle"
                        label="Featured Content Title"
                        defaultValue={this.props.siteOptions.featuredTitle}
                        onBlur={this.handleSetSiteOptions}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="featuredDesc"
                        cid="featuredDesc"
                        id="featuredDesc"
                        label="Featured Content Description"
                        defaultValue={this.props.siteOptions.featuredDesc}
                        onBlur={this.handleSetSiteOptions}
                    />
                    {/*<Box className={this.props.dark && "dark"} sx={{ backgroundColor: this.props.dark ? "#000" : "white", height: "100%", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black, }}>*/}
                    {/*    <RichTextEditor*/}
                    {/*        initialContent={this.props.siteOptions.featuredDesc}*/}
                    {/*        updateContent={(content, text) => this.handleUpdateFeaturedDesc(content, text)}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                    <SelectList dark={this.props.dark}
                        ckey="newContentType"
                        label="Choose content type"
                        defaultValue={this.props.siteOptions.featuredContentType ?? -1}
                        onChange={this.handleTypeChange}
                        listItems={contentTypeOptions}
                        width={250}
                        marginRight="6px"
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="knewContentRefID"
                        cid="cnewContentRefID"
                        id="newContentRefID"
                        label="Content ID"
                        defaultValue={this.props.siteOptions.featuredContentID}
                    />
                </SectionFieldGroup>
            </Section>)
    }
    profileImageUploader = () => {
        return (
            <ComponentImageUploader
                parentID={this.props.user.userID}
                appIdentifier={this.props.appIdentifier}
                dark={this.props.dark}
                user={this.props.user}
                open={this.state.showPhotoUploader}
                handleSave={this.handleUpdateCover}
                handleClose={() => this.setState({ showPhotoUploader: false })}
                cropHeight={400}
                cropWidth={1000}
            />
        )
    }
    preview = () => {
        if (this.state.showPreview) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showPreview}
                    title="Preview"
                    handleClose={() => this.setState({ showPreview: false })}
                    closeLabel="Close"
                    fullWidth={true}
                    maxWidth="lg" >
                    <UserSite
                        userSite={this.props.siteOptions}
                        user={this.props.user}
                        dark={this.props.dark}
                    />
                </ModalDialog>


            )
        }
        return null;
    }
    render() {

        return (
            <>
                <SectionGrid key="pbasic" ckey="profilesiteoptions" xs={12} md={6} height="calc(100% - 40px)">
                    {this.coverPic()}
                    {this.headerOptions()}
                    {this.bio() }
                    {this.contentOptions()}
                    {this.featuredOptions()}
                </SectionGrid>
                {this.profileImageUploader()}
                {this.preview()}
            </>
        );
    }
}
