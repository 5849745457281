
export async function getPhotoLibraries(parentID, appid) {
    var url;
    if (parentID) {
        url = "photo/getownerlibraries?id=" + parentID + '&appid=' + appid;
    }
    else {
        url = "photo/getlibraries?appid=" + appid;
    }
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getSiteLibrary(id) {
    var url = "photo/getsitelibrary?id=" + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getPhotoList(parentID, appid) {
    const response = await fetch("photo/getphotos?id=" + parentID + '&appid=' + appid);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getPhoto(id) {
    const response = await fetch("photo/getphoto?id=" + id);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getPhotoThumb(id) {
    const response = await fetch("photo/getphotothumb?id=" + id);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data.thumb;
    return null;
}

export async function savePhoto(file, parentID,appid) {
    var b64;
    var reader = new FileReader();
    reader.onload = e => {
        const { result } = e.target;
        var base64result = result.split(',')[1];
        b64 = base64result;
    }

    await reader.readAsDataURL(file);

    //post photo to api 
    const formData = new FormData();
    formData.append('file', file);
    formData.append('parentid', parentID);
    formData.append('appid', appid);

    const photoID = await fetch('photo/newphoto', {
        method: 'POST',
        body: formData
    }).then(
        response => response.json()
    );

    const photoObj = { photoID: photoID, thumb: b64 };

    return photoObj;
}
export async function updatePhoto(file, photoid) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('photoid', photoid);

    const result = await fetch('photo/updatephoto', {
        method: 'POST',
        body: formData
    }).then(
        response => response.json()
    );

    return result;
}
export async function deletePhoto(id) {
    const req = {
        PhotoID: id,
    };

    const response = await fetch("photo/deletephoto", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    console.error(response);
    return false;
}
export async function movePhoto(id, parentid) {
    const req = {
        PhotoId: id,
        ParentID: parentid
    };

    const response = await fetch("photo/movephoto", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    console.error(response);
    return false;
}
export async function newLibrary(name, parentID, appid) {
    const req = {
        ParentID: parentID,
        Name: name,
        AppID: appid
    };
    const response = await fetch("photo/createlibrary", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}

export async function editLibrary(id, name) {
    const req = {
        LibraryID: id,
        Name: name
    };
    const response = await fetch("photo/editlibrary", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    console.error(response);
    return false;
}

export async function deleteLibrary(id) {
    const req = {
        LibraryID: id,
    };
    const response = await fetch("photo/deletelibrary", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    console.error(response);
    return false;
}
export async function sharePhoto(id, ownerid, userid, isFolder,  appid, canedit, hasexpiration, expiration ) {
    const req = {
        PhotoId: id,
        OwnerID: ownerid,
        UserId: userid,
        IsFolder: isFolder,
        AppID: appid,
        CanEdit: canedit,
        HasExpiration: hasexpiration,
        Expiration: expiration
    };
    const response = await fetch("photo/sharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function unSharePhoto(id, ownerid, userid, appid) {
    const req = {
        PhotoId: id,
        OwnerID: ownerid,
        UserId: userid,
        AppID: appid
    };
    const response = await fetch("photo/unsharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function hideSharedPhoto(id, ownerid, userid, appid) {
    const req = {
        PhotoId: id,
        OwnerID: ownerid,
        UserId: userid,
        AppID: appid
    };
    const response = await fetch("photo/hidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function unhideSharedPhoto(id, ownerid, userid, appid) {
    const req = {
        PhotoId: id,
        OwnerID: ownerid,
        UserId: userid,
        AppID: appid
    };
    const response = await fetch("photo/unhidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}