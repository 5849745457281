import React, { Component } from 'react';

//Custom Components
import { Section } from '../Utilities/Section';
import { SelectionChip } from '../Utilities/SelectionChip';
import { SectionGrid } from '../Utilities/SectionGrid';

//Material UI
import Paper from '@mui/material/Paper';

export class ProfileStrengthsAndWeaknesses extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    strength = (item) => {
        let variant = "outlined";
        let selected = false;
        if (this.props.profile.demographics.personalStrengths &&
            this.props.profile.demographics.personalStrengths.find((x) => x.strength === item.value)) {
            variant = "filled";
            selected = true;
        }
        return (
            <SelectionChip
                dark={this.props.dark}
                value={item.value}
                label={item.label}
                onClick={() => this.props.toggleSelectedStrength(item)}
                selected={selected}
                variant={variant}
            />
        )
    }
    strengths = () => {
        return (
            <Section dark={this.props.dark} title="My Strengths" {...this.props} publicAttribute="strengths">
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        boxShadow: "none",
                        background: "transparent",
                        p: 0.5,
                        m: 0,
                    }}
                    component="div"
                >
                    {this.props.profile && this.props.profile.strengthList.map((item) => {
                        return this.strength(item)
                    })}
                </Paper>
            </Section>
        )
    }
    weakness = (item) => {
        let variant = "outlined";
        let selected = false;
        if (this.props.profile.demographics.personalWeaknesses &&
            this.props.profile.demographics.personalWeaknesses.find((x) => x.weakness === item.value)) {
            variant = "filled";
            selected = true;
        }
        return (
            <SelectionChip
                dark={this.props.dark}
                value={item.value}
                label={item.label}
                onClick={() => this.props.toggleSelectedWeakness(item)}
                selected={selected}
                variant={variant}
            />
        )
    }
    weaknesses = () => {
        return (
            <Section dark={this.props.dark} title="My Weaknesses" {...this.props} publicAttribute="weaknesses">
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        boxShadow: "none",
                        background: "transparent",
                        p: 0.5,
                        m: 0,
                    }}
                    component="div"
                >
                    {this.props.profile && this.props.profile.weaknessList.map((item) => {
                        return this.weakness(item)
                    })}
                </Paper>
            </Section>
        )
    } 
    content = () => {
        return (
            <SectionGrid xs={12} md={6} height="calc(100% - 40px)">
                {this.strengths()}
                {this.weaknesses()}
            </SectionGrid>
        );
    }

    render() {
        return this.content();

    }
}
