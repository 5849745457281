import React, { PureComponent } from 'react';

//Custom Components
import { Section } from '../Utilities/Section';
import { ModalDialog } from '../Utilities/ModalDialog'
import { DocumentViewer } from '../Content/Documents/DocumentViewer';
import { Spin } from '../Utilities/Spin';

//Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

import { getDocument } from '../../DataServices/DocumentService'

export class UserSiteFiles extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showDocument: false,
            selectedDocumentContent: null,
            selectedDocumentName: ""
        }
    }

    //event handlers
    handleClickDocument = async (e, id) => {
        let ele = e.target;
        ele.style.cursor = 'wait';
        document.body.style.cursor = 'wait';

        const doc = await getDocument(id);
        ele.style.cursor = 'default';
        document.body.style.cursor = 'default';

        if (doc && doc.eFiles && doc.eFiles.length > 0 && doc.eFiles[0].fileContent) {
            this.setState({
                selectedDocumentName: doc.documentName,
                selectedDocumentContent: doc.eFiles[0].fileContent,
                showDocument: true
            });
        }
    }

    //Render funcs
    folders = () => {
        if (this.props.loading) {
            return (
                <Box sx={{ height: "100%", width: "100%", display: 'flex', justifyContent: "center" }}>
                    <Spin sub />
                </Box>
            )
        }
        if (this.props.folders && this.props.folders.length > 0) {
            let hasDocs = false;
            return (
                <Stack>
                    {this.props.folders.map((item) => {
                        if (item.documents && item.documents.length > 0) {
                            hasDocs = true;
                            return (
                                <Section key={item.id + "k"} dark={this.props.dark} title={item.name} padBottom >
                                    {this.documents(item)}
                                </Section>
                            )
                        }
                        return null;
                    })}
                    {!hasDocs &&
                        <Box sx={{ height: "100%", width: "100%", display: 'flex', justifyContent: "center" }}>
                            <Typography variant="h5" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>No shared files at this time.</Typography>
                        </Box>

                    }
                    {this.state.showDocument && this.docPreview() }
                </Stack>
                )
        }
        return null;
    }
    documents = (item) => {
        return (
            <Stack direction={{ xs: 'column', sm:'row'}} sx={{ width: "100%", display: "inherit" }}>
                {(item.documents && item.documents.length > 0) && item.documents.map((doc) => {
                    return (
                        <Card
                            sx={{  display:"inline", marginLeft: "auto", marginRight: "auto", mb: 4, width: "fit-content", background: (theme) => this.props.dark && theme.palette.card.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }}
                            raised
                            elevation={this.props.dark ? 1 : 4}
                        >
                            <CardActionArea onClick={(e) => this.handleClickDocument(e, doc.documentID)}>
                                <Box sx={{ p: 3, textAlign:"center" }}>
                                <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "64px", color: 'rgb(50,71,140)', margin:"auto", }} />
                                <Stack sx={{ mt:3 }}>
                                    <Typography gutterBottom variant="subtitle1" sx={{ fontSize: ".8rem", marginBottom: 0 }} component="div">
                                        {doc.documentName ? doc.documentName.substr(0, 30) : "Document"}
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {`Added ${new Date(doc.createDate).toLocaleDateString()}`}
                                    </Typography>
                                    </Stack>
                                </Box>
                            </CardActionArea>
                        </Card>
                    )
                })
                }
            </Stack>
        )
    }
    docPreview = () => {
        if (this.state.selectedDocumentContent && this.state.showDocument) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showDocument}
                    handleClose={() => this.setState({ showDocument: false })}
                    fullWidth={true}
                    disableButtons
                    hideScroll={true}
                    draggable={true}
                    noUpperClose
                    maxWidth="xl"                  
                    closeLabel="Close"
                    noSidePad
                >
                    <DocumentViewer
                        dark={this.props.dark}
                        fileName={this.state.selectedDocumentName}
                        document={this.state.selectedDocumentContent}
                        scale={1.6}
                        handleClose={() => this.setState({ showDocument: false })}
                        fullHeight
                    />
                </ModalDialog>
            )
        }
        return null;
    }

    //Main component render
    render() {
        return this.folders();
    }
}

