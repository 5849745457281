import React, { Component } from 'react';

//Custom Components
import { UserSite } from '../UserSites/UserSite';
import { ModalDialog } from '../Utilities/ModalDialog'
import { Menu } from '../Utilities/Menu'
//Material UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

//Data Services
import { getPhotoThumb } from '../../DataServices/PhotoService'
import { followUser, unfollowUser, getSiteOptions, getUserAvatar } from '../../DataServices/UserService'
import { sendEmail } from '../../DataServices/EmailService'

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

export class UserAvatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorUserEl: null,
            showUserSite: false,
            avatarImage: this.props.user && (this.props.user.avatar ?? this.props.user.avatarB64),
            width: 0,
            height: 0,
            userSiteFullScreen: false
        };
    }

    componentDidMount() {
        this.getAvatar();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    //Data getters
    getSiteOptions = async () => {

        const data = await getSiteOptions(this.props.user.userID);
        if (data) {
            data.user = this.props.user;
            this.setState({ siteOptions: data });
            if (data.coverPhotoID) {
                const thumb = await getPhotoThumb(data.coverPhotoID);
                if (thumb) {
                    this.setState(
                        state => {
                            state.siteOptions.coverPhoto = thumb;
                            return state;
                        }
                    );
                }
            }
        }
    }
    getAvatar = async () => {
        if (this.props.user && !this.state.avatarImage) {
            const avatar = await getUserAvatar(this.props.user.userID);
            if (avatar) {
                this.setState({ avatarImage: avatar.avatarB64 });
               // this.forceUpdate();
            }
        }
    }

    //event handlers
    handleShowUserMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ anchorUserEl: e.currentTarget });
    }
    handleCloseUserMenu = (e) => {
        this.setState({ anchorUserEl: null });
    }
    handleUserSite = async (e) => {
        let target = e.target;
        target.style.cursor = 'wait';
        document.body.style.cursor = 'wait';
        this.handleCloseUserMenu();
        await this.getSiteOptions();
        this.setState({ showUserSite: true });
        target.style.cursor = 'default';
        document.body.style.cursor = 'default';
    }
    handleFollow = async () => {
        this.handleCloseUserMenu();
        const res = await followUser(this.props.activeUser.userID, this.props.user.userID);
        if (res) {
            this.props.handleFollow && this.props.handleFollow();
        }
    }
    handleUnfollow = async () => {
        this.handleCloseUserMenu();
        const res = await unfollowUser(this.props.activeUser.userID, this.props.user.userID);
        if (res) {
            this.props.handleUnfollow && this.props.handleUnfollow();
        }
    }
    handleMessageUser = async () => {
        this.handleCloseUserMenu();
        await sendEmail('brianesabin@gmail.com', 'Brian Sabin', 'Test Email', 'This is a test email!', '', this.props.appIdentifier);
    }
    handleCloseSite = (e, reason) => {
        if (reason !== "backdropClick") {
            this.setState({ showUserSite: false })
        }

    }

    userSite = () => {
        if (this.state.showUserSite) {
            return (
                <ModalDialog
                    dark={this.state.width < 600 ? true : this.props.dark}
                    show={this.state.showUserSite}   
                    handleClose={this.handleCloseSite}
                    closeLabel="Close"
                    fullWidth={true}
                    fullScreen={this.state.width < 600 ? true : this.state.userSiteFullScreen}
                    isFullScreen={this.state.width < 600 ? true : this.state.userSiteFullScreen}
                    setFullScreen={(val) => this.setState({ userSiteFullScreen: val })}
                    toggleFullScreen={this.state.width < 600 ? false : true}
                    solid
                    fullHeight
                    noPad
                    disableButtons
                    maxWidth="xl"
                    hideScroll
                >
                    <UserSite
                        site={this.state.siteOptions}
                        user={this.props.activeUser}
                        dark={this.props.dark}
                        fullScreen={this.state.width < 600 ? true : this.state.userSiteFullScreen}
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    userMenu = () => {
        return (
            <Menu
                id={this.props.id}
                open={this.state.anchorUserEl !== null}
                onClose={this.handleCloseUserMenu}
                anchorEl={this.state.anchorUserEl}
                dark={this.props.dark}
                width={this.props.width}
            >
                <MenuItem onClick={this.handleUserSite}>
                    User Site
                </MenuItem>
                {(this.props.user && this.props.user.following) ?
                    <MenuItem  onClick={this.handleUnfollow}>Stop Following</MenuItem>
                    :
                    <MenuItem  onClick={this.handleFollow}>{"Follow " + this.props.user.firstName ?? ""} </MenuItem>
                }
                <MenuItem  onClick={this.handleMessageUser}>Message</MenuItem>
                {this.props.post && <MenuItem  onClick={this.handleHide}>Hide Posts</MenuItem>}
                {this.props.menuItem1 && <MenuItem  onClick={() => { this.setState({ anchorUserEl: null }); this.props.menuItem1(this.props.user, this.props.userIndex); }}>{this.props.menuItem1Label ? this.props.menuItem1Label : "Custom Action"}</MenuItem>}
            </Menu> 
            )

    }
    userAvatar = () => {
        return (
            <Box sx={{ display: { xs: 'flex' } }}>
                <Box sx={{ display: "inline-block" }}>
                    {this.props.loaded ?
                        <>
                            <Tooltip title={this.props.user.online ? "Online now" : ""} placement="left-start" >
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    variant="dot"
                                    invisible={!this.props.user.online}
                                >
                                    <Avatar
                                        className={this.props.large ? "avatarImageLarge" : (this.props.tiny ? "avatarImageTiny" : (this.props.small ? "avatarImageSmall" : "avatarImageMedium"))}
                                        alt={this.props.user && this.props.user.firstName + " " + this.props.user.lastName}
                                        src={"data:image/png;base64, " + this.state.avatarImage}
                                        onClick={this.handleShowUserMenu}
                                        imgProps={{ style: { cursor: "pointer", backgroundColor: this.props.dark ? "#444" : "#ccc" } }}
                                    />
                                </StyledBadge>
                            </Tooltip>
                            {this.userMenu()}
                        </>
                        :
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    }
                </Box>
                {!this.props.hideName &&
                    <Box sx={{ color: "#ccc", marginLeft: "15px", display: "inline-block" }}>
                        {this.props.loaded ?
                            <Box display="flex">
                                <Typography variant="subtitle2" sx={{ fontSize: { xs: "1rem", sm: ".875rem" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, fontWeight: 550 }}>
                                    {this.props.user.firstName + " " + this.props.user.lastName}
                                </Typography>

                                {this.props.user.following &&

                                    <Tooltip title="Following.  Click to unfollow">
                                        <IconButton
                                            color="info"
                                            onClick={this.handleUnfollow}
                                            sx={{ marginLeft: "8px", padding: 0, }}
                                        >
                                            <CheckCircleIcon sx={{ fontSize: "18px" }} />
                                        </IconButton>
                                    </Tooltip>
                                }


                            </Box>
                            :
                            <Skeleton
                                animation="wave"
                                height={10}
                                width={150}
                                style={{ marginBottom: 6 }}
                            />
                        }
                        {this.props.post &&
                            <>
                                {(this.props.loaded && this.props.post && (this.props.post.whenPosted || this.props.post.postDate)) ?
                                <Typography variant="body2" sx={{ color: this.props.dark ? "#aaa" : "#444", fontWeight: 500, fontSize: { xs: ".7rem", sm: ".7rem" }, }}>
                                        {this.props.post.whenPosted ?? new Date(this.props.post.postDate).toLocaleDateString()}
                                    </Typography>
                                    :
                                    <Skeleton animation="wave" height={10} width={100} />
                                }
                            </>
                        }
                    </Box>
                }
                {this.state.showUserSite && this.userSite()}
            </Box>
        )
    }

    //main component render
    render() {
        return this.userAvatar()
    }
}
