import React, { Component } from 'react';

//Custom
import { ModalDialog } from '../Utilities/ModalDialog'
import { Section } from '../Utilities/Section';
import { SectionItem } from '../Utilities/SectionItem';
import { SectionGrid } from '../Utilities/SectionGrid';
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
import { SelectList } from '../Utilities/SelectList';
import { DateSelector } from '../Utilities/DateSelector';
import { TextInput } from '../Utilities/TextInput';
import { PublicAttribute } from '../Utilities/PublicAttribute';

//MUI
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';


export class ProfileLifeEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDeleteEvent: false,
            showEventEditor: false,
            showConfirmDeleteEducation: false,
            showEducationEditor: false,
            showConfirmDeleteJob: false,
            showJobEditor: false,
        }
    }

    handleShowConfirmDeleteEvent  = (id, idx) => {
        this.setState({
            selectedID: id,
            selectedIDX: idx,
            showConfirmDeleteEvent : true
        })
    }

    handleAddEvent  = () => {
        this.setState({
            editMode: "New",
            titleValue: "",
            typeValue: 0,
            descValue: "",
            impactsValue: "",
            currentImpactValue: false,
            startValue: new Date(),
            endValue: new Date(),
            showEventEditor: true
        })
    }

    handleEditEvent = (id, idx) => {

        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            titleValue: this.props.profile.demographics.lifeEvents[idx].title,
            typeValue: this.props.profile.demographics.lifeEvents[idx].eventType,
            descValue: this.props.profile.demographics.lifeEvents[idx].description,
            impactsValue: this.props.profile.demographics.lifeEvents[idx].impacts,
            currentImpactValue: this.props.profile.demographics.lifeEvents[idx].currentImpact,
            startValue: this.props.profile.demographics.lifeEvents[idx].startDate,
            endValue: this.props.profile.demographics.lifeEvents[idx].endDate,
            showEventEditor: true
        })
    }

    handleSaveEvent = async () => {
        const title = document.getElementById("fldTitle").value.trim();
        const desc = document.getElementById("fldDesc").value.trim() ?? "";
        const impacts = document.getElementById("fldImpacts").value.trim() ?? "";
        const currentImpact = this.state.currentImpactValue;
        const type = this.state.typeValue;
        const start = this.state.startValue;
        const end = this.state.endValue;
    

        if (this.state.editMode !== "Edit") {
            if (title) {
                const response = await fetch('home/addlifeevent?type=' + type + '&title=' + title + '&desc=' + desc + '&impacts=' + impacts + '&currentimpact=' + currentImpact + '&from=' + start + '&to=' + end, { method: 'POST' })
                const id = await response.text();
                const itm = { id: id, title: title, description: desc, eventType: type,  impacts: impacts, currentImpact: currentImpact, startDate: start, endDate: end };
                this.props.profile.demographics.lifeEvents.push(itm);
                this.setState({ showEventEditor: false });
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (title)  {
                await fetch('home/editlifeevent?id=' + id + '&type=' + type + '&title=' + title + '&desc=' + desc + '&impacts=' + impacts + '&currentimpact=' + currentImpact + '&from=' + start + '&to=' + end, { method: 'POST' })
                const itm2 = { id: id, title: title, description: desc, eventType: type, impacts: impacts, currentImpact: currentImpact, startDate: start, endDate: end };
                this.props.updateEvent(idx, itm2);
                this.setState({ showEventEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleDeleteEvent = async () => {
        this.props.profile.demographics.lifeEvents.splice(this.state.selectedIDX,1);
        await fetch('home/removelifeevent?id=' + this.state.selectedID, { method: 'POST' });

        this.setState({
            showConfirmDeleteEvent: false,
            selectedID: "",
            selectedIDX: ""
        })  
    }

    handleShowConfirmDeleteEducation = (id, idx) => {
        this.setState({
            selectedID: id,
            selectedIDX: idx,
            showConfirmDeleteEducation: true
        })
    }

    handleAddEducation = () => {
        this.setState({
            editMode: "New",
            schoolValue: "",
            typeValue: 0,
            degreeTypeValue: 0,
            fieldValue: "",
            degreeValue: false,
            startValue: new Date(),
            endValue: new Date(),
            showEducationEditor: true
        })
    }

    handleEditEducation = (id, idx) => {

        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            schoolValue: this.props.profile.educationHistory[idx].schoolName,
            typeValue: this.props.profile.educationHistory[idx].schoolType,
            degreeTypeValue: this.props.profile.educationHistory[idx].degreeType,
            fieldValue: this.props.profile.educationHistory[idx].fieldOfStudy,
            degreeValue: this.props.profile.educationHistory[idx].degreeEarned,
            startValue: this.props.profile.educationHistory[idx].startDate,
            endValue: this.props.profile.educationHistory[idx].endDate,
            showEducationEditor: true
        })
    }

    handleSaveEducation = async () => {
        const school = document.getElementById("fldSchool").value.trim();
        const field = document.getElementById("fldField").value.trim() ?? "";
        const degree = this.state.degreeValue;
        const type = this.state.typeValue;
        const degreeType = this.state.degreeTypeValue;
        const start = this.state.startValue;
        const end = this.state.endValue;


        if (this.state.editMode !== "Edit") {
            if (school) {
                const response = await fetch('home/addschool?school=' + school + '&type=' + type + '&field=' + field + '&degree=' + degree + '&degreetype=' + degreeType + '&from=' + start + '&to=' + end, { method: 'POST' })
                const id = await response.text();
                const itm = {
                    id: id,
                    startDate: start,
                    endDate: end,
                    schoolName: school,
                    schoolType: type,
                    fieldOfStudy: field,
                    degreeEarned: degree,
                    degreeType: degreeType
                };
                this.props.profile.educationHistory.push(itm);
                this.setState({ showEducationEditor: false });
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (school) {
                await fetch('home/editschool?id=' + id + '&school=' + school + '&type=' + type + '&field=' + field + '&degree=' + degree + '&degreetype=' + degreeType + '&from=' + start + '&to=' + end, { method: 'POST' })
                const itm2 = {
                    id: id,
                    startDate: start,
                    endDate: end,
                    schoolName: school,
                    schoolType: type,
                    fieldOfStudy: field,
                    degreeEarned: degree,
                    degreeType: degreeType
                };
                this.props.updateEducation(idx, itm2);
                this.setState({ showEducationEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleDeleteEducation = async () => {
        this.props.profile.educationHistory.splice(this.state.selectedIDX, 1);
        await fetch('home/removeschool?id=' + this.state.selectedID, { method: 'POST' });

        this.setState({
            showConfirmDeleteEducation: false,
            selectedID: "",
            selectedIDX: ""
        })
    }

    handleShowConfirmDeleteJob = (id, idx) => {
        this.setState({
            selectedID: id,
            selectedIDX: idx,
            showConfirmDeleteJob: true
        })
    }

    handleAddJob = () => {
        this.setState({
            editMode: "New",
            titleValue: "",
            employerValue: "",
            descValue: "",
            achievementsValue: "",
            salaryValue: "",
            leaveReasonValue: "",
            startValue: new Date(),
            endValue: new Date(),
            showJobEditor: true
        })
    }

    handleEditJob = (id, idx) => {

        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            titleValue: this.props.profile.employmentHistory[idx].title,
            employerValue: this.props.profile.employmentHistory[idx].employer,
            descValue: this.props.profile.employmentHistory[idx].jobDescription,
            achievementsValue: this.props.profile.employmentHistory[idx].notableAchievements,
            salaryValue: this.props.profile.employmentHistory[idx].endingSalary,
            leaveReasonValue: this.props.profile.employmentHistory[idx].leaveReason,
            startValue: this.props.profile.employmentHistory[idx].startDate,
            endValue: this.props.profile.employmentHistory[idx].endDate,
            showJobEditor: true
        })
    }

    handleSaveJob = async () => {
        const title = document.getElementById("fldTitle").value.trim();
        const employer = document.getElementById("fldEmployer").value.trim() ?? "";
        const desc = document.getElementById("fldDesc").value.trim() ?? "";
        const achievements = document.getElementById("fldAchievements").value.trim() ?? "";
        const salary = document.getElementById("fldSalary").value.trim() ?? "";
        const leaveReason = document.getElementById("fldLeaveReason").value.trim() ?? "";
        const start = this.state.startValue;
        const end = this.state.endValue;


        if (this.state.editMode !== "Edit") {
            if (title) {
                const response = await fetch('home/addjob?title=' + title + '&employer=' + employer + '&desc=' + desc + '&achievements=' + achievements + '&salary=' + salary + '&reason=' + leaveReason + '&from=' + start + '&to=' + end, { method: 'POST' })
                const id = await response.text();
                const itm = {
                    id: id,
                    startDate: start,
                    endDate: end,
                    title: title,
                    employer: employer,
                    jobDescription: desc,
                    notableAchievements: achievements,
                    endingSalary: salary,
                    leaveReason: leaveReason
                };
                this.props.profile.employmentHistory.push(itm);
                this.setState({ showJobEditor: false });
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (title) {
                await fetch('home/editjob?id=' + id + '&title=' + title + '&employer=' + employer + '&desc=' + desc + '&achievements=' + achievements + '&salary=' + salary + '&reason=' + leaveReason + '&from=' + start + '&to=' + end, { method: 'POST' })
                const itm2 = {
                    id: id,
                    startDate: start,
                    endDate: end,
                    title: title,
                    employer: employer,
                    jobDescription: desc,
                    notableAchievements: achievements,
                    endingSalary: salary,
                    leaveReason: leaveReason
                };
                this.props.updateEmployment(idx, itm2);
                this.setState({ showJobEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleDeleteJob = async () => {
        this.props.profile.employmentHistory.splice(this.state.selectedIDX, 1);
        await fetch('home/removejob?id=' + this.state.selectedID, { method: 'POST' });

        this.setState({
            showConfirmDeleteJob: false,
            selectedID: "",
            selectedIDX: ""
        })
    }
    handleDegreeTypeChange = (e) => {
        this.setState({ degreeTypeValue: e.target.value });
    }
    handleTypeChange = (e) => {
        this.setState({ typeValue: e.target.value });
    }
    handleStatusChange = (e) => {
        this.setState({ statusValue: e.target.value });
    }
    handleCategoryChange = (e) => {
        this.setState({ categoryValue: e.target.value });
    }
    handleImportanceChange = (e) => {
        this.setState({ importanceValue: e.target.value });
    }
    handleStartChange = (e) => {
        this.setState({ startValue: new Date(e).toLocaleDateString() });
    }
    handleEndChange = (e) => {
        this.setState({ endValue: new Date(e).toLocaleDateString() });
    }
    handleCurrentImpactChange = (e) => {
        this.setState({ currentImpactValue: e.target.checked });
    }
    handleDegreeChange = (e) => {
        this.setState({ degreeValue: e.target.checked });
    }
    formatMoney = (e) => {
        var oldval = parseFloat(e.target.value);
        if (oldval) {
            e.target.value = (oldval).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        else {
            e.target.value = "";
        }
    }

    render() {
        const eventItem = (item, index) => {
            return (
                <SectionItem
                    itemID={item.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.lifeEventTypeList.find((x) => x.value === item.eventType).label}
                    primaryContent={item.title}
                    handleEdit={this.handleEditEvent}
                    handleDelete={this.handleShowConfirmDeleteEvent}
                >
                    <PublicAttribute {...this.props} attr={item.id} /> 
                </SectionItem>

            )
        }
        const Event = (<Section dark={this.props.dark} title="My Life Events" handleAdd={this.props.profile.demographics.lifeEvents.length > 0 && this.handleAddEvent} {...this.props} publicAttribute="lifeevents">
            {this.props.profile.demographics.lifeEvents.length > 0 ?
                this.props.profile.demographics.lifeEvents.map((item, index) => eventItem(item, index))
                :
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" onClick={this.handleAddEvent} color="secondary" sx={{ width: "250px" }}>Add Life Event</Button>
                </div>
                }
        </Section>)

       
        const deleteConfirmationEvent = (<ModalDialog {...this.props} show={this.state.showConfirmDeleteEvent}
            title="Confirm Deletion"
            subTitle={"Event will be deleted.  Proceed?"}
            handleClose={() => this.setState({ showConfirmDeleteEvent: false })}
            handleSave={this.handleDeleteEvent}
            fullWidth={false}
            maxWidth="sm"
            saveLabel="Delete" /> )
        const eventEditor = (<ModalDialog  {...this.props} show={this.state.showEventEditor}
            title={this.state.editMode + " Event"}
            handleClose={() => this.setState({ showEventEditor: false })}
            handleSave={this.handleSaveEvent}
                fullWidth={true}
                maxWidth="sm"
        >
            <SectionFieldGroup dark={this.props.dark}>
                <TextInput
                    dark={this.props.dark}
                    cKey="title"
                    cid="titleF"
                    id="fldTitle"
                    label="Title"
                    defaultValue={this.state.titleValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="desc"
                    cid="descF"
                    id="fldDesc"
                    label="Description"
                    defaultValue={this.state.descValue}
                />
                <SelectList
                    dark={this.props.dark}
                    key="type"
                    label="Type"
                    value={this.state.typeValue}
                    onChange={this.handleTypeChange}
                    listItems={this.props.profile.lifeEventTypeList}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="impacts"
                    cid="impactsF"
                    id="fldImpacts"
                    label="Impacts on my life"
                    defaultValue={this.state.impactsValue}
                />
                <FormControlLabel
                    sx={{ fontSize: ".8rem", color:(theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
                    control={<Checkbox checked={this.state.currentImpactValue} onChange={this.handleCurrentImpactChange} color="secondary" size="small" />}
                    label="Has Current Impact?"
                />
                <DateSelector
                    dark={this.props.dark}
                    label="Start Date"
                    value={this.state.startValue}
                    handleChange={this.handleStartChange}
                />
                <DateSelector
                    dark={this.props.dark}
                    label="End Date"
                    value={this.state.endValue}
                    handleChange={this.handleEndChange}
                />
            </SectionFieldGroup>
        </ModalDialog>)

        const educationItem = (item, index) => {
            return (
                <SectionItem
                    itemID={item.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.schoolTypeList.find((x) => x.value === item.schoolType).label}
                    primaryContent={item.schoolName}
                    handleEdit={this.handleEditEducation}
                    handleDelete={this.handleShowConfirmDeleteEducation}
                >
                    <PublicAttribute {...this.props} attr={item.id} />
                </SectionItem>
            )
        }

        const Education = (<Section dark={this.props.dark} title="My Education History" handleAdd={this.props.profile.educationHistory.length > 0 && this.handleAddEducation} {...this.props} publicAttribute="education">
            {this.props.profile.educationHistory.length > 0 ?
                this.props.profile.educationHistory.map((item, index) => educationItem(item, index))
                :
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" onClick={this.handleAddEducation} color="secondary" sx={{ width: "250px" }}>Add Education</Button>
                </div>
                }
        </Section>)

        const deleteConfirmationEducation = (<ModalDialog {...this.props} show={this.state.showConfirmDeleteEducation}
            title="Confirm Deletion"
            subTitle={"School will be deleted.  Proceed?"}
            handleClose={() => this.setState({ showConfirmDeleteEducation: false })}
            handleSave={this.handleDeleteEducation}
            fullWidth={false}
            maxWidth="sm"
            saveLabel="Delete" />)
        const educationEditor = (<ModalDialog {...this.props} show={this.state.showEducationEditor}
            title={this.state.editMode + " School"}
            handleClose={() => this.setState({ showEducationEditor: false })}
            handleSave={this.handleSaveEducation}
            fullWidth={true}
            maxWidth="sm"
        >
            <SectionFieldGroup dark={this.props.dark}>
                <TextInput
                    dark={this.props.dark}
                    cKey="school"
                    cid="schoolF"
                    id="fldSchool"
                    label="School"
                    defaultValue={this.state.schoolValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="field"
                    cid="fieldF"
                    id="fldField"
                    label="Field of Study"
                    defaultValue={this.state.fieldValue}
                />
                <SelectList
                    dark={this.props.dark}
                    key="type"
                    label="Type"
                    value={this.state.typeValue}
                    onChange={this.handleTypeChange}
                    listItems={this.props.profile.schoolTypeList}
                />
                <FormControlLabel
                    sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black }}
                    control={<Checkbox checked={this.state.degreeValue} onChange={this.handleDegreeChange} color="secondary" />}
                    label="Degree Earned?"
                />
                <SelectList
                    dark={this.props.dark}
                    key="dtype"
                    label="Degree Type"
                    value={this.state.degreeTypeValue}
                    onChange={this.handleDegreeTypeChange}
                    listItems={this.props.profile.degreeTypeList}
                />
                <DateSelector
                    dark={this.props.dark}
                    label="Start Date"
                    value={this.state.startValue}
                    handleChange={this.handleStartChange}
                />
                <DateSelector
                    dark={this.props.dark}
                    label="End Date"
                    value={this.state.endValue}
                    handleChange={this.handleEndChange}
                />
            </SectionFieldGroup>
        </ModalDialog>)
    
        const jobItem = (item, index) => {
            return (
                <SectionItem
                    itemID={item.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={item.title}
                    primaryContent={item.employer + " from " + new Date(item.startDate).toLocaleDateString() + " to " + new Date(item.endDate).toLocaleDateString()}
                    handleEdit={this.handleEditJob}
                    handleDelete={this.handleShowConfirmDeleteJob}
                >
                    <PublicAttribute {...this.props} attr={item.id} />
                </SectionItem>
            )
        }

        const Job = (<Section dark={this.props.dark} title="My Employment History" handleAdd={this.props.profile.employmentHistory.length > 0 && this.handleAddJob} {...this.props} publicAttribute="employment">
            {this.props.profile.employmentHistory.length > 0 ?
                this.props.profile.employmentHistory.map((item, index) => jobItem(item, index)) :
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" onClick={this.handleAddJob} color="secondary" sx={{ width: "250px" }}>Add Employment</Button>
                </div>
                }
        </Section>)


        const deleteConfirmationJob = (<ModalDialog {...this.props} show={this.state.showConfirmDeleteJob}
            title="Confirm Deletion"
            subTitle={"Job will be deleted.  Proceed?"}
            handleClose={() => this.setState({ showConfirmDeleteJob: false })}
            handleSave={this.handleDeleteJob}
            fullWidth={false}
            maxWidth="sm"
            saveLabel="Delete" />)
        const jobEditor = (<ModalDialog {...this.props} show={this.state.showJobEditor}
            title={this.state.editMode + " Job"}
            handleClose={() => this.setState({ showJobEditor: false })}
            handleSave={this.handleSaveJob}
            fullWidth={true}
            maxWidth="sm"
        >
            <SectionFieldGroup dark={this.props.dark}>
                <TextInput
                    dark={this.props.dark}
                    cKey="title"
                    cid="titleF"
                    id="fldTitle"
                    label="Title"
                    defaultValue={this.state.titleValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="employer"
                    cid="employerF"
                    id="fldEmployer"
                    label="Employer"
                    defaultValue={this.state.employerValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="desc"
                    cid="descF"
                    id="fldDesc"
                    label="Job Description"
                    defaultValue={this.state.descValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="achievements"
                    cid="achievementsF"
                    id="fldAchievements"
                    label="Notable Achievements"
                    defaultValue={this.state.achievementsValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="leaveReason"
                    cid="leaveReasonF"
                    id="fldLeaveReason"
                    label="Reason for leaving"
                    defaultValue={this.state.leaveReasonValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="salary"
                    cid="salaryF"
                    id="fldSalary"
                    label="Ending Salary"
                    defaultValue={this.state.salaryValue}
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }}
                />
                <DateSelector
                    dark={this.props.dark}
                    label="Start Date"
                    value={this.state.startValue}
                    handleChange={this.handleStartChange}
                />
                <DateSelector
                    dark={this.props.dark}
                    label="End Date"
                    value={this.state.endValue}
                    handleChange={this.handleEndChange}
                />
            </SectionFieldGroup>

        </ModalDialog>)


        return (
            <>
                <SectionGrid xs={12} md={6} xl={4} height="calc(100% - 40px)">
                    {Event}
                    {Education}
                    {Job}
                </SectionGrid>          
                {deleteConfirmationEvent}
                {eventEditor}
                {deleteConfirmationEducation}
                {educationEditor}
                {deleteConfirmationJob}
                {jobEditor}
            </>
        );
    }
}
