
export async function getDailyNote(date) {

    const url = 'note/getdailynote?date=' + new Date(date).toLocaleDateString();
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function saveDailyNote(content, preview, date) {
    if (!date)
        date = new Date();
    date.setHours(0, 0, 0, 0);
    const req = { NoteContent: content, PreviewText: preview,  NoteDate: date };
    const response = await fetch('note/editdailynote', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function getNotebooks() {
    const url = 'note/getallnotebooks';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data.noteBooks;
    return null;
}

export async function newNote(title = "New Note", notebookID) {
    const req = { NoteBookID: notebookID, Title: title };
    const response = await fetch('note/createusernote', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function editNote(id, content, preview) {
    const req = { NoteContent: content, PreviewText: preview, NoteID: id };
    const response = await fetch('note/editnotecontent', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function deleteNote(id) {
    const req = { NoteID: id };
    const response =  await fetch('note/removenote', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}
export async function moveNote(id, parentid) {
    const req = { NoteID: id, NoteBookID: parentid };
    const response = await fetch('note/movenote', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}
export async function newNotebook(title) {
    const req = { Title: title };
    const response = await fetch('note/createnotebook', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        const nb = { id : id, title : title, createDate: new Date(), notes:[]}
        return nb;
    }
    return null;
}

export async function editNotebook(id, title) {
    const req = { Title: title, NoteBookID: id };
    const response = await fetch('note/editnotebook', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function deleteNotebook(id) {
    const req = { NoteBookID: id };
    const response =  await fetch('note/removenotebook', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}
export async function shareNote(id, ownerid, userid, isFolder, appid, canedit, hasexpiration, expiration) {
    const req = {
        NoteID: id,
        OwnerID: ownerid,
        UserID: userid,
        IsFolder: isFolder,
        AppID: appid,
        CanEdit: canedit,
        HasExpiration: hasexpiration,
        Expiration: expiration
    };
    const response = await fetch("note/sharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function unShareNote(id, ownerid, userid, appid) {
    const req = {
        NoteID: id,
        OwnerID: ownerid,
        UserID: userid,
        AppID: appid
    };
    const response = await fetch("note/unsharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function hideSharedNote(id, ownerid, userid, appid) {
    const req = {
        NoteID: id,
        OwnerID: ownerid,
        UserID: userid,
        AppID: appid
    };
    const response = await fetch("note/hidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function unhideSharedNote(id, ownerid, userid, appid) {
    const req = {
        NoteID: id,
        OwnerID: ownerid,
        UserID: userid,
        AppID: appid
    };
    const response = await fetch("note/unhidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}