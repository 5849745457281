import React, { Component } from 'react';

//Custom Components
import { Blog } from '../Content/Blog/Blog';
import { Event } from '../Content/Events/Event';


//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

//Link Components
import Linkify from 'react-linkify';
import { LinkPreview } from '@dhaiwat10/react-link-preview';

//Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


//React Player (videos)
/*import ReactPlayer from 'react-player'*/

//Data Services
import { getPostContent, repost } from '../../DataServices/PostService'

export class Repost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDelete: false,
            mode: this.props.mode,
            anchorEl: null,
            anchorUserEl: null,
            openPhotoViewer: false,
            selectedPhoto: null,
            showComments: false,
            showConfirmDeletePost: false,
            contentLoaded: false,
            showCommentSection: this.props.post.commentCount > 0 ? true : true,
            showBlog: false,
            showDocument: false
        };
    }

    componentDidMount() {
        this.loadPost();
    }

    loadPost = async () => {
        await this.getPostContent();
        this.setState({ contentLoaded: true });
    }
    getPostContent = async () => {
        const post = this.props.post;
        if (post.content) {
            for (var j = 0; j < post.content.length; j++) {
                if (post.content[j].id &&
                    (post.content[j].type === 2 && (!post.content[j].photo || !post.content[j].photo.thumb)) ||
                    (post.content[j].type === 3) ||
                    (post.content[j].type === 4 && !post.content[j].blog) ||
                    (post.content[j].type === 8 && !post.content[j].event) ||
                    (post.content[j].type === 6 && !post.content[j].document)) {
                    var content = post.content[j];
                    if (content.type !== 0 && content.type !== 1) {
                        const data = await getPostContent(content.id);
                        this.props.updatePostContent(this.props.postIdx, this.props.idx, j, data);
                    }
                   
                }
            }
        }
    }


                           
    //Render funcs
    postHeaderContent = () => {
        if (this.props.post.user) {
            return (
                <Box key={this.props.post.id + "h"} sx={{ display: "flex", padding: { xs: "8px", md: "0px" }, marginBottom: "8px" }}>
                    <Box sx={{ display: { xs: 'flex' } }}>
                        <Box sx={{ display: "inline-block" }}>
                            {this.state.contentLoaded ?
                                <Avatar
                                    className="avatarImageMedium"
                                    alt={this.props.post.user && this.props.post.user.firstName + " " + this.props.post.user.lastName}
                                    src={(this.props.post.user && this.props.post.user.avatarB64) && "data:image/png;base64, " + this.props.post.user.avatarB64}
                                    imgProps={{ style: { cursor: "pointer" } }}
                                />
                                :
                                <Skeleton animation="wave" variant="circular" width={40} height={40} />
                            }
                        </Box>
                        <Box sx={{ color: "#ccc", marginLeft: "15px", display: "inline-block" }}>
                            {this.state.contentLoaded ?
                                <Typography variant="subtitle2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, fontWeight: 550 }}>
                                    {this.props.post.user.firstName + " " + this.props.post.user.lastName}
                                </Typography>
                                :
                                <Skeleton
                                    animation="wave"
                                    height={10}
                                    width={150}
                                    style={{ marginBottom: 6 }}
                                />
                            }
                            {this.state.contentLoaded ?
                                <Typography variant="body2" sx={{ color: this.props.dark ? "#aaa" : "#444", fontWeight: 500, fontSize: ".7rem" }}>
                                    {this.props.post.whenPosted ?? new Date(this.props.post.postDate).toLocaleDateString()}
                                </Typography>
                                :
                                <Skeleton animation="wave" height={10} width={100} />
                            }

                        </Box>
                    </Box>
                  
                  
                </Box>
            )
        }
        return null;
    }
    postContent = () => {

        if (this.props.post.content && this.state.contentLoaded) {
            return (
                <Box key={this.props.post.id + "contentRegion"}>
                    {this.props.post.content.map((content, index) => {
                        switch (content.type) {
                            case 0:
                                return this.textContent(content, index);
                            case 1:
                                return this.linkContent(content, index);
                            default:
                                return null;
                        }
                    })}
                    {this.props.post.content.map((content, index) => {
                        switch (content.type) {
                            case 2:
                                return this.photoContent(content, index);
                            case 3:
                                return this.videoContent(content, index);
                            case 4:
                                return this.blogContent(content, index);
                            case 6:
                                return this.docContent(content, index);
                            case 8:
                                return this.eventContent(content, index);
                            case 11:
                                return this.repost(content.post)
                            default:

                                return null;
                        }
                    })}
                </Box>
            )

        }
        return (
            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
        )
    }
    textContent = (content, index) => {
        if (content.textContent && content.textContent !== "") {
            return (
                <Box key={"tc-" + index + content.id} className="prettyScroll" sx={{ padding: "8px 24px", maxHeight: "400px" }}>
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                        <Typography variant="subtitle2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimmerblack, fontWeight: 500 }}>
                            {content.textContent}
                        </Typography>
                    </Linkify>
                </Box >
            )
        }
        
        return null;
    }
    linkContent = (content, index) => {
        if (content.textContent) {
            return (
                <Box key={"lc-" + index + content.id} sx={{ padding: "8px 8px" }} className={this.props.dark ? "dark" : "light"}>
                    <LinkPreview
                        url={content.textContent}
                        width='100%'

                        borderColor={this.props.dark ? "transparent" : "#ddd"}
                        primaryTextColor={this.props.dark ? "#ccc" : "#444"}
                        secondaryTextColor={this.props.dark ? "#ddd" : "#555"}
                        backgroundColor={this.props.dark ? "#050505" : "#fff"}
                        className={"linkPreview"}
                        openInNewTab={true}
                        showLoader={false}
                        fallback={<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                            <Typography variant="subtitle2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimmerblack, fontWeight: 500 }}>
                                {content.textContent}
                            </Typography>
                        </Linkify>}
                    />
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                        <Typography variant="subtitle2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimmerblack, fontWeight: 500 }}>
                            {content.textContent}
                        </Typography>
                    </Linkify>
                </Box>
            )
        }
        return null;
    }
    photoContent = (content, index) => {
        const photos = this.props.post.content.filter((x) => x.type === 2);
        let photoCount = 1;
        if (photos)
            photoCount = photos.length;
        if (content.photo && content.photo.thumb) {
            const imageUrl = "data:image/png;base64, " + content.photo.thumb;
            return (
                <Box key={"photofor" + content.photo.photoID} sx={{ marginTop: "8px", textAlign: "center" }}>
                    <Paper
                        component="img"
                        elevation={this.props.dark ? 0 : 4}
                        key={"pc-" + index}
                        id={"image_" + content.id + "_" + index}
                        className="postimage"
                        src={imageUrl}
                        style={{
                            backgroundColor: this.props.dark ? "transparent" : "white",
           
                            minWidth: 200,
                            height: "auto",
                            objectFit: "cover",
                            cursor: "pointer",
                            borderRadius: "0px",
                            border: this.props.dark ? "none" : "solid 1px #ddd"
                        }}
                        sx={{
                            maxHeight: { xs: photoCount > 1 ? 250 : 500, md: photoCount > 1 ? 350 : 700, lg: photoCount > 1 ? 400 : 800, xl: photoCount > 1 ? 500 : 1000, },
                            maxWidth: { xs: '100%', md: photoCount === 1 ? "640px" : "100%" },
                            width: { xs:  "100%", md: photoCount > 1 ? "50%" : "100%"  },

                        }}
                    />
                </Box>
            )
        }
        return null;
    }
    docContent = (content, index) => {
    
        if (content.document) {
            return (
                <Card
                    key={"cardfor" + content.document.documentID}
                    sx={{ margin: "16px", maxWidth: 360, background: (theme) => this.props.dark && theme.palette.doc.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }}
                    raised
                    elevation={this.props.dark ? 1 : 4}
                >

                        <CardContent sx={{ padding:"12px" }}>
                            <Box display="flex" >
                                <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "42px", color: 'rgb(50,71,140)', }} />
                                <Stack sx={{ marginLeft: "8px" }}>
                                    <Typography gutterBottom variant="subtitle1" sx={{ fontSize: ".9rem", marginBottom: 0 }} component="div">
                                        {content.document.documentName ? content.document.documentName.substr(0, 30) : "Document"}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {new Date(content.document.createDate).toLocaleDateString()}
                                    </Typography>
                                </Stack>
                            </Box>
                        </CardContent>

                </Card>

            )
        }
        return (
            <Skeleton key={content.id + index} sx={{ height: 190 }} variant="rectangular" />
        )
    }
    videoContent = (content, index) => {
        if (content.rumbleVideo) {
            return (
                <Box key={content.rumbleVideo.id} sx={{ marginTop: "8px", textAlign:"center" }}>
                    <Box
                        component="iframe"
                        sx={{
                            width: { xs: "400px",  sm: "640px" },
                            height: { xs: "220px", sm: "360px" },
                            marginLeft: "auto",
                            marginRight:"auto"
                        }}
                        className="rumble"

                        src={content.rumbleVideo.rumbleURL}
                        frameBorder="0"
                        allowFullScreen
                    />
                </Box>
            )
        }
        return null;
    }
    blogContent = (content, index) => {
        if (content.blog) {
            return (
                <Box key={"outercfor" + content.id}>
                <Paper
                    key={"cfor" + content.id}
                    elevation={this.props.dark ? 0 : 4}
                    sx={{ cursor:"pointer", padding: 0, marginTop: "8px", borderRadius: "0px", maxHeight: "500px", overflow: "hidden", border: this.props.dark ? "none" : "solid 1px #ddd", }}>
                    <Blog
                        key={content.id}
                        dark={this.props.dark}
                        pubDate={new Date(this.props.post.postDate).toLocaleDateString()}
                        blog={content.blog}
                        channel={null}
                        user={this.props.user}
                        hideSubscribe
                    />

                    </Paper>
                </Box>

            )
        }
        return (
            <Skeleton key={content.id + index}  sx={{ height: 190}} variant="rectangular" />
        )
    }
    eventContent = (content, index) => {
        if (content.event) {
            return (
                <Box key={"outercfor" + content.id}>
                    <Paper
                        key={"cfor" + content.id}
                        elevation={this.props.dark ? 0 : 4}
                        sx={{ cursor: "pointer", padding: 0, marginTop: "8px", borderRadius: "0px", maxHeight: "300px", overflow: "hidden", border: this.props.dark ? "none" : "solid 1px #ddd", }}>
                        <Event
                            key={content.id}
                            dark={this.props.dark}
                            event={content.event}
                            user={this.props.user}
                            post
                        />

                    </Paper>
                </Box>

            )
        }
        return (
            <Skeleton key={content.id + index} sx={{ height: 190 }} variant="rectangular" />
        )
    }

    render() {

        return (
            <Paper
                key={"repost" + this.props.post.id}
                elevation={this.props.dark ? 0 : 4}
                sx={{
                    height: "100%",
                    width:"100%",
                    padding: { xs: "0px", md: "8px" },
                    paddingTop: "8px",
                    borderRadius: { xs: "0px", md: "8px" },
                    border:"none",
                    background: (theme) => this.props.dark ? theme.palette.repost.dark : theme.palette.component3.light,
                }}

            >
                <Box onClick={() => this.props.setSelectedPost &&  this.props.setSelectedPost(this.props.post, -2)} sx={{cursor: "pointer", width:"100%", display:"flex", textAlign:"left"}}>
                    <Stack sx={{width:"100%"}}>
                        {this.postHeaderContent()}
                        {this.postContent()}
                    </Stack>
                    </Box>
            </Paper>
        );
    }


}
