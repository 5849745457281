
export async function newTextTag(targetid, type, text) {
    const req = { TargetID: targetid, TargetType: type, TagComment: text };
    const response = await fetch('tag/addtexttag', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}






