import React, { Component } from 'react';

//Custom Components
import { UserAvatar } from '../Utilities/UserAvatar'
import { Section } from '../Utilities/Section';
import { Invitation } from '../Invitations/Invitation';
import { ModalDialog } from '../Utilities/ModalDialog'

//Material UI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

//Database Services
import { getGroupMembers, getGroupInvitedMembers, removeInvitation, removeMember } from '../../DataServices/GroupService'

export class Group extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInvitation: false,
            showDeleteInvitation: false
        };
    } 

    componentDidMount() {
        this.getGroupMembers();
        this.getGroupInvitedMembers();
    }

    //Get Data----------------------
    getGroupMembers = async () => {
        const members = await getGroupMembers(this.props.group.id);
        if (members) {
            this.props.updateGroupMembers(members, this.props.idx);
        }
    }
    getGroupInvitedMembers = async () => {
        const members = await getGroupInvitedMembers(this.props.group.id);
        if (members) {
            this.props.updateGroupInvitedMembers(members, this.props.idx);
        }
    }

    //Event Handlers-----------------
    handleAddNewMember = () => {
        this.setState({ showInvitation: true });
    }
    handleEditGroup = (e) => {

    }
    updateGroupInviteList = (users) => {
        console.log(users);

        if (users && users.length > 0) {
            const members = [...this.props.group.invitedMembers, ...users];
            this.props.updateGroupInvitedMembers(members, this.props.idx);
        }
    }
    handleShowDeleteMember = (user, idx) => {
        let type;
        let index;
        if (idx <= this.props.group.groupMembers.length - 1) {
            type = "member";
            index = idx;
        }
        else {
            type = "invite";
            index = idx - this.props.group.groupMembers.length;
        }
        if (type === "member") {

            this.setState({ selectedMember: this.props.group.groupMembers[index], selectedMemberType: "member", selectedMemberIDX: index, showDeleteInvitation: true });
        }
        else {
            this.setState({ selectedMember: this.props.group.invitedMembers[index], selectedMemberType: "invite", selectedMemberIDX: index, showDeleteInvitation: true });
        }

    }
    handleDeleteMember = async () => {
        this.setState({ showDeleteInvitation: false });

        if (this.state.selectedMemberType === "member") {
            const members = [...this.props.group.groupMembers];
            members.splice(this.state.selectedMemberIDX, 1);
            this.props.updateGroupMembers(members, this.props.idx);
            await removeMember(this.state.selectedMember.id);
        }
        else {
            const invited = [...this.props.group.invitedMembers];
            invited.splice(this.state.selectedMemberIDX, 1);
            this.props.updateGroupInvitedMembers(invited, this.props.idx);
            await removeInvitation(this.state.selectedMember.id);
        }
    }

    //Render Funcs
    userAvatar = (user, invited, idx) => {
        let name = user.firstName;
        if (user.lastName && user.lastName !== null && user.lastName !== "null" && user.lastName.length > 0) {
            name += " " + user.lastName;       
        }
        let invitedText = "Invited";
        if (user.userID === null || !user.userID) {
            invitedText += " by Email";
        }
        let creator = user.userID === this.props.group.ownerID;
        return (
            <Stack>
                <Box sx={{ margin: "auto" }}>
                    <UserAvatar
                        id={user.userID}
                        user={user}
                        dark={this.props.dark}
                        loaded={true}
                        activeUser={this.props.user}
                        appIdentifier={this.props.appIdentifier}
                        hideName={true}
                        large
                        menuItem1={this.handleShowDeleteMember}
                        menuItem1Label="Remove Member"
                        userIndex={idx}
                    />
                </Box>

                <Typography variant="subtitle2" sx={{ color: (theme) => creator ? (this.props.dark ? theme.palette.common.white : theme.palette.common.black) : (this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack) }}>
                    {name}
                </Typography>
                {invited &&
                    <Typography variant="body2" align="center" sx={{ fontSize:".7rem", color: (theme) => theme.palette.green.light}}>
                        {invitedText}
                    </Typography>
                }
                {creator &&
                    <Typography variant="body2" align="center" sx={{ fontSize: ".7rem", color: (theme) => theme.palette.secondary.light }}>
                        Group Creator
                    </Typography>
                }
                {(!creator && !invited) &&
                    <Typography variant="body2" align="center" sx={{ fontSize: ".7rem", color: (theme) => theme.palette.secondary.main }}>
                        Active Member
                    </Typography>
                }
            </Stack>
        )
    }
    groupInfo = () => {
        if (this.props.group) {
            return (
                <Section dark={this.props.dark} title={this.props.group.name} handleEdit={this.handleEditGroup} itemType="group">
                    <Box display="flex" sx={{ margin: "auto" }}>
                        <Chip
                            key={"type" + this.props.group.id}
                            label={this.props.group.type + " Group"}
                            color="info"
                            sx={{ width: "fit-content" }}
                            variant="contained"
                        />
                    </Box>
                </Section>

            )
        }
        return null;
    }
    member = (member, index) => {
        if (member && member.user && member.user.firstName) {
            return (
                <Box key={member.id + "m"} display="inline-block" sx={{ p: 2}} >
                    {this.userAvatar(member.user, false, index)}
                </Box>
            )
        }
        return null;
    }
    invitedMember = (member, index) => {
        if (member && member.user && member.user.firstName) {
            const idx = index + this.props.group.groupMembers.length;
            return (
                <Box key={member.id + "i"} display="inline-block" sx={{ p: 2 }} >
                    {this.userAvatar(member.user, true, idx)}
                </Box>
            )
        }
        return null;
    }
    groupMembers = () => {
        return (
            <Section
                dark={this.props.dark}
                title="Members"
                itemType="member"
                handleAdd={this.handleAddNewMember}
            >
                <Box display="block" sx={{ width: "100%" }}>
                    {(this.props.group.groupMembers && this.props.group.groupMembers.length > 0) &&
                        this.props.group.groupMembers.map((item, index) => {
                            return this.member(item, index);
                        })
                    }
                    {(this.props.group.invitedMembers && this.props.group.invitedMembers.length > 0) &&
                        this.props.group.invitedMembers.map((item, index) => {
                            return this.invitedMember(item, index);
                        })
                    }
                </Box>
            </Section>

        )
    }
    invitation = () => {
        if (this.state.showInvitation) {
            return (
                <ModalDialog
                    {...this.props}
                    show={this.state.showInvitation}
                    title="Invite new members"
                    subTitle="Find users to add by entering their name or email address."
                    fullWidth={true}
                    maxWidth="sm"
                    disableButtons={true}
                    noBodyPad
                    fullHeight
                >
                    <Invitation
                        user={this.props.user}
                        dark={this.props.dark}
                        appIdentifier={this.props.appIdentifier}
                        updateInviteList={this.updateGroupInviteList}
                        groupID={this.props.group.id}
                        groupName={this.props.group.name}
                        groupType={0}
                        parentName={this.props.group.name}
                        handleCancel={() => this.setState({ showInvitation: false })}
                        inviteMessage={"You have been invited to join the group " + this.props.group.name}
                    />
                </ModalDialog>)
        }
        return null;
    } 
    memberDeleter = () => {
        return (
            <ModalDialog show={this.state.showDeleteInvitation}
                dark={this.props.dark}
                title="Confirm Deletion"
                subTitle={"Member will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showDeleteInvitation: false })}
                handleDelete={this.handleDeleteMember}
                fullWidth={false}
                maxWidth="sm"
            />
        )
    }
    group = () => {
        return (
            <Stack spacing={2} sx={{ m: 2 }}>
                {this.groupInfo()}
                {this.groupMembers()}
                {this.state.showInvitation && this.invitation()}
                {this.state.showDeleteInvitation && this.memberDeleter()}
            </Stack>
            )
    }

    //main component render
    render() {
        return this.group(); 
    }
}
