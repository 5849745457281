
export async function getLinkFolders(parentID, appid) {
    var url;
    if (parentID) {
        url = 'link/getfolders?parentid=' + parentID + '&appid=' + appid;
    }
    else {
        url = 'link/getfolders?appid=' + appid;
    }
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getLink(id){
    const response = await fetch('link/getlink?id=' + id);
    if (response.status === 200) {
        const data = await response.json();
        if (data)
            return data;
    }
        return null;
}




export async function createLink(href, title, parentid, appid = "LS"){

    const req = { Title: title, Href: href, ParentID: parentid, AppID: appid };
    const response = await fetch('link/createlink', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}


export async function deleteLink(id) {
    const req = { ID: id };
    const response = await fetch("link/deletelink", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}

export async function editLink(id, href, title ) {
    const req = { ID: id, Title: title, Href: href };
    const response = await fetch("link/editlink", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function moveLink(id, parentid) {
    const req = { ID: id, ParentID: parentid };
    const response = await fetch("link/movelink", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function createFolder(name, parentID = "", appid = "LS") {
    const req = { Name: name, ParentID: parentID, AppID: appid };
    const response = await fetch("link/createfolder", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        if (data) {
            const folder = { id: data, name: name, links: [] };
            return folder;
        }
    }
    return false;
}
export async function newOwnerFolder(name, userid, parentID, appid) {
    const req = { Name: name, ParentID: parentID, AppID: appid, UserID: userid };
    const response = await fetch("link/createownerfolder", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        if (data) {
            const folder = { id: data, name: name, links: [] };
            return folder;
        }
    }
    return false;
}

export async function deleteFolder(id) {
    const req = { FolderID: id };
    const response = await fetch("link/deletefolder", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}

export async function editFolder(id, name) {
    const req = { FolderID: id, Name: name };
    const response = await fetch("link/editfolder", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}

export async function shareLink(id, ownerid, userid, isFolder, appid, canedit, hasexpiration, expiration) {
    const req = {
        ID: id,
        OwnerID: ownerid,
        UserId: userid,
        IsFolder: isFolder,
        AppID: appid,
        CanEdit: canedit,
        HasExpiration: hasexpiration,
        Expiration: expiration
    };
    const response = await fetch("link/sharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function unShareLink(id, ownerid, userid, appid) {
    const req = {
        ID: id,
        OwnerID: ownerid,
        UserId: userid,
        AppID: appid
    };
    const response = await fetch("link/unsharecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function hideSharedLink(id, ownerid, userid, appid) {
    const req = {
        ID: id,
        OwnerID: ownerid,
        UserId: userid,
        AppID: appid
    };
    const response = await fetch("link/hidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function unhideSharedLink(id, ownerid, userid, appid) {
    const req = {
        ID: id,
        OwnerID: ownerid,
        UserId: userid,
        AppID: appid
    };
    const response = await fetch("link/unhidesharedcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}