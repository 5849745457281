import React, { Component } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


export class ContextMenu extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.menuItems) {
            var items = this.props.menuItems.map((item, i) => {
                return (
                    <MenuItem key={i} onClick={item.callBack}>{item.label}</MenuItem>
                )
            });
        }

        return (
            <Menu
                id={this.props.id}
                open={this.props.show}
                onClose={this.props.onClose}
                anchorReference="anchorPosition"
                PaperProps={{
                    elevation: 2,
                    sx: {
                        backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundModal.dark : theme.palette.backgroundModal.light,
                        color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimwhite
                    }
                }}
                anchorPosition={
                    this.props.contextMenu !== null
                        ? { top: this.props.contextMenu.mouseY, left: this.props.contextMenu.mouseX }
                        : undefined
                }
                MenuListProps={{ sx: { backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundModal.dark : theme.palette.backgroundModal.light, color: this.props.dark ? "#ccc" : "#444" } }}
            >
            {items}
            </Menu>

        );
    }
}
