export async function getUserBlogChannels(appid) {
    let url = 'blog/getuserblogchannels?appid=' + appid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getBlogChannel(id) {
    if (id) {
        let url = 'blog/getblogchannel?id=' + id;
        const response = await fetch(url);
        if (response.status !== 200)
            return "error";
        const data = await response.json();
        if (data)
            return data;
    }
    return null;
}

//Returns new BlogChannelID
export async function createBlogChannel(ownerid, category, name, desc, allowsubscribers, allowinteractions, appid) {
    const req = { OwnerID: ownerid, Category: category, Name: name, Desc: desc, AllowSubscribers: allowsubscribers, AllowInteractions: allowinteractions, AppID : appid };
    const response = await fetch("blog/createblogchannel", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//Returns true is success
export async function editBlogChannel(id, category, name, desc, allowsubscribers, allowinteractions) {

    const req = { ID: id, Category: category, Name: name, Desc: desc, AllowSubscribers: allowsubscribers, AllowInteractions: allowinteractions };
    console.log(req);
    const response = await fetch("blog/editblogchannel", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//Returns true is success
export async function deleteBlogChannel(id) {
    const req = { ID: id };
    const response = await fetch("blog/deleteblogchannel", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function getBlogTemplates() {
    let url = 'blog/getblogtemplates';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

//Returns new BlogTemplateID
export async function createBlogTemplate(name, photoid, styles) {
    const req = { Name: name, PhotoID: photoid, Styles: styles, AuthorID: "" };
    const response = await fetch("blog/createblogtemplate", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//Returns true is success
export async function editBlogTemplate(id, name, photoid, styles) {

    const req = { ID: id, Name: name, PhotoID: photoid, Styles: styles, AuthorID: "" };
    console.log(req);
    const response = await fetch("blog/editblogtemplate", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//Returns true is success
export async function deleteBlogTemplate(id) {
    const req = { ID: id };
    const response = await fetch("blog/deleteblogtemplate", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
//Returns true is success
export async function setChannelTemplate(id, templateid) {
    const req = { ID: id, TemplateID: templateid };
    const response = await fetch("blog/setchanneltemplate", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
//Returns true is success
export async function subscribe(id, userid) {
    const req = { ID: id, UserID: userid };
    const response = await fetch("blog/subscribe", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//Returns true is success
export async function unsubscribe(id, userid) {
    const req = { ID: id, UserID: userid };
    const response = await fetch("blog/unsubscribe", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function getUserBlogs(appid) {
    let url = 'blog/getuserblogs?appid=' + appid;
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getBlog(id) {
    if (id) {
        let url = 'blog/getblog?blogid=' + id;
        const response = await fetch(url);
        if (response.status !== 200)
            return "error";
        const data = await response.json();
        if (data)
            return data;
    }
    return null;
}

//Returns new BlogID
export async function createBlog(category, parentid, allowinteractions, appid) {
    const req = { Category: category, ParentID: parentid, AllowInteractions: allowinteractions, AppID: appid };
    const response = await fetch("blog/createblog", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function editBlog(id, category, parentid, allowinteractions) {
    const req = { ID: id, Category: category, ParentID: parentid, AllowInteractions: allowinteractions };
    const response = await fetch("blog/editblog", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function updateHeader(id, title, subtitle) {
    const req = { BlogID: id, Title: title, SubTitle: subtitle };
    const response = await fetch("blog/updateheader", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function updateHeaderPhotoID(id, photoid) {
    const req = { BlogID: id, PhotoID: photoid };
    const response = await fetch("blog/updateheaderphotoid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
//returns true is successful
export async function updateHeaderCoverPhotoID(id, photoid) {
    const req = { BlogID: id, PhotoID: photoid };
    const response = await fetch("blog/updateheadercoverphotoid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

export async function updateAuthor(id, name, desc, fb, li, tw, ls) {
    const req = {
        BlogID: id,
        AuthorName: name,
        AuthorDesc: desc,
        AuthorFB: fb,
        AuthorLI: li,
        AuthorTW: tw,
        AuthorLS: ls
    };
    const response = await fetch("blog/updateauthor", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })


    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
export async function updateAuthorPhotoID(id, photoid) {
    const req = { BlogID: id, PhotoID: photoid };
    const response = await fetch("blog/updateauthorphotoid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}
//return rowid
export async function createBodyRow(id, numcols) {
    const req = { BlogID: id, NumCols: numcols };
    const response = await fetch("blog/createbodyrow", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function moveBodyRow(id, rowid, idx) {
    const req = { BlogID: id, RowID: rowid, Idx: idx };
    const response = await fetch("blog/movebodyrow", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function updateRowColCount(rowid, numcols) {
    const req = { RowID: rowid, NumCols: numcols };
    const response = await fetch("blog/updaterowcolcount", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function deleteRow(rowid) {
    const req = { RowID: rowid };
    const response = await fetch("blog/deleterow", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns contentid
export async function addContent(id, rowid, idx, contenttype, textcontent, photoid) {
    const req = { BlogID: id, RowID: rowid, Idx: idx, ContentType: contenttype, TextContent: textcontent, PhotoID: photoid };
    const response = await fetch("blog/addcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function editTextContent(id, textcontent) {
    const req = { ID: id, TextContent: textcontent };
    const response = await fetch("blog/edittextcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function updateContentPhotoID(id, photoid) {
    const req = { ID: id, PhotoID: photoid};
    const response = await fetch("blog/updatecontentphotoid", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function moveContent(id, idx) {
    const req = { ID: id, Idx: idx};
    const response = await fetch("blog/movecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function deleteContent(id) {
    const req = { ID: id };
    const response = await fetch("blog/deletecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function updateStatus(id, status) {
    const req = { ID: id, Status: status };
    const response = await fetch("blog/updatestatus", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}

//returns true is successful
export async function deleteBlog(id) {
    const req = { BlogID: id };
    const response = await fetch("blog/deleteblog", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return null;
}


