import React, { Component } from 'react';

//Custom Components
import { Section } from '../Utilities/Section';
import { Spin } from '../Utilities/Spin';

//Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';


export class UserSiteAbout extends Component {
    constructor(props) {
        super(props);
    }

    about = () => {
        if (this.props.loading) {
            return (
                <Box sx={{ height:"100%", width: "100%", display: 'flex', justifyContent: "center" }}>
                    <Spin sub />
                </Box>
            )
        }
        return (
            <Stack>
                <Section key={"personal"} dark={this.props.dark} title="Personal Info" padBottom >
                    <Box disaply="flex" sx={{ textAlign: "center" }}>
                        {(this.props.siteProfile && this.props.siteProfile.gender) &&
                            <Chip label={this.props.siteProfile.gender} color="secondary" sx={{ m: 1 }} />
                        }
                        {(this.props.siteProfile && this.props.siteProfile.relationshipStatus) &&
                            <Chip label={this.props.siteProfile.relationshipStatus} color="secondary" sx={{ m: 1 }} />
                        }
                        {(this.props.siteProfile && this.props.siteProfile.sexualPreference) &&
                            <Chip label={this.props.siteProfile.sexualPreference} color="secondary" sx={{ m: 1 }} />
                        }
                        {(this.props.siteProfile && this.props.siteProfile.politicalSlant) &&
                            <Chip label={this.props.siteProfile.politicalSlant + " Politics"} color="secondary" sx={{ m: 1 }} />
                        }
                        {(this.props.siteProfile && this.props.siteProfile.religiousViews) &&
                            <Chip label={this.props.siteProfile.religiousViews} color="secondary" sx={{ m: 1 }} />
                        }
                        {(this.props.siteProfile && this.props.siteProfile.socialType) &&
                            <Chip label={this.props.siteProfile.socialType} color="secondary" sx={{ m: 1 }} />
                        }
                        {(this.props.siteProfile && this.props.siteProfile.dob) &&
                            <Chip label={"Birthdate " + new Date(this.props.siteProfile.dob).toLocaleDateString()} color="secondary" sx={{ m: 1 }} />
                        }
                        {(this.props.siteProfile && this.props.siteProfile.weddingAnniversary) &&
                            <Chip label={"Wedding Anniversary " + new Date(this.props.siteProfile.weddingAnniversary).toLocaleDateString()} color="secondary" sx={{ m: 1 }} />
                        }
                        {(this.props.siteProfile && this.props.siteProfile.jobAnniversary) &&
                            <Chip label={"Job Anniversary " + new Date(this.props.siteProfile.jobAnniversary).toLocaleDateString()} color="secondary" sx={{ m: 1 }} />
                        }
                    </Box>
                </Section>
                {(this.props.siteProfile && ((this.props.siteProfile.emailAddresses && this.props.siteProfile.emailAddresses.length > 0) || (this.props.siteProfile.addresses && this.props.siteProfile.addresses.length > 0) || (this.props.siteProfile.phoneNumbers && this.props.siteProfile.phoneNumbers.length >0))) &&
                    <Section key={"contact"} dark={this.props.dark} title="Contact Info" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {(this.props.siteProfile && this.props.siteProfile.emailAddresses) &&
                                this.props.siteProfile.emailAddresses.map((item) => {
                                    return (<Chip label={item.address} color="secondary" sx={{ m: 1 }} />)
                                })
                            }
                            {(this.props.siteProfile && this.props.siteProfile.addresses) &&
                                this.props.siteProfile.addresses.map((item) => {
                                    return (<Chip label={item.street + ", " + item.city + ", " + item.state + " " + item.zip} color="secondary" sx={{ m: 1 }} />)
                                })
                            }
                            {(this.props.siteProfile && this.props.siteProfile.phoneNumbers) &&
                                this.props.siteProfile.phoneNumbers.map((item) => {
                                    return (<Chip label={item.number} color="secondary" sx={{ m: 1 }} />)
                                })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.interests && this.props.siteProfile.interests.length > 0) &&
                    <Section key={"interests"} dark={this.props.dark} title="Interests" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.interests.map((item) => {
                                return (<Chip label={item} color="secondary" sx={{ m: 1 }} />)
                            })
                            }           
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.movieTastes && this.props.siteProfile.movieTastes.length > 0) &&
                    <Section key={"movie"} dark={this.props.dark} title="Movie Tastes" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.movieTastes.map((item) => {
                                return (<Chip label={item} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.musicTastes && this.props.siteProfile.musicTastes.length > 0) &&
                    <Section key={"music"} dark={this.props.dark} title="Music Tastes" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.musicTastes.map((item) => {
                                return (<Chip label={item} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && (this.props.siteProfile.foodTastes && this.props.siteProfile.foodTastes.length > 0)) &&
                    <Section key={"food"} dark={this.props.dark} title="Food Tastes" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.foodTastes.map((item) => {
                                return (<Chip label={item} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.drinkTastes && this.props.siteProfile.drinkTastes.length > 0) &&
                    <Section key={"drink"} dark={this.props.dark} title="Drink Tastes" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.drinkTastes.map((item) => {
                                return (<Chip label={item} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.readingTastes && this.props.siteProfile.readingTastes.length > 0) &&
                    <Section dark={this.props.dark} title="Reading Tastes" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.readingTastes.map((item) => {
                                return (<Chip label={item} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.personalStrengths && this.props.siteProfile.personalStrengths.length > 0) &&
                    <Section key={"strengths"} dark={this.props.dark} title="Personal Strengths" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.personalStrengths.map((item) => {
                                return (<Chip label={item} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.personalWeaknesses && this.props.siteProfile.personalWeaknesses.length > 0) &&
                    <Section key={"weakness"} dark={this.props.dark} title="Personal Weaknesses" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.personalWeaknesses.map((item) => {
                                return (<Chip label={item} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.educationHistory && this.props.siteProfile.educationHistory.length > 0) &&
                    <Section key={"education"} dark={this.props.dark} title="Education" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.educationHistory.map((item) => {
                                return (<Chip label={item.schoolName} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.employmentHistory && this.props.siteProfile.employmentHistory.length > 0) &&
                    <Section key={"work"} dark={this.props.dark} title="Work History" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.employmentHistory.map((item) => {
                                return (<Chip label={item.title + (item.employer && " at " + item.employer)} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.lifeRoles && this.props.siteProfile.lifeRoles.length > 0) &&
                    <Section key={"roles"} dark={this.props.dark} title="Life Roles" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.lifeRoles.map((item) => {
                                return (<Chip label={item.desc} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.occupations && this.props.siteProfile.occupations.length > 0) &&
                    <Section key={"occupations"} dark={this.props.dark} title="Other Occupations or Responsibilities" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.occupations.map((item) => {
                                return (<Chip label={item.title} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.personalGoals && this.props.siteProfile.personalGoals.length > 0) &&
                    <Section key={"goals"} dark={this.props.dark} title="Personal Goals" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.personalGoals.map((item) => {
                                return (<Chip label={item.goalDesc} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.bucketList && this.props.siteProfile.bucketList.length > 0) &&
                    <Section key={"bucket"} dark={this.props.dark} title="Bucket List" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.bucketList.map((item) => {
                                return (<Chip label={item.title} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
                {(this.props.siteProfile && this.props.siteProfile.lifeEvents && this.props.siteProfile.lifeEvents.length > 0) &&
                    <Section key={"lifeevents"} dark={this.props.dark} title="Significant Life Events" padBottom>
                        <Box disaply="flex" sx={{ textAlign: "center" }}>
                            {this.props.siteProfile.lifeEvents.map((item) => {
                                return (<Chip label={item.title} color="secondary" sx={{ m: 1 }} />)
                            })
                            }
                        </Box>
                    </Section>
                }
            </Stack>
        )
    }

    render() {
        return this.about();
    }
}

