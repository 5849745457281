// ----------------------------------------------------------------------

export default function AccordionSummary(theme) {
    return {

        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        backgroundColor: "transparent"
                    }
                },

            }
        },
        MuiExpanded: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        backgroundColor: "transparent"
                    }
                },
                MuiAccordionSummary: {
                    styleOverrides: {
                        root: {
                            '&.Mui-focused': {
                                backgroundColor: "transparent"
                            }
                        },

                    }
                },


            }
        }



    
       


    };
}
