
export async function getFamilies() {
    let url = 'family/getallfamilies';
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function newFamily(name) {
    const req = { Name: name };
    const response = await fetch('family/createfamily', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function editFamily(id, name) {
    const req = { Name: name, FamilyID: id };
    const response = await fetch('family/editfamily', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function deleteFamily(id) {
    const req = { FamilyID: id };
    const response = await fetch("family/deletefamily", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}



