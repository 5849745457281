import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { TextInput } from '../Utilities/TextInput';

export class Tags extends Component {


  constructor(props) {
      super(props);
      this.state = {
            tags: [],
      };
    }
    componentDidMount() {
        this.getTextTags();
    }
    getTextTags = async () => {
        const response = await fetch('tags/gettexttags?targetid= ' + this.props.targetID);
        if (response.status === 200) {
            const data = await response.json();
            this.setState({ tags: data });
        }
    }

    parseTags = (text) => {
        var regex = /(?:^|\s)(?:#)([a-zA-Z\d]+)/gm;
        var matches = [];
        var match;

        while ((match = regex.exec(text))) {
            matches.push(match[1]);
        }

        return matches;
    }

    handleRemoveTag = (idx) => {
        const tag = this.state.tags[idx];
        let originalPost = this.state.postValue
        let tagStart = originalPost.indexOf("#" + tag);
        const finalString = originalPost.slice(0, tagStart) + originalPost.slice(tagStart + 1);
        var ele = document.getElementById("newPostTextContent");
        if (ele) {
            ele.value = finalString;
            var event = new Event('input', {
                bubbles: true,
                cancelable: true,
            });
            ele.dispatchEvent(event);
        }
    }

    handleInput = (e) => {
        var val = e.target.value;
        let tags = this.parseTags(val);
        if (this.state.tags !== tags) {
            this.setState({ tags: tags });
        }
    }

    handleBlur = (e) => {
        var val = e.target.value;
        let tags = this.parseTags(val);
        if (this.state.tags !== tags) {
            this.setState(
                state => {
                    state.tags.push(tags);
                    return state;
                }
            );
            e.target.value = "";
        }
    }

    render() {
        const tagInput = (
            <TextInput
                {...this.props}
                cKey="tag"
                cid="tag"
                id="tag"
                label="New Tag"
                defaultValue=""
                onBlur={this.handleBlur}
            />
            )

        const tagContainer = () => {
            if (this.state.tags !== null) {
                return (
                    <Box display="flex" sx={{ flexWrap: 'wrap', padding: "8px" }}>
                        {tagInput}
                        {this.state.tags.map((item, index) => {
                            return (
                                <Chip
                                    label={item}
                                    onDelete={() => this.handleRemoveTag(index)}
                                    size="small"
                                    color="secondary"
                                    sx={{ marginRight: "6px", marginBottom: "6px" }}

                                />
                            )
                        })}
                    </Box>
                )
            }
        }

        return (
            <Box sx={{ display:  'flex' }} >
                {tagContainer()}
            </Box>
        );
    }
}
