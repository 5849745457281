import React, { PureComponent } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'

const modules = {
    toolbar: [
        /*            [{ 'header': [1, 2, false] }],*/
        [{ 'header': [1, 2, false] }, 'bold', 'italic', 'underline', { 'color': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'align': [] }],

    ],
};
const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'align'
];
export class RichTextEditor extends PureComponent {
    componentDidMount() {
        if(this.input)
            this.input.focus();
    }


    componentWillUnmount() {
        if(this.props.onClose) this.props.onClose(this.state.currentContent, this.state.currentPreview);
    }

    updateContent = (content, text) => {
        this.setState({
            currentContent: content,
            currentPreview: text
        });
        this.props.updateContent(this.props.id, this.props.idx, content, text);
    }

    render() {

        return (
            <ReactQuill
                ref={(el) => { this.input = el; }}
                theme="snow"
                defaultValue={this.props.initialContent}
                onChange={(content, delta, source, editor) => this.updateContent(content, editor.getText())}
                modules={modules}
                formats={formats}
            />
        );
    }
};

