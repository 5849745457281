import React, { Component } from 'react';

//Custom
import { ModalDialog } from '../Utilities/ModalDialog'
import { Section } from '../Utilities/Section';
import { SelectionChip } from '../Utilities/SelectionChip';
import { SectionGrid } from '../Utilities/SectionGrid';


//MUI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { setPublic, removePublic } from '../../DataServices/UserService'

export class ProfileInterests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFavoriteType: ""
        };
    }

    clickFavorite = async (item, selected, userfav) => {
        if (!selected) {
            this.setState({
                selectedFavorite: item,
                selectedFavoriteType: item.label,
                showFavoriteEditor: true,
            });
        }
        else {
            this.props.removeFavorite(userfav);
            await fetch('home/removefavorite?id=' + userfav.id, { method: 'POST' });
        }

    }
    handleSaveFavorite = async () => {
        const favVal = document.getElementById("fldFavorite").value;
        if (favVal) {
            const response = await fetch('home/addfavorite?type=' + this.state.selectedFavorite.value + ' &value=' + favVal, { method: 'POST' })
            const id = await response.text();
            const newFav = { id: id, favoriteType: this.state.selectedFavorite.value, favoriteValue: favVal };
            this.props.addFavorite(newFav);
        }
        this.setState({
            selectedFavorite: null,
            showFavoriteEditor: false,
        });
    }
    handleSetPublic = async (attr, val) => {
        if (val === true) {
            await setPublic(attr);
        }
        else {
            await removePublic(attr);
        }
    }

    publicAttribute = (attr) => {
        let val = false;
        if (this.props.profile && this.props.profile.publicAttributes && this.props.profile.publicAttributes.find((x) => x === attr)) {
            val = true;
        }
        return (
            <FormControlLabel
                key={attr + "ctl"}
                sx={{
                    paddingLeft: "16px", marginTop: "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black
                }}
                control={<Checkbox defaultChecked={val} onChange={(e) => this.handleSetPublic(attr, e.target.checked)} color="secondary" size="small" />}
                label={
                    <Typography sx={{ fontSize: ".8rem" }}>
                        Public
                    </Typography>
                }
            />
        )
    }


    render() {
        const interest = (item) => {
            let variant = "outlined";
            let selected = false;
            if (this.props.profile.demographics.interests &&
                this.props.profile.demographics.interests.includes(item.value)) {
                variant = "filled";
                selected = true;
            }
            return (
               <SelectionChip
                    dark={this.props.dark}
                    value={item.value}
                    label={item.label}
                    onClick={() => this.props.toggleSelected(item.value)}
                    selected={selected}
                    variant={variant}
                />
            )
        }
        const Interests = (
            <Section dark={this.props.dark} title="My Interests" {...this.props} publicAttribute="interests">
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        boxShadow: "none",
                        background: "transparent",
                        p: 0.5,
                        m: 0,
                    }}
                    component="div"
                >
                    {this.props.profile.interestsList.map((item) => {
                        return interest(item)
                    })}
                </Paper>
            </Section>)


        const favorite = (item) => {
            let variant = "outlined";
            let selected = false;
            let favVal = "";
            let userFav = null;
            if (this.props.profile.demographics.favorites &&
                this.props.profile.demographics.favorites.find((x) => x.favoriteType === item.value)) {
                variant = "filled";
                selected = true;
                userFav = this.props.profile.demographics.favorites.find((x) => x.favoriteType === item.value);
                favVal = ": " + userFav.favoriteValue;
            }
            
            return (
                <SelectionChip
                    dark={this.props.dark}
                    value={item.value}
                    label={(item.label + favVal).trim()}
                    onClick={() => this.clickFavorite(item, selected, userFav)}
                    selected={selected}
                    variant={variant}
                />
            )

        }
        const favoriteEditor = (<ModalDialog dark={this.props.dark} show={this.state.showFavoriteEditor}
                title={"Favorite " + this.state.selectedFavoriteType}
                handleClose={() => this.setState({ showFavoriteEditor: false })}
                handleSave={this.handleSaveFavorite}
                fullWidth={true}
                maxWidth="xs"
            >
                <Box sx={{ borderRadius: "8px", padding: "8px" }}>
                    <TextField
                        margin="dense"
                        id="favorite"
                        inputProps={{ id: "fldFavorite" }}
                        label="Favorite"
                        type="text"
                        fullWidth
                        variant="standard"
                        defaultValue=""
                        InputProps={{ sx: { color: "#ccc" } }}
                        InputLabelProps={{ style: { color: "#ccc" } }}
                    />
                </Box>
            </ModalDialog>)
        const Favorites = (
            <Section dark={this.props.dark} title="My Favorites"  {...this.props} publicAttribute="favorites">
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        boxShadow: "none",
                        background: "transparent",
                        p: 0.5,
                        m: 0,
                    }}
                    component="div"
                >
                    {this.props.profile.favoritesTypeList.map((item) => {
                        return favorite(item)
                    })}
                </Paper>
            </Section>)

        const music = (item) => {
            let variant = "outlined";
            let selected = false;
            if (this.props.profile.demographics.musicTastes &&
                this.props.profile.demographics.musicTastes.includes(item.value)) {
                variant = "filled";
                selected = true;
            }
            return (
                <SelectionChip
                    dark={this.props.dark}
                    value={item.value}
                    label={item.label}
                    onClick={() => this.props.toggleSelectedTaste(0, item.value, selected)}
                    selected={selected}
                    variant={variant}
                />
            )
        }
        const Music = (
            <Section dark={this.props.dark} title="Music Tastes"  {...this.props} publicAttribute="musictastes">
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        boxShadow: "none",
                        background: "transparent",
                        p: 0.5,
                        m: 0,
                    }}
                    component="div"
                >
                    {this.props.profile.musicTasteList.map((item) => {
                        return music(item)
                    })}
                </Paper>
            </Section>
        )

        const movie = (item) => {
            let variant = "outlined";
            let selected = false;
            if (this.props.profile.demographics.movieTastes &&
                this.props.profile.demographics.movieTastes.includes(item.value)) {
                variant = "filled";
                selected = true;
            }
            return (
               <SelectionChip
                    dark={this.props.dark}
                    value={item.value}
                    label={item.label}
                    onClick={() => this.props.toggleSelectedTaste(1, item.value, selected)}
                    selected={selected}
                    variant={variant}
                />
            )
        }
        const Movies = (
            <Section dark={this.props.dark} title="Movie Tastes"  {...this.props} publicAttribute="movietastes">
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        boxShadow: "none",
                        background: "transparent",
                        p: 0.5,
                        m: 0,
                    }}
                    component="div"
                >
                    {this.props.profile.movieTasteList.map((item) => {
                        return movie(item)
                    })}
                </Paper>
            </Section>)

        const food = (item) => {
            let variant = "outlined";
            let selected = false;
            if (this.props.profile.demographics.foodTastes &&
                this.props.profile.demographics.foodTastes.includes(item.value)) {
                variant = "filled";
                selected = true;
            }
            return (
                <SelectionChip
                    dark={this.props.dark}
                    value={item.value}
                    label={item.label}
                    onClick={() => this.props.toggleSelectedTaste(2, item.value, selected)}
                    selected={selected}
                    variant={variant}
                />
            )
        }
        const Food = (
            <Section dark={this.props.dark} title="Food Tastes"  {...this.props} publicAttribute="foodtastes">
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        boxShadow: "none",
                        background: "transparent",
                        p: 0.5,
                        m: 0,
                    }}
                    component="div"
                >
                    {this.props.profile.foodTasteList.map((item) => {
                        return food(item)
                    })}
                </Paper>
            </Section>)

        const drink = (item) => {
            let variant = "outlined";
            let selected = false;
            if (this.props.profile.demographics.drinkTastes &&
                this.props.profile.demographics.drinkTastes.includes(item.value)) {
                variant = "filled";
                selected = true;
            }
            return (
                <SelectionChip
                    dark={this.props.dark}
                    value={item.value}
                    label={item.label}
                    onClick={() => this.props.toggleSelectedTaste(3, item.value, selected)}
                    selected={selected}
                    variant={variant}
                />
            )
        }
        const Drink = (
            <Section dark={this.props.dark} title="Drink Tastes"  {...this.props} publicAttribute="drinktastes">
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        boxShadow: "none",
                        background: "transparent",
                        p: 0.5,
                        m: 0,
                    }}
                    component="div"
                >
                    {this.props.profile.drinkTasteList.map((item) => {
                        return drink(item)
                    })}
                </Paper>
            </Section>)

        const reading = (item) => {
            let variant = "outlined";
            let selected = false;
            if (this.props.profile.demographics.readingTastes &&
                this.props.profile.demographics.readingTastes.includes(item.value)) {
                variant = "filled";
                selected = true;
            }
            return (
                <SelectionChip
                    dark={this.props.dark}
                    value={item.value}
                    label={item.label}
                    onClick={() => this.props.toggleSelectedTaste(4, item.value, selected)}
                    selected={selected}
                    variant={variant}
                />
            )
        }
        const Reading = (<Section dark={this.props.dark} title="Reading Tastes"  {...this.props} publicAttribute="readingtastes">
            <Paper
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    boxShadow: "none",
                    background: "transparent",
                    p: 0.5,
                    m: 0,
                }}
                component="div"
            >
                {this.props.profile.readingTasteList.map((item) => {
                    return reading(item)
                })}
            </Paper>
        </Section>)

        return (
            <>
                <SectionGrid xs={12} sm={6} lg={4} height="calc(100% - 40px)">
                    {Interests}
                    {Favorites}
                    {Music}
                    {Movies}
                    {Food}
                    {Drink}
                    {Reading}                    
                </SectionGrid>
                {favoriteEditor}
            </>
        );
    }
}
