
export async function createNotification(userid, type, refid, refuserid, message, appid) {
    const req = { UserID: userid, Type: type, RefID: refid, RefUserID: refuserid, Message: message, AppID: appid };
    const response = await fetch('notifications/createnotification', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function getNotifications(all, appid) {

    const response = await fetch('notifications/getnotifications?all=' + (all ?? true) + '&appid=' + appid);

    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

export async function updateNotification(id, status) {
    const req = { NotificationID: id, NewStatus: status };
    const response = await fetch('notifications/updatenotification', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}


