import React, { Component } from 'react';
import { ModalDialog } from '../Utilities/ModalDialog'
import { Section } from '../Utilities/Section';
import { SectionItem } from '../Utilities/SectionItem';
import { SectionGrid } from '../Utilities/SectionGrid';
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
import { SelectList } from '../Utilities/SelectList';
import { DateSelector } from '../Utilities/DateSelector';
import { TextInput } from '../Utilities/TextInput';
import Button from '@mui/material/Button';
import { PublicAttribute } from '../Utilities/PublicAttribute';


export class ProfileBucketList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDeleteBucketList: false,
            showBucketListEditor: false,
            showConfirmDeleteGoal: false,
            showGoalEditor: false
        }
    }

    handleShowConfirmDeleteBucketList  = (id, idx) => {
        this.setState({
            selectedID: id,
            selectedIDX: idx,
            showConfirmDeleteBucketList : true
        })
    }

    handleAddBucketList  = () => {
        this.setState({
            editMode: "New",
            titleValue: "",
            reasonValue: "",
            categoryValue: 0,
            importanceValue: 0,
            dueValue: new Date(),
            showBucketListEditor: true
        })
    }

    handleEditBucketList = (id, idx) => {

        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            titleValue: this.props.profile.demographics.bucketList[idx].title,
            reasonValue: this.props.profile.demographics.bucketList[idx].reasonNotDone,
            categoryValue: this.props.profile.demographics.bucketList[idx].category,
            importanceValue: this.props.profile.demographics.bucketList[idx].importanceRank,
            dueValue: this.props.profile.demographics.bucketList[idx].dueDate,
            showBucketListEditor: true
        })
    }

    handleSaveBucketList = async () => {
        const title = document.getElementById("fldTitle").value.trim();
        const reason = document.getElementById("fldReason").value.trim() ?? "";
        const category = this.state.categoryValue;
        const importance = this.state.importanceValue;
        const due = this.state.dueValue;

        if (this.state.editMode !== "Edit") {
            if (title) {
                const response = await fetch('home/addbucketlistitem?category=' + category + '&title=' + title + '&due=' + due + '&importance=' + importance + '&reason=' + reason, { method: 'POST' })
                const id = await response.text();
                const itm = { id: id, title: title, reasonNotDone: reason, category: category, dueDate: due, importanceRank: importance };
                this.props.profile.demographics.bucketList.push(itm);
                this.setState({ showBucketListEditor: false });
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (title)  {
                await fetch('home/editbucketlistitem?id=' + id + '&category=' + category + '&title=' + title + '&due=' + due + '&importance=' + importance + '&reason=' + reason, { method: 'POST' })
                const itm2 = { id: id, title: title, reasonNotDone: reason, category: category, dueDate: due, importanceRank: importance };
                this.props.updateBucketList(idx, itm2);
                this.setState({ showBucketListEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleDeleteBucketList = async () => {
        this.props.profile.demographics.bucketList.splice(this.state.selectedIDX,1);
        await fetch('home/removebucketlistitem?id=' + this.state.selectedID, { method: 'POST' });

        this.setState({
            showConfirmDeleteBucketList: false,
            selectedID: "",
            selectedIDX: ""
        })  
    }

    handleCategoryChange = (e) => {
        this.setState({ categoryValue: e.target.value });
    }

    handleImportanceChange = (e) => {
        this.setState({ importanceValue: e.target.value });
    }

    handleDueChange = (e) => {
        this.setState({ dueValue: new Date(e).toLocaleDateString() });
    }

    handleShowConfirmDeleteGoal = (id, idx) => {
        this.setState({
            selectedID: id,
            selectedIDX: idx,
            showConfirmDeleteGoal: true
        })
    }

    handleAddGoal = () => {
        this.setState({
            editMode: "New",
            descValue: "",
            typeValue: 0,
            statusValue: 0,
            importanceValue: 0,
            dueValue: new Date(),
            showGoalEditor: true
        })
    }

    handleEditGoal = (id, idx) => {

        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            descValue: this.props.profile.personalGoals[idx].goalDesc,
            typeValue: this.props.profile.personalGoals[idx].goalType,
            statusValue: this.props.profile.personalGoals[idx].goalStatus,
            importanceValue: this.props.profile.personalGoals[idx].importance,
            dueValue: this.props.profile.personalGoals[idx].targetDate,
            showGoalEditor: true
        })
    }

    handleSaveGoal = async () => {
        const desc = document.getElementById("fldDesc").value.trim();
        const type = this.state.typeValue;
        const importance = this.state.importanceValue;
        const due = this.state.dueValue;
        const status = this.state.statusValue;

        if (this.state.editMode !== "Edit") {
            if (desc) {
                const response = await fetch('home/addgoal?type=' + type + '&desc=' + desc + '&target=' + due + '&importance=' + importance + '&status=' + status, { method: 'POST' })
                const id = await response.text();
                const itm = { id: id, goalDesc: desc, goalType: type, targetDate: due, importance: importance, goalStatus: status };
                console.log(itm);
                this.props.profile.personalGoals.push(itm);
                this.setState({ showGoalEditor: false });
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (desc) {
                await fetch('home/editgoal?id=' + id + '&type=' + type + '&desc=' + desc + '&target=' + due + '&importance=' + importance + '&status=' + status, { method: 'POST' });
                const itm2 = { id: id, goalDesc: desc, goalType: type, targetDate: due, importance: importance, goalStatus: status };
                this.props.updatePersonalGoal(idx, itm2);
                this.setState({ showGoalEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleDeleteGoal = async () => {
        this.props.profile.personalGoals.splice(this.state.selectedIDX, 1);
        await fetch('home/removegoal?id=' + this.state.selectedID, { method: 'POST' });

        this.setState({
            showConfirmDeleteGoal: false,
            selectedID: "",
            selectedIDX: ""
        })
    }

    handleTypeChange = (e) => {
        this.setState({ typeValue: e.target.value });
    }

    handleStatusChange = (e) => {
        this.setState({ statusValue: e.target.value });
    }

    handleShowConfirmDeleteHabit = (id, idx) => {
        this.setState({
            selectedID: id,
            selectedIDX: idx,
            showConfirmDeleteHabit: true
        })
    }

    handleAddHabit = () => {
        this.setState({
            editMode: "New",
            descValue: "",
            typeValue: 0,
            impactsValue:"",
            importanceValue: 0,
            showHabitEditor: true
        })
    }

    handleEditHabit = (id, idx) => {

        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            descValue: this.props.profile.demographics.badHabits[idx].habitDescription,
            typeValue: this.props.profile.demographics.badHabits[idx].habitType,
            importanceValue: this.props.profile.demographics.badHabits[idx].importanceRank,
            impactsValue: this.props.profile.demographics.badHabits[idx].impacts,
            showHabitEditor: true
        })
    }

    handleSaveHabit = async () => {
        const desc = document.getElementById("fldDesc").value.trim();
        const type = this.state.typeValue;
        const importance = this.state.importanceValue;
        const impacts = document.getElementById("fldImpacts").value.trim();


        if (this.state.editMode !== "Edit") {
            if (desc) {
                const response = await fetch('home/addbadhabit?type=' + type + '&desc=' + desc + '&importance=' + importance + '&impacts=' + impacts, { method: 'POST' })
                const id = await response.text();
                const itm = { id: id, habitDescription: desc, habitType: type, impacts: impacts, importance: importance};
                this.props.profile.demographics.badHabits.push(itm);
                this.setState({ showHabitEditor: false });
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (desc) {
                await fetch('home/editbadhabit?id=' + id + '&type=' + type + '&desc=' + desc + '&importance=' + importance + '&impacts=' + impacts, { method: 'POST' })
                const itm2 = { id: id, habitDescription: desc, habitType: type, impacts: impacts, importance: importance };
                this.props.updateHabit(idx, itm2);
                this.setState({ showHabitEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleDeleteHabit = async () => {
        this.props.profile.demographics.badHabits.splice(this.state.selectedIDX, 1);
        await fetch('home/removebadhabit?id=' + this.state.selectedID, { method: 'POST' });

        this.setState({
            showConfirmDeleteHabit: false,
            selectedID: "",
            selectedIDX: ""
        })
    }

    render() {
        const bucketListItem = (item, index) => {
            return (
                <SectionItem
                    itemID={item.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.bucketListCategoryList.find((x) => x.value === item.category).label}
                    primaryContent={item.title + ": " + new Date(item.dueDate).toLocaleDateString()}
                    handleEdit={this.handleEditBucketList}
                    handleDelete = {this.handleShowConfirmDeleteBucketList}
                >
                    <PublicAttribute {...this.props} attr={item.id} /> 
                </SectionItem>
            )
        }
        const BucketList = (
            <Section dark={this.props.dark} title="My Bucket List" handleAdd={this.props.profile.demographics.bucketList.length > 0 && this.handleAddBucketList} {...this.props} publicAttribute="bucketlist">
                {this.props.profile.demographics.bucketList.length > 0 ?
                    this.props.profile.demographics.bucketList.map((item, index) => bucketListItem(item, index))
                    :
                    <div style={{ textAlign: "center" }}>
                        <Button variant="contained" onClick={this.handleAddBucketList} color="secondary" sx={{ width: "250px" }}>Add Bucket List Item</Button>
                    </div>
                    }
            </Section>)

 
        const deleteConfirmationBucketList = (<ModalDialog {...this.props} show={this.state.showConfirmDeleteBucketList}
            title="Confirm Deletion"
            subTitle={"Bucket list item will be deleted.  Proceed?"}
            handleClose={() => this.setState({ showConfirmDeleteBucketList: false })}
            handleSave={this.handleDeleteBucketList}
            fullWidth={false}
            maxWidth="sm"
            saveLabel="Delete" /> )
        const bucketListEditor = (<ModalDialog {...this.props}  show={this.state.showBucketListEditor}
                title={this.state.editMode + " Bucket List Item"}
                handleClose={() => this.setState({ showBucketListEditor: false })}
                handleSave={this.handleSaveBucketList}
                fullWidth={true}
                maxWidth="sm"
        >
            <SectionFieldGroup dark={this.props.dark}>
                <TextInput
                    dark={this.props.dark}
                    cKey="title"
                    cid="titleF"
                    id="fldTitle"
                    label="Title"
                    defaultValue={this.state.titleValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="reason"
                    cid="reasonF"
                    id="fldReason"
                    label="Reason not already done"
                    defaultValue={this.state.reasonValue}
                />
                <SelectList
                    dark={this.props.dark}
                    key="category"
                    label="Category"
                    value={this.state.categoryValue}
                    onChange={this.handleCategoryChange}
                    listItems={this.props.profile.bucketListCategoryList}
                />
                <SelectList
                    dark={this.props.dark}
                    key="importance"
                    label="Importance"
                    value={this.state.importanceValue}
                    onChange={this.handleImportanceChange}
                    listItems={this.props.profile.importanceList}
                />
                <DateSelector
                    dark={this.props.dark}
                    label="Target Due Date"
                    value={this.state.dueValue}
                    handleChange={this.handleDueChange}
                />
            </SectionFieldGroup>
        </ModalDialog>

        )
        const goalItem = (item, index) => {
            return (
                <SectionItem
                    itemID={item.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.goalTypeList.find((x) => x.value === item.goalType).label}
                    primaryContent={item.goalDesc + ": " + this.props.profile.goalStatusList.find((x) => x.value === item.goalStatus).label}
                    handleEdit={this.handleEditGoal}
                    handleDelete={this.handleShowConfirmDeleteGoal}
                >
                    <PublicAttribute {...this.props} attr={item.id} /> 
                </SectionItem>

            )
        }
        const Goal = (
            <Section dark={this.props.dark} title="My Personal Goals" handleAdd={this.props.profile.personalGoals.length > 0 && this.handleAddGoal} {...this.props} publicAttribute="personalgoals">
                {this.props.profile.personalGoals.length > 0 ?
                    this.props.profile.personalGoals.map((item, index) => goalItem(item, index))
                    :
                    <div style={{ textAlign: "center" }}>
                        <Button variant="contained" onClick={this.handleAddGoal} color="secondary" sx={{ width: "250px" }}>Add Personal Goal</Button>
                    </div>
                    }
            </Section>)


        const deleteConfirmationGoal = (<ModalDialog {...this.props} show={this.state.showConfirmDeleteGoal}
            title="Confirm Deletion"
            subTitle={"Personal Goal will be deleted.  Proceed?"}
            handleClose={() => this.setState({ showConfirmDeleteGoal: false })}
            handleSave={this.handleDeleteGoal}
            fullWidth={false}
            maxWidth="sm"
            saveLabel="Delete" />)
        const goalEditor = (<ModalDialog {...this.props}  show={this.state.showGoalEditor}
                title={this.state.editMode + " Personal Goal"}
                handleClose={() => this.setState({ showGoalEditor: false })}
                handleSave={this.handleSaveGoal}
                fullWidth={true}
            maxWidth="sm"
        >
            <SectionFieldGroup dark={this.props.dark}>
                <TextInput
                    dark={this.props.dark}
                    cKey="desc"
                    cid="descF"
                    id="fldDesc"
                    label="Goal Description"
                    defaultValue={this.state.descValue}
                />
                <SelectList
                    dark={this.props.dark}
                    key="etype"
                    label="Type"
                    value={this.state.typeValue}
                    onChange={this.handleTypeChange}
                    listItems={this.props.profile.goalTypeList}
                />
                <SelectList
                    dark={this.props.dark}
                    key="importance"
                    label="Importance"
                    value={this.state.importanceValue}
                    onChange={this.handleImportanceChange}
                    listItems={this.props.profile.importanceList}
                />
                <SelectList
                    dark={this.props.dark}
                    key="status"
                    label="Status"
                    value={this.state.statusValue}
                    onChange={this.handleStatusChange}
                    listItems={this.props.profile.goalStatusList}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="impacts"
                    cid="impactsF"
                    id="fldImpacts"
                    label="Impacts of this Habit"
                    defaultValue={this.state.impactsValue}
                />
                <DateSelector
                    dark={this.props.dark}
                    label="Target Due Date"
                    value={this.state.dueValue}
                    handleChange={this.handleDueChange}
                />
            </SectionFieldGroup>
        </ModalDialog>)

        const habitItem = (item, index) => {
            return (
                <SectionItem
                    itemID={item.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.habitTypeList.find((x) => x.value === item.habitType).label}
                    primaryContent={item.habitDescription}
                    handleEdit={this.handleEditHabit}
                    handleDelete={this.handleShowConfirmDeleteHabit}
                    itemType="Bad Habit"
                >
                    <PublicAttribute {...this.props} attr={item.id} /> 
                </SectionItem>
            )
        }

        const Habit = (
            <Section itemType="Bad Habit" dark={this.props.dark} title="My Bad Habits" handleAdd={this.props.profile.demographics.badHabits.length > 0 && this.handleAddHabit} {...this.props} publicAttribute="habits">
                {this.props.profile.demographics.badHabits.length > 0 ?
                    this.props.profile.demographics.badHabits.map((item, index) => habitItem(item, index))
                    :
                    <div style={{ textAlign: "center" }}>
                        <Button variant="contained" onClick={this.handleAddHabit} color="secondary" sx={{ width: "250px" }}>Add Bad Habit to Break</Button>
                    </div>
                    }
            </Section>)

        const deleteConfirmationHabit = (<ModalDialog {...this.props} show={this.state.showConfirmDeleteHabit}
            title="Confirm Deletion"
            subTitle={"Bad Habit will be deleted.  Proceed?"}
            handleClose={() => this.setState({ showConfirmDeleteHabit: false })}
            handleSave={this.handleDeleteGHabit}
            fullWidth={false}
            maxWidth="sm"
            saveLabel="Delete" />)
        const habitEditor = (<ModalDialog {...this.props} show={this.state.showHabitEditor}
            title={this.state.editMode + " Bad Habit"}
            handleClose={() => this.setState({ showHabitEditor: false })}
            handleSave={this.handleSaveHabit}
            fullWidth={true}
            maxWidth="sm"
        >
            <SectionFieldGroup dark={this.props.dark}>
                <TextInput
                    dark={this.props.dark}
                    cKey="desc"
                    cid="descF"
                    id="fldDesc"
                    label="Habit Description"
                    defaultValue={this.state.descValue}
                />
                <SelectList
                    dark={this.props.dark}
                    key="etype"
                    label="Type"
                    value={this.state.typeValue}
                    onChange={this.handleTypeChange}
                    listItems={this.props.profile.habitTypeList}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="impacts"
                    cid="impactsF"
                    id="fldImpacts"
                    label="Impacts of this Habit"
                    defaultValue={this.state.impactsValue}
                />
                <SelectList
                    dark={this.props.dark}
                    key="importance"
                    label="Importance"
                    value={this.state.importanceValue}
                    onChange={this.handleImportanceChange}
                    listItems={this.props.profile.importanceList}
                />
            </SectionFieldGroup>
        </ModalDialog>)
        return (
            <>
                <SectionGrid xs={12} md={6} xl={4} height="calc(100% - 40px)">
                    {BucketList}
                    {Goal}
                    {Habit}
                </SectionGrid>          
                {deleteConfirmationBucketList}
                {bucketListEditor}
                {deleteConfirmationGoal}
                {goalEditor}
                {deleteConfirmationHabit}
                {habitEditor}
            </>
        );
    }
}
