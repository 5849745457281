export async function getMyComments(index, count) {
    let url = 'comment/getmycomments?index=' + index + '&count=' + count;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}


export async function getComments(targetid, index, count) {
    let url = 'comment/getcomments?targetid=' + targetid + "&index=" + index + "&count=" + count;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}




