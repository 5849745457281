
export async function addReaction(targetid, type, reaction) {
    const req = { TargetID: targetid, TargetType: type, Reaction: reaction };
    const response = await fetch('reactions/addreaction', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

export async function getMyReactions() {
    let url = 'reactions/getmyreactions';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}




