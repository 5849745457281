import React, { PureComponent } from 'react';


//Custom Components
import { PostContentComposer } from '../Posts/PostContentComposer';
import { ContextMenu } from './ContextMenu';
import { ModalDialog } from './ModalDialog';
import { PhotoViewer } from '../Content/Photos/PhotoViewer';
import { DocumentViewer } from '../Content/Documents/DocumentViewer';
import { ContentShareComposer } from './ContentShareComposer';
import { RichTextEditor } from './RichTextEditor'
import { ItemDeleter } from './ItemDeleter'
import { ItemCreator } from './ItemCreator';
import { VideoPlayer } from './VideoPlayer';

//Database Services
import { addMention } from '../../DataServices/MentionsService';
import { createNotification } from '../../DataServices/NotificationService';
import { getPhoto, } from '../../DataServices/PhotoService';
import { editNote, } from '../../DataServices/NoteService';
import { getDocument, } from '../../DataServices/DocumentService';
import { newEventPost, newBlogPost, newMarketPost, newVideoPost, newSongPost, newPhotoPost,newPdfDocumentPost  } from '../../DataServices/PostService'
import { newTextTag } from '../../DataServices/TagService';
import { capitalize, b64toBlob } from '../../DataServices/Utility';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'



import Microlink from '@microlink/react'

//Material UI
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import EditIcon from '@mui/icons-material/Edit';
import { CardActionArea } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const calcCardWidth = (width, small) => {
    let cardWidth;
    const leftmenu = 40;
    const rightSpacer = 16;
    const containerWidth = width - leftmenu - rightSpacer;
    if (small) {
        if (containerWidth > 0 && containerWidth <= 400) {
            cardWidth = (containerWidth - 16);
        }
        if (containerWidth > 400 && containerWidth <= 600) {
            cardWidth = containerWidth / 2 - 16;
        }
        if (containerWidth > 600 && containerWidth <= 800) {
            cardWidth = containerWidth / 3 - 16;
        }
        if (containerWidth > 800 && containerWidth <= 1000) {
            cardWidth = containerWidth / 4 - 16;
        }
        if (containerWidth > 1000 && containerWidth <= 1200) {
            cardWidth = containerWidth / 5 - 16;
        }
        if (containerWidth > 1200 && containerWidth <= 1400) {
            cardWidth = containerWidth / 6 - 16;
        }
        if (containerWidth > 1400 && containerWidth <= 1600) {
            cardWidth = containerWidth / 7 - 16;
        }
        if (containerWidth > 1600) {
            cardWidth = containerWidth / 8 - 16;
        }
    }
    else {
        if (containerWidth > 0 && containerWidth <= 720) {
            cardWidth = (containerWidth - 16);
        }
        if (containerWidth > 720 && containerWidth <= 1080) {
            cardWidth = containerWidth / 2 - 16;
        }
        if (containerWidth > 1080 && containerWidth <= 1440) {
            cardWidth = containerWidth / 3 - 16;
        }
        if (containerWidth > 1440) {
            cardWidth = containerWidth / 4 - 16;
        }
    }


    return Math.floor(cardWidth);
}
export class ContentCard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            contextMenu: null,
            showViewer: false,
            showConfirmDelete: false,
            showPostSuccess: false,
            showPostError: false,
            showShareSuccess: false,
            showShareError: false,
            showPostComposer: false,
            showPostCopySuccess: false,
            showPostCopyError: false,
            copiedID: null,
            streamBase: "https://mylivesphere.com/api/videostream/",
        }
    }

    //Event Handlers-----------------
    handleClickItem = async (e) => {
        if (!this.props.noItemClick) {
            var ele = e.target;
            ele.style.cursor = 'wait';
            document.body.style.cursor = 'wait';

            const item = this.props.item;

            switch (this.props.contentType) {
                case 'photos':
                    if (!item.fullPhoto) {
                        const photo = await getPhoto(this.props.itemID);
                        item.fullPhoto = photo.fullPhoto
                        this.handleUpdateItem(item, this.props.itemIDX);
                    }
                    break;
                case 'documents':
                    const doc = await getDocument(this.props.itemID);

                    if (doc && doc.eFiles && doc.eFiles.length > 0 && doc.eFiles[0].fileContent) {
                        this.setState({
                            selectedDocumentName: doc.documentName,
                            docContent: doc.eFiles[0].fileContent,
                        });
                    }
            }

            this.setState({ showViewer: true })
            document.body.style.cursor = 'default';
            ele.style.cursor = 'default';
        }
    }
    handleShowConfirmDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            showConfirmDelete: true,
            contextMenu: null
        })
    }
    handleShowEdit = () => {
        this.setState({ showEdit: true})
    }
    handleShowPostComposer = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showPostComposer: true })
    }
    handleSavePost = async (post) => {
        let id;
        switch (this.props.contentType) {
            case 'photos':
                id = await newPhotoPost(this.props.user.userID, this.props.itemID, post.textContent, this.props.appIdentifier);
                break;
            case 'documents':
                id = await newPdfDocumentPost(this.props.user.userID, this.props.itemID, post.textContent, this.props.appIdentifier);
                break;
            case 'videos':
                id = await newVideoPost(this.props.user.userID, this.props.itemID, post.textContent, this.props.appIdentifier);
                break;
            default:
        }

        if (id) {
            this.setState({
                showPostSuccess: true,
                showPostComposer: false
            });
        }
        else {
            this.setState({
                showPostError: true,
                showPostComposer: false
            });
        }

        if (post.tags && post.tags.length > 0) {
            for (var t = 0; t < post.tags.length; t++) {
                await newTextTag(id, this.props.contentTypeID, post.tags[t]);
            }
        }
        if (post.mentions && post.mentions.length > 0) {
            for (var m = 0; m < post.mentions.length; m++) {
                await addMention(post.mentions[m].id, this.props.user.userID, this.props.contentTypeID, id);

                let message = (this.props.user.displayName ? this.props.user.displayName : "A user") + " mentioned you in a post";
                if (post.mentions[m].id === this.props.user.userID) {
                    message = "You mentioned yourself in a post";
                }
                await createNotification(post.mentions[m].id, this.props.contentTypeID, id, this.props.user.userID, message, this.props.appIdentifier);
            }
        }
    }
    handleShowShareComposer =  (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({showContentShareComposer: true  })
    }
    copyToClickBoard = (e) => {
        e.preventDefault();
        e.stopPropagation();

        navigator.clipboard.writeText(this.props.itemID)
            .then(() => {
                this.setState({
                    copiedID: this.props.itemID,
                    showPostCopySuccess: true
                })
            })
            .catch(err => {
                this.setState({
                    showPostCopyError: true
                })
            })

    }
    handleDelete = async () => {
        this.setState({ showConfirmDelete: false });
        const result = await this.props.functions.deleteItem(this.props.itemID);
        if (result) {
            const folders = [...this.props.folders];
            folders[this.props.index][this.props.contentType].splice(this.props.itemIDX, 1);
            this.props.updateData(this.props.contentType, folders);
        }
    }
    handleEdit = async (values) => {
        this.setState({ showEdit: false });
        const folders = [...this.props.folders];
        const fields = this.props.itemFields;
        for (var i = 0; i < fields.length; i++) {
            folders[this.props.index][this.props.contentType][this.props.itemIDX][fields[i].fieldName] = values[i];
        }
        this.props.updateData(this.props.contentType, folders);
        await this.props.functions.editItem(this.props.itemID, ...values);
    }
    handleEditNote = async (id, idx, content, text) => {
        let item = this.props.item;
        item.noteContent = content;
        item.previewText = text;
        this.handleUpdateItem(item, idx);
        await editNote(id, content, text);
    }
    handleUpdateItem =  (item, idx) => {
        const folders = [...this.props.folders];
        folders[this.props.index][this.props.contentType][idx] = item;
        this.props.updateData(this.props.contentType, folders);
    }
    handleShareComplete = (success) => {
        if (success) {
            this.setState({ showShareSuccess: true });
        }
        else {
            this.setState({ showShareError: true });
        }
    }

    //Render functions
    cardPhoto = () => {
        return (
            <CardMedia
                component="img"
                height={300}
                image={"data:image/png;base64, " + this.props.item.thumb}
                sx={{ objectFit: "cover" }}
            />
            )
    }
    cardVideo = () => {
        return (
            <VideoPlayer video={this.props.item} />
            )
    }
    cardDocument = () => {
        return (
            <Stack sx={{ p: 2 }}>
                <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "64px", color: 'rgb(50,71,140)', margin: "auto", }} />
                <Stack sx={{ mt: 3, mx: "auto" }}>
                    <Typography gutterBottom variant="subtitle1" sx={{ fontSize: ".8rem", marginBottom: 0 }} component="div">
                        {this.props.itemName ? this.props.itemName.substr(0, 30) : "Document"}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                        {`Added ${new Date(this.props.item.createDate).toLocaleDateString()}`}
                    </Typography>
                </Stack>
            </Stack>
            )
    }
    cardNote = () => {
        return (
            <CardContent sx={{ minHeight: "150px" }}>
                <Typography gutterBottom variant="subtitle1" component="div">
                    {new Date(this.props.item.modifyDate).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" >
                    {this.props.item.previewText ? (this.props.item.previewText.length > 100 ? this.props.item.previewText.substring(0, 100) + "..." : this.props.item.previewText.substring(0, 100)) : ""}
                </Typography>
            </CardContent>
            )
    }
    cardLink = () => {
        return (
            <Stack sx={{ p: 1}}>
                <Microlink url={this.props.item.href} size='small' media='logo' style={{ border: "none", minHeight: "50px",maxWidth: "-webkit-fill-available", background: this.props.dark && "#222", color: this.props.dark && "white" }} />
                <Typography align="center"  sx={{ p: 1,pb:0,  fontSize:".8rem", fontWeight:600, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}>
                    {this.props.item.title}
                </Typography>
            </Stack>
            )
    }
    cardOptions = () => {
        return (
            <Box display="flex" sx={{ justifyContent: "center", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack, backgroundColor: this.props.dark ? "none" : "#f3f3f3", borderTop: this.props.dark ? "1px solid #2a2a2a" : "1px solid #eee" }} >
                {(this.props.dragOptions.drag && this.props.dragOptions.dragProvided !== null) &&
                    <Tooltip title="Click here to drag to new folder">
                        <div  {...this.props.dragOptions.dragProvided.dragHandleProps} style={{ padding: "8px" }}>
                            <DragIndicatorIcon sx={{ fontSize: "18px" }} />
                        </div>
                    </Tooltip>
                }
                {this.props.options.edit &&
                    <Tooltip title="Edit">
                        <IconButton onClick={this.handleShowEdit} >
                            <EditIcon sx={{ fontSize: "18px", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }} />
                        </IconButton>
                    </Tooltip>
                }
                {this.props.options.delete &&
                    <Tooltip title="Delete">
                        <IconButton onClick={this.handleShowConfirmDelete} >
                            <DeleteIcon sx={{ fontSize: "18px", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }} />
                        </IconButton>
                    </Tooltip>
                }
                {this.props.options.share &&
                    <Tooltip title="Share">
                        <IconButton sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }} onClick={this.handleShowShareComposer} >
                            <ShareIcon sx={{ fontSize: "18px" }} />
                        </IconButton>
                    </Tooltip>
                }
                {this.props.options.post &&
                    <Tooltip title="Post">
                        <IconButton sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }} onClick={this.handleShowPostComposer} >
                            <PublishIcon sx={{ fontSize: "18px" }} />
                        </IconButton>
                    </Tooltip>
                }
                {this.props.options.copy &&
                    <Tooltip title="Copy ID to clipboard">
                        <IconButton sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }} onClick={this.copyToClickBoard} >
                            <ContentCopyIcon sx={{ fontSize: "18px" }} />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
            )
    }
    card = () => {
        const cardWidth = calcCardWidth(this.props.width, this.props.smallCard);

        return (
            <Card
                sx={{
                    width: cardWidth,
                    mx: "auto",
                    m: 1,
                    background: (theme) => this.props.dark && theme.palette.card.dark,
                    color: (theme) => this.props.dark && theme.palette.common.dimwhite,
                    borderRadius: 0.5
                }}
                raised
                elevation={this.props.dark ? 1 : 8}
            >
                {!this.props.noItemClick &&
                    <CardActionArea onClick={this.handleClickItem} onContextMenu={(e) => this.props.contextMenuItems && this.handleContextMenu(e)}>
                        {this.props.contentType === 'photos' && this.cardPhoto()}
                        {this.props.contentType === 'documents' && this.cardDocument()}
                        {this.props.contentType === 'notes' && this.cardNote()}
                    </CardActionArea>
                }
                {this.props.contentType === 'videos' && this.cardVideo()}
                {this.props.contentType === 'links' && this.cardLink()}
                {this.props.options && this.cardOptions()}
            </Card>
        )
    }
    viewerPhoto = () => {
        if (this.props.contentType === "photos") {
            return (
                <PhotoViewer
                    handleUpdateItem={this.handleUpdateItem}
                    dark={this.props.dark}
                    open={this.state.showViewer}
                    photoID={this.props.itemID}
                    photo={this.props.item}
                    handleClose={() => this.setState({ showViewer: false })}
                    index={this.props.itemIDX}
                    updatePhoto={this.props.handleUpdateItem}
                    user={this.props.user}
                    appIdentifier={this.props.appIdentifier}
                    fullScreen
                    width={this.state.width}
                    allowEdit={this.props.allowEdit}
                />
            )
        }
        return null;
    }
    viewerDocument = () => {
        if (this.props.contentType === "documents") {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showViewer}
                handleClose={() => this.setState({ showViewer: false })}
                fullWidth={true}
                disableButtons
                hideScroll={true}
                noUpperClose
                maxWidth="xl"
                closeLabel="Close"
                noSidePad
                fullScreen
                solid
                fullHeight
            >
                <DocumentViewer
                    dark={this.props.dark}
                    fileName={this.props.itemName}
                    document={this.state.docContent}
                    scale={this.props.width < 400 ? 0.5 : 1.6}
                    handleClose={() => this.setState({ showViewer: false })}
                    fullHeight
                    width={this.props.width}
                />
            </ModalDialog>
            )
        }
        return null;
    }
    viewerNote = () => {
        if (this.props.contentType === "notes") {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showViewer}
                handleClose={() => this.setState({ showViewer: false })}
                fullWidth={true}
                fullScreen={this.props.width <= 600}
                solid={this.props.width <= 600}
                fullHeight
                noPad
                prettyScroll
                maxWidth="xl"
                closeLabel="Close"
                disableButtons
                toggleFullScreen
                title="Note Editor"
            >
                <Box className={this.props.dark ? "dark prettyScroll" : "prettyScroll"} sx={{ minHeight: "calc(100vh - 200px)", backgroundColor: this.props.dark ? "#000" : "white", height: { xs: "calc(100vh - 107px)", sm: "calc(100vh - 232px)", lg: "100%" }, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black, }}>
                    <RichTextEditor
                        id={this.props.itemID}
                        idx={this.props.itemIDX}
                        initialContent={this.props.item.noteContent}
                        updateContent={this.handleEditNote}
                    />
                </Box>
            </ModalDialog>
            )
        }
        return null;
    }
    viewer = () => {
        if (this.state.showViewer) {
            switch (this.props.contentType) {
                case 'photos': return this.viewerPhoto();
                case 'documents': return this.viewerDocument();
                case 'notes': return this.viewerNote();
                default:  return null;
            }
        }
        return null;
    }
    contextMenu = () => {
        if (this.props.contextMenuItems) {
            return (
                <ContextMenu
                    {...this.props}
                    show={this.state.contextMenu !== null}
                    onClose={() => this.setState({ contextMenu: null })}
                    contextMenu={this.state.contextMenu}
                    menuItems={this.props.contextMenuItems}
                />
            )
        }
        return null;
    }
    contentShareComposer = () => {
        if (this.state.showContentShareComposer) {
            return (
                <ContentShareComposer
                    show={this.state.showContentShareComposer}
                    hide={() => this.setState({ showContentShareComposer: false })}
                    appIdentifier={this.props.appIdentifier}
                    contentID={this.props.itemID}
                    user={this.props.user}
                    dark={this.props.dark}
                    notify={this.props.notifyOnShare}
                    contentType={this.props.contentType.slice(0, -1)}
                    handleCancel={() => this.setState({ showContentShareComposer: false })}
                    handleComplete={this.handleShareComplete}
                    title={`Share ${capitalize(this.props.contentType.slice(0, -1))}`}
                />
            )
        }
        return null;
    }
    contentPostComposer = () => {
        return (
            <PostContentComposer
                user={this.props.user}
                appIdentifier={this.props.appIdentifier}
                show={this.state.showPostComposer}
                savePost={this.handleSavePost}
                cancelPost={() => this.setState({ showPostComposer: false })}
                dark={this.props.dark}
                postType={this.props.postContentType}
                userList={this.props.userList}
                contentID={this.props.itemID}
                title={`New ${capitalize(this.props.contentType.slice(0, -1))} Post`}
            />

        )
    }
    postSuccessAlert = () => {
        return (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.showPostSuccess} autoHideDuration={6000} onClose={() => this.setState({ showPostSuccess: false })}>
                <Alert onClose={() => this.setState({ showPostSuccess: false })} severity="success" sx={{ width: '100%' }}>
                    {`${capitalize(this.props.contentType.slice(0, -1))} has been posted`}
                </Alert>
            </Snackbar>
        )
    }
    postErrorAlert = () => {
        return (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.showPostError} autoHideDuration={6000} onClose={() => this.setState({ showPostError: false })}>
                <Alert onClose={() => this.setState({ showPostError: false })} severity="error" sx={{ width: '100%' }}>
                    {`Error posting ${this.props.contentType.slice(0, -1) }`}
                </Alert>
            </Snackbar>
        )
    }
    shareSuccessAlert = () => {
        return (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.showShareSuccess} autoHideDuration={6000} onClose={() => this.setState({ showShareSuccess: false })}>
                <Alert onClose={() => this.setState({ showShareSuccess: false })} severity="success" sx={{ width: '100%' }}>
                    {`${capitalize(this.props.contentType.slice(0, -1)).slice(0, -1)} has been shared`}
                </Alert>
            </Snackbar>
        )
    }
    shareErrorAlert = () => {
        return (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.showShareError} autoHideDuration={6000} onClose={() => this.setState({ showShareError: false })}>
                <Alert onClose={() => this.setState({ showShareError: false })} severity="error" sx={{ width: '100%' }}>
                    {`Error sharing ${this.props.contentType.slice(0, -1) }`}
                </Alert>
            </Snackbar>
        )
    }
    postCopyAlert = () => {
        return (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.showPostCopySuccess} autoHideDuration={6000} onClose={() => this.setState({ showPostCopySuccess: false })}>
                <Alert onClose={() => this.setState({ showPostCopySuccess: false })} severity="success" sx={{ width: '100%' }}>
                    {`ID: ${this.state.copiedID} has been copied to the clipboard`}
                </Alert>
            </Snackbar>
        )
    }
    postCopyErrorAlert = () => {
        return (
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.showPostCopyError} autoHideDuration={6000} onClose={() => this.setState({ showPostCopyError: false })}>
                <Alert onClose={() => this.setState({ showPostCopyError: false })} severity="error" sx={{ width: '100%' }}>
                    Error copying ID to clipboard
                </Alert>
            </Snackbar>
        )
    }
    deleter = () => {
        return (
            <ItemDeleter 
                {...this.props }
                handleClose={() => this.setState({ showConfirmDelete: false })}
                handleDelete={this.handleDelete}
                itemLabel={capitalize(this.props.contentType).slice(0, -1) }
                show={this.state.showConfirmDelete}
            />
            )
    }
    editor = () => {
        if (this.state.showEdit) {
            const fields = this.props.itemFields;
            for (var i = 0; i < fields.length; i++) {
                fields[i].value = this.props.item[fields[i].fieldName];
            }
            return (
                <ItemCreator
                    {...this.props}
                    show={this.state.showEdit}
                    item={capitalize(this.props.contentType).slice(0, -1)}
                    handleClose={() => this.setState({ showEdit: false })}
                    handleSave={this.handleEdit}
                    fields={fields}
                    edit
                />
            )
        }
        return null;
    }

    //main component render
    render() {
        return (
            <>
                {this.card()}
                {this.viewer()}
                {this.contextMenu()}
                {this.deleter()}
                {this.editor()}
                {this.postSuccessAlert()}
                {this.postErrorAlert()}
                {this.shareSuccessAlert()}
                {this.shareErrorAlert()}
                {this.contentPostComposer()}
                {this.postCopyAlert()}
                {this.postCopyErrorAlert()}
                {this.contentShareComposer()}
            </>
        )
    }
}
