
import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Linkify from 'react-linkify';
import { LinkPreview } from '@dhaiwat10/react-link-preview';

import { ModalDialog } from '../Utilities/ModalDialog'
import { EmojiPicker } from '../Utilities/EmojiPicker'
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';

import { MentionsInput, Mention } from 'react-mentions'


const parseTags = (text) => {
    var regex = /(?:^|\s)(?:#)([a-zA-Z\d]+)/gm;
    var matches = [];
    var match;

    while ((match = regex.exec(text))) {
        matches.push(match[1]);
    }
    return matches;
}


export class PostContentComposer extends Component {
  constructor(props) {
      super(props);
      this.state = {
          user: this.props.user,
          show: this.props.show,
          newPostText: [],
          newPostPhoto: [],
          newPostVideo: [],
          tags: [],
          postValue: "",
          postLink: null,
          postLinks: [],
          saving: false,
          anchorEl: null,
          mentions: [],
      };
  }
  

    handleSubmitPost = () => {
        let textContent = "";
        var ele = document.getElementById("newPostTextContent");
        if (ele) {
            textContent = ele.value;
        }
        var linkContent = this.state.postLink;

        if (linkContent && linkContent == textContent) {
            textContent = "";
        }
        var post = { textContent: textContent, tags: this.state.tags, type: this.props.postType, mentions: this.state.mentions};
        this.props.savePost(post);
        this.setState({
            postLink: null,
            newPostText: [],
            newPostPhoto: [],
            newPostVideo: [],
            tags: [],
            mentions: [],
            postValue: "",
            showEmojiPicker: false,
            showPhotoDropZone: false
        });
    }

    handlePostCompose = (e) => {
        var val = e.target.value;
        this.setState({ postValue: val });
        let tags = parseTags(val);
        if (this.state.tags !== tags) {
            this.setState({ tags: tags });
        }
        setTimeout(() => {
            var eles = document.querySelectorAll("div #linkContainer a");
            if (eles.length > 0) {
                if (eles[0].href) {
                    this.setState({ postLink: eles[0].href });
                }

            }
        }, 500)
    }



    handleRemoveTag = (idx) => {
        const tag = this.state.tags[idx];
        let originalPost = this.state.postValue
        let tagStart = originalPost.indexOf("#" + tag);
        const finalString = originalPost.slice(0, tagStart) + originalPost.slice(tagStart + 1);
        var ele = document.getElementById("newPostTextContent");
        if (ele) {
            ele.value = finalString;
            var event = new Event('input', {
                bubbles: true,
                cancelable: true,
            });

            ele.dispatchEvent(event);
        }
    }
    handleSelectEmoji = (emoji) => {
        document.getElementById("newPostTextContent").value = document.getElementById("newPostTextContent").value + emoji.emoji;
        this.setState({ showEmojiPicker: false, anchorEl: null })
    }
    addMention = (id, display, startPos, endPos) => {
        const mentions = [...this.state.mentions, { id: id, display: display }];
        this.setState({ mentions });

    }
    formatMention = (id, display) => {
        return "@" + display;
    }
    handleRemoveMention = (idx) => {
        const mentions = [...this.state.mentions];
        mentions.splice(idx, 1);
        this.setState({ mentions });
    }



    postLinks = () => {
        return (
            <Linkify>
                <div id="linkContainer" style={{ display: "none" }}>
                    {this.state.postValue}
                </div>
            </Linkify>
        )
    } 
    linkPreview = () => {
        return (
            <Box sx={{ padding: "8px", margin: "8px", borderRadius: "8px", background: this.props.dark ? "#222" : "#eee" }}>
                <LinkPreview
                    url={this.state.postLink}
                    width='230px'
                    height='230px'
                    backgroundColor={this.props.dark ? "#222" : "#ddd"}
                    primaryTextColor={this.props.dark ? "#ccc" : "#444"}
                    secondaryTextColor={this.props.dark ? "#ccc" : "#444"}
                    borderColor={this.props.dark ? "#444" : "#ddd"}
                    margin="8px"
                    className="linkPreview"
                    descriptionLength={50}
                />
            </Box>
        )
    }
    tagContainer = () => {
        if (this.state.tags && this.state.tags.length > 0) {
            return (
                <Box display="flex" sx={{ flexWrap: 'wrap', padding: "8px", margin: "8px", borderRadius: "8px", background: this.props.dark ? "#222" : "#eee" }}>

                    {this.state.tags.map((item, index) => {
                        return (
                            <Chip
                                key={"tag" + index}
                                label={item}
                                onDelete={() => this.handleRemoveTag(index)}
                                size="small"
                                color="secondary"
                                sx={{ marginRight: "6px", marginBottom: "6px" }}
                            />
                        )
                    })}
                </Box>
            )
        }
    }
    mentions = () => {
        return (
            <Box
                sx={{
                    m: 1,
                    backgroundColor: this.props.dark ? '#050505' : '#fff',
                    borderRadius: "4px", color: this.props.dark ? "#ccc" : "#444",

                }}>
                <MentionsInput
                    placeholder={this.state.postType < 2 ? "What would you like to post, " + this.props.user.firstName + "?" : "Add a comment or tags..."}
                    allowSpaceInQuery
                    id="newPostTextContent"
                    value={this.state.mentionValue}
                    onChange={(e) => this.setState({ mentionValue: e.target.value })}
                    onInput={this.handlePostCompose}
                    className={this.props.dark ? "postTextDark" : "postText"}
                    rows={2}
                    style={{
                        backgroundColor: this.props.dark ? '#111' : '#fff',
                        minHeight: "60px",
                        border: "none",
                    }}
                >

                    <Mention
                        trigger="@"
                        data={this.props.userList}
                        onAdd={this.addMention}
                        displayTransform={this.formatMention}
                    />
                </MentionsInput>
            </Box>

        )
    }
    render() {


        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.props.show}
                handleClose={this.props.cancelPost}
                fullWidth={true}
                maxWidth="sm"
                closeLabel="Cancel"
                saveLabel="Post"
                hideScroll={true}
                draggable={true}
                title={this.props.title ?? "New Post"}
                subTitle=""
                handleSave={this.handleSubmitPost}
            >
                {this.mentions()}
                {this.tagContainer()}
                {this.postLinks()}
                {this.state.postLink && this.linkPreview()}
            </ModalDialog>
        );
    }


}
