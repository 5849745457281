import React, { Component } from 'react';

//Custom Components
import { Section } from '../Utilities/Section';
import { PhotoViewer } from '../Content/Photos/PhotoViewer';
import { Spin } from '../Utilities/Spin';

//Material UI
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';

import { getPhoto } from '../../DataServices/PhotoService'
const getCols = (w) => {
    let cols = 1;
    if (w > 600 && w <= 800) {
        cols = 2;
    }
    if (w > 800 && w <= 1000) {
        cols = 3;
    }
    if (w > 1000 && w <= 1200) {
        cols = 4;
    }
    if (w > 1200 && w <= 1400) {
        cols = 4;
    }
    if (w > 1400) {
        cols = 5;
    }
    return cols;
}
export class UserSitePhotos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPhotoViewer: false,
            selectedPhoto: null
        }
    }

    //Event handlers
    handleClickPhoto = async (e, photo) => {
        var ele = e.target;
        ele.style.cursor = 'wait';
        document.body.style.cursor = 'wait';
        if (!photo.fullPhoto) {
            photo = await getPhoto(photo.photoID);
        }

        this.setState({
            selectedPhoto: photo,
            openPhotoViewer: true
        })

        document.body.style.cursor = 'default';
        ele.style.cursor = 'default';

    }

    //Render funcs
    photoLibraries = () => {
        if (this.props.loading) {
            return (
                <Box sx={{ height: "100%", width: "100%", display: 'flex', justifyContent: "center" }}>
                    <Spin sub />
                </Box>
            )
        }
        if (this.props.siteContent && this.props.siteContent.photoAlbums && this.props.siteContent.photoAlbums.length > 0) {
            return (
                <Stack>
                    {this.props.siteContent.photoAlbums.map((item) => {
                        if (item.photos && item.photos.length > 0) {
                            return (
                                <Section key={item.id + "k"} dark={this.props.dark} title={item.name} padBottom >
                                    {(item.photos && item.photos.length > 0) && this.photos(item)}
                                </Section>
                            )
                        }
                        return null;
                    })}
                    {this.photoViewer()}
                </Stack>
                )
        }
        return null;
    }
    photos = (album) => {
       
        return (
            <ImageList sx={{ width: "100%", height: "100%" }} cols={getCols(this.props.width)} gap={6}  rowHeight={360} sx={{ overflowY: "hidden" }}>
                {album.photos.map((item) => (
                    <ImageListItem key={item.photoID}>
                        {item.thumb ?
                            <img
                                src={"data:image/png;base64, " + item.thumb}
                                alt={item.photoName}
                                style={{ objectFit: "cover", height: "360px", cursor:"pointer", minWidth:360, margin:"auto" }}
                                onClick={(e) => this.handleClickPhoto(e, item)}
                            />
                            :
                            <Skeleton height="100%" variant="rectangular" animation="wave" />
                        }
                    </ImageListItem>
                ))}
            </ImageList>
            )
    }
    photoViewer = () => {
        if (this.state.selectedPhoto) {
            return (
                <PhotoViewer
                    dark={this.props.dark}
                    open={this.state.openPhotoViewer}
                    photoID={this.state.selectedPhoto && this.state.selectedPhoto.photoID}
                    photo={this.state.selectedPhoto}
                    handleClose={() => this.setState({ openPhotoViewer: false })}
                    index={0}
                    allowNext={false}
                    allowPrevious={false}
                    user={this.props.user}
                    appIdentifier={this.props.appIdentifier}
                    fullScreen={this.props.fullScreen}
                />
            )
        }
        else {
            return null;
        }

    }

    //main component render func
    render() {
        return this.photoLibraries();
    }
}

