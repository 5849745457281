import React, { Component } from 'react';

//Custom components
import { Reactions } from '../Reactions/Reactions';
import { ModalDialog } from '../Utilities/ModalDialog'
import { UserAvatar } from '../Utilities/UserAvatar'

//Material UI
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Typography from '@mui/material/Typography';

export class Reply extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            showReplies: false,
            showConfirmDelete: false
        };
    }

    //event handlers
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    handleDelete = () => {
        this.handleClose();
        this.setState({ showConfirmDelete: true });   
    }
    handleConfirmDelete = () => {
        this.setState({ showConfirmDelete: false});
        this.props.handleDelete(this.props.message.id, this.props.idx);
    }
    handleEdit = () => {
        this.handleClose();
        this.props.handleEdit(this.props.message.id, this.props.idx);
    }

    //Render funcs
    avatar = () => {
        return (
            <UserAvatar
                id={this.props.message.id}
                user={this.props.message.user}
                dark={this.props.dark}
                loaded={true}
                activeUser={this.props.user}
                appIdentifier={this.props.appIdentifier}
                hideName
                small
            />
        )
    }
    userAvatar = (me) => {
        return (
            <ListItemIcon sx={{ color: this.props.dark ? "#ccc" : "#444", marginRight: "16px", marginTop: "8px" }}>
                {this.avatar()}
            </ListItemIcon>
        )
    }
    msgMenu = () => {
        return (
            <Menu
                id="msg-menu"
                anchorEl={this.state.anchorEl}
                open={this.state.anchorEl !== null}
                onClose={() => this.setState({ anchorEl: null })}
                PaperProps={{ style: { backgroundColor: this.props.dark ? "#333" : "#e0e0e0", color: this.props.dark ? "#ccc" : "#444" } }}
                MenuListProps={{ style: { backgroundColor: this.props.dark ? "#333" : "#e0e0e0", color: this.props.dark ? "#ccc" : "#444" } }}
            >

                <MenuItem onClick={this.handleDelete}>Delete</MenuItem>
            </Menu>
        )
    }
    editButton = () => {
        return (
            <Box sx={{ display: "flex" }}>
                <IconButton
                    aria-label="Options"
                    size="small"
                    onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                    sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}
                >
                    <MoreHorizIcon />
                </IconButton>
            </Box>
        )
    }
    deleteConfirmation = () => {
        return (
            <ModalDialog dark={this.props.dark} show={this.state.showConfirmDelete}
                title="Confirm Deletion"
                subTitle={"Reply will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDelete: false })}
                handleSave={this.handleConfirmDelete}
                fullWidth={false}
                maxWidth="sm"
                saveLabel="Delete"
            />
        )
    }
    reactions = () => {
        return (
            <Box sx={{ marginTop: "auto", marginBottom: "auto" }}>
                <Reactions
                    dark={true}
                    myReaction={this.props.message.myReaction}
                    likeCount={this.props.message.likeCount}
                    loveCount={this.props.message.loveCount}
                    targetID={this.props.message.id}
                    idx={this.props.index}
                    targetType={2}
                    inline={true}
                    noBack={true}
                    noColor={true}
                    refUser={this.props.message.user}
                    user={this.props.user}
                />
            </Box >
        )
    }
    msg = () => {
        return (
            <ListItem key={this.props.index} sx={{ justifyContent: this.props.message.fromMe ? "end" : "start", paddingTop: "0px", paddingBottom: "0px", alignItems: "start", marginBottom: "16px" }}>
                {this.userAvatar(this.props.message.fromMe)}
                <Box sx={{ width: "100%" }}>
                    <Paper
                        elevation={this.props.dark ? 0 : 4}
                        sx={{
                            borderRadius: "12px",
                            backgroundColor: theme => this.props.dark ? theme.palette.reply.dark : theme.palette.reply.light,
                            padding: "8px"
                        }}>
                        <Box display="flex">
                            {this.props.message.fromMe && this.editButton()}
                            <Box sx={{ flexGrow: 1 }} />
                            <Stack>
                                <Box sx={{ display: "block", textAlign: this.props.message.fromMe ? "right" : "right", padding: "0px 8px" }}>
                                    <Typography variant="body1" sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack, fontSize: ".9rem" }}>
                                        {this.props.message.commentText}
                                    </Typography>
                                    <Box display="flex">
                                        <Box sx={{ flexGrow: 1 }} />
                                        {!this.props.message.fromMe && this.reactions()}
                                        <Typography variant="subtitle2" sx={{ marginTop: "auto", marginBottom: "auto", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack, fontSize: ".7rem" }}>
                                            {this.props.message.whenPosted ? this.props.message.whenPosted : new Date(this.props.message.commentDate).toLocaleDateString() + " " + new Date(this.props.message.commentDate).toLocaleTimeString()}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Stack>

                        </Box>
                    </Paper>
                </Box>
            </ListItem >
            )
    }
    content = () => {
        return (
            <>
                {this.msg()}
                {this.msgMenu()}
                {this.deleteConfirmation()}
            </>
        );
    }

    //main component render
    render() {
        return this.content();
    }
}

