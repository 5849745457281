import { newNote, editNote, deleteNote, newNotebook, editNotebook, deleteNotebook, moveNote } from '../DataServices/NoteService'
const itemFields = [];

const folderFields = [
    {
        type: "text",
        label: "Notebook Name",
        fieldName: "title",
        value: "",
        maxLength: 100,
        required: true
    },
]

const functions = {
    createItem: newNote,
    editItem: null,
    deleteItem: deleteNote,
    moveItem: moveNote,
    createFolder: newNotebook,
    editFolder: editNotebook,
    deleteFolder: deleteNotebook,
}
const options = {
    notify: true,
    noItemClick: true,
    edit: false,
    delete: true,
    share: true,
    post: false,
    copy: true
}
const noteProperties = {
    itemFields: itemFields,
    folderFields: folderFields,
    functions: functions,
    options: options
}

export default noteProperties;

