import React, { Component } from 'react';
import { TextInput } from './TextInput';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export class DateSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPicker: false
        }
    }

    typeEntry = () => {
        return (
            <Box sx={{ display: "flex" }}>
                <DatePicker
                    disableOpenPicker
                    label={this.props.label}
                    value={this.props.value}
                    minDate={this.props.minValue && this.props.minValue}
                    maxDate={this.props.maxValue && this.props.maxValue}
                    sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black, }}
                    onChange={(newValue) => this.props.onChange(new Date(newValue).toLocaleDateString())}
                    renderInput={(params) =>
                        <TextInput
                            {...params}
                            dark={this.props.dark}
                        />
                    }
                />
                <IconButton size="small" color="secondary" onClick={() => this.setState({ showPicker: !this.state.showPicker })}>
                    <CalendarMonthIcon sx={{ color: theme => this.props.dark ? theme.palette.common.dimwhite : undefined }} />
                </IconButton>
            </Box>
        )
    }

    calendarView = () => {
        return (
            <Box sx={{ display: this.state.showPicker ? "block" : "none" }}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    label={this.props.label}
                    value={this.props.value}
                    minDate={this.props.minValue && this.props.minValue}
                    maxDate={this.props.maxValue && this.props.maxValue}
                    sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black, }}
                    onChange={(newValue) => this.props.onChange(new Date(newValue).toLocaleDateString())}
                    onAccept={() => this.setState({ showPicker: false })}
                    renderInput={(params) =>
                        <TextInput
                            {...params}
                            dark={this.props.dark}

                        />

                    }
                />
            </Box>
        )
    }
    render() {
        return (
            <Box
                sx={{
                    width: !this.props.narrow ? "-webkit-fill-available" : "unset",
                    color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                    mt: 0.5,
                }}
                className={this.props.dark ? "dark" : "light"}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {this.typeEntry()}
                    {this.calendarView()}
                </LocalizationProvider>
            </Box>
        );
    }
}
