
export async function getVideoLibraries(appid) {
    var url = "video/getlibraries?appid=" + appid;
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getVideoLibrary(id) {
    const response = await fetch("video/getlibrary?id=" + id);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getRumbleVideo(id) {
    const response = await fetch("video/getrumblevideo?id=" + id);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}


export async function newLibrary(title, parentID, appid) {
    const req = {
        ParentID: parentID,
        Title: title,
        AppID: appid
    };
    const response = await fetch("video/createlibrary", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}

export async function editLibrary(id, title) {
    const req = {
        ID: id,
        Title: title
    };
    const response = await fetch("video/editlibrary", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
   // console.error(response);
    return false;
}

export async function deleteLibrary(id) {
    const req = {
        ID: id,
    };
    const response = await fetch("video/deletelibrary", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
   // console.error(response);
    return false;
}
export async function updateCover(id, photoid) {
    const req = {
        ID: id,
        PhotoID: photoid
    };
    const response = await fetch("video/updatecover", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    //console.error(response);
    return false;
}
export async function saveRumbleVideo(title, parentID, rumbleID, rumbleURL, appid) {
    const req = {
        ParentID: parentID,
        Title: title,
        RumbleID: rumbleID,
        RumbleURL: rumbleURL,
        AppID: appid
    };
    const response = await fetch("video/saverumblevideo", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}

export async function editRumbleVideo(id, title, parentID, rumbleID, rumbleURL) {
    const req = {
        ID: id,
        ParentID: parentID,
        Title: title,
        RumbleID: rumbleID,
        RumbleURL: rumbleURL
    };
    const response = await fetch("video/editrumblevideo", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}
export async function deleteRumbleVideo(id) {
    const req = {
        ID: id,
    };
    const response = await fetch("video/deleterumblevideo", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const library = await response.json();
        return library;
    }
    return null;
}

export async function saveVideo(file, parentID, appid, cover) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('parentid', parentID);
    formData.append('appid', appid);
    formData.append('coverB64', cover);

    const vidID = await fetch('video/savevideo', {
        method: 'POST',
        body: formData
    }).then(
        response => response.json()
    );
    return vidID;
}
export async function deleteVideo(id) {
    const req = {
        ID: id,
    };
    const response = await fetch("video/deletevideo", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    // console.error(response);
    return false;
}
export async function moveVideo(id, parentid) {
    const req = {
        ID: id,
        ParentID: parentid
    };
    const response = await fetch("video/movevideo", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    // console.error(response);
    return false;
}
export async function getVideo(id) {
    const response = await fetch("video/getvideo?id=" + id);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}