import React, { Component } from 'react';

//Custom components
import { TextInput } from '../Utilities/TextInput';
import { Message } from './Message'
import { ModalDialog } from '../Utilities/ModalDialog'
import { Spin } from '../Utilities/Spin';

//Material UI
import Box from '@mui/material/Box';
import List from '@mui/material/List';

let composing = false;

export class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshSeconds: 15,
            loading: true,
            messages: [],
            anchorEl: null,
            showEmojiPicker: false,
            avatars: [],
            refreshing: false,
            composing: false,
            lastLoadedMessageID: "",
            messageToEdit: "",
            showEditMessage: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.threadID !== this.props.threadID) {
            this.setState({ messages: [] });
            this.getThread();
        }
    }
    componentDidMount() {
        this.initialize();
        setTimeout(
            this.interval = setInterval(() => {
                this.getNewMessages();
            }, this.state.refreshSeconds * 1000),
            30000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
        if (this.state.newMessageID && this.state.composing) {
            this.handleDeleteMessage(this.state.newMessageID);
        }
    }
    initialize = async () => {
        const me = { userID: this.props.user.userID, avatarB64: this.props.user.avatar, firstName: this.props.user.firstName, lastName: this.props.user.lastName };
        this.setState({ me: me });
        await this.getThread();
        setTimeout(this.scrollToBottom,100);
    }
    refresh = () => { this.initialize(); }
    cleanseThread = async (messages) => {
        if (this.state.messages && this.state.messages.length > 0) {
            for (var i = 0; i < messages.length - 1; i++) {
                if (messages[i].status === "Composing") {
                    this.handleDeleteMessage(messages[i].id);
                }
            }

            if (!this.state.composing && messages[messages.length - 1].fromMe && messages[messages.length - 1].status === "Composing") {
                this.handleDeleteMessage(messages[messages.length - 1].id);
            }
        }
    }
    getThread = async () => {
        if (!this.state.refreshing) {
            this.setState({ refreshing: true });
            const response = await fetch('messenger/getthread?id=' + this.props.threadID);
            if (response.status === 200) {
                const data = await response.json();
                if (data.id === this.props.threadID) {
                    await this.cleanseThread(data.messages);
                    if (data.members && data.members.length > 0) {
                        for (var i = 0; i < data.messages.length; i++) {
                            let localAvatar = data.members.find((item) => item.userID === data.messages[i]?.user.userID);
                            if (localAvatar) {
                                data.messages[i].user.avatarB64 = localAvatar.avatarB64;
                            }
                        }
                    }

                    this.setState(
                        state => {
                            state.messages = data.messages;
                            state.users = data.members;
                            state.refreshing = false;
                            state.loading = false;
                            state.lastLoadedMessageID = (data.messages && data.messages.length > 0) && data.messages[data.messages.length - 1].id;
                            return state;
                        }
                    );
                    this.forceUpdate();
                    this.scrollToBottom();
                }
                else {
                    this.setState({ refreshing: false, loading: false, lastUpdate: new Date().toUTCString() });
                }
                this.scrollToBottom();
            }
            else {
                this.setState({ refreshing: false, loading: false, lastUpdate: new Date().toUTCString()  });
            }
        }
    }
    getNewMessages = async () => {

        if (!this.state.loading && !this.state.refreshing) {
            const response = await fetch('messenger/getnewmessages?threadid=' + this.props.threadID + "&lastmessageid=" + this.state.lastLoadedMessageID);
            if (response.status === 200) {
                const data = await response.json();
                if (data && data.length > 0) {
                    await this.cleanseThread(data);
                    if (this.state.users && this.state.users.length > 0) {
                        for (var i = 0; i < data.length; i++) {
                            let localAvatar = this.state.users.find((item) => item.userID === data[i].user.userID);
                            if (localAvatar) {
                                data[i].user.avatarB64 = localAvatar.avatarB64;
                            }
                        }
                    }

                    this.setState(
                        state => {
                            for (var i = 0; i < data.length; i++) {
                                state.messages.push(data[i]);
                            }
 
                            state.refreshing = false;
                            state.loading = false;
                            state.lastLoadedMessageID = data[data.length - 1].id;
                            return state;
                        }
                    );

                }
            }
        }
    }
    setAvatars = async () => {
        if (this.state.messages && this.state.messages.length > 0) {
            let avatars = [];
            for (var i = 0; i < this.state.messages.length; i++) {
                let user = this.state.messages[i].user;
                let localAvatar = this.state.avatars.find((x) => x.id === user.userID);
                if (!localAvatar) {
                    let avatar = await this.getAvatar(user);
                    if (avatar) {
                        avatars.push(avatar);
                    }
     
                }
            }
            this.setState({ avatars: avatars });
        }
    }
    getAvatar = async (user) => {
        const response = await fetch('home/getuseravatar?userid=' + user.userID);
        const userinfo = await response.json();
        if (userinfo && userinfo.avatarB64) {
            const avatar = { id: user.userID, avatar: userinfo.avatarB64 };
            return avatar;
        }
        return null;
    }
    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
            //{ behavior: "smooth" }
        }
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handlePostMessage();
        }
    }
    startMessage = async () => {
        const response = await fetch('messenger/startmessage?threadid=' + this.state.threadID, {
            method: 'POST'
        })
        const messageID = await response.json();
        return messageID;
    }
    handlePostMessage = async () => {

        const msg = document.getElementById("fldNewMessage").value;
        document.getElementById("fldNewMessage").value = "";

        const post = {
            id: "newposttempid",
            content: msg,
            postDate: new Date(),
            whenPosted: "just now",
            fromMe: true,
            user: this.state.me
        };

        this.setState(
            state => {
                state.messages.push(post);
                state.newMessageID = null;
                return state;
            }
        );

        const req = { ThreadID: this.props.threadID, Content: msg };
        const response = await fetch('messenger/postmessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        if (response.status === 200) {
            const data = await response.json();
            this.setState(
                state => {
                    state.messages.find((x) => x.id === "newposttempid").id = data;
                    state.lastLoadedMessageID = data;
                    return state;
                }
            );

        }

        this.scrollToBottom();
    }
    handleStartMessage = async () => {
        if (!composing) {
            composing = true;

            const response = await fetch('messenger/startmessage?threadid=' + this.state.threadID, {
                method: 'POST'
            })
            const messageID = await response.json();

            const post = {
                id: messageID,
                content: "Typing...",
                postDate: new Date(),
                whenPosted: "just now",
                fromMe: true,
                status: "Composing",
                user: this.state.me ?? { firstName: "Me", lastName: "" }
            };

            this.setState(
                state => {
                    state.messages.unshift(post);
                    state.newMessageID = messageID;
                    return state;
                }
            );
        }
        else {
            if (document.getElementById("fldNewMessage").value === "" && this.state.newMessageID) {
                this.handleDeleteMessage(this.state.newMessageID);
                this.setState({ newMessageID: null });
            }
        }
    }
    handleEditMessage = async (id, idx) => {

        const msg = this.state.messages[idx].content;
        this.setState({
            messageToEdit: msg,
            showEditMessage: true,
            selectedMessageIDX: idx,
            selectedMessageID: id
        })

    }
    handleSaveEditMessage = async () => {
        const ele = document.getElementById("editmessage");
        if (ele) {
            const msg = ele.value;
            const id = this.state.selectedMessageID;

            this.setState(
                state => {
                    state.messages[this.state.selectedMessageIDX].content = msg;
                    state.showEditMessage = false;
                    state.selectedMessageID = null;
                    state.selectedMessageIDX = null;
                    return state;
                }
            );

            ele.value = "";
            const req = { MessageID: id, Content: msg  };
            await fetch('messenger/editmessage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(req)
            })
        }
    }
    handleDeleteMessage = async (id, idx) => {
        this.setState(
            state => {
                state.messages.splice(idx, 1);
                return state;
            }
        );
        const req = { MessageID: id };
        await fetch('messenger/deletemessage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        })

    }
    handleSelectEmoji = (emoji) => {

        const ele = document.getElementById("fldNewMessage");
        ele.value = ele.value + emoji.emoji;
        ele.click();
        ele.focus();
    }

    //render funcs
    composer = () => {
        return (
            <Box key="composer" sx={{ zIndex:1000,height:"70px", width: "-webkit-fill-available", position: "fixed", bottom: { xs: 56, lg: 0 }, background: (theme) => this.props.dark ? "#191919" : "#f2f3f7", borderTop: this.props.dark ? "solid 1px #222" : "solid 1px #ddd",  p:1}} >
                <TextInput
                    dark={this.props.dark}
                    inputProps={{ key: "composerinput" }}
                    cKey="CNewMessage"
                    cid="CnewMessage"
                    id="fldNewMessage"
                    label="Type New Message"
                    defaultValue=""
                    onKeyPress={this.handleKeyPress}
                />
            </Box>
        )
    }
    messages = () => {
        return (
            <Box
                key="messagelist"
                className="prettyScroll"
                sx={{
                    background: (theme) => this.props.dark ? "transparent" : "transparent",
                    maxHeight: { xs: "calc(100vh -  230px)", sm: "calc(100vh -  238px)", lg: "calc(100vh -  182px)" },

                    p: 1,
                    maxWidth: "1200px",
                    height: "100%"
                }}
            >

                <List>
                    {(this.state.messages && this.state.messages.length > 0) &&
                        this.state.messages.map((msg, index) => (
                            <Message
                                user={this.props.user}
                                key={"m" + index}
                                dark={this.props.dark}
                                message={msg}
                                index={index}
                                handleEdit={this.handleEditMessage}
                                handleDelete={this.handleDeleteMessage}
                            />
                        ))
                    }
                    <div style={{ float: "left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>
                </List>

            </Box>
        )
    }
    messageEditor = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showEditMessage}
                title="Edit Message"
                handleClose={() => this.setState({ showEditMessage: false })}
                handleSave={this.handleSaveEditMessage}
                fullWidth={true}
                maxWidth="sm"
            >
                <Box sx={{ background: this.props.dark ? "#151515" : "#eaeaea", borderRadius: "6px", padding: "8px" }}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="editmessage"
                        cid="editmessage"
                        id="editmessage"
                        label="Message"
                        defaultValue={this.state.messageToEdit}
                    />
                </Box>
            </ModalDialog>
        )
    }

    contents = () => {
        let contents = this.state.loading
            ?
            <Spin dark={this.props.dark} sub />
            :
            <Box
                sx={{
                    width: { xs: "100%", md: "400px", lg: "500px" },
                    textAlign: 'left',
                    color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                    backgroundColor: "transparent",
                    borderTop: this.props.dark ? "1px solid #222" : "none"
                }}
                key="chatitemexplorer"
            >
                {this.messages()}
                {this.composer()}
                {this.state.showEditMessage && this.messageEditor()}
            </Box>
        return contents;
    }

    //main component render
    render() {
        return this.contents();
    }
}
