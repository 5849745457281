import {  deleteDocument, moveDocument, renameDocument, newFolder, deleteFolder, renameFolder } from '../DataServices/DocumentService'

const itemFields = [
    {
        type: "text",
        label: "Document Name",
        fieldName: "documentName",
        value: "",
        maxLength: 100,
        required: true
    },]

const folderFields = [
    {
        type: "text",
        label: "Folder Name",
        fieldName: "name",
        value: "",
        maxLength: 100,
        required: true
    },
]

const functions = {
    createItem: null,
    editItem: renameDocument,
    deleteItem: deleteDocument,
    moveItem: moveDocument,
    createFolder: newFolder,
    editFolder: renameFolder,
    deleteFolder: deleteFolder,
}
const options = {
    notify: true,
    noItemClick: true,
    edit: true,
    delete: true,
    share: true,
    post: true,
    copy: true
}
const documentProperties = {
    itemFields: itemFields,
    folderFields: folderFields,
    functions: functions,
    options: options
}

export default documentProperties;

