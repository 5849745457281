import React, { Component } from 'react';

//Custom
import { ModalDialog } from '../Utilities/ModalDialog'
import { Section } from '../Utilities/Section';
import { SectionItem } from '../Utilities/SectionItem';
import { SectionGrid } from '../Utilities/SectionGrid';
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
import { SelectList } from '../Utilities/SelectList';
import { TextInput } from '../Utilities/TextInput';
import { PublicAttribute } from '../Utilities/PublicAttribute';

//MUI
import Button from '@mui/material/Button';

export class ProfileResponsibilities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmDeleteOccupation: false,
            showOccupationEditor: false,
            showConfirmDeleteRole: false,
            showRoleEditor: false
        }
    }

    handleShowConfirmDeleteOccupation  = (id, idx) => {
        this.setState({
            selectedID: id,
            selectedIDX: idx,
            showConfirmDeleteOccupation : true
        })
    }

    handleAddOccupation  = () => {
        this.setState({
            editMode: "New",
            titleValue: "",
            statusValue: 0,
            typeValue: 0,
            employerValue: "",
            schoolValue: "",
            fieldValue: "",
            showOccupationEditor: true
        })
    }

    handleEditOccupation = (id, idx) => {

        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            titleValue: this.props.profile.occupations[idx].title,
            statusValue: this.props.profile.occupations[idx].status,
            typeValue: this.props.profile.occupations[idx].occupationType,
            employerValue: this.props.profile.occupations[idx].employer,
            schoolValue: this.props.profile.occupations[idx].school,
            fieldValue: this.props.profile.occupations[idx].fieldOfStudy,
            showOccupationEditor: true
        })
    }

    handleSaveOccupation = async () => {
        const title = document.getElementById("fldTitle").value.trim();
        const employer = document.getElementById("fldEmployer").value.trim() ?? "";
        const school = document.getElementById("fldSchool").value.trim() ?? "";
        const field = document.getElementById("fldField").value.trim() ?? "";
        const status = this.state.statusValue;
        const type = this.state.typeValue;
    

        if (this.state.editMode !== "Edit") {
            if (title) {
                const response = await fetch('home/addoccupation?type=' + type + '&status=' + status + '&title=' + title + '&employer=' + employer + '&school=' + school + '&field=' + field + '&primary=false', { method: 'POST' })
                const id = await response.text();
                const itm = { id: id, title: title, occupationType: type, status: status, primaryOccupation: false, employer: employer, school: school, fieldOfStudy: field };
                this.props.profile.occupations.push(itm);
                this.setState({ showOccupationEditor: false });
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            const primary = this.props.profile.occupations[idx].primaryOccupation;
            if (title)  {
                await fetch('home/editoccupation?id=' + id + '&type=' + type + '&status=' + status + '&title=' + title + '&employer=' + employer + '&school=' + school + '&field=' + field + '&primary=' + primary, { method: 'POST' })
                const itm2 = { id: id, title: title, occupationType: type, status: status, primaryOccupation: false, employer: employer, school: school, fieldOfStudy: field };
                this.props.updateOccupation(idx, itm2);
                this.setState({ showOccupationEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleDeleteOccupation = async () => {
        this.props.profile.occupations.splice(this.state.selectedIDX,1);
        await fetch('home/removeoccupation?id=' + this.state.selectedID, { method: 'POST' });

        this.setState({
            showConfirmDeleteOccupation: false,
            selectedID: "",
            selectedIDX: ""
        })  
    }

    handleShowConfirmDeleteRole = (id, idx) => {
        this.setState({
            selectedID: id,
            selectedIDX: idx,
            showConfirmDeleteRole: true
        })
    }

    handleAddRole= () => {
        this.setState({
            editMode: "New",
            descValue: "",
            typeValue: 0,
            importanceValue: 0,
            impactedValue: "",
            showRoleEditor: true
        })
    }

    handleEditRole = (id, idx) => {

        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            descValue: this.props.profile.lifeRoles[idx].desc,
            typeValue: this.props.profile.lifeRoles[idx].roleType,
            importanceValue: this.props.profile.lifeRoles[idx].importance,
            impactedValue: this.props.profile.lifeRoles[idx].impactedPeople,
            showRoleEditor: true
        })
    }

    handleSaveRole = async () => {
        const desc = document.getElementById("fldDesc").value.trim();
        const impacted = document.getElementById("fldImpacted").value.trim() ?? "";
        const importance = this.state.importanceValue;
        const type = this.state.typeValue;

        if (this.state.editMode !== "Edit") {
            if (desc) {
                const response = await fetch('home/addrole?role=' + type + '&desc=' + desc + '&importance=' + importance + '&impacted=' + impacted, { method: 'POST' })
                const id = await response.text();
                const itm = { id: id, desc: desc, roleType: type, importance: importance, impactedPeople: impacted};
                this.props.profile.lifeRoles.push(itm);
                this.setState({ showRoleEditor: false });
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (desc) {
                await fetch('home/editrole?id=' + id + '&role=' + type + '&desc=' + desc + '&importance=' + importance + '&impacted=' + impacted, { method: 'POST' })
                const itm2 = { id: id, desc: desc, roleType: type, importance: importance, impactedPeople: impacted };
                this.props.updateRole(idx, itm2);
                this.setState({ showRoleEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleDeleteRole = async () => {
        this.props.profile.lifeRoles.splice(this.state.selectedIDX, 1);
        await fetch('home/removerole?id=' + this.state.selectedID, { method: 'POST' });

        this.setState({
            showConfirmDeleteRole: false,
            selectedID: "",
            selectedIDX: ""
        })
    }

    handleShowConfirmDeleteRole = (id, idx) => {
        this.setState({
            selectedID: id,
            selectedIDX: idx,
            showConfirmDeleteRole: true
        })
    }

    handleTypeChange = (e) => {
        this.setState({ typeValue: e.target.value });
    }
    handleStatusChange = (e) => {
        this.setState({ statusValue: e.target.value });
    }
    handleCategoryChange = (e) => {
        this.setState({ categoryValue: e.target.value });
    }
    handleImportanceChange = (e) => {
        this.setState({ importanceValue: e.target.value });
    }


    render() {

        const occupationItem = (item, index) => {
            return (
                <SectionItem
                    itemID={item.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.occupationTypeList.find((x) => x.value === item.occupationType).label}
                    primaryContent={item.title}
                    handleEdit={this.handleEditOccupation}
                    handleDelete={this.handleDeleteOccupation}
                >
                    <PublicAttribute {...this.props} attr={item.id} />
                </SectionItem>
            )
        }

        const Occupation = (
            <Section dark={this.props.dark} title="My Occupations" handleAdd={this.props.profile.occupations.length > 0 && this.handleAddOccupation}  {...this.props} publicAttribute="occupation">
                {this.props.profile.occupations.length > 0 ? this.props.profile.occupations.map((item, index) => occupationItem(item, index))
                :
                <div style={{ textAlign: "center" }}>
                        <Button variant="contained" onClick={this.handleAddOccupation} color="secondary" sx={{ width: "250px" }}>Add Occupation</Button>
                </div>
                }
            </Section>)

        const deleteConfirmationOccupation = (<ModalDialog {...this.props} show={this.state.showConfirmDeleteOccupation}
            title="Confirm Deletion"
            subTitle={"Occupation will be deleted.  Proceed?"}
            handleClose={() => this.setState({ showConfirmDeleteOccupation: false })}
            handleSave={this.handleDeleteOccupation}
            fullWidth={false}
            maxWidth="sm"
            saveLabel="Delete" /> )
        const occupationEditor = (<ModalDialog {...this.props} show={this.state.showOccupationEditor}
            title={this.state.editMode + " Occupation"}
            handleClose={() => this.setState({ showOccupationEditor: false })}
            handleSave={this.handleSaveOccupation}
                fullWidth={true}
                maxWidth="sm"
        >
            <SectionFieldGroup dark={this.props.dark}>
                <TextInput
                    dark={this.props.dark}
                    cKey="title"
                    cid="titleF"
                    id="fldTitle"
                    label="Title"
                    defaultValue={this.state.titleValue}
                />
                <SelectList
                    dark={this.props.dark}
                    key="etype"
                    label="Type"
                    value={this.state.typeValue}
                    onChange={this.handleTypeChange}
                    listItems={this.props.profile.occupationTypeList}
                />
                <SelectList
                    dark={this.props.dark}
                    key="status"
                    label="Status"
                    value={this.state.statusValue}
                    onChange={this.handleStatusChange}
                    listItems={this.props.profile.occupationStatusList}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="employer"
                    cid="employerF"
                    id="fldEmployer"
                    label="Employer"
                    defaultValue={this.state.employerValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="school"
                    cid="schoolF"
                    id="fldSchool"
                    label="School"
                    defaultValue={this.state.schoolValue}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="field"
                    cid="fieldF"
                    id="fldField"
                    label="Field of Study"
                    defaultValue={this.state.fieldValue}
                />
            </SectionFieldGroup>
        </ModalDialog>
        )

        const roleItem = (item, index) => {
            return (
                <SectionItem
                    itemID={item.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.lifeRoleTypeList.find((x) => x.value === item.roleType).label}
                    primaryContent={item.desc}
                    handleEdit={this.handleEditRole}
                    handleDelete={this.handleDeleteRole}
                >
                    <PublicAttribute {...this.props} attr={item.id} />
                </SectionItem>
            )
        }
        const Role = (<Section dark={this.props.dark} title="My Life Roles" handleAdd={this.props.profile.lifeRoles.length > 0 && this.handleAddRole}  {...this.props} publicAttribute="liferole">
            {this.props.profile.lifeRoles.length > 0 ?
                this.props.profile.lifeRoles.map((item, index) => roleItem(item, index))
                :
                <div style={{ textAlign: "center" }}>
                    <Button variant="contained" onClick={this.handleAddRole} color="secondary" sx={{ width: "250px" }}>Add Life Role</Button>
                </div>
                }
            </Section>)

        const deleteConfirmationRole = (<ModalDialog {...this.props} show={this.state.showConfirmDeleteRole}
            title="Confirm Deletion"
            subTitle={"Role will be deleted.  Proceed?"}
            handleClose={() => this.setState({ showConfirmDeleteRole: false })}
            handleSave={this.handleDeleteRole}
            fullWidth={false}
            maxWidth="sm"
            saveLabel="Delete" />)
        const roleEditor = (<ModalDialog {...this.props} show={this.state.showRoleEditor}
            title={this.state.editMode + " Role"}
            handleClose={() => this.setState({ showRoleEditor: false })}
            handleSave={this.handleSaveRole}
            fullWidth={true}
            maxWidth="sm"
        >
            <SectionFieldGroup dark={this.props.dark}>
                <TextInput
                    dark={this.props.dark}
                    cKey="desc"
                    cid="descF"
                    id="fldDesc"
                    label="Description"
                    defaultValue={this.state.descValue}
                />
                <SelectList
                    dark={this.props.dark}
                    key="etype"
                    label="Type"
                    value={this.state.typeValue}
                    onChange={this.handleTypeChange}
                    listItems={this.props.profile.lifeRoleTypeList}
                />
                <SelectList
                    dark={this.props.dark}
                    key="importance"
                    label="Importance"
                    value={this.state.importanceValue}
                    onChange={this.handleImportanceChange}
                    listItems={this.props.profile.importanceList}
                />
                <TextInput
                    dark={this.props.dark}
                    cKey="impacted"
                    cid="impactedF"
                    id="fldImpacted"
                    label="Impacted People"
                    defaultValue={this.state.impactedValue}
                />
            </SectionFieldGroup>
        </ModalDialog>
        )

        return (
            <>
                <SectionGrid xs={12} md={6} height="calc(100% - 40px)">
                    {Occupation}
                    {Role}
                </SectionGrid>          
                {deleteConfirmationOccupation}
                {occupationEditor}
                {deleteConfirmationRole}
                {roleEditor}
            </>
        );
    }
}
