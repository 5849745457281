import React, { Component } from 'react';

//material ui
import Box from '@mui/material/Box';

export class ContentOuterContainer extends Component {

    //main component render
    render() {
        return (
            <Box
                sx={{
                    width: "calc(100vw - 40px)",
                    height: {
                        xs: "calc(100vh - 112px)",
                        sm: "calc(100vh - 120px)",
                        lg: "calc(100vh - 64px)"
                    },
                    background: theme => this.props.dark ? theme.palette.backgroundMain.dark : "#f2f3f7"
                }}
                className={this.props.dark && "dark"}
            >
                {this.props.children}
            </Box>
        )

    }
}
