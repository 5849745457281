export async function getGroups() {
    let url = 'org/getusergroups';
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getGroupMembers(id) {
    let url = 'org/getgroupmembers?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getGroupInvitedMembers(id) {
    let url = 'org/getgroupinvitedmembers?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export function getGroupTypes() {
    return [{ value: 0, label: "Private Group" }, { value: 1, label: "Public Group" }];
}

export async function newGroup(name, type) {
    const req = { GroupName: name, GroupType: type };
    const response = await fetch('org/creategroup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function editGroup(id, name) {
    const req = { GroupName: name, GroupID: id };
    const response = await fetch('org/editgroup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function deleteGroup(id) {
    const req = { GroupID: id };
    const response = await fetch("org/deletegroup", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}
export async function removeMember(id) {
    const req = { GroupMemberID: id };
    const response = await fetch("org/removemember", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}
export async function removeInvitation(id) {
    const req = { InvitationID: id };
    const response = await fetch("org/removeinvitation", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}
