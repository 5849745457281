
export async function getPosts(index, count, type, appid, filter = 0) {
    const req = { Index: index, Count: count, Type: type, AppID: appid, Filter: filter };
    const response = await fetch('post/getposts', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })

    if (response.status !== 200) {
        if (response.status === 401 || response.status === 400)
            return response.status;
        return 'error';
    }
       
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getPostsByUser(postuserid, index, count, type, appid) {
    const req = { PostUserID: postuserid, Index: index, Count: count, Type: type, AppID: appid };
    const response = await fetch('post/getpostsbyuser', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status !== 200) {
        if (response.status === 401 || response.status === 400)
            return response.status;
        return 'error';
    }
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getPostsByIdRange(postids, index, count) {
    const req = { IDs: postids, Index: index, Count: count};
    const response = await fetch('post/getpostsbyidrange', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })

    if (response.status !== 200) {
        if (response.status === 401)
            return response.status;
        return 'error';
    }

    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getNewPosts(type, id, appid, filter = 0) {
    const req = { Type: type, PostID: id, AppID: appid, Filter: filter };
    const response = await fetch('post/getnewposts', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })

    if (response.status !== 200) {
        if (response.status === 401)
            return response.status;
        return 'error';
    }

    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getPost(id) {
    let url = 'post/getpostdetails?postid=' + id;
    const response = await fetch(url);

    if (response.status !== 200) {
        if (response.status === 401)
            return response.status;
        return 'error';
    }

    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getPostContent(id) {
    const req = { ID: id };
    const response = await fetch('post/getpostcontent', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const post = await response.json();
        return post;
    }
    return null;
}

export async function newMobilePhotoPost(textcontent, photocontent, appid) {
    const b64 = await toBase64(photocontent);
    const photob64 = b64.split(',')[1];
    const req = { PhotoContent: photob64, TextContent: textcontent, AppID: appid };
    const response = await fetch('post/createmobilephotopost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function newPost(userid, type, appid, category) {
    const req = { OwnerType: 0, OwnerID: userid, Type: type, AppID: appid, TextCategory: category };
    const response = await fetch('post/createpost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function repost(userid, postid, textcontent, appid) {
    const req = { OwnerType: 0, OwnerID: userid, PostID: postid, TextContent: textcontent, AppID: appid };
    const response = await fetch('post/repost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function newBlogPost(userid, blogid, textcontent, appid) {
    const req = { OwnerType: 0, OwnerID: userid, RefID: blogid, TextContent: textcontent, AppID: appid };
    const response = await fetch('post/createblogpost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function newEventPost(userid, eventid, textcontent, appid) {
    const req = { OwnerType: 0, OwnerID: userid, RefID: eventid, TextContent: textcontent, AppID: appid };
    const response = await fetch('post/createeventpost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function newMarketPost(userid, itemid, textcontent, appid) {
    const req = { OwnerType: 0, OwnerID: userid, RefID: itemid, TextContent: textcontent, AppID: appid };
    const response = await fetch('post/createmarketpost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function newVideoPost(userid, videoid, textcontent, appid) {
    const req = { OwnerType: 0, OwnerID: userid, RefID: videoid, TextContent: textcontent, AppID: appid  };
    const response = await fetch('post/createvideopost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function newPhotoPost(userid, photoid, textcontent, appid) {
    const req = { OwnerType: 0, OwnerID: userid, RefID: photoid, TextContent: textcontent, AppID: appid };
    const response = await fetch('post/createphotopost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function newSongPost(userid, songid, textcontent, appid) {
    const req = { OwnerType: 0, OwnerID: userid, RefID: songid, TextContent: textcontent, AppID: appid };
    const response = await fetch('post/createsongpost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function newPdfDocumentPost(userid, docid, textcontent,appid) {
    const req = { OwnerType: 0, OwnerID: userid, RefID: docid, TextContent: textcontent, AppID: appid };
    const response = await fetch('post/createpdfdocumentpost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function deletePost(id) {
    const req = { PostID: id };
    const response = await fetch('post/deletepost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    });
    if (response.status === 200) {
        const success = await response.json();
        return success;
    }
    return null;
}

export async function addTextContent(id, idx, type, content) {
    const req = { PostID: id, Index: idx, Type: type, TextContent: content };
    const response = await fetch('post/addpostcontent', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function addPhotoContent(id, idx, file) {
    var formData = new FormData();
    formData.append('file', file);
    formData.append('postid', id);
    formData.append('index', idx);
    const response = await fetch('post/addpostphotocontent', {
        method: 'POST',
        body: formData
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function addVideoContent(id, idx, file, thumb) {
    var formData = new FormData();
    formData.append('file', file);
    formData.append('postid', id);
    formData.append('index', idx);
    formData.append('coverB64', thumb);
    const response = await fetch('post/addpostvideocontent', {
        method: 'POST',
        body: formData
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function savePost(id) {
    const req = { PostID: id };
    const response = await fetch('post/savepost', {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    });
    if (response.status === 200) {
        return await response.json();

    }
    return null;
}

export async function unsavePost(id) {
    const req = { PostID: id };
    const response = await fetch('post/unsavepost', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    });
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export async function addSource(postid, source) {
    const req = { PostID: postid, Source: source };
    const response = await fetch('post/addsource', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    });
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

export async function editSource(postid, source) {
    const req = { ID: postid, Source: source };
    const response = await fetch('post/editsource', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    });
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

export async function deleteSource(postid) {
    const req = { ID: postid };
    const response = await fetch('post/deletesource', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    });
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}

export async function getPostTextCategories() {
    let url = 'post/getposttextcategories';
    const response = await fetch(url);

    if (response.status !== 200) {
        if (response.status === 401)
            return response.status;
        return 'error';
    }

    const data = await response.json();
    if (data)
        return data;
    return null;
}

