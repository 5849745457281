import React, { Component } from 'react';

import { TextInput } from './TextInput';
import { UserAvatar } from './UserAvatar';


import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';


//Database Services
import { validateEmail } from '../../DataServices/Utility'

export class UserSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
            newMessageRecipients: [],
            newEmailRecipients: [],
            emailError: "",
        };  
    } 


    handleDeleteEmail = (idx) => {
        const emails = this.props.selectedEmails.filter((item, j) => idx !== j);
        this.props.setSelectedEmails(emails);
    }
    handleDeleteUser = (idx) => {
        const users = this.props.selectedUsers.filter((item, j) => idx !== j);
        this.props.setSelectedUsers(users);
    }
    handleSelectUser = async (idx) => {
        const user = this.state.searchResults[idx];
        this.addExistingUser(user);
    }
    addExistingUser = (user) => {
        const users = this.props.selectedUsers.concat(user);
        this.props.setSelectedUsers(users);
        this.setState({ searchResults: [] });
        document.getElementById("newThreadMember").value = "";
        document.getElementById("newThreadMember").focus();
    }
    handleSelectEmail = async (email) => {
        const emails = this.props.selectedEmails.concat(email);
        this.props.setSelectedEmails(emails);
        this.setState({ searchResults: [] });
        document.getElementById("newThreadMember").value = "";
        document.getElementById("newThreadMember").focus();
    }
    handleKeyPress = async (event) => {
        if (event.keyCode === 9) {
            event.preventDefault();
        }
        if (event.key === 'Enter' || event.keyCode === 9) {
            const name = event.target.value;
            if (this.validateEmail(name)) {
                const user = await this.checkIfUserExists(name);
                if (user) {
                    this.addExistingUser(user);
                }
                else {
                    if(this.props.allowEmails)
                        this.handleSelectEmail(name);
                }
            }
        }
    }
    validateEmail = (email) => {
        if (!validateEmail(email)) {
            this.setState({ emailError: "Invalid email" });
            return false;
        }
        this.setState({ emailError: "" });
        return true;
    }
    validateMember = async (name) => {
        if (!this.state.validating) {
            this.setState({ validating: true });
            if (name) {
                const response = await fetch('messenger/searchusersbyname?name=' + name)
                const searchResults = await response.json();
                if (searchResults && searchResults.length > 0) {
                    this.setState({ searchResults });
                    //for (var i = 0; i < searchResults.length; i++) {
                    //    let avatar = await this.getUserInfo(searchResults[i].userID);
                    //    if (avatar) {
                    //        searchResults[i].avatarB64 = avatar;
                    //        this.setState({ searchResults });
                    //    }

                    //}
                }
                else {
                    this.setState({ searchResults: [] });
                }
            }
            else {
                this.setState({ searchResults: [] });
            }
            this.setState({ validating: false });
        }

    }

    checkIfUserExists = async (email) => {
        if (email) {
            const response = await fetch('messenger/getuserinfobyemail?email=' + email);
            return await response.json();
        }
    }

    getUserInfo = async (id) => {
        if (id) {
            const response = await fetch('home/getuseravatar?userid=' + id);
            const userinfo = await response.json();
            if (userinfo) {
                return userinfo.avatarB64;
            }
        }
        return null;
    }

    //Render funcs
    input = () => {
        return (
            <Box sx={{ m: 0.5, display: "inline-block" }}>
                <TextInput {...this.props}
                    cKey="CnewThreadMember"
                    cid="CnewThreadMember"
                    id="newThreadMember"
                    label="Enter name or email"
                    defaultValue=""
                    margin="none"
                    onChange={(e) => this.validateMember(e.target.value)}
                    onKeyPress={this.handleKeyPress}
                    autoFocus
                />
            </Box>
        )

    }

    selectedEmail = (email, idx) => {
        return (
            <Box sx={{ m: 0.5, display: "inline-block" }}>
                <Chip
                    size="small"
                    color="warning"
                    label={email}
                    onDelete={() => this.handleDeleteEmail(idx)}
                />
            </Box>

        )
    }

    selectedUser = (user, idx) => {
        return (
            <Box sx={{ m: 0.5, display: "inline-block" }}>
                <Chip
                    size="small"
                    color="secondary"
                    label={user.firstName + " " + user.lastName}
                    onDelete={() => this.handleDeleteUser(idx)}
                    sx={{ marginTop: "auto", marginBottom: "auto" }}
                />
           </Box>
        )
    }

    selectedUserList = () => {
        return (
            <Box sx={{ display: "flex", p: 1, background: this.props.dark ? "#101010" : "#fff" , borderRadius:"8px", border: this.props.dark && "1px solid #191919"}}>
                {this.props.selectedUsers && this.props.selectedUsers.map((user, index) => {
                    return this.selectedUser(user, index)
                })}
                {this.props.selectedEmails && this.props.selectedEmails.map((email, index) => {
                    return this.selectedEmail(email, index)
                })}
                {this.input()}
            </Box>
        )
    }

    searchResults = () => {
        return (
            <Box sx={{
                background: (theme) => this.props.dark ? theme.palette.component.dark : theme.palette.component.light,
                color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                padding: "6px, 16px",
                maxHeight: "300px"
            }}
                className="prettyScroll" >
                <List>
                    {this.state.searchResults.map((user, index) => {
                        return (
                            <ListItem
                                key={"sr" + user.userID}
                            >
                                <ListItemButton onClick={() => this.handleSelectUser(index)} >
                                    <ListItemAvatar>
                                        <UserAvatar
                                            id={user.userID}
                                            user={user}
                                            dark={this.props.dark}
                                            loaded={true}
                                            activeUser={this.props.user}
                                            appIdentifier={this.props.appIdentifier}
                                            hideName={true}
                                            tiny
                                        />
                                    </ListItemAvatar>
                                    <ListItemText primary={user.firstName + " " + user.lastName} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </Box>
        )
    }

    render() {

        return (
            <>
                {this.selectedUserList()}
                {(this.state.searchResults && this.state.searchResults.length > 0) && this.searchResults()}
            </>

        );
    }
}
