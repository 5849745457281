import React, { Component } from 'react';
import { default as MuiMenu } from '@mui/material/Menu';

export class Menu extends Component {
    content = () => {
        return (
            <MuiMenu               
                id={"usermenu" + this.props.id}
                open={this.props.open}
                onClose={this.props.onClose}
                anchorEl={this.props.anchorEl}
                PaperProps={{
                    elevation: this.props.dark ? 0 : 4,
                    sx: {
                        backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundMenu.dark : theme.palette.backgroundMenu.light,
                        border: (theme) => this.props.dark ? theme.palette.borderMenu.dark : theme.palette.borderMenu.light,
                        color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack
                    }
                }}
                MenuListProps={{
                    dense: this.props.width < 600,
                    sx: {
                        backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundMenu.dark : theme.palette.backgroundMenu.light,
                        color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack
                    }
                }}
            >
                {this.props.children}
            </MuiMenu>
        )

    }


    //main component render
    render() {
        return this.content()
    }
}