import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../Utilities/ModalDialog'

//Material UI
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export class ProfileImageUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            selectedFile: null,
            imgScale: 1.0,
            imgRotation: 0,

        }
    }
    setEditorRef = (editor) => (this.editor = editor);
    handleSavePhoto = async () => {

        // Select your input type file and store it in a variable
        const input = document.getElementById('filepic');
        const formData = new FormData();
        const canvas = await this.editor.getImageScaledToCanvas().toDataURL();
        const b64 = await fetch(canvas);
        const file = await b64.blob();


        let name = this.state.selectedFile.name;

        if (!name || name.length === 0) {
            name = "image.png";
        }
        formData.append('file', file, name);

        fetch('home/uploadprofileimage', {
            method: 'POST',
            body: formData
        }).then(
            response => response.json()
        ).then(
            success => { }
        ).catch(
            error => console.log(error)
        );


        var reader = new FileReader();

        reader.onload = e => {
            const { result } = e.target;
            var base64result = result.split(',')[1];
            //base64 = base64result;
            this.props.handleSave(base64result);
        }

        reader.readAsDataURL(file);


    }




    updateStateForNewPhoto = (base64result) => {
        //Update State to display new photo
        const itemCount = this.state.itemCount + 1;
        this.setState({
            currentUpload: base64result,
            itemCount: itemCount
        });
    }

    handleClose = () => {
        this.setState({
            currentUpload: "",
            itemCount: 0
        })
        this.props.handleClose();
    }

    toggleProcessing = (status) => {
        if (status != null) {
            this.setState({ processing: status });
        }
        else {
            this.setState({ processing: !this.state.processing });
        }  
    }
    handleDragOverDropZone = (e) => {
        e.currentTarget.classList.add("hoverdropzone");
    }
    handleDragLeaveDropZone = (e) => {
        e.currentTarget.classList.remove("hoverdropzone");
    }
    handleRotateImage = () => {
        var rot = parseInt(this.state.imgRotation) + 90;
        if (rot === 450) {
            rot = 0;
        }
        this.setState({ imgRotation: rot });
    }
    handleScaleUpImage = () => {
        var scale = parseFloat(this.state.imgScale);
        scale = scale + .1;
        this.setState({ imgScale: scale });
    }
    handleScaleDownImage = () => {
        var scale = parseFloat(this.state.imgScale);
        if (scale > .2) {
            scale = scale - .1;
        }
        this.setState({ imgScale: scale });
    }
    handleDrop = async (files) => {
        this.toggleProcessing(true);
        if (files) {
            var numAllowed = 1;
            for (var i = 0; i < numAllowed; i++) {
                var file = files[i];
                var extension = file.type;
                if (extension === "image/jpeg" || extension === "image/jpg" || extension === "image/png" || extension === "image/bmp") {
                    this.setState({ selectedFile: file });
                }
            }
        }

        //clean up iu from any progress indicators, etc.
        var ele = document.getElementById("dropZone");
        if (ele) ele.classList.remove("hoverdropzone");
        this.toggleProcessing(false);
    }

    render() {


        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.props.open}
                handleClose={this.props.handleClose}
                fullWidth={false}
                maxWidth="md"
                closeLabel="Close"
                hideScroll={true}
                draggable={true}
                title="Upload Profile Photo"
                subTitle={this.state.selectedFile ? "Edit the photo using the zoom and rotate buttons below..." : "Drag photos below, or click for file browser..."}
                disableButtons
                fullScreen={this.props.fullScreen}
                solid={this.props.fullScreen}
                fullHeight={this.props.fullScreen}
            >
                <Stack sx={{ p: 1 }}>
                    {this.state.selectedFile ?
                        <>
                        <AvatarEditor
                            className="avatareditor"
                            ref={this.setEditorRef}
                            image={this.state.selectedFile}
                                height={window.innerWidth < 516 ? window.innerWidth - 116 : 400}
                                width={window.innerWidth < 516 ? window.innerWidth - 116 : 400}
                            border={50}
                            color={[51, 51, 51, 0.6]} // RGBA
                            scale={this.state.imgScale}
                            rotate={this.state.imgRotation}
                            />
                            <Box display="flex" sx={{ justifyContent: "center", width:"100%" }}>
                                <IconButton >
                                    <ZoomInIcon sx={{ color:(theme) => this.props.dark ? theme.palette.common.dimwhite: theme.palette.secondary.main}} onClick={this.handleScaleUpImage} />
                                </IconButton>
                                <IconButton >
                                    <ZoomOutIcon sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.secondary.main }} onClick={this.handleScaleDownImage} />
                                </IconButton>
                                <IconButton >
                                    <RotateRightIcon sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.secondary.main }} onClick={this.handleRotateImage} />
                                </IconButton>
                            </Box>
                        </>
                        :
                        <Dropzone
                            onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}
                            onDragOver={this.handleDragOverDropZone}
                            onDragLeave={this.handleDragLeaveDropZone}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <Box {...getRootProps()}
                                    id={"dropZone"}
                                    sx={{ backgroundColor: this.props.dark ? "#444" : "#BBB", height: this.props.height ?? { xs: window.innerWidth, sm: 400 }, width: this.props.width ?? { xs: "100%", sm: 400 }, position: "relative", borderRadius: 1 }}
                                    className="hoverPointer"
                                >
                                    <input {...getInputProps()} accept="image/*" />
                                    <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translateY(-50%) translateX(-50%)" }}>
                                        <InsertPhotoIcon sx={{ color: (theme) => this.props.dark ? "#bbb" : "#444" }} style={{ fontSize: "76px" }} />
                                    </div>                        
                                </Box>
                            )}
                        </Dropzone>
                    }
                    {this.state.selectedFile &&
                        <Box sx={{ display: "flex", width: "100%",p:2 }}>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button variant="contained" color="secondary" onClick={this.handleSavePhoto} sx={{ width: { xs: "100%", sm: "150px" }, mt: 3 }}>Save</Button>
                        </Box>
   
                    }
                </Stack>
            </ModalDialog>
        );
    }
}

