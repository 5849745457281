import React, { Component } from 'react';

//material ui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export class Spin extends Component {


    //render funcs
    content = () => {
        return (
            <>
                {this.props.sub ?
                    <Box sx={{ m: "auto", display: 'flex', width: "100%", height: "100%", justifyContent: "center", minHeight: "200px" }}>
                        {this.props.message ?
                            <Stack sx={{ margin: "auto", }}>
                                <Typography
                                    sx={{
                                        p: 3,
                                        alignSelf: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        mx: "auto",
                                        fontWeight: 500,
                                        mb: 1,
                                        fontSize: ".9rem",
                                        color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black
                                    }}>
                                    {this.props.message}
                                </Typography>
                                <CircularProgress sx={{ color: (theme) => this.props.color ?? theme.palette.secondary.main, alignSelf: "center" }} />
                            </Stack>
                            :
                            <CircularProgress sx={{ color: (theme) => this.props.color ?? theme.palette.secondary.main, alignSelf: "center" }} size={this.props.tiny && 16} />
                        }
                    </Box>
                    :
                    <Backdrop
                        sx={{ color: '#', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={this.props.open}
                    >
                        {this.props.message ?
                            <Stack sx={{ margin: "auto", }}>
                                <Typography variant="subtitle1" sx={{ p:3,alignSelf: "center", justifyContent: "center", textAlign: "center", marginLeft: "auto", marginRight: "auto", fontWeight: 500, marginBottom: "6px", fontSize: ".9rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                                    {this.props.message}
                                </Typography>
                                <CircularProgress sx={{ color: (theme) => this.props.color ?? theme.palette.secondary.main, alignSelf: "center" }} />
                            </Stack>
                            :
                            <CircularProgress sx={{ color: (theme) => this.props.color ?? theme.palette.secondary.main, alignSelf: "center" }} />
                        }
                    </Backdrop >
                }
            </>
        );
    }

    //main component render
    render() {
        return this.content();
    }
}
