import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Box from '@mui/material/Box';

export class ModalDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullScreen: this.props.fullScreen ?? false,
            toggled: false,
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.toggled) {
            if (this.props.fullScreen !== this.state.fullScreen) {
                this.setState({ fullScreen: this.props.fullScreen });
                return;
            }
        }
        else {
            if (prevProps.fullScreen !== this.props.fullScreen) {
                this.setState({ fullScreen: this.props.fullScreen, toggled: false });
            }
        }
    }
    handleToggleFullScreen = () => {
        const fs = !this.state.fullScreen;
        this.setState({ fullScreen: !this.state.fullScreen, toggled: true });
        if (this.props.setFullScreen) {
            this.props.setFullScreen(fs);
        }
        this.forceUpdate();
    }
    closeButton = () => {
        return (
            <IconButton onClick={this.props.handleClose} sx={{ padding: "4px", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.white }}>
                <CloseIcon  fontSize="1.9rem" />
            </IconButton>
        )
    }
    fullScreenButton = (fullScreen) => {
        return (
            <IconButton onClick={this.handleToggleFullScreen} sx={{ padding: "4px", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.white }}>
                {(fullScreen === true) ?
                    < CloseFullscreenIcon fontSize="medium" />
                :

                    < FullscreenIcon fontSize="medium" />
                }
            </IconButton>
        )
    }
    editButton = () => {
        return (
            <IconButton onClick={this.props.handleEdit} sx={{ padding: "4px", color: (theme) => this.props.editModeActive ? "blue" : (this.props.dark ? theme.palette.common.white : theme.palette.common.white )}}>
                <EditIcon fontSize="medium" />
            </IconButton>
        )
    }
    content = (fullScreen) => {
        return (
        <Dialog
            open={this.props.show}
            onClose={this.props.handleClose}
            fullWidth={this.props.fullWidth}
            maxWidth={this.props.maxWidth}
            fullScreen={fullScreen}
                disableEnforceFocus={this.props.userPopper}
                PaperProps={{
                    id:"dialog",
                sx: {
                    backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundModal.dark : theme.palette.backgroundModal.light,
                    color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white,
                    border: (!fullScreen && !this.props.noBorder) && (this.props.dark ? "solid 2px #222" : "solid 1px #666"),
                    borderRadius: !fullScreen && "6px",
                    padding: this.props.padding && this.props.padding,
                    pb: { xs: this.props.fullScreen ? 7 : 0, lg: 0 },

                },
                elevation: this.props.dark ? 1 : 4,

            }}


        >
            {(this.props.title || !this.props.noUpperClose) &&
                <DialogTitle
                        sx={{
                            backgroundColor:  "#222",
                            fontWeight: 500,
                            color: (theme) => this.props.fullScreen ? theme.palette.common.white : (this.props.dark ? theme.palette.common.white : theme.palette.common.black),
                            py: 0.5,
                            px:1,
                            boxShadow: this.props.dark ? 0 : 8
                        }}
                >
                        <Box sx={{ display: "flex" }}>
                            <Box sx={{ display: { xs: 'flex' }, marginTop: "auto", color: "white", marginBottom: "auto", fontSize: fullScreen ? "1.3rem" : "1.2rem", pl: 1 }} >
                                {this.props.title && this.props.title}
                            </Box>
                            <Box sx={{ flexGrow: 1, textAlign: "center" }} />
                            <Box sx={{ display: { xs: 'flex' } }} >
                                {(this.props.allowEdit && this.props.handleEdit) && this.editButton()}
                                {this.props.toggleFullScreen && this.fullScreenButton(fullScreen)}
                            </Box>
                            <Box sx={{ display: { xs: 'flex' } }} >
                                {(!this.props.noUpperClose && this.props.handleClose) && this.closeButton()}
                            </Box>
                        </Box>
                </DialogTitle>
            }
            <DialogContent
                className={this.props.prettyScroll && "prettyScroll"}
                sx={{
                    minHeight: "40px",
                    maxHeight: { md: this.props.noUpperClose ? "100vh" : (this.props.fullHeight ? "unset" : (fullScreen ? "calc(100vh - 80px)" : "calc(100vh - 80px)")) },
                    p: "0px!important",
                    overflow: this.props.hideScroll ? "hidden" : "auto",
                    color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
    
                }}>
                {this.props.subTitle &&
                        <DialogContentText sx={{ p: { xs: 1, sm: 2 }, my: "auto", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, fontSize: { xs: ".8rem",sm:".9rem" } }}>
                        {this.props.subTitle}
                    </DialogContentText>
                }
                {this.props.subTitleContent && this.props.subTitleContent}
                {this.props.children}
            </DialogContent>
            {!this.props.disableButtons &&
                <DialogActions sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, p: this.props.containedButton ? "8px 16px" : 1 }}>
                    {this.props.handleDelete &&
                        <Button
                            variant={this.props.containedButton ? "contained" : "text"}
                            color="secondary"
                            onClick={this.props.handleDelete}
                                sx={{ fontSize: "1rem", color: (theme) => this.props.containedButton ? "white" : (this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black) }}
                        >
                            {this.props.deleteLabel ?? "Delete"}
                        </Button>
                    }

                    {(this.props.handleClose && this.props.noUpperClose) &&
                        <Button
                            variant={this.props.containedButton ? "contained" : "text"}
                            color="secondary"
                            onClick={this.props.handleClose}
                                sx={{ fontSize: "1rem", color: (theme) => this.props.containedButton ? "white" :  (this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black) }}
                        >
                            {this.props.closeLabel ?? "Cancel"}
                        </Button>
                    }

                    {this.props.handleSave &&
                        <Button
                            variant={this.props.containedButton ? "contained" : "text"}
                            color="secondary"
                            onClick={this.props.handleSave}
                            sx={{ fontSize: "1rem", color: (theme) => this.props.containedButton ? "white" :  (this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black) }}
                        >
                            {this.props.saveLabel ?? "Save"}
                        </Button>
                    }

                </DialogActions>
            }
        </Dialog>
        );
    }

    render() {

        return this.content(this.state.fullScreen);

    }
}
