
import React, { Component } from 'react';

//custom components
import { InputTags } from '../Utilities/InputTags'
import { ModalDialog } from '../Utilities/ModalDialog'
import { TextInput } from '../Utilities/TextInput'
import { PhotoEditor } from '../Content/Photos/PhotoEditor';
import { Spin } from '../Utilities/Spin'

//materialui
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

//other libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages, faVideo } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'react-dropzone'
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import ReactPlayer from 'react-player'


//Database Services
import { getPostTextCategories } from '../../DataServices/PostService'
import { isValidURL, getVideoCover, fileToBase64, b64toBlob } from '../../DataServices/Utility'

export class PostComposer extends Component {
  constructor(props) {
      super(props);
      this.state = {
          newPostText: [],
          newPostPhoto: [],
          newPostVideo: [],
          tags: [],
          mentions: [],
          showPhotoDropZone: false,
          showVideoDropZone: false,
          postValue: "",
          postLink: null,
          postLinks: [],
          saving: false,
          anchorEl: null,
          postType: 0,
          selectedTextCategory: 0,
          sources: [],
          invalidURL: false,
          noText: false,
          videoThumbBitmap: null,
          showEditor: false,
          title: "New Post",
          addMorePhotos: false
      };
  }

    componentDidMount() {
        this.getPostTextCategories();
    }
    getPostTextCategories = async () => {
        const postTextCategories = await getPostTextCategories();
        if (postTextCategories) {
            this.setState({ postTextCategories });
        }
    }

    //event handlers
    handleSubmitPost = async () => {
        this.setState({ title: "Posting..." });
        let textContent = this.state.textContent;
        let linkContent = this.state.postLink;

        //if text and link content are the same, blank out textcontent
        if (linkContent && linkContent == textContent) {
            textContent = "";
        }

        //if still has text content, remove the link content so they are separate.
        if (textContent) {
            textContent = textContent.replaceAll(linkContent, '');
        }

        //if channel 0, there must be text content.
        if (this.props.channel === 0 && (!textContent || textContent === "")) {
            this.setState({ noText: true });
        }
        else {
            if (this.state.selectedTextCategory === 0 && document.getElementById("source")) {
                const pendingSource = document.getElementById("source").value;
                if (pendingSource && pendingSource !== "") {
                    await this.handleAddSource();
                    if (this.state.invalidURL) {
                        return;
                    }
                }
            }

            //create post object and submit
            var post = { textContent: textContent, photoContent: this.state.newPostPhoto, videoContent: this.state.newPostVideo, linkContent: linkContent, tags: this.state.tags, type: this.props.channel, mentions: this.state.mentions, sources: this.state.sources, category: this.state.selectedTextCategory, videoThumbs: this.state.videoThumbs  };
            this.props.savePost(post);

            //update post state while posting
            this.setState({
                postLink: null,
                newPostText: [],
                newPostPhoto: [],
                newPostVideo: [],
                tags: [],
                mentions: [],
                postValue: "",
                showEmojiPicker: false,
                showPhotoDropZone: false,
            });
        }
    }
    handleDragOverDropZone = (e) => {
        e.currentTarget.classList.add("hoverdropzone");
    }
    handleDragLeaveDropZone = (e) => {
        e.currentTarget.classList.remove("hoverdropzone");
    }
    handleDroppedFiles = async (files) => {
        if (files) {
            const checkedFiles = [];
            var i;
            for (i = 0; i < files.length; i++) {
                var extension = files[i].type;
                if (extension === "image/jpeg" || extension === "image/jpg" || extension === "image/png" || extension === "image/bmp") {
                    checkedFiles.push(files[i]);
                }
            }

            this.setState({ newPostPhoto: [...this.state.newPostPhoto, ...checkedFiles], addMorePhotos : false });
        }
        document.getElementById("dropZone").classList.remove("hoverdropzone");
    }
    handleDroppedFilesVideo = async (files) => {
        if (files) {
            const checkedFiles = [];
            const thumbs = [];
            var i;
            for (i = 0; i < files.length; i++) {
                var extension = files[i].type;
                if (extension === "video/mp4" || extension === "video/quicktime") {
                    checkedFiles.push(files[i]);
                    thumbs.push(await this.createVideoCover(files[i]));
                }
            }
            this.setState({ newPostVideo: checkedFiles, videoThumbs: thumbs });
            
        }
        document.getElementById("dropZoneVideo").classList.remove("hoverdropzone");
    }
    createVideoCover = async  (file) => {
        try {
            // get the frame at 1.5 seconds of the video file
            const cover = await getVideoCover(file, 1.5);
            // print out the result image blob
            console.log(cover);
            return cover;
        } catch (ex) {
            console.log("ERROR: ", ex);
            return "error";
        }
    }
    handleAttachPhotos = () => {
        this.setState({ showPhotoDropZone: true });
    }
    handleAttachVideos = () => {
        this.setState({ showPhotoDropZone: true });
    }
    cancelDropAttach = (e) =>  {
        e.stopPropagation();
        this.setState({ showVideoDropZone: false });
        this.setState({ showPhotoDropZone: false });
    }
    handleInputChange = (value, links, tags, mentions) => {
        this.setState({
            textContent: value,
            postLink: links,
            tags: tags,
            mentions: mentions
        });
    }
    handlePostContentSelection = (content) => {
        if (content == 2) {
            if (this.state.postType === 2) {
                this.setState({
                    postType: 0,
                    showPhotoDropZone: false,
                    showVideoDropZone: false,
                });
            }
            else {
                this.setState({
                    postType: 2,
                    showPhotoDropZone: true,
                    showVideoDropZone: false,
                });
            }
        }
        if (content == 3) {
            if (this.state.postType === 3) {
                this.setState({
                    postType: 0,
                    showPhotoDropZone: false,
                    showVideoDropZone: false,
                });
            }
            else {
                this.setState({
                    postType: 3,
                    showPhotoDropZone: false,
                    showVideoDropZone: true,
                });
            }
        }
    }

    handleTextCategoryChange = (e, val) => {
        this.setState({ selectedTextCategory: val });
    }
    handleRemoveSource = (idx) => {
        const sources = [...this.state.sources]
        sources.splice(idx, 1);
        this.setState({ sources });
    }
    handleAddSource = () => {
        const source = document.getElementById("source").value;
        if (source) {
            if (source === "") {
                this.setState({ invalidURL: false });
            }
            else {
                if (this.state.sources.find(x => x === source))
                    return;
                if (isValidURL(source)) {
                    const sources = [...this.state.sources]
                    sources.push(source);
                    this.setState({ sources, invalidURL: false });
                    document.getElementById("source").value = "";
                }
                else {
                    this.setState({ invalidURL: true });
                }
            }
        }
        else {
            this.setState({ invalidURL: false });
        }
    }   
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleAddSource();
        }
    }
    handleSavePhotoEdit = async  (b64) => {
        const blob = await b64toBlob(b64, "image/png");
        const oPhoto = this.state.selectedPhoto;
        const photo = new File([blob], oPhoto.name, { type: "image/png", lastModified: new Date().getTime() });
        const newPostPhoto = this.state.newPostPhoto;
        newPostPhoto[this.state.selectedPhotoIDX] = photo;
        this.setState({ newPostPhoto, showEditor: false });
    }
    handleClickPhoto = async  (e, photo, idx) => {
        e.preventDefault();
        const b64 = await fileToBase64(photo);
        this.setState({ selectedPhotoIDX: idx, selectedPhoto: photo, selectedPhotoB64: b64, showEditor: true });
    }

    photoEditor = () => {
        if (this.state.showEditor && this.state.selectedPhotoIDX !== null) {
            const index = this.state.selectedPhotoIDX;
            const b64 = this.state.selectedPhotoB64;
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showEditor}
                    handleClose={() => this.setState({ showEditor: false })}
                    fullWidth
                    fullScreen
                    fullHeight
                    solid
                    noPad
                    disableButtons
                    maxWidth="xl"
                    prettyScroll
                    title={"Edit Photo"}
                >
                    <PhotoEditor src={"data:image/png;base64, " + b64} dark={this.props.dark} width={this.props.width} onSave={this.handleSavePhotoEdit} photoID={index} height="100%" />
                </ModalDialog>
            )
        }
        return null;
    }

    //render funcs
    selectedPhotos = () => {
        if (this.state.newPostPhoto && this.state.newPostPhoto.length > 0) {
            return (
                <Stack sx={{ mt: this.state.addMorePhotos ? 2 : 0, display: "flex", backgroundColor: this.props.dark ? "#000" : "#fff", borderRadius:"8px" }}>
                    <Box sx={{ display: "flex", px:2 }}>
                        <Typography sx={{ margin:"auto", fontWeight: 600, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>Click or tap photo to edit.</Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Tooltip title="Add more photos to this post">
                            <IconButton onClick={() => this.setState({ addMorePhotos: !this.state.addMorePhotos })}>
                                <AddIcon sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Stack sx={{ maxHeight: { xs: 376, sm: 416, md: 516 }, display: "block" }} className="prettyScroll" >
                        {this.state.newPostPhoto.map((photo, index) => {
                            return (
                                <Tooltip key={"picPreview" + index} title="Click on photo to edit">
                                    <Box sx={{ maxWidth: { xs: 360, sm: 400, md: 500, }, m: "auto", mt: 1, mb: 2, backgroundColor: this.props.dark ? "#111" : "#fff", boxShadow: this.props.dark ? 4 : 8, }}>
                                        <img
                                            src={URL.createObjectURL(photo)}
                                            style={{ marginBottom:"8px",height: "auto", width: "100%", objectFit: "contain", cursor: "pointer",}}
                                            onClick={(e) => this.handleClickPhoto(e, photo, index)}
                                    
                                        />
                                    </Box>
                                </Tooltip>
                            )
                        })}
                    </Stack>
                </Stack>
                )

        }
        return null;
    }
    selectedVideos = () => {
        if (this.state.newPostVideo && this.state.newPostVideo.length > 0) {
   
                return (
                    <Box key={'uploadedVid'} className='player-wrapper' sx={{ boxShadow: this.props.dark ? 2 : 8, margin: "auto" }} >
                        <ReactPlayer
                            url={URL.createObjectURL(this.state.newPostVideo[0])}
                            controls={true}
                            className='react-player'
                            width="100%"
                            height="100%"
                            onReady={this.handleVideoLoaded}

                        />
                    </Box>

                )

        }
    }
    dropZone = () => {
        return (
            <Box sx={{ padding: "6px" }}>

                {((this.state.newPostPhoto && this.state.newPostPhoto.length === 0) || (this.state.newPostPhoto.length > 0 && this.state.addMorePhotos)) &&
                    <Dropzone
                        onDrop={acceptedFiles => this.handleDroppedFiles(acceptedFiles)}
                        onDragOver={this.handleDragOverDropZone}
                        onDragLeave={this.handleDragLeaveDropZone}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <Box
                                {...getRootProps()}
                                id="dropZone"
                                sx={{
                                    display:"flex",
                                    height: this.state.newPostPhoto.length > 0 ? 120 : (this.props.width < 400 ? 180 : 300),
                                    background: (theme) => this.props.dark ? theme.palette.backgroundInput.dark : theme.palette.backgroundInput.light,
                                    color: this.props.dark ? "#999" : "#444",
                                    textAlign: "center",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    maxWidth: 400,
                                    mx: "auto",
                                    boxShadow: this.props.dark ? 0 : 8,
                                }}>
                                <input {...getInputProps()} accept=".jpg, .jpeg, .png" />

                                <Box sx={{ display: "inline-flex", m:"auto" }}>
                                    <Box sx={{ display: { xs: "none", sm: "block" }, m: "auto" }}>
                                        <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                            Drag and Drop Photos
                                        </Typography>
                                        <FontAwesomeIcon icon={faImages} style={{ fontSize: "48px", color: this.props.dark?"unset": "#384370" } } />
                                    </Box>
                                    <Box sx={{ display: { xs: "block", sm: "none" }, m: "auto" }}>
                                        <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                            Tap or click to add photos
                                        </Typography>
                                        <FontAwesomeIcon icon={faImages} style={{ fontSize: "48px", color: this.props.dark ? "unset" :  "#384370" }} />
                                    </Box>
                                </Box>

                            </Box>
                        )}
                    </Dropzone>
                }
                {(this.state.newPostPhoto && this.state.newPostPhoto.length > 0)
                    &&
                    this.selectedPhotos()
                }
            </Box>

        )
    }
    dropZoneVideo = () => {
        return (
            <Box sx={{ padding: "6px" }}>
                {(this.state.newPostVideo && this.state.newPostVideo.length === 0) &&
                    <Dropzone
                        onDrop={acceptedFiles => this.handleDroppedFilesVideo(acceptedFiles)}
                        onDragOver={this.handleDragOverDropZone}
                        onDragLeave={this.handleDragLeaveDropZone}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <Box
                                {...getRootProps()}
                                className="prettyScroll"
                                id="dropZoneVideo"
                                sx={{
                                    display: "flex",
                                    height: this.state.newPostPhoto.length > 0 ? 120 : (this.props.width < 400 ? 180 : 300),
                                    background: (theme) => this.props.dark ? theme.palette.backgroundInput.dark : theme.palette.backgroundInput.light,
                                    color: this.props.dark ? "#999" : "#444",
                                    textAlign: "center",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    maxWidth: 400,
                                    mx: "auto",
                                    boxShadow: this.props.dark ? 0 : 8,
                                }}>
                                <input {...getInputProps()} accept=".mp4, .mov" />
     
                                <Box sx={{ display: "inline-flex", m: "auto" }}>
                                        <Box sx={{ display: { xs: "none", sm: "block" }, m: "auto" }}>
                                            <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                                Drag and Drop Video
                                            </Typography>
                                        <FontAwesomeIcon icon={faImages} style={{ fontSize: "48px", color: this.props.dark ? "unset" : "#384370" }} />
                                        </Box>
                                        <Box sx={{ display: { xs: "block", sm: "none" }, m: "auto" }}>
                                            <Typography sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto", fontWeight: 540 }}>
                                                Tap or click to add video
                                            </Typography>
                                        <FontAwesomeIcon icon={faVideo} style={{ fontSize: "48px", color: this.props.dark ? "unset" : "#384370" }} />
                                        </Box>
                                    </Box>
                            </Box>
                        )}
                    </Dropzone>
                    }
                {(this.state.newPostVideo && this.state.newPostVideo.length > 0)
                    &&
                    this.selectedVideos()
                }
            </Box>

        )
    } 
    linkPreview = () => {
        return (
            <Box sx={{ padding: "8px", margin: "8px", borderRadius: "8px", background: this.props.dark ? "#222" : "#eee" }}>
                <LinkPreview
                    url={this.state.postLink}
                    width='230px'
                    height='230px'
                    backgroundColor={this.props.dark ? "#222" : "#ddd"}
                    primaryTextColor={this.props.dark ? "#ccc" : "#444"}
                    secondaryTextColor={this.props.dark ? "#ccc" : "#444"}
                    borderColor={this.props.dark ? "#444" : "#ddd"}
                    margin="8px"
                    className="linkPreview"
                    descriptionLength={50}
                />
            </Box>
        )
    }
    sourcesContainer = () => {
        if (this.state.sources && this.state.sources.length > 0) {
            return (
                <Box display="flex" sx={{ mb: 1, flexWrap: 'wrap', padding: "8px", margin: "0px 6px", borderRadius: "8px", background: this.props.dark ? "#222" : "#fff" }}>
                    <Typography variant="subtitle2" sx={{ paddingRight: "8px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto" }}>
                        Sources:
                    </Typography>
                    {this.state.sources.map((item, index) => {
                        return (
                            <Chip
                                key={"source" + index}
                                label={item}
                                onDelete={() => this.handleRemoveSource(index)}
                                size="small"
                                color="secondary"
                                sx={{ marginRight: "6px", marginBottom: "6px", width: "fit-content" }}
                            />
                        )
                    })}
                </Box>
            )
        }
        return null;
    }
    postContentButtons = () => {
        return (
            <Box sx={{ padding: "6px" }}>
                <Box sx={{  borderRadius: "6px" }}>
                <Tooltip title="Post some photos">
                    <IconButton
                        size="large"
                        onClick={() => this.handlePostContentSelection(2)}
                        sx={{ color: (theme) => theme.palette.secondary.main }}
                    >
                        <FontAwesomeIcon icon={faImages} />
                    </IconButton>
                </Tooltip>
                </Box>
            </Box>
            )
    }
    categoryButton = () => {
        if (this.state.postTextCategories) {
            return (
                <Box sx={{ p:1, pb:0}}>
                    <ToggleButtonGroup
                        color="secondary"
                        value={this.state.selectedTextCategory}
                        exclusive
                        onChange={this.handleTextCategoryChange}
                        aria-label="Platform"
                        fullWidth
                        size="small"
                   
                    >
                        {this.state.postTextCategories.map((item) => {
                            return (
                                <ToggleButton value={item.value} sx={{ borderColor: this.props.dark ? "transparent" : "#ddd", backgroundColor: this.props.dark ? "transparent" : "transparent", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, fontSize: ".7rem" }} aria-label={item.label}>{item.label}</ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                </Box>
            );
        }
        return null;
    }
    sources = () => {
        return (
            <Stack sx={{ p: 2 }}>
                <Typography variant="body2" sx={{ fontWeight: 500, fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                    When sharing news or information, we suggest providing valid url sources for your audience.
                </Typography>
                <TextInput
                    dark={this.props.dark}
                    cKey="source"
                    cid="source"
                    id="source"
                    label="Enter Source"
                    defaultValue=""
                    onBlur={this.handleAddSource}
                    onKeyPress={this.handleKeyPress}
                />
                {this.state.invalidURL && <Alert severity="error">Invalid URL</Alert>}
                {this.sourcesContainer() }
            </Stack>
        )
    }
    postButton = () => {

        let textContent;
        if (this.props.channel === 0) {
            let ele = document.getElementById("newPostTextContent");
            if (ele) {
                textContent = ele.value;
                let linkContent = this.state.postLink;
                if (linkContent && linkContent == textContent)
                    textContent = "";
                textContent = textContent.replaceAll(linkContent, '');
            }
        }

        if ((this.props.channel === 0 && textContent && textContent !== "") ||
            (this.props.channel === 2 && this.state.newPostPhoto && this.state.newPostPhoto.length > 0) ||
            (this.props.channel === 3 && this.state.newPostVideo && this.state.newPostVideo.length > 0)
        ) {
            return (
                <Box sx={{ display: "flex", py: 2, px: 2, width: "100%" }}>
                    <Box sx={{ display: { xs: "none", sm: "flex" }, flexGrow: 1 }} />
                    <Button onClick={!this.props.saving && this.handleSubmitPost} variant="contained" color="secondary" sx={{ width:"100%", m: "auto", boxShadow: this.props.dark ? 0 : 8 }}>Post</Button>
                </Box>
            )
        }
        return null;
    }
    input = () => {
        return (
            <InputTags
                { ...this.props }
                onChange={this.handleInputChange}
                defaultValue=""
                linkPreview
            />
            )
    }
    content = () => {
        return (
            <ModalDialog
                dark={this.props.fullScreen ? true : this.props.dark}
                show={this.props.show}
                handleClose={!this.props.saving ? this.props.cancelPost : null}
                fullScreen={this.props.fullScreen}
                fullWidth={true}
                maxWidth="sm"
                closeLabel="Cancel"
                saveLabel="Post"
                hideScroll={true}
                draggable={true}
                title={this.state.title}
                subTitle=""
                handleSave={!this.props.saving && this.handleSubmitPost}
                containedButton
                fullHeight
                solid
                disableButtons
                noPad
            >
                {this.props.saving ?
                    <Spin sub dark={this.props.dark} open={this.props.saving} message={this.props.statusMessage ?? ""} />
                    :
                    <Box
                        sx={{
                            background: this.props.dark ? "#222" : "#f0f0f0",
                            minWidth: { sm: 500 },
                            height: "100%",
                            borderTop: this.props.dark ? "1px solid #444" : "none",
                            py: 1,
                            px: 1,
                            maxHeight: { xs: "calc(100vh - 107px)", lg: "calc(100vh - 200px)" }
                        }}
                        className="prettyScroll"
                    >
                        {this.postButton()}
                        {(this.state.showPhotoDropZone || this.props.channel === 2) && this.dropZone()}
                        {(this.state.showVideoDropZone || this.props.channel === 3) && this.dropZoneVideo()}
                        {this.props.channel === 0 && this.categoryButton()}
                        {(this.props.channel === 0 || (this.state.newPostPhoto && this.state.newPostPhoto.length > 0) || (this.state.newPostVideo && this.state.newPostVideo.length > 0)) && this.input()}
                        {this.state.noText && <Alert sx={{ m: 0.5, ml: 2, mr: 2 }} severity="error">No Content was Provided</Alert>}
                        {(this.props.channel === 0 && this.state.selectedTextCategory === 0) && this.sources()}
                        {this.photoEditor()}
                    </Box>
                }
            </ModalDialog>
        );
    }

    render() {
        return this.content();
    }
}
