import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export class SectionGrid extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (     
            <Box key={this.props.ckey ?? Math.random()} sx={{ flexGrow: 1, pt: 0, px: { xs: 0, sm: 2 }, pb:2, height: this.props.height ? this.props.height : "calc(100vh - 120px)" }} className="prettyScroll">
                <Grid container spacing={this.props.spacing ?? 2} sx={{ marginTop: "0px!important" }}>
                    {this.props.children.length > 1 ? this.props.children.map((item, index) => {
                        return (
                            <Grid
           
                                item
                                xs={this.props.xs ?? 12}
                                sm={this.props.sm ?? this.props.xs ?? 6}
                                md={this.props.md ?? this.props.sm ?? this.props.xs ?? 6}
                                lg={this.props.lg ?? this.props.md ?? this.props.sm ?? this.props.xs ?? 6}
                                lg={this.props.xl ?? this.props.lg ?? this.props.md ?? this.props.sm ?? this.props.xs ?? 6}
                            >
                                {item}
                            </Grid>

                        )

                    }) :

                        <Grid
   
                            item
                            xs={this.props.xs ?? 12}
                            sm={this.props.sm ?? this.props.xs ?? 6}
                            md={this.props.md ?? this.props.sm ?? this.props.xs ?? 6}
                            lg={this.props.lg ?? this.props.md ?? this.props.sm ?? this.props.xs ?? 6}
                            lg={this.props.xl ?? this.props.lg ?? this.props.md ?? this.props.sm ?? this.props.xs ?? 6}
                        >
                            {this.props.children}
                        </Grid>
                        }

                </Grid>
            </Box> 
        );
    }
}
