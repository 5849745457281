import React, { Component } from 'react';
//mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
//other libraries
import Linkify from 'react-linkify';
import { MentionsInput, Mention } from 'react-mentions'
//helper funcs
const parseTags = (text) => {
    var regex = /(?:^|\s)(?:#)([a-zA-Z\d]+)/gm;
    var matches = [];
    var match;

    while ((match = regex.exec(text))) {
        matches.push(match[1]);
    }

    return matches;
}

export class InputTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            mentions: [],
            value: this.props.defaultValue ?? "",
            link: null,
            noText: false,
        };
    }
    //event handlers
    updateParent = (textValue, link, tags, mentions) => {
        this.props.onChange(textValue, link, tags, mentions);
    }
    extractTags = (val) => {
        let tags = parseTags(val);
        if (this.state.tags !== tags) {
            this.setState({ tags });
        }
        return tags;
    }
    extractLinks = () => {
        let link;

            var eles = document.querySelectorAll("div #linkContainer a");
            if (eles.length > 0) {
                if (eles[0].href) {
                    this.setState({ link: eles[0].href });
                    link =  eles[0].href;
                }
            }
        return link;    
    }
    handlePostCompose = (e) => {
        var val = e.target.value;
        var noText = true;
        if (val) noText = false; 
        this.setState({ value: val, noText: noText });
        setTimeout(() => {
        const tags = this.extractTags(val);
        const link = this.extractLinks();

        this.updateParent(val, link, tags, this.state.mentions)
        }, 500);
    }
    handleRemoveTag = (idx) => {
        const tag = this.state.tags[idx];
        let originalPost = this.state.value
        let tagStart = originalPost.indexOf("#" + tag);
        const finalString = originalPost.slice(0, tagStart) + originalPost.slice(tagStart + 1);
        var ele = document.getElementById("newPostTextContent");
        if (ele) {
            ele.value = finalString;
            var event = new Event('input', {
                bubbles: true,
                cancelable: true,
            });

            ele.dispatchEvent(event);
        }
    }
    addMention = (id, display, startPos, endPos) => {
        const mentions = [...this.state.mentions, { id: id, display: display }];
        this.setState({ mentions });
        this.updateParent(this.state.value, this.state.link, this.state.tags, mentions);

    }
    formatMention = (id, display) => {
        return `@${display}`;
    }
    handleRemoveMention = (idx) => {
        const mentions = [...this.state.mentions];
        mentions.splice(idx, 1);
        this.setState({ mentions });
        this.updateParent(this.state.value, this.state.link, this.state.tags, mentions);
    }

    //render funcs
    postLinks = () => {
        return (
            <Linkify>
                <div id="linkContainer" style={{ display: "none" }}>
                    {this.state.value}
                </div>
            </Linkify>
        )
    }
    tags = () => {
        return (
            <>
                <Typography variant="subtitle2" sx={{ paddingRight: "8px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto" }}>
                    Tags:
                </Typography>
                {this.state.tags.map((item, index) => {
                    return (
                        <Chip
                            key={"tag" + index}
                            label={item}
                            onDelete={() => this.handleRemoveTag(index)}
                            size="small"
                            color="secondary"
                            sx={{ marginRight: "6px", marginBottom: "6px" }}
                        />
                    )
                })}
                <br />
            </>
            )
    }
    mentions = () => {
        return (
            <>
                <Typography variant="subtitle2" sx={{ paddingRight: "8px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto" }}>
                    Mentions:
                </Typography>
                {this.state.mentions.map((item, index) => {
                    return (
                        <Chip
                            key={"mentions" + index}
                            label={item.display}
                            size="small"
                            color="secondary"
                            sx={{ marginRight: "6px", marginBottom: "6px" }}
                            onDelete={() => this.handleRemoveMention(index)}
                        />
                    )
                })}
            </>
            )
    }
    link = () => {
        return (
            <>
                <Typography variant="subtitle2" sx={{ paddingRight: "8px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black, marginTop: "auto", marginBottom: "auto" }}>
                    Link:
                </Typography>
                <Chip
                    key={"link0"}
                    label={this.state.link}
                    size="small"
                    color="secondary"
                    sx={{ marginRight: "6px", marginBottom: "6px" }}
                    onDelete={() => this.setState({ link: "" })}
                />

            </> 
            )
    }
    additionsContainer = () => {
        if (this.state.tags && this.state.tags.length > 0 || this.state.mentions && this.state.mentions.length > 0 || this.state.sources && this.state.sources.length > 0) {
            return (
                <Box display="flex" sx={{ mb: 1, flexWrap: 'wrap', padding: "8px", }}>
                    {(this.state.tags && this.state.tags.length > 0) && this.tags()}
                    {(this.state.mentions && this.state.mentions.length > 0) && this.mentions()}
                    {this.state.link && this.link()}
                </Box>
            )
        }
    }

    render() {
        return (
            <Box sx={{ m: 1, minHeight: this.props.channel === 0 && "100px", backgroundColor: this.props.dark ? '#050505' : '#fff', borderRadius: "8px", color: this.props.dark ? "#ccc" : "#444", }}>
                <MentionsInput
                    placeholder={this.props.channel === 0 ? "What would you like to post, " + this.props.user.firstName + "?" : "Add a comment or tags..."}
                    allowSpaceInQuery
                    allowSuggestionsAboveCursor
                    id="newPostTextContent"
                    value={this.state.mentionsValue}
                    onChange={(e) => this.setState({ mentionsValue: e.target.value })}
                    onInput={this.handlePostCompose}
                    className={this.props.dark ? "postTextDark" : "postText"}
                    rows={3}
                >
                    <Mention
                        trigger="@"
                        data={this.props.userList}
                        onAdd={this.addMention}
                        displayTransform={this.formatMention}
                        style={{ maxHeight: "200px" }}
                    />
                </MentionsInput>
           
                {this.additionsContainer()}
                {this.postLinks()}
            </Box>
        )
    }
}
