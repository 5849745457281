import React, { Component } from 'react';

//Custom Components
import { UserAvatar } from '../Utilities/UserAvatar'

//Database Services
import { getUserFollowers, getUsersFollowing } from '../../DataServices/UserService'
import { getMentionsByUser } from '../../DataServices/MentionsService'
import { getMyReactions } from '../../DataServices/ReactionService'
import { getMyComments } from '../../DataServices/CommentService'

//Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

//Helper Functions
const a11yProps = (index, id) => {
    return {
        id: `simple-tab-${index}-${id}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`affil-tabpanel-${index}`}
            aria-labelledby={`affil-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export class Activity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
        }
    }

    componentDidMount() {
        this.getFollowers();
        this.getFollowing();
        this.getMentions();
        this.getReactions();
        this.getComments();
    }

    //Get Data----------------------
    getFollowers = async () => {
        const data = await getUserFollowers();
        if (data && data !== 'error') {
            this.setState({ followers: data });
        }
    }
    getFollowing = async () => {
        const data = await getUsersFollowing();
        if (data && data !== 'error') {
            this.setState({
                following: data,
                followingLoaded: true
            });
        }
    }
    getMentions = async () => {
        const data = await getMentionsByUser(this.props.user.userID);
        if (data && data !== 'error') {
            this.setState({ mentions: data });
        }
    }
    getReactions = async () => {
        const data = await getMyReactions();
        if (data && data !== 'error') {
            this.setState({ reactions: data });
        }
    }
    getComments = async () => {
        const data = await getMyComments(0, 20);
        if (data && data !== 'error') {
            this.setState({ comments: data });
        }
    }

    //Event Handlers-----------------
    //Global
    handleTabChange = (event, newValue) => {
        this.setState({ tabValue: newValue });
    }

    //Render Funcs
    userAvatar = (user, hideName) => {
        return (
            <Box key={new Date().toUTCString() + user.userID} sx={{ padding:"16px"}}>
                <UserAvatar
                    id={user.userID}
                    user={user}
                    dark={this.props.dark}
                    loaded={this.state.followingLoaded}
                    activeUser={this.props.user}
                    appIdentifier={this.props.appIdentifier}
                    hideName={hideName}
                />
            </Box>
        )
    }
    tabContainer = () => {
        return (
            <Box sx={{ width: '100%', height: { xs: "calc(100vh - 108px)", sm: '100%' }, minHeight: "600px", background: this.props.dark ? "#050505" : "#eee" }} className={this.props.dark && "dark"} >
                <Box sx={{ borderBottom: this.props.dark && "1px solid #151515", background: theme => this.props.dark ? "#151515" : theme.palette.secondary.main, boxShadow: this.props.dark ? 0 : 8 }} elevation={this.props.dark ? 0 : 8}>
                    <Tabs variant="scrollable" scrollButtons="auto" sx={{ minHeight: "36px", maxWidth: "fit-content", mx: "auto" }} allowScrollButtonsMobile indicatorColor="secondary" value={this.state.tabValue} onChange={(e, newValue) => this.setState({ tabValue: newValue })} aria-label="content tabs">
                        <Tab label="Following" {...a11yProps(0)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        <Tab label="Followers" {...a11yProps(1)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        <Tab label="Mentions" {...a11yProps(2)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        <Tab label="Reactions" {...a11yProps(3)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        <Tab label="Comments" {...a11yProps(4)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                    </Tabs>
                </Box>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={0}>
                    {this.following()}
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={1}>
                    {this.followers()}
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={2}>
                    {this.mentions()}
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={3}>
                    {this.reactions()}
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={4}>
                    {this.comments()}
                </TabPanel>
            </Box>
        )
    }

    following = () => {
        if (this.state.following && this.state.following.length > 0) {
            return (
                <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" }  }} className="prettyScroll" >
                    <Box display="flex" sx={{ marginBottom: "8px" }}>
                        <Typography variant="subtitle1" sx={{  marginBottom: "8px", fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            Users I am following
                        </Typography>
                    </Box>
                    {this.state.following.map((item, index) => {
                        return this.userAvatar(item.user, false);
                    })}
                </Box>
            )
        }
        return (
            <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" } }} className="prettyScroll" >
                <Typography variant="subtitle1" sx={{ m: 3, fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                    You are not following any users..
                </Typography>
            </Box>

            )
    }
    followers = () => {
        if (this.state.followers && this.state.followers.length > 0) {
            return (
                <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" } }} className="prettyScroll" >
                    <Box display="flex" sx={{ marginBottom: "8px" }}>
                        <Typography variant="subtitle1" sx={{ marginBottom: "8px", fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            Users following me
                        </Typography>
                    </Box>
                    {this.state.followers.map((item, index) => {
                        return this.userAvatar(item.user, false);
                    })}
                </Box>
            )
        }
        return (
            <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" } }} className="prettyScroll" >
                <Typography variant="subtitle1" sx={{ m: 3, fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                    You have no followers.
                </Typography>
            </Box>

            )
    }
    mentions = () => {
        if (this.state.mentions && this.state.mentions.length > 0) {
            return (

                <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" } }} className="prettyScroll" >
                    <Box display="flex" sx={{ marginBottom: "8px" }}>
                        <Typography variant="subtitle1" sx={{ marginBottom: "8px", fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            Where I have been mentioned
                        </Typography>
                    </Box>
                    {this.state.mentions.map((item, index) => {
                        return (
                            <Box key={item.id} display="flex">
                                {this.userAvatar(item.mentioningUser, true)}
                                <Typography variant="body1" sx={{ marginTop: "auto", marginBottom: "auto", fontSize: ".9rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                    {item.mentioningUser.firstName + " " + item.mentioningUser.lastName + " mentioned you on " + new Date(item.createDate).toLocaleDateString()}
                                </Typography>
                            </Box>

                        )
                    })}
                </Box>
            )
        }
        return (
            <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" } }} className="prettyScroll" >
                <Typography variant="subtitle1" sx={{ m: 3, fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                    You have no mentions.
                </Typography>
            </Box>
            )
    }
    reactions = () => {
        if (this.state.reactions && this.state.reactions.length > 0) {
            return (
                <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" } }} className="prettyScroll" >
                    <Box display="flex" sx={{ marginBottom: "8px" }}>
                        <Typography variant="subtitle1" sx={{ marginBottom: "8px", fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            Reactions I have left
                        </Typography>
                    </Box>
                    {this.state.reactions.map((item, index) => {
                        let r = "liked";
                        if (item.reactionTypeID === 1)
                            r = "loved";
                        return (
                            <Box key={"rea" + index} sx={{ display: "inline-block", margin: "4px" }}>
                                <Card sx={{ maxWidth: 345, background: (theme) => this.props.dark && theme.palette.card.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }} raised elevation={this.props.dark ? 1 : 4}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {"You " + r + " a " + item.targetTypeDesc}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: ".8rem" }} color="text.secondary">
                                                {new Date(item.reactionDate).toLocaleDateString()}
                                            </Typography>
                                        </CardContent>

                                    </CardActionArea>
                                </Card>
                            </Box>
                        )
                    })}
                </Box>
            )
        }
        return (
            <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" } }} className="prettyScroll" >
                <Typography variant="subtitle1" sx={{ m: 3, fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                    You have not left any reactions.
                </Typography>
            </Box>
            )
    }
    comments = () => {
        if (this.state.comments && this.state.comments.length > 0) {
            return (
                <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" } }} className="prettyScroll" >
                    <Box display="flex" sx={{ marginBottom: "8px" }}>
                        <Typography variant="subtitle1" sx={{  marginBottom: "8px", fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            Comments I have left
                        </Typography>
                    </Box>
                    {this.state.comments.map((item, index) => {
                        return (
                            <Box key={"cmt" + index} sx={{ display: "inline-block", margin: "4px" }}>
                                <Card sx={{ maxWidth: 345, background: (theme) => this.props.dark && theme.palette.card.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }} raised elevation={this.props.dark ? 1 : 4}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {item.commentText}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: ".8rem" }} color="text.secondary">
                                                {new Date(item.commentDate).toLocaleDateString()}
                                            </Typography>
                                        </CardContent>

                                    </CardActionArea>
                                </Card>
                            </Box>
                        )
                    })}
                </Box>
            )
        }
        return (
            <Box sx={{ p: 3, height: { xs: "calc(100vh - 143px)", sm: "calc(100vh - 147px)" }  }} className="prettyScroll" >
                <Typography variant="subtitle1" sx={{ m: 3, fontSize: "1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                    You have not posted any comments.
                </Typography>
            </Box>
            )
    }

    render() {
        return this.tabContainer(); 
    }
}
