import React, { Component } from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

export class SelectList extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <FormControl
                className={this.props.dark && "dark"}
                key={"select-" + this.props.label}
                color="secondary" variant="standard"
                sx={{
                    width: this.props.width ?? "100%",
                    mt: this.props.center ? "auto" : 1,
                    mb: 0.5,
                    mr: this.props.marginRight ?? 0
                }}>
                {this.props.label &&
                    <InputLabel
                        id={"il" + this.props.label}
                        sx={{
                            color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack,
                            fontSize: ".8rem"
                        }}
                    >
                        {this.props.label}
                    </InputLabel>
                }
                <Select
                    variant="standard"
                    labelId={"il" + this.props.label}
                    value={this.props.value}
                    defaultValue={this.props.defaultValue && this.props.defaultValue}
                    onChange={(e) => this.props.onChange(e.target.value)}
                    sx={{
                        color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                        fontSize: ".8rem"
                    }}
                    inputProps={{
                        required: this.props.required,
                        id: "select-" + this.props.label,
                        style: {
                            color: this.props.dark ? "#bbb" : "#444", fontSize: ".8rem"
                        }
                    }}
                    MenuProps={{
                        sx: {
                            color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack,
                            fontSize: ".8rem",
                            maxHeight: "300px",
                            borderRadius: 1
                        },
                        MenuListProps: {
                            dense: true,
                            sx: {
                                backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundMenu.dark : theme.palette.backgroundMenu.light,
                                border: (theme) => this.props.dark ? theme.palette.borderMenu.dark : theme.palette.borderMenu.light,
                                color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack,
                                fontSize: ".8rem" 
                            }
                        }
                    }}
                >
                    {this.props.listItems.map((item, index) =>
                        <MenuItem key={item.label + index} value={item.value}>{item.label}</MenuItem>
                    )}
                    {this.props.allowNull &&
                        <MenuItem value={null}>
                            <em></em>
                        </MenuItem>
                    }
                </Select>
            </FormControl>

        );
    }
}
