import { newLibrary, editLibrary, deleteLibrary, deleteVideo, moveVideo } from '../DataServices/VideoService'

const itemFields = [
    {
        type: "text",
        label: "Title",
        fieldName:"title",
        validateOnBlur: false,
        maxLength: 100,
        required: true,
        value: "",
    },
];

const folderFields = [
    {
        type: "text",
        label: "Folder Name",
        fieldName: "name",
        defaultValue: "",
        maxLength: 100,
        required: true
    },
]

const functions = {
    createItem: null,
    editItem: null,
    deleteItem: deleteVideo,
    moveItem: moveVideo,
    createFolder: newLibrary,
    editFolder: editLibrary,
    deleteFolder: deleteLibrary,
}
const options = {
    notify: true,
    noItemClick: true,
    edit: false,
    delete: true,
    share: true,
    post: true,
    copy: true
}
const videoProperties = {
    itemFields: itemFields,
    folderFields: folderFields,
    functions: functions,
    options: options
}

export default videoProperties;

