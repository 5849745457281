import React, { PureComponent } from 'react';

//Custom Components
import { Section } from '../Utilities/Section';
import { Spin } from '../Utilities/Spin';
import { PostsContainer } from '../Posts/PostsContainer';

//Material UI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

export class UserSiteHome extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            videoStreamBase: "https://mylivesphere.com/api/videostream/",
            musicStreamBase: "https://mylivesphere.com/api/musicstream/",
        }
    }

    //Render funcs
    home = () => {
        let content = this.props.loading
            ?
            <Spin dark={this.props.dark} sub />
            :
            <Stack spacing={2} >
                {this.props.bio && this.bio()}
                {this.posts()}          
            </Stack>
        return content;
    }
    bio = () => {
        if (this.props.bio) {
            return (
                <Section dark={this.props.dark} title="Bio">
                    <Box display="flex" sx={{ maxWidth: "1000px", m: "auto", px:1,color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack, }}>
        
                            <div dangerouslySetInnerHTML={{ __html: this.props.bio }} style={{ textAlign:"center",width: "100%", fontSize:".85rem", fontStyle:"italic" }} />
             
                    </Box>
                </Section>
            )
        }
        return null;
    }
    avatar = () => {
        return (
            <Avatar
                className="avatarImageColossel"
                src={"data:image/png;base64, " + this.props.photo}
                imgProps={{ style: { cursor: "pointer", borderRadius: "8px" } }}
                variant="square"
            />
        )
    }
    posts = () => {
        return (
            <Section dark={this.props.dark} noPad title={this.props.siteOwner ? this.props.siteOwner.firstName + "'s Posts" : "Posts"}>
                <PostsContainer
                    dark={this.props.dark}
                    channel={-1}
                    posts={this.props.posts}
                    loaded={this.props.postsloaded}
                    user={this.props.user}
                    handleDeletePost={this.handleDeletePost}
                    handleEditPost={this.handleEditPost}
                    handleSharePost={this.handleSharePost}
                    handleHidePost={this.handleHidePost}
                    menuOpen={this.toggleMenu}
                    updatePostContent={this.props.updatePostContent}
                    updateRepostContent={this.props.updateRepostContent}
                    appIdentifier={this.props.appIdentifier ?? "LS"}
                    updateLastPost={this.props.updateLastPost}
                    updatePosts={this.props.updatePosts}
                    setSelectedPost={this.props.setSelectedPost}
                    userList={this.props.userList}
                    getMorePosts={this.props.getMorePosts}
                    grid
                    site
                    addDivider={this.props.width < 600 ? true : false}
                />
            </Section>
            )
    }

    //Main component render
    render() {
        return this.home();
    }
}

