// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        //root: {
        //  boxShadow: theme.customShadows.z1,
        //  borderRadius: theme.shape.borderRadius,
        //  position: 'relative',
        //  zIndex: 0 // Fix Safari overflow: hidden with border radius
        //}
            //root: {
            //    '&:hover': {
            //        background:"unset"
            //    }
            //},
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' }
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 1, 0)
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1)
        }
        },
      },
      //MuiCardActionArea: {
      //    styleOverrides: {
      //        root: {
      //            '&:hover': { opactity: .7}
      //        }
      //    }
      //}
  };
}
