import React, { Component } from 'react';

import { Box, Stack, Tooltip, Typography } from '@mui/material'

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTiktok, faSpotify, faYoutube, faAmazon } from '@fortawesome/free-brands-svg-icons'

export class Socials extends Component {
    constructor(props) {
        super(props);
    }
    spacer = (y) => {
        return (<Box sx={{ height: { xs: 0, sm: y } }} />)
    }

    icon = (brand) => {
        switch (brand) {
            case "facebook":
                return faFacebook;
            case "instagram":
                return faInstagram;
            case "tiktok":
                return faTiktok;
            case "spotify":
                return faSpotify;
            case "amazon music":
                return faAmazon;
            case "youtube":
                return faYoutube;
            default:
                return null;
        }
    }
    content = () => {
        return (
            <Box display="flex" sx={{ background: this.props.socials.background, p:4, }} >
                <Stack sx={{ width: "100%", maxWidth: "1440px", margin: "auto" }}>
                    {this.props.socials.title &&
                        <Typography variant={this.props.compact ? "h3" : "h2"} sx={{ color: this.props.socials.titleColor, textAlign: "center", marginBottom: this.props.compact ? 2 : 5 }}>
                            {this.props.socials.title}
                        </Typography>
                    }
                    {this.props.socials.subtitle &&
                        <Typography variant="h5" sx={{ fontWeight: 500, marginLeft: "auto", marginRight: "auto", maxWidth: "1200px", color: this.props.socials.titleColor, textAlign: "center", marginBottom: 7 }}>
                            {this.props.socials.subtitle}
                        </Typography>
                    }
                    {this.spacer(24)}
                    <Box display="-webkit-inline-box" sx={{ m: "auto", lineHeight: 4, textAlign: "center" }}>
                        {this.props.socials.items.map(item => {
                            return (
                                <Tooltip key={"k" + item.primary} title={item.primary} >
                                    <a href={item.secondary} target="_blank" style={{ margin: "24px", color: this.props.socials.subtitleColor }}>
                                        <FontAwesomeIcon icon={this.icon(item.primary.toLowerCase())} style={{ fontSize: "32px", }} />
                                    </a>
                                </Tooltip>
                                )
                        })}
                    </Box>

                </Stack>
            </Box>
        )
    }
 

    render() {
        return this.content();
    }
}

