import React, { Component } from 'react';

//Materiual UI
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

//Database Services
import { createNotification } from '../../DataServices/NotificationService'
import { addReaction } from '../../DataServices/ReactionService'


export class Reactions extends Component {
  constructor(props) {
      super(props);
      this.state = {
          likeCount: this.props.likeCount,
          myReaction: this.props.myReaction,
      };
  }
  
    handleLikeMessage = async (e) => {
        if (this.state.myReaction === 0) {
            this.setState({
                myReaction: -1,
                likeCount: (this.state.likeCount - 1)
            });
        }
        else {
            if (this.state.myReaction === 1) {
                this.setState({
                    loveCount: (this.state.loveCount - 1)
                });
            }

            this.setState({
                myReaction: 0,
                likeCount: (this.state.likeCount + 1)
            });
            let message = (this.props.user.displayName ? this.props.user.displayName : "A user") + " liked your  " + this.props.targetTypeDesc;
            if (this.props.refUser.userID === this.props.user.userID) {
                message = "You loved your own " + this.props.targetTypeDesc;
            }
            await createNotification(this.props.refUser.userID, this.props.targetType, this.props.targetID, this.props.user.userID, message, this.props.appIdentifier);
        }

        await addReaction(this.props.targetID, this.props.targetType, 0);
    }

    tooltip = () => {
        if (this.state.likeCount >= 0) {
            const totalReactions = parseInt(this.state.likeCount);
            let suffix = " likes";
            if (totalReactions === 0) {
                return "No likes yet";
            }
            if (totalReactions === 1) {
                suffix = " like";
            }
            return totalReactions + suffix;
        }
        return "Likes"
    }
    render() {
        return (
            <Box
                sx={{
                    display: this.props.inline ? "inline" : { xs: 'flex' }
                }}
            >
                <Tooltip title={this.tooltip()}>
                    <Box
                        sx={{
                            py: (this.props.dark || this.props.noBack) ? (this.props.small ? 0 : 0.5) : 0.5,
                            px: (this.props.dark || this.props.noBack) ? (this.props.small ? 0 : 0.5) : (this.props.small ? 0 : 1.5),
                            br:  this.props.small ? 0 : 2.5,
                            backgroundColor: (theme) => this.props.dark ? (this.props.noColor ? "transparent" : theme.palette.reactionContainer.dark) : "transparent"
                        }}
                    >
                        <IconButton
                            aria-label="like"
                            id={"like_" + this.props.targetID + "_" + this.props.idx}
                            onClick={this.handleLikeMessage}
                            sx={{
                                fontSize: this.props.small ? "12px!important" : "14px!important",
                                padding: "6px 4px",
                                color: (theme) => this.props.dark ?
                                    (this.props.noColor ? theme.palette.common.white : theme.palette.secondary.main) 
                                    :
                                    (this.state.myReaction === 0 ? (this.props.noColor ? theme.palette.common.black : theme.palette.secondary.main) : theme.palette.common.black )
                            }}
                        >
                            {this.state.myReaction === 0
                                ?
                                <ThumbUpIcon sx={{ fontSize: "14px" }} />
                                :
                                <ThumbUpOutlinedIcon sx={{ fontSize: this.props.small ? "12px!important" : "14px!important", }} />
                            }
                        </IconButton>
                    </Box>
                </Tooltip>
            </Box>
        );
    }
}
