
export async function getAlbums() {
    const response = await fetch("music/getalbums");
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getPlaylists() {
    const response = await fetch("music/getplaylists");
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getArtists() {
    const response = await fetch("music/getartists");
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getSongs() {
    const response = await fetch("music/getsongs");
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getGenres() {
    const response = await fetch("music/getgenres");
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getAlbum(id) {
    const response = await fetch("music/getalbum?id=" + id);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function getPlaylist(id) {
    const response = await fetch("music/getplaylist?id=" + id);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getArtist(id) {
    const response = await fetch("music/getartist?id=" + id);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getSong(id) {
    const response = await fetch("music/getsong?id=" + id);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function createArtist(name, origin, ownerid, genre, photoid) {
    const req = {
        Name: name,
        Origin: origin,
        OwnerID: ownerid,
        GenreID: genre,
        PhotoID: photoid,
    };

    const response = await fetch("music/createartist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function editArtist(id, name, origin, genre, photoid) {
    const req = {
        ID: id,
        Name: name,
        Origin: origin,
        GenreID: genre,
        PhotoID: photoid,
    };

    const response = await fetch("music/editartist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function deleteArtist(id) {
    const req = {
        ID: id,
    };

    const response = await fetch("music/deleteartist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function createAlbum(name, artistid, genreid, label, ownerid, releasedate, statusid, typeid, photoid) {
    const req = {
        Name: name,
        ArtistID: artistid,
        GenreID: genreid,
        Label: label,
        ReleaseDate: releasedate,
        StatusID: statusid,
        TypeID: typeid,
        Origin: origin,
        OwnerID: ownerid,
        PhotoID: photoid,
    };

    const response = await fetch("music/createalbum", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function editAlbum(id, name, artistid, genreid, label, releasedate, statusid, typeid, photoid) {
    const req = {
        ID: id,
        Name: name,
        ArtistID: artistid,
        GenreID: genreid,
        Label: label,
        ReleaseDate: releasedate,
        StatusID: statusid,
        TypeID: typeid,
        Origin: origin,
        PhotoID: photoid,
    };

    const response = await fetch("music/editalbum", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function deleteAlbum(id) {
    const req = {
        ID: id,
    };

    const response = await fetch("music/deletealbum", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function createPlaylist(name, artistid, genreid, desc,  statusid, isprivate, photoid, ownerid) {
    const req = {
        Name: name,
        Desc: desc,
        ArtistID: artistid,
        GenreID: genreid,
        StatusID: statusid,
        Private: isprivate,
        Origin: origin,
        PhotoID: photoid,
        OwnerID: ownerid
    };

    const response = await fetch("music/createplaylist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function editPlaylist(id, name, artistid, genreid, desc, statusid, isprivate, photoid) {
    const req = {
        ID: id,
        Name: name,
        Desc: desc,
        ArtistID: artistid,
        GenreID: genreid,
        StatusID: statusid,
        Private: isprivate,
        Origin: origin,
        PhotoID: photoid,
    };

    const response = await fetch("music/editplaylist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function deletePlaylist(id) {
    const req = {
        ID: id,
    };

    const response = await fetch("music/deleteplaylist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function createSong(name, artistid, albumid, genreid, desc, releasedate, format, lyrics, statusid, photoid, content, ownerid) {
    let req;
    if (content) {
        req = {
            Name: name,
            Desc: desc,
            ArtistID: artistid,
            AlbumID: albumid,
            ReleaseDate: releasedate,
            Format: format,
            Lyrics: lyrics,
            GenreID: genreid,
            StatusID: statusid,
            PhotoID: photoid,
            BinaryContent: content,
            OwnerID: ownerid
        };
    }
    else {
        req = {
            Name: name,
            Desc: desc,
            ArtistID: artistid,
            AlbumID: albumid,
            ReleaseDate: releasedate,
            Format: format,
            Lyrics: lyrics,
            GenreID: genreid,
            StatusID: statusid,
            PhotoID: photoid,
            OwnerID: ownerid
        };
    }

    console.log(req);
    const response = await fetch("music/createsong", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function editSong(id, name, artistid, albumid, genreid, desc, releasedate, format, lyrics, statusid) {
    const req = {
        ID: id,
        Name: name,
        Desc: desc,
        ArtistID: artistid,
        AlbumID: albumid,
        ReleaseDate: releasedate,
        Format: format,
        Lyrics: lyrics,
        GenreID: genreid,
        StatusID: statusid,
    };
    console.log(req);
    const response = await fetch("music/editsong", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function updateSongImage(id, photoid) {
    const req = {
        ID: id,
        PhotoID: photoid,
    };

    const response = await fetch("music/updatesongimage", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function updateSongContent(id, file) {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);


    const response = await fetch("music/updatesongcontent", {
        method: 'POST',
        body: formData
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function deleteSong(id) {
    const req = {
        ID: id,
    };

    const response = await fetch("music/deletesong", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function addSongToPlaylist(songid, playlistid) {
    const req = {
        SongID: songid,
        PlaylistID: playlistid
    };

    const response = await fetch("music/addsongtoplaylist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function removeSongFromPlaylist(songid, playlistid) {
    const req = {
        SongID: songid,
        PlaylistID: playlistid
    };

    const response = await fetch("music/removesongfromplaylist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function moveSongInPlaylist(songid, playlistid, idx) {
    const req = {
        SongID: songid,
        PlaylistID: playlistid,
        Idx: idx
    };

    const response = await fetch("music/movesonginplaylist", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function addSongToAlbum(songid, albumid) {
    const req = {
        SongID: songid,
        AlbumID: albumid
    };

    const response = await fetch("music/addsongtoalbum", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function removeSongFromAlbum(songid, albumid) {
    const req = {
        SongID: songid,
        AlbumID: albumid
    };

    const response = await fetch("music/removesongfromalbum", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function moveSongInAlbum(songid, albumid, idx) {
    const req = {
        SongID: songid,
        AlbumID: albumid,
        Idx: idx
    };

    const response = await fetch("music/movesonginalbum", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}
export async function recordPlay(songid) {
    const req = {
        SongID: songid,
    };

    const response = await fetch("music/recordplay", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return false;
}