import { createLink, deleteLink, moveLink, editLink, createFolder, deleteFolder, editFolder } from '../DataServices/LinkService';

const itemFields = [
    {
        type: "text",
        label: "URL",
        fieldName:"href",
        validateOnBlur: true,
        format: 'url',
        maxLength: 500,
        required: true,
        value: "",
        helpText: "Provide a valid url including http or https"
    },
    {
        type: "text",
        label: "Description",
        fieldName:"title",
        maxLength: 100,
        required: true,
        value: "",

    },
];

const folderFields = [
    {
        type: "text",
        label: "Folder Name",
        fieldName: "name",
        value: "",
        maxLength: 100,
        required: true
    },
]

const functions = {
    createItem: createLink,
    editItem: editLink,
    deleteItem: deleteLink,
    moveItem: moveLink,
    createFolder: createFolder,
    editFolder: editFolder,
    deleteFolder: deleteFolder,
}
const options = {
    notify: true,
    noItemClick: true,
    edit: true,
    delete: true,
    share: true,
    post: true,
    copy: true
}
const linkProperties = {
    itemFields: itemFields,
    folderFields: folderFields,
    functions: functions,
    options: options
}

export default linkProperties;

