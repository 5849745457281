import { useState } from 'react';

async function CheckForTokens(appid) {
    const response = await fetch('home/checkfortokens?appid=' + appid);

    if (response.status !== 200) {
        return "";
    }
    else {
        const data = await response.json();
        if (!data || data === null || data === "NoToken") {
            return "";
        }
        return data.accessToken;

    }
}

export default function useToken(appid) {
    const getToken = () => {
        return { token: CheckForTokens(appid) };
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
}

