import React, { PureComponent } from 'react';

//Custom Components
import { ModalDialog } from '../Utilities/ModalDialog'
import { ContextMenu } from '../Utilities/ContextMenu'
import { TextInput } from '../Utilities/TextInput'
import { SelectList } from '../Utilities/SelectList'
import { DocumentViewer } from '../Content/Documents/DocumentViewer';
import { DocumentUploader } from '../Content/Documents/DocumentUploader';
import { Spin } from '../Utilities/Spin'
import { FolderLabel } from '../Utilities/FolderLabel' 
//Database Services
import { getDocument, newOwnerDocument, deleteDocument, renameDocument, newOwnerFolder, deleteFolder, renameFolder, getDocumentFolders } from '../../DataServices/DocumentService'


//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFilePdf } from '@fortawesome/free-solid-svg-icons'

//Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const docTypeList = () => {
    return [{ value: 0, label: Document },];
}
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`docs-tabpanel-${index}`}
            aria-labelledby={`docs-tab-${index}`}
            style={{ minWidth: "130px" }}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export class ClientDocuments extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showDocument: false,
            selectedDocument: null,
            showAddDocuments: false,
            contextMenuDocument: null,
            showConfirmDeleteDocument: false,
            contextMenuDocumentItems: [
                {
                    callBack: this.handleShowRenameDocument,
                    label: "Rename"
                },
                {
                    callBack: this.handleShowConfirmDeleteDocument,
                    label: "Delete"
                },
            ],
            showRenameDocument: false,
            documentFolders: [],
            folderMode: "new",
            newFolderName: "",
            showNewFolder: false,
            showRenameFolder: false,
            contextMenuFolder: null,
            showConfirmDeleteFolder: false,
            contextMenuFolderItems: [
                {
                    callBack: this.handleShowRenameFolder,
                    label: "Rename"
                },
                {
                    callBack: this.handleShowConfirmDeleteFolder,
                    label: "Delete"
                },
            ],
            tabValue: 0,
            loadingDocument: false

        }
    }
    componentDidMount() {
        this.getDocuments();
    }
    getDocuments = async () => {

        const parentID = this.props.clientID ?? "";
        const data = await getDocumentFolders(parentID, this.props.appIdentifier);
        if (data === 'error') {
            return;
        }

        this.setState({
            documentFolders: data,
        })
        if (data && data.length > 0) {
            this.setState({ tabValue: 1 });
        }
    }

    //Event Handlers
    handleClickDocument = async (e, id, idx) => {
        this.setState({loadingDocument: true})
        let ele = e.target;
        ele.style.cursor = 'wait';
        document.body.style.cursor = 'wait';

        const doc = await getDocument(id);
        console.log(doc);
        ele.style.cursor = 'default';
        document.body.style.cursor = 'default';

        if (doc && doc.eFiles && doc.eFiles.length > 0 && doc.eFiles[0].fileContent) {
            this.setState({
                selectedDocumentName: doc.documentName,
                selectedDocumentContent: doc.eFiles[0].fileContent,
                showDocument: true,
                loadingDocument: false
            });
        }

    }
    handleClickNewDocument = () => {
        this.setState({ showAddDocuments: true });
    }
    handleNewDocument = async (file) => {

        const doc = await newOwnerDocument(file, this.props.clientID, this.state.documentFolders[this.state.tabValue - 1].id, this.props.appIdentifier);

        if (doc) {
            const documentFolders = [...this.state.documentFolders];
            documentFolders[this.state.tabValue - 1].documents.push(doc);
            this.setState({ documentFolders });
        }

        this.setState({ showAddDocuments: false });

        document.body.style.cursor = 'wait';
        const newDoc = await getDocument(doc.documentID);
        if (newDoc) {
            this.setState({
                selectedDocument: newDoc,
                showDocument: true
            });
        }

        document.body.style.cursor = 'default';
    }
    handleShowConfirmDeleteDocument = (id, idx) => {
        this.setState({
            selectedDocumentID: id,
            selectedDocumentIDX: idx,
            showConfirmDeleteDocument: true,
            contextMenuDocument: null
        });
    }
    handleContextMenuDocument = (e, id, idx) => {
        e.preventDefault();
        if (this.state.contextMenuDocument === null) {
            this.setState({
                contextMenuDocument: {
                    mouseX: e.clientX - 2,
                    mouseY: e.clientY - 4,
                },
                selectedDocument: this.state.documentFolders[this.state.tabValue - 1].documents[idx],
                selectedDocumentID: id,
                selectedDocumentIDX: idx,
            })
        }
        else {
            this.setState({
                contextMenuDocument: null,
            });
        }

    }
    handleDeleteDocument = async () => {
        const result = await deleteDocument(this.state.selectedDocumentID);
        if (result) {
            const documentFolders = [...this.state.documentFolders];
            documentFolders[this.state.tabValue - 1].documents.splice(this.state.selectedDocumentIDX, 1);
            this.setState({ documentFolders });

            this.setState({ showConfirmDeleteDocument: false });

        }
    }
    handleShowRenameDocument = () => {
        this.setState({ showRenameDocument: true, contextMenuDocument: null });
    }
    handleRenameDocument = async () => {
        var ele = document.getElementById("newDocumentName");
        if (ele) {
            const name = ele.value;
            if (name) {
                const result = await renameDocument(this.state.selectedDocumentID, name);
                if (result) {

                    const documentFolders = [...this.state.documentFolders];
                    documentFolders[this.state.tabValue - 1].documents[this.state.selectedDocumentIDX].documentName = name;
                    this.setState({ documentFolders, showRenameDocument: false });
                }
            }
            ele.value = "";
        }
    }
    handleShowNewFolder = () => {
        this.setState({
            showNewFolder: true,
            newFolderName: "",
            contextMenuFolder: null
        });
    }
    handleContextMenuFolder = (e, id, idx) => {
        e.preventDefault();
        if (this.state.contextMenuFolder === null) {
            this.setState({
                contextMenuFolder: {
                    mouseX: e.clientX - 2,
                    mouseY: e.clientY - 4,
                },
                selectedFolder: this.state.documentFolders[idx],
                selectedFolderID: id,
                selectedFolderIDX: idx,
            })
            this.setState({ tabValue: idx + 1 });
        }
        else {
            this.setState({
                contextMenuFolder: null,
            });
        }

    }
    handleNewFolder = async () => {
        const parentID = this.props.clientID ?? "";
        var ele = document.getElementById("newfolder");
        if (ele) {
            this.setState({ showNewFolder: false });
            const name = ele.value;
            if (name) {
                const folder = await newOwnerFolder(name, parentID, parentID, this.props.appIdentifier);
                if (folder) {

                    const documentFolders = [...this.state.documentFolders];
                    documentFolders.push(folder);
                    this.setState({ documentFolders });
                }
            }
            ele.value = "";
        }
    }
    handleShowConfirmDeleteFolder = () => {
        this.setState({
            showConfirmDeleteFolder: true,
            contextMenuFolder: null
        });
    }
    handleDeleteFolder = async () => {
        this.setState({ showConfirmDeleteFolder: false });
        const documentFolders = [...this.state.documentFolders];
        documentFolders.splice(this.state.selectedFolderIDX, 1);
        this.setState({ documentFolders });

        await deleteFolder(this.state.selectedFolderID);
    }
    handleShowRenameFolder = () => {
        this.setState({
            showRenameFolder: true,
            newFolderName: this.state.selectedFolder.name,
            contextMenuFolder: null
        });
    }
    handleRenameFolder = async () => {
        var ele = document.getElementById("editfolder");
        if (ele) {
            this.setState({ showRenameFolder: false });
            const name = ele.value;
            if (name) {
                const result = await renameFolder(this.state.selectedFolderID, name);
                if (result) {
                    const documentFolders = [...this.state.documentFolders];
                    documentFolders[this.state.tabValue - 1].name = name;
                    this.setState({ documentFolders });
                }
            }
            ele.value = "";
        }
    }
    handleShowComposer = async (e, doc) => {
        this.setState({
            selectedDocument: doc,
            showPostComposer: true
        })
    }

    //Render functions
    folderLabel = (label, closed) => {
        return (
            <Box display="flex" sx={{ width: "100%", textAlign: "left" }}>
                <Typography variant="body1" sx={{ fontSize: ".9rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                    {label}
                </Typography>
            </Box>

        )
    }
    Documents = () => {
        return (
            <Box display="block" sx={{ padding: "0px", width: "100%", height: "100%", display: 'flex', flexGrow: 1, background: (theme) => this.props.dark ? "#050505" : theme.palette.component3.light, }} className="prettyScroll">
                <Tabs
                    indicatorColor="secondary"
                    value={this.state.tabValue}
                    onChange={(e, newValue) => this.setState({ tabValue: newValue })}
                    aria-label="content tabs"
                    orientation="vertical"
                    variant="scrollable"
                    sx={{ minWidth: "175px", width: "25%", borderRight: 1, borderColor: 'divider', background: this.props.dark ? "unset" : "#f5f5f5", height: "calc(100vh - 105px)" }}
                >

                    <Box sx={{ display: "flex", padding: "12px" }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500, paddingRight:"8px", marginTop: "auto", marginBottom: "auto", fontSize: "1.1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                            Folders
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton size="small" color="secondary" key="newnotebook" aria-label="newdocfolder" onClick={() => this.setState({ showNewFolder: true })} sx={{ boxShadow: this.props.dark && "none", }}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    {(this.state.documentFolders && this.state.documentFolders.length > 0) &&
                        this.state.documentFolders.map((item, index) => {
                            if (!item.id.includes("shared!")) {
                                return (
                                    <Tab
                                        onContextMenu={(e) => this.handleContextMenuFolder(e, item.id, index)}
                                        key={item.id}
                                        label={<FolderLabel dark={this.props.dark} label={item.name} isUserFolder={false} isClosed={(this.state.tabValue - 1) !== index} /> }
                                        sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}
                                    />
                                )
                            }
                            return null;
                        })
                    }
                </Tabs>
                {(this.state.documentFolders && this.state.documentFolders.length > 0) &&
                    this.state.documentFolders.map((item, index) => {
                        if (!item.id.includes("shared!")) {
                            return (
                                <TabPanel key={item.id + 'tabpanel'} style={{ display: "contents", width: "100%", }} value={this.state.tabValue} index={index + 1}>
                                    <Box sx={{ width: "100%", padding: "12px", display: "block", height: "calc(100vh - 105px)" }} className="prettyScroll" >
                                        <Box display="flex" sx={{ marginBottom: "12px" }}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 500, marginTop: "auto", marginBottom: "auto", fontSize: "1.1rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                                                {item.name}
                                            </Typography>
                                            <Box sx={{ flexGrow: 1 }} />
                                            <IconButton
                                                size="medium"
                                                color="secondary"
                                                aria-label="newdoc"
                                                onClick={this.handleClickNewDocument}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                        <Grid container spacing={{ xs: 1.5, md: 2 }} columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 6 }} >
                                            {item.documents && item.documents.map((item2, index2) => {
                                                return (
                                                    <Grid item key={"doc" + item2.documentID} xs={1} sm={1} md={1} lg={1} xl={1} sx={{
                                                        transition: "transform .2s",
                                                        "&:hover": { transform: "scale(1.05)" }
                                                    }}>
                                                        {this.docCard(item2, index2)}
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Box>

                                </TabPanel>
                            )
                        }
                        return null;
                    })}
            </Box>
        )

    }
    docCard = (doc, index) => {
        return (
            <Card sx={{background: (theme) => this.props.dark && theme.palette.card.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }} raised elevation={this.props.dark ? 1 : 4}>
                <CardActionArea onClick={(e) => this.handleClickDocument(e, doc.documentID, index)}>
                    <CardContent sx={{  }}>
                        <Box display="flex" >
                            <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "42px", color: 'rgb(50,71,140)', }} />
                            <Stack sx={{ marginLeft: "8px" }}>
                                <Typography gutterBottom variant="subtitle1" sx={{ fontSize: ".9rem", marginBottom: 0 }} component="div">
                                    {doc.documentName ? doc.documentName.substr(0, 30) : "Document"}
                                </Typography>
                                <Typography variant="body2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                    {new Date(doc.createDate).toLocaleDateString()}
                                </Typography>
                            </Stack>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
    docUploader = () => {
        if (this.state.documentFolders) {
            return (
                <DocumentUploader
                    dark={this.props.dark}
                    multi={true}
                    open={this.state.showAddDocuments}
                    handleClose={() => this.setState({ showAddDocuments: false })}
                    handleSave={this.handleNewDocument}
                />
            )
        }
        return null;
    }
    docPreview = () => {
        if (this.state.selectedDocumentContent && this.state.showDocument) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showDocument}
                    handleClose={() => this.setState({ showDocument: false })}
                    fullWidth={true}
                    disableButtons
                    hideScroll={true}
                    draggable={true}
                    noUpperClose
                    maxWidth="xl"
                    fullScreen={false}
                    closeLabel="Close"
                    noBodyPad
                    
                >
                    <DocumentViewer
                        dark={this.props.dark}
                        fileName={this.state.selectedDocumentName}
                        document={this.state.selectedDocumentContent}
                        scale={1.6}
                        handleClose={() => this.setState({ showDocument: false })}
                        fullHeight
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    documentDeleteConfirmation = () => {
        return (
            <ModalDialog show={this.state.showConfirmDeleteDocument}
                dark={this.props.dark}
                title="Confirm Deletion"
                subTitle={"Document will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeleteDocument: false })}
                handleDelete={this.handleDeleteDocument}
                fullWidth={false}
                maxWidth="sm"
            />
        )
    }
    documentContextMenu = () => {
        return (
            <ContextMenu show={this.state.contextMenuDocument !== null}
                dark={this.props.dark}
                onClose={() => this.setState({ contextMenuDocument: null })}
                contextMenu={this.state.contextMenuDocument}
                menuItems={this.state.contextMenuDocumentItems}
                id="Documentctx" />
        )
    }
    documentEditor = () => {
        if (this.state.selectedDocument) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showRenameDocument}
                    title="Rename Document"
                    handleClose={() => this.setState({ showRenameDocument: false })}
                    handleSave={this.handleRenameDocument}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <Box sx={{ background: this.props.dark ? "#151515" : "#eaeaea", borderRadius: "6px", padding: "8px" }}>
                        <TextInput
                            dark={this.props.dark}
                            cKey="CnewDocumentName"
                            cid="CnewDocumentName"
                            id="newDocumentName"
                            label="Document Name"
                            defaultValue={this.state.selectedDocument.documentName}
                        />
                    </Box>
                </ModalDialog>
            )
        }
        return null;
    }
    folderCreator = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showNewFolder}
                title="New Folder"
                handleClose={() => this.setState({ showNewFolder: false })}
                handleSave={this.handleNewFolder}
                fullWidth={true}
                maxWidth="sm"
            >
                <Box sx={{ background: this.props.dark ? "#151515" : "#eaeaea", borderRadius: "6px", padding: "8px" }}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="Cnewfolder"
                        cid="Cnewfolder"
                        id="newfolder"
                        label="Folder Name"
                        defaultValue={this.state.newFolderName}
                    />
                </Box>
            </ModalDialog>
        )
    }
    folderEditor = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showRenameFolder}
                title="Rename Folder"
                handleClose={() => this.setState({ showRenameFolder: false })}
                handleSave={this.handleRenameFolder}
                fullWidth={true}
                maxWidth="sm"
            >
                <Box sx={{ background: this.props.dark ? "#151515" : "#eaeaea", borderRadius: "6px", padding: "8px" }}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="Ceditfolder"
                        cid="Ceditfolder"
                        id="editfolder"
                        label="Folder Name"
                        defaultValue={this.state.newFolderName}

                    />
                    {(this.state.folderMode === "edit" && this.props.entities && this.props.entities.length > 0) &&
                        <SelectList
                            dark={this.props.dark}
                            key="dtype"
                            label="Document Category"
                            value={this.state.defaultEntity}
                            onChange={(e) => this.setState({ defaultEntity: e.target.value })}
                            listItems={docTypeList()}
                        />
                    }
                </Box>
            </ModalDialog>
        )
    }
    folderDeleter = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showConfirmDeleteFolder}
                title="Confirm Deletion"
                subTitle={"Folder will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeleteFolder: false })}
                handleDelete={this.handleDeleteFolder}
                fullWidth={false}
                maxWidth="sm" />
        )
    }
    folderContextMenu = () => {
        return (
            <ContextMenu show={this.state.contextMenuFolder !== null}
                dark={this.props.dark}
                onClose={() => this.setState({ contextMenuFolder: null })}
                contextMenu={this.state.contextMenuFolder}
                menuItems={this.state.contextMenuFolderItems}
                id="Folderctx" />
        )
    }
    content = () => {
        if (this.state.loadingDocument) {
            return (
                <Spin dark={this.props.dark} open={this.state.loadingDocument} message="Loading Document.  This may take a moment..." />
            )
        }
        return (
            <React.StrictMode>
                {this.Documents()}
                {this.state.selectedDocumentContent && this.docPreview()}
                {this.docUploader()}
                {this.documentDeleteConfirmation()}
                {this.documentContextMenu()}
                {this.documentEditor()}
                {this.folderCreator()}
                {this.folderDeleter()}
                {this.folderContextMenu()}
                {this.folderEditor()}

            </React.StrictMode>
        ) 
    }


    render() {
        return this.content();
    }
}
