import React, { Component } from 'react';

//Custom Components
import { UserAvatar } from '../Utilities/UserAvatar'

//Material UI
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Typography from '@mui/material/Typography';


export class Message extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    //event handlers
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    handleDelete = (id, idx) => {
        this.handleClose();
        this.props.handleDelete(id, idx);
    }
    handleEdit = (id, idx) => {
        this.handleClose();
        this.props.handleEdit(id, idx);
    }
    userAvatar = (me) => {
        return (
            <ListItemIcon sx={{ color: this.props.dark ? "#ccc" : "#444", marginLeft: me ? "16px" : "0px", marginRight: me ? "0px" : "16px" }}>
                <UserAvatar
                    id={this.props.message.user.userID}
                    user={this.props.message.user}
                    dark={this.props.dark}
                    loaded={true}
                    activeUser={this.props.user}
                    appIdentifier={this.props.appIdentifier}
                    hideName={true}

                />
            </ListItemIcon>
        )
    }
    msgMenu = () => {
        return (
            <Menu
                id="msg-menu"
                anchorEl={this.state.anchorEl}
                open={this.state.anchorEl !== null}
                onClose={() => this.setState({ anchorEl: null })}
                PaperProps={{ style: { backgroundColor: this.props.dark ? "#333" : "#e0e0e0", color: this.props.dark ? "#ccc" : "#444" } }}
                MenuListProps={{ style: { backgroundColor: this.props.dark ? "#333" : "#e0e0e0", color: this.props.dark ? "#ccc" : "#444" } }}
            >
                <MenuItem onClick={() => this.handleEdit(this.props.message.id, this.props.index)}>Edit</MenuItem>
                <MenuItem onClick={() => this.handleDelete(this.props.message.id, this.props.index)}>Delete</MenuItem>
            </Menu>
        )
    }
    editButton = () => {
        return (
            <Box sx={{ display: "flex" }}>
                <IconButton color="primary" aria-label="Options" size="small" onClick={(e) => this.setState({ anchorEl: e.currentTarget })}>
                    <MoreHorizIcon sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                </IconButton>
            </Box>
        )
    }
    composingContents = () => {
        return (
            <>
                {this.props.message.fromMe && <Box sx={{ flexGrow: 1 }} />}
                <Box sx={{ display: "block", textAlign: this.props.message.fromMe ? "right" : "left", padding: "0px 8px" }}>
                    <div className="typing" style={{ marginLeft: "auto" }}>
                        <div className="typing__dot"></div>
                        <div className="typing__dot"></div>
                        <div className="typing__dot"></div>
                    </div>
                </Box>
            </>
            )
    }
    messageContents = () => {
        return (

                <Box sx={{ width:"100%", display: "block", textAlign: this.props.message.fromMe ? "right" : "left", padding: "0px 8px" }}>
                <Typography variant="body1" sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black, fontSize: { xs: "1rem" , sm: ".9rem"} }}>
                        {this.props.message.content}
                    </Typography>
                    <Typography variant="body2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, fontSize: ".7rem" }}>
                        {this.props.message.whenPosted ?? new Date(this.props.message.postDate).toLocaleDateString()}
                    </Typography>
                </Box>

            )
    }
    contents = () => {
        return (
            <ListItem key={"msg" + this.props.message.id} sx={{ justifyContent: this.props.message.fromMe ? "end" : "start" }}>
                {!this.props.message.fromMe && this.userAvatar(false)}
                <Box sx={{ width: "75%", cursor: this.props.message.fromMe && "pointer" }} onClick={(e) => this.props.message.fromMe && this.setState({ anchorEl: e.currentTarget })} >
                    <Paper elevation={this.props.dark ? 0 : 4 }
                        sx={{
                            borderRadius: "16px",
                            backgroundColor: (theme) => this.props.dark ? (this.props.message.fromMe ? theme.palette.comment.dark : theme.palette.reply.dark) : (this.props.message.fromMe ? theme.palette.comment.light : theme.palette.reply.light),
                        }}>
                        <Grid container sx={{ padding: "8px", }} >
                            {this.props.message.status === "Composing"
                                ?
                                this.composingContents()
                                :
                                this.messageContents()
                            }
                        </Grid>
                    </Paper>

                </Box>
                {this.props.message.fromMe && this.userAvatar(true)}
                {this.msgMenu()}
            </ListItem>
            )
    }
    render() {
        return this.contents();
    }
}

              //{this.props.message.fromMe && this.editButton()}
              //  {this.props.message.fromMe && <Box sx={{ flexGrow: 1 }} />}