import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../Utilities/ModalDialog'


//Material UI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';


export class Invitations extends Component {

  constructor(props) {
      super(props);
      this.state = {
          loading: true,
      };
  }
    handleClick = async (id) => {

    }
    handleAccept = (event, item, index) => {
        event.stopPropagation();
        this.props.handleAccept(item, index);
    }
    handleDecline = (event, item, index) => {
        event.stopPropagation();
        this.props.handleDecline(item, index);
    }
    handleDismiss = (event, item, index) => {
        event.stopPropagation();
        this.props.handleCancel(item, index);
    }

    invitation = (item, index) => {
        return (
            <ListItem button key={item.id} onClick={() => this.handleClick(item.groupID)}>
                <ListItemAvatar>
                    <Avatar alt={item.fromUser.firstName} src={"data:image/png;base64, " + item.fromUser.avatarB64} className="avatarImageMedium"/>
                </ListItemAvatar>
                <ListItemText
                    sx={{ color: this.props.dark ? "#ccc" : "#444" }}
                    //{/*secondary={<Typography sx={{ fontSize: ".8rem", display: 'inline', color: this.props.dark ? "#ccc" : "#444" }} >*/}
                    //{/*    {new Date(item.dateSent).toLocaleDateString() ?? ""}*/}
                   // {/*</Typography>}*/}
                    primary={
                        <Box display="flex">
                            <Typography sx={{ fontSize: ".9rem", display: 'inline', color: this.props.dark ? "#ccc" : "#444" }} >
                                {item.message}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                                <Box sx={{ marginTop: "auto", marginBottom: "auto" }}>
                                    <Tooltip title="Accept">
                                        <IconButton
                                            key="invAccept"
                                            aria-label="accept"
                                            onClick={(e) => this.handleAccept(e, item, index)}
                                            size="small"
                                            sx={{ margin: "0px 4px", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}
                                        >
                                            <ThumbUpIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Decline">
                                        <IconButton key="invDecline" aria-label="accept" onClick={(e) => this.handleDecline(e, item, index)} size="small" sx={{ margin: "0px 4px", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                            <ThumbDownIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Dismiss">
                                        <IconButton key="invIgnore" aria-label="accept" onClick={(e) => this.handleDismiss(e, item, index)} size="small" sx={{ margin: "0px 4px", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        <CloseIcon size="small" sx={{fontSize:"16px"}}/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                    }
                />
            </ListItem>
        )
    }

    contents = () => {
        return (
            <ModalDialog
                show={this.props.show}
                handleClose={this.props.handleClose}
                fullWidth={true}
                maxWidth="md"
                hideScroll={true}
                closeLabel="Close"
                title="Invitations"
                dark={this.props.dark}
            >
                <Box sx={{ background: (theme) => this.props.dark ? theme.palette.item.dark : theme.palette.item.light, borderRadius: "8px", minHeight: "300px", maxHeight: "500px" }} className="prettyScroll">
                    {this.props.invitations.length > 0
                        ?
                        <List sx={{ pt: 0, pb: 0 }}>
                            {this.props.invitations.map((item, index) => {
                                return this.invitation(item, index);
                            })}
                        </List>
                        :
                        <Box sx={{padding:"8px"}}>
                            <span>No Invitations</span>
                        </Box>
                    }
                </Box>
            </ModalDialog>
            )
    }

    render() {
        return this.contents();
    };



}
