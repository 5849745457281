import React, { Component } from 'react';

//Custom Components
import { ContentOuterContainer } from './ContentOuterContainer';

//Material UI
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export class ContentLoader extends Component {

    content = () => {
        return (
            <ContentOuterContainer dark={this.props.dark}>
                <Stack sx={{ marginTop: "auto", marginBottom: "auto", justifyContent: "center", textAlign: "center", height:"inherit" }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500, marginBottom: "6px", fontSize: ".9rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                        {"Loading..."}
                    </Typography>
                    <CircularProgress color="secondary" style={{ alignSelf: "center" }} />
                </Stack>
            </ContentOuterContainer>
        )
    }

    //main component render
    render() {
        return this.content();
    }
}
