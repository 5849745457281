export async function getMentionsByUser(userid) {
    let url = 'mentions/getmentionsbyuser?userid=' + userid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getMention(id) {
    let url = 'mentions/getmention?id=' + id;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function addMention(userid, refuserid, targettype, targetid){
    const req = { TargetID: targetid, TargetType: targettype, UserID: userid, RefUserID: refuserid };
    const response = await fetch('mentions/addmention', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}
export async function removeMention(id) {
    const req = { ID: id };
    const response = await fetch('mentions/removemention', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}





