import React, { PureComponent } from 'react';

//Custom Components
import { Post } from './Post'
import { PostsContainer } from './PostsContainer'
import { PostComposer } from './PostComposer'
import { SelectList } from '../Utilities/SelectList';
import { ModalDialog } from '../Utilities/ModalDialog'
import { Input } from '../Utilities/Input'
//Material UI
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import WidgetsIcon from '@mui/icons-material/Widgets';
import FilterListIcon from '@mui/icons-material/FilterList';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

//Data Services
import { getPost, newPost, deletePost, addTextContent, addPhotoContent, addVideoContent, newMobilePhotoPost,addSource } from '../../DataServices/PostService'
import { newTextTag } from '../../DataServices/TagService'
import { addMention } from '../../DataServices/MentionsService'
import { createNotification } from '../../DataServices/NotificationService'

//Helper Functions
const a11yProps = (index, id) => {
    return {
        id: `simple-tab-${index}-${id}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`affil-tabpanel-${index}`}
            aria-labelledby={`affil-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}
const convertBlobToBase64 = async (blob) => { // blob data
    return await blobToBase64(blob);
}

const blobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export class Posts extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: true,
            loaded: false,
            //loadIndex: 0,
            //loadCount: 14,
            showComposer: false,
            showFilters: false,
            menuOpen: false,
            refreshing: false,
            newPost: false,
            saving: false,
            saveMessage: "",
            postChannel: -1,
            filters: [
                { label: "All", value: 0 },
                { label: "Followed User's Posts", value: 1 },
                { label: "My Posts", value: 2 },
                { label: "Saved Posts", value: 3 },
            //    { label: "Search Results", value: 100 }
            ],
            showSettings: false,
            showPopupPost: false,
            tabValue: 0,
            feed: -1,
            anchorElFilter: null,
            showFilter: false,
            showFeedPrefs: false,
            anchorElPrefs: null,
            width: 0,
            height: 0,
            showPostInfo: true
        };
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        if (window.innerWidth < 600) {
            this.setState({ showPostInfo: false });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.getTabValueFromChannel(prevProps.postChannel) !== this.state.tabValue) {
            this.setState({ tabValue: this.getTabValueFromChannel(this.props.postChannel) });
        }
    }
    handleSaveMobilePost = async (post) => {
        this.setState({ saving: true });
        await newMobilePhotoPost(post.textContent, post.photoContent[0], this.props.appIdentifier);
    }
    handleSavePost = async (post) => {
        this.props.pauseRefreshes(true);
        this.setState({ saving: true, saveMessage: "Creating Post..." });
        //create main post in db
        const postid = await newPost(this.props.user.userID, post.type ?? 0, this.props.appIdentifier, post.category);
        this.props.updateLastPost(postid);
        //Now Save all content to db.
        try {
            if (post.textContent)
                await addTextContent(postid, 0, 0, post.textContent);
            if (post.linkContent)
                await addTextContent(postid, 1, 1, post.linkContent);
            if (post.photoContent) {
                for (var j = 0; j < post.photoContent.length; j++) {
                    this.setState({ saveMessage: post.photoContent.length === 1 ? "Uploading photo..." : "Uploading " + (j + 1) + " of " + post.photoContent.length + " photos..." });
                    await addPhotoContent(postid, j, post.photoContent[j]);
                }

            }
            if (post.videoContent) {
                for (j = 0; j < post.videoContent.length; j++) {
                    this.setState({ saveMessage: post.videoContent.length === 1 ? "Uploading video..." : "Uploading " + (j + 1) + " of " + post.videoContent.length + " videos..." });
                    let thumbB64;
                    if (post.videoThumbs[j]) {
                        thumbB64 = await convertBlobToBase64(post.videoThumbs[j]);
                        let thumbB64Split = thumbB64.split(',');
                        if (thumbB64Split.length > 1) {
                            thumbB64 = thumbB64Split[1]
                        }
                    }

                    await addVideoContent(postid, j, post.videoContent[j], thumbB64);


                }

            }
            this.setState({ saveMessage: "Posting to feed..." });
            //add other content (sources, tags and mentions)
            if (post.tags && post.tags.length > 0) {
                for (var t = 0; t < post.tags.length; t++) {
                    await newTextTag(postid, 2, post.tags[t]);
                }
            }
            if (post.mentions && post.mentions.length > 0) {
                for (var m = 0; m < post.mentions.length; m++) {
                    await addMention(post.mentions[m].id, this.props.user.userID, 2, postid);

                    let message = (this.props.user.displayName ? this.props.user.displayName : "A user") + " mentioned you in a post";
                    if (post.mentions[m].id === this.props.user.userID) {
                        message = "You mentioned yourself in a post";
                    }
                    await createNotification(post.mentions[m].id, 2, postid, this.props.user.userID, message, this.props.appIdentifier);
                }
            }
            if (post.sources && post.sources.length > 0) {
                for (var t = 0; t < post.sources.length; t++) {
                    await addSource(postid, post.sources[t]);
                }
            }

            //get clean post object from db
            const postDetail = await getPost(postid);
            if (postDetail === 'error') {
                return;
            }
            //update state to show new post
            const channel = this.getChannelFromTabValue(this.state.tabValue);
            switch (channel) {
                case 0:
                    const thoughtposts = [postDetail, ...this.props.thoughts.posts];
                    this.props.updateData("thoughtposts", thoughtposts);
                    break;
                case 2:
                    const photoposts = [postDetail, ...this.props.photos.posts];
                    this.props.updateData("photoposts", photoposts);
                    break;
                case 3:
                    const videoposts = [postDetail, ...this.props.videos.posts];
                    this.props.updateData("videoposts", videoposts);
                    break;
                case 5:
                    const songposts = [postDetail, ...this.props.songs.posts];
                    this.props.updateData("songposts", songposts);
                    break;
                case 8:
                    const eventposts = [postDetail, ...this.props.events.posts];
                    this.props.updateData("eventposts", eventposts);
                    break;
                default:
                    break;
            }

            this.props.updateLastPost(postid, channel);
            //cleanup status
            this.setState({
                saving: false,
                saveMessage: "",
                newPost: false
            });

        }
        catch (err) {
            console.log(err);
            await deletePost(postid);
            //cleanup status
            this.setState({
                saveMessage: "Error Saving Post",
            });
        }




        this.props.pauseRefreshes(false);


    }
    handleCancelPost = () => {
        this.setState({ newPost: false });
    }
    handleShowComposer = () => {
        this.setState({ showComposer: true });
    }
    handleDeletePost = async (id, idx) => {
        const channel = this.getChannelFromTabValue(this.state.tabValue);
        switch (channel) {
            case 0:
                const thoughtposts = [...this.props.thoughts.posts];
                thoughtposts.splice(idx, 1);
                this.props.updateData("thoughtposts", thoughtposts);
                break;
            case 2:
                const photoposts = [...this.props.photos.posts];
                photoposts.splice(idx, 1);
                this.props.updateData("photoposts", photoposts);
                break;
            case 3:
                const videoposts = [...this.props.videos.posts];
                videoposts.splice(idx, 1);
                this.props.updateData("videoposts", videoposts);
                break;
            case 5:
                const songposts = [...this.props.songs.posts];
                songposts.splice(idx, 1);
                this.props.updateData("songposts", songposts);
                break;
            case 8:
                const eventposts = [...this.props.events.posts];
                eventposts.splice(idx, 1);
                this.props.updateData("eventposts", eventposts);
                break;
            default:
                break;
        }
        await deletePost(id);
    }
    handleEditPost = async (post) => {
        //todo
    }
    handleSharePost = async (post) => {
        //todo: fix this
        //const newpostid = await repost(this.props.user.userID, post.id, this.props.appIdentifier)
      //  const posts = [post, ...this.props.posts];
       // this.props.updateData("posts", posts);
    }
    handleHidePost = async (post) => {
        //todo
    }
    handleShowFilters = (show) => {
        this.setState({ showFilters: show })
    }
    updatePhotoContent = (photoIdx, contentIdx, content) => {
        const posts = [...this.state.posts];
        posts[photoIdx].content[contentIdx] = content;
        this.setState({ posts });
    }
   // 02358 thoughts, photos, videos, songs, events
    handleFilterChange = (val) => {
        this.handleCloseFilter();
        const filter = val;
        switch (this.props.postChannel) {
            case 0:
                if (filter !== this.props.thoughts.filter) {
                    this.props.changeFilter(filter);
                }
                break;
            case 2:
                if (filter !== this.props.photos.filter) {
                    this.props.changeFilter(filter);
                }
                break;
            case 3:
                if (filter !== this.props.videos.filter) {
                    this.props.changeFilter(filter);
                }
                break;
            case 5:
                if (filter !== this.props.songs.filter) {
                    this.props.changeFilter(filter);
                }
                break;
            case 8:
                if (filter !== this.props.events.filter) {
                    this.props.changeFilter(filter);
                }
                break;
            default:
                return;

        }

    }
    toggleMenu = (isOpen) => {
       this.setState({ menuOpen: isOpen })
    }
    setSelectedPost = (post, idx) => {
        this.setState({
            selectedPost: post,
            selectePostIDX: idx,
            showPopupPost: true
        });
    }
    handleShowFilter = (e) => {
        this.setState({
            anchorElFilter: e.currentTarget,
            showFilter: true
        })
    }
    handleCloseFilter = (e) => {
        this.setState({
            anchorElFilter: null,
            showFilter: false
        })
    }
    getChannelFromTabValue = (tab) => {
        let channel = 0;
        switch (tab) {
            case 0:
                channel = 0;
                break;
            case 1:
                channel = 2;
                break;
            case 2:
                channel = 3;
                break;
            case 3:
                channel = 5;
                break;
            case 4:
                channel = 8;
                break;
            //case 5:
            //    channel = 12;
            //    break;
            //case 6:
            //    channel = 4;
            //    break;
            case 5:
                channel = 100;
                break;
            default:
                break;
        }
        return channel;
    }
    getTabValueFromChannel = (channel) => {
        let tabValue = 0;
        switch (channel) {
            case 0:
                tabValue = 0;
                break;
            case 2:
                tabValue = 1;
                break;
            case 3:
                tabValue = 2;
                break;
            case 5:
                tabValue = 3;
                break;
            case 8:
                tabValue = 4;
                break;
            //case 12:
            //    tabValue = 5;
            //    break;
            //case 4:
            //    tabValue = 6;
            //    break;
            case 100:
                tabValue = 5;
                break;
            default:
                break;
        }
        return tabValue;
    }
    handleTabChange = (e, newValue) => {
       // this.setState({ tabValue: newValue })
        let channel = this.getChannelFromTabValue(newValue);
        if (channel !== this.props.postChannel) {
            this.props.changePostChannel(channel);
        }
    }
    handleShowPrefs = (e) => {
        this.setState({
            anchorElPrefs: e.currentTarget,
            showFeedPrefs: true
        })
    }
    handleClosePrefs = (e) => {
        this.setState({
            anchorElPrefs: null,
            showFeedPrefs: false
        })
    }
    handlePoliticsPrefChange = (e) => {
        let feedPrefs = this.props.feedPrefs;
        feedPrefs.politics = e.target.value;
        this.props.setFeedPrefs(feedPrefs);
    }
    handleSetProfanityPrefs = (e) => {
        let feedPrefs = this.props.feedPrefs;
        feedPrefs.profanity = e.target.checked;
        this.props.setFeedPrefs(feedPrefs);
    }
    handleSetAdultContentPrefs = (e) => {
        let feedPrefs = this.props.feedPrefs;
        feedPrefs.adultContent = e.target.checked;
        this.props.setFeedPrefs(feedPrefs);
    }

    //render funcs
    filterButton = () => {
        let filter = 0;
        switch (this.props.postChannel) {
            case 0:
                filter = this.props.thoughts.filter;
                break;
            case 2:
                filter = this.props.photos.filter;
                break;
            case 3:
                filter = this.props.videos.filter;
                break;
            case 5:
                filter = this.props.songs.filter;
                break;
            case 8:
                filter = this.props.events.filter;
                break;
            default:
                break;
        }
        return (
            <Popover
                id="filterpopover"
                open={this.state.showFilter}
                anchorEl={this.state.anchorElFilter}
                onClose={this.handleCloseFilter}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{
                    elevation: this.props.dark ? 0 : 4,
                    sx: {
                        mt: 1,
                        display: "flex",
                        backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundMenu.dark : theme.palette.backgroundMenu.light,
                        border: (theme) => this.props.dark ? theme.palette.borderMenu.dark : theme.palette.borderMenu.light,
                        color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack,
                        minWidth: "300px",
                        p: 2,
     
                    }
                }}     
                MenuListProps={{
                    dense: this.props.width < 600,
                    sx: {
                        backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundMenu.dark : theme.palette.backgroundMenu.light,
                        color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack
                    }
                }}
            >
                <Stack sx={{ width: "100%" }} className={this.props.dark && "dark"}>
                    <Typography variant="subtitle1" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                        Feed Filter
                    </Typography>
                    <Box display="flex" sx={{ width: "100%", maxWidth: { xs: "120px", md: "150px", lg: "200px", xl: "250px" } }}>
                        <Input
                            key="ftype"
                            type="select"
                            dark={this.props.dark}
                            value={filter}
                            onChange={this.handleFilterChange}                          
                            listItems={this.state.filters}
                        />
                    </Box>
                </Stack>
            </Popover>
            )
    }
    feedPreferences = () => {
        return (
            <Popover
                id="feedPrefpopover"
                open={this.state.showFeedPrefs}
                anchorEl={this.state.anchorElPrefs}
                onClose={this.handleClosePrefs}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{
                    elevation: this.props.dark ? 0 : 4,
                    sx: {
                        mt: 1,
                        display: "flex",
                        backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundMenu.dark : theme.palette.backgroundMenu.light,
                        border: (theme) => this.props.dark ? theme.palette.borderMenu.dark : theme.palette.borderMenu.light,
                        color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack,
                        minWidth: "300px",
                        p: 1,
                        pl: 4,
                        pr: 4
                    }
                }}
            >
                <Stack sx={{ width: "100%" }} className={this.props.dark && "dark" }>
                    <Typography align="center" variant="subtitle1" sx={{ display: { xs: "none", sm: "block" }, width: "-webkit-fill-available", mb: 1.5, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black}}>
                        Feed Preferences
                    </Typography>
                    {this.politicsSlider()}
                    {this.profanitySwitch()}
                    {this.adultContentSwitch()}
                    {this.postInfoSwitch()}
                </Stack>

           </Popover>
        )
    }
    politicsSlider = () => {
        const marks = [
            {
                value: 0,
                label: 'Far Left'
            },
            {
                value: 1,
                label: 'Lean Left',
            },
            {
                value: 2,
                label: 'Balanced',
            },
            {
                value: 3,
                label: 'Lean Right',
            },
            {
                value: 4,
                label: 'Far Right',
            },
        ];
        return (
            <Stack sx={{ mb: 2 }}>
                <Typography align="center" variant="subtitle2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                    Political Bias
                </Typography>
                <Slider
                    aria-label="politicalpref"
                    defaultValue={this.props.feedPrefs.politics ?? 2}
                    valueLabelDisplay="auto"
                    step={1}
                    valueLabelDisplay="auto"
                    marks={marks}
                    onChange={this.handlePoliticsPrefChange}
                    min={0}
                    max={4}
                    color="secondary"
                    valueLabelDisplay="off"
                    track={false}
                />
                </Stack>

            )
    }
    profanitySwitch = () => {
        return (
            <FormControlLabel
                key={"profanityctl"}
                control={<Switch defaultChecked={this.props.feedPrefs.profanity} onChange={this.handleSetProfanityPrefs} color="secondary" />}
                label={
                    <Typography variant="subtitle2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                        Profanity Filter
                    </Typography>
                }
                labelPlacement="start"
                sx={{ marginRight: "auto", ml: 0 }}
            />
            )
    }
    adultContentSwitch = () => {
        return (
            <FormControlLabel
                key={"adultcontentctl"}
                control={<Switch defaultChecked={this.props.feedPrefs.adultContent} onChange={this.handleSetAdultContentPrefs} color="secondary"  />}
                label={
                    <Typography variant="subtitle2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                        Adult Content Filter
                    </Typography>
                }
                labelPlacement="start"
                sx={{ marginRight: "auto", ml:0 }}
            />
        )
    }
    postInfoSwitch = () => {
        return (
            <FormControlLabel
                key={"postinfoctl"}
                control={<Switch defaultChecked={this.state.showPostInfo} onChange={() => this.setState({ showPostInfo: !this.state.showPostInfo })} color="secondary" />}
                label={
                    <Typography variant="subtitle2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                        Show Post Info
                    </Typography>
                }
                labelPlacement="start"
                sx={{ marginRight: "auto", ml: 0 }}
            />
        )
    }
    composer = () => {
        if (this.state.newPost) {
            return (
                <PostComposer
                    user={this.props.user}
                    show={this.state.newPost}
                    savePost={this.handleSavePost}
                    cancelPost={this.handleCancelPost}
                    dark={this.props.dark}
                    saving={this.state.saving}
                    statusMessage={this.state.saveMessage}
                    postType={this.state.newPostType}
                    userList={this.props.userList}
                    channel={this.props.postChannel}
                    fullScreen={this.props.width <= 600}
                    width={this.props.width}
                />
            )
        }
        return null;
    }
    tabContainer = () => {
        return (
            <Box sx={{ width: '100%', height: { xs: "calc(100vh - 112px)", sm: "calc(100vh - 120px)", lg:"calc(100vh - 64px)" },  }} className={this.props.dark && "dark"} >
                <Box sx={{ borderBottom: this.props.dark ?  "1px solid #222" : "none", background: theme => this.props.dark ? "#050505" : "#f0f0f0", position:"relative", boxShadow: this.props.dark ? 0 : 8, zIndex:100}} elevation={this.props.dark ? 0 : 8 }>
                    <Tabs variant="scrollable" scrollButtons="auto" sx={{ minHeight: "36px", maxWidth:"fit-content", mx:"auto" }} allowScrollButtonsMobile indicatorColor="secondary" value={this.state.tabValue} onChange={(e, newValue) => this.handleTabChange(e, newValue)} aria-label="content tabs">
                        <Tab label="News Feed" {...a11yProps(0)} sx={{ paddingTop:"0px", paddingBottom:"0px", minHeight:"36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />
                        {/*<Tab label="Photos" {...a11yProps(1)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />*/}
                        {/*<Tab label="Videos" {...a11yProps(2)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />*/}
                        {/*<Tab label="Music" {...a11yProps(3)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />*/}
                        {/*<Tab label="Events" {...a11yProps(4)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />*/}
{/*                        <Tab label="Market" {...a11yProps(5)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />*/}
                        {this.props.showSearch &&
                            <Tab label="Search Results" {...a11yProps(5)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />
                        }
                    
                        {/*<Tab label="Recipes" {...a11yProps(6)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />*/}
       {/*                 <Tab label="Blogs" {...a11yProps(7)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />*/}
                    </Tabs>
                </Box>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={0}>
                    <PostsContainer
                        dark={this.props.dark}
                        channel={0}
                        posts={this.props.thoughts.posts}
                        loaded={this.props.thoughts.loaded}
                        user={this.props.user}
                        handleDeletePost={this.handleDeletePost}
                        handleEditPost={this.handleEditPost}
                        handleSharePost={this.handleSharePost}
                        handleHidePost={this.handleHidePost}
                        menuOpen={this.toggleMenu}
                        updatePostContent={this.props.updatePostContent}
                        updateRepostContent={this.props.updateRepostContent}
                        appIdentifier={this.props.appIdentifier}
                        updateLastPost={this.props.updateLastPost}
                        updatePosts={this.props.updatePosts}
                        setSelectedPost={this.props.setSelectedPost}
                        userList={this.props.userList}
                        getMorePosts={this.props.getMoreThoughtPosts}
                        showInfo={this.state.showPostInfo}
                        width={this.props.width}
                    />
                </TabPanel>
                {/*<TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={1}>*/}
                {/*    <PostsContainer*/}
                {/*        dark={this.props.dark}*/}
                {/*        channel={2}*/}
                {/*        posts={this.props.photos.posts}*/}
                {/*        loaded={this.props.photos.loaded}*/}
                {/*        user={this.props.user}*/}
                {/*        handleDeletePost={this.handleDeletePost}*/}
                {/*        handleEditPost={this.handleEditPost}*/}
                {/*        handleSharePost={this.handleSharePost}*/}
                {/*        handleHidePost={this.handleHidePost}*/}
                {/*        menuOpen={this.toggleMenu}*/}
                {/*        updatePostContent={this.props.updatePostContent}*/}
                {/*        updateRepostContent={this.props.updateRepostContent}*/}
                {/*        appIdentifier={this.props.appIdentifier}*/}
                {/*        updateLastPost={this.props.updateLastPost}*/}
                {/*        updatePosts={this.props.updatePosts}*/}
                {/*        setSelectedPost={this.props.setSelectedPost}*/}
                {/*        userList={this.props.userList}*/}
                {/*        getMorePosts={this.props.getMorePhotoPosts}*/}
                {/*        showInfo={this.state.showPostInfo}*/}
                {/*        width={this.props.width}*/}
                {/*    />*/}
                {/*</TabPanel>*/}
                {/*<TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={2}>*/}
                {/*    <PostsContainer*/}
                {/*        dark={this.props.dark}*/}
                {/*        channel={3}*/}
                {/*        posts={this.props.videos.posts}*/}
                {/*        loaded={this.props.videos.loaded}*/}
                {/*        user={this.props.user}*/}
                {/*        handleDeletePost={this.handleDeletePost}*/}
                {/*        handleEditPost={this.handleEditPost}*/}
                {/*        handleSharePost={this.handleSharePost}*/}
                {/*        handleHidePost={this.handleHidePost}*/}
                {/*        menuOpen={this.toggleMenu}*/}
                {/*        updatePostContent={this.props.updatePostContent}*/}
                {/*        updateRepostContent={this.props.updateRepostContent}*/}
                {/*        appIdentifier={this.props.appIdentifier}*/}
                {/*        updateLastPost={this.props.updateLastPost}*/}
                {/*        updatePosts={this.props.updatePosts}*/}
                {/*        setSelectedPost={this.props.setSelectedPost}*/}
                {/*        userList={this.props.userList}*/}
                {/*        getMorePosts={this.props.getMoreVideoPosts}*/}
                {/*        showInfo={this.state.showPostInfo}*/}
                {/*        width={this.props.width}*/}
                {/*    />*/}
                {/*</TabPanel>*/}
                {/*<TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={3}>*/}
                {/*    <PostsContainer*/}
                {/*        dark={this.props.dark}*/}
                {/*        channel={5}*/}
                {/*        posts={this.props.songs.posts}*/}
                {/*        loaded={this.props.songs.loaded}*/}
                {/*        user={this.props.user}*/}
                {/*        handleDeletePost={this.handleDeletePost}*/}
                {/*        handleEditPost={this.handleEditPost}*/}
                {/*        handleSharePost={this.handleSharePost}*/}
                {/*        handleHidePost={this.handleHidePost}*/}
                {/*        menuOpen={this.toggleMenu}*/}
                {/*        updatePostContent={this.props.updatePostContent}*/}
                {/*        updateRepostContent={this.props.updateRepostContent}*/}
                {/*        appIdentifier={this.props.appIdentifier}*/}
                {/*        updateLastPost={this.props.updateLastPost}*/}
                {/*        updatePosts={this.props.updatePosts}*/}
                {/*        setSelectedPost={this.props.setSelectedPost}*/}
                {/*        userList={this.props.userList}*/}
                {/*        getMorePosts={this.props.getMoreMusicPosts}*/}
                {/*        showInfo={this.state.showPostInfo}*/}
                {/*        width={this.props.width}*/}
                {/*    />*/}
                {/*</TabPanel>*/}
                {/*<TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={4}>*/}
                {/*    <PostsContainer*/}
                {/*        dark={this.props.dark}*/}
                {/*        channel={8}*/}
                {/*        posts={this.props.events.posts}*/}
                {/*        loaded={this.props.events.loaded}*/}
                {/*        user={this.props.user}*/}
                {/*        handleDeletePost={this.handleDeletePost}*/}
                {/*        handleEditPost={this.handleEditPost}*/}
                {/*        handleSharePost={this.handleSharePost}*/}
                {/*        handleHidePost={this.handleHidePost}*/}
                {/*        menuOpen={this.toggleMenu}*/}
                {/*        updatePostContent={this.props.updatePostContent}*/}
                {/*        updateRepostContent={this.props.updateRepostContent}*/}
                {/*        appIdentifier={this.props.appIdentifier}*/}
                {/*        updateLastPost={this.props.updateLastPost}*/}
                {/*        updatePosts={this.props.updatePosts}*/}
                {/*        setSelectedPost={this.props.setSelectedPost}*/}
                {/*        userList={this.props.userList}*/}
                {/*        getMorePosts={this.props.getMoreEventPosts}*/}
                {/*        showInfo={this.state.showPostInfo}*/}
                {/*        width={this.props.width}*/}
                {/*    />*/}
                {/*</TabPanel>*/}
                {this.props.showSearch &&
                    <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={5}>
                        <PostsContainer
                            dark={this.props.dark}
                            channel={100}
                            searchTerm={this.props.searchTerm}
                            posts={this.props.searchResults.posts}
                            loaded={this.props.searchResults.loaded}
                            user={this.props.user}
                            handleDeletePost={this.handleDeletePost}
                            handleEditPost={this.handleEditPost}
                            handleSharePost={this.handleSharePost}
                            handleHidePost={this.handleHidePost}
                            menuOpen={this.toggleMenu}
                            updatePostContent={this.props.updatePostContent}
                            updateRepostContent={this.props.updateRepostContent}
                            appIdentifier={this.props.appIdentifier}
                            updateLastPost={this.props.updateLastPost}
                            updatePosts={this.props.updatePosts}
                            setSelectedPost={this.props.setSelectedPost}
                            userList={this.props.userList}
                            getMorePosts={this.props.getMoreSearchPosts}
                            hasResults={this.props.hasResults}
                            searching={this.props.searching}
                            showInfo={this.state.showPostInfo}
                            width={this.props.width}
                        />
                    </TabPanel>
                }
            </Box>
        )
    }
    popupPost = () => {
        if (this.state.showPopupPost) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showPopupPost}
                    handleClose={() => this.setState({ showPopupPost: false })}
                    closeLabel="Close"
                    fullWidth={true}
                    title={(this.state.selectedPost.user.firstName && this.state.selectedPost.user.lastName) ? this.state.selectedPost.user.firstName + " " + this.state.selectedPost.user.lastName + "'s Post" : "User Post"}
                    maxWidth="xl"
                >
                    <Post
                        dark={this.props.dark}
                        post={this.state.selectedPost}
                        idx={this.state.selectedPostIDX}
                        user={this.props.user}
                        handleDeletePost={this.handleDeletePost}
                        handleEditPost={this.handleEditPost}
                        handleSharePost={this.handleSharePost}
                        handleHidePost={this.handleHidePost}
                        menuOpen={this.toggleMenu}
                        updatePostContent={this.props.updatePostContent}
                        updateRepostContent={this.props.updateRepostContent}
                        appIdentifier={this.props.appIdentifier}
                        updateLastPost={this.props.updateLastPost}
                        updatePosts={this.props.updatePosts}
                        width={this.props.width}
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    speedDial = () => {
        let dir = "down";
        if (this.props.width < 1200) {
            dir = "up";
        }
        let filterTooltip, filter;
        switch (this.props.postChannel) {
            case 0:
                filter = this.props.thoughts.filter;
                break;
            case 2:
                filter = this.props.photos.filter;
                break;
            case 3:
                filter = this.props.videos.filter;
                break;
            case 5:
                filter = this.props.songs.filter;
                break;
            case 8:
                filter = this.props.events.filter;
                break;
            default:
                break;
        }
        filterTooltip = this.state.filters.find(x => x.value === filter).label;

        return (
            <SpeedDial
                direction={dir}
                ariaLabel="Feed Actions"
                sx={{
                    position: 'absolute', bottom: { xs: "66px", lg: "unset" },
                    top: { lg: "120px" }, right: 16,  }}
                icon={<SpeedDialIcon />}
                FabProps={{ id: "speeddial", size:"medium", color: "secondary", sx: { background: theme => this.props.dark ? theme.palette.secondary.main : "#333", boxShadow: this.props.dark ? 0 : 8  } }}
            >
                {(this.state.tabValue >= 0 && this.state.tabValue <= 2) &&
                    <SpeedDialAction
                        key='New Post'
                        icon={<AddIcon />}
                        tooltipTitle='New Post'
                        onClick={() => this.setState({ newPost: true, newPostType: 0 })}
                        FabProps={{ color: "secondary", sx: { background: theme => theme.palette.secondary.main, color: "white", boxShadow: this.props.dark ? 0 : 8 } }}
                    />
                }
                <SpeedDialAction
                    key='Filter Settings'
                    icon={<FilterListIcon />}
                    tooltipTitle={`Current Filter: "${filterTooltip}"`}
                    onClick={this.handleShowFilter}
                    FabProps={{ onClick: this.handleShowFilter, color: "secondary", sx: { background: theme => theme.palette.secondary.main, color: "white", boxShadow: this.props.dark ? 0 : 8 } }}
                />
                <SpeedDialAction
                    key='Feed Preferences'
                    icon={<SettingsApplicationsIcon />}
                    tooltipTitle='Feed Preferences'
                    onClick={this.handleShowPrefs}
                    FabProps={{ color: "secondary", sx: { background: theme => theme.palette.secondary.main, color: "white", boxShadow: this.props.dark ? 0 : 8 } }}
                />
            </SpeedDial>
            
            )
    }
    posts = () => {
        if (this.props.searchTerm && this.props.postChannel === 100) {
            let filters = this.state.filters;
            let sr = filters.find(x => x.value === 100);
            if (sr) {
                sr.label = 'Search Results for "' + this.props.searchTerm + '"';
            }
        }
        return (
            <>
                {this.tabContainer()}
                {this.state.showPopupPost && this.popupPost()}
                {this.speedDial()}
                {this.state.showFilter && this.filterButton()}
                {this.state.showFeedPrefs && this.feedPreferences()}
                {this.composer()}
            </>
        );

    }

    //main component render
    render() {
        return this.posts();
    }
}
