import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../Utilities/ModalDialog';
import { Section } from '../Utilities/Section';
import { SectionItem } from '../Utilities/SectionItem';
import { SectionGrid } from '../Utilities/SectionGrid';
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
import { SelectList } from '../Utilities/SelectList';
import { DateSelector } from '../Utilities/DateSelector';
import { TextInput } from '../Utilities/TextInput';
import { PublicAttribute } from '../Utilities/PublicAttribute';

//Material UI
import Button from '@mui/material/Button';

//Data Services
import { addAddress, editAddress, deleteEmail, deletePhone, deleteAddress, deleteFamily, addEmail, editEmail, addPhone, editPhone, addFamily, editFamily, setPublic, removePublic } from '../../DataServices/UserService'
import { formatPhoneNumberInline, validateEmail, validateZip } from '../../DataServices/Utility'


export class ProfileContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    handleDeleteEmail = (id, idx) => {
        this.setState({
            deleteType: "Email",
            selectedID: id,
            selectedIDX: idx,
            showConfirmDelete: true
        })
    }

    handleEditEmail = (id, idx) => {
        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            emailValue: this.props.profile.demographics.emailAddresses[idx].address,
            typeValue: this.props.profile.demographics.emailAddresses[idx].emailType,
            showEmailEditor: true
        })
    }

    handleAddEmail = () => {
        this.setState({
            editMode: "New",
            emailValue: "",
            showEmailEditor: true
        })
    }

    handleDeletePhone = (id, idx) => {
        this.setState({
            deleteType: "Phone",
            selectedID: id,
            selectedIDX: idx,
            showConfirmDelete: true
        })
    }

    handleEditPhone = (id, idx) => {
        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            phoneValue: formatPhoneNumberInline(this.props.profile.demographics.phoneNumbers[idx].number),
            typeValue: this.props.profile.demographics.phoneNumbers[idx].phoneType,
            showPhoneEditor: true
        })
    }

    handleAddPhone = () => {
        this.setState({
            editMode: "New",
            phoneValue: "",
            showPhoneEditor: true
        })
    }

    handleDeleteAddress = (id, idx) => {
        this.setState({
            deleteType: "Address",
            selectedID: id,
            selectedIDX: idx,
            showConfirmDelete: true
        })
    }

    handleAddAddress = () => {
        this.setState({
            editMode: "New",
            streetValue: "",
            cityValue: "",
            stateValue: "",
            zipValue: "",
            typeValue: "",
            showAddressEditor: true
        })
    }

    handleEditAddress = (id, idx) => {
        const st = this.props.profile.stateList.find((x) => x.label === this.props.profile.demographics.addresses[idx].state);
        var state = 0
        if (st) {
            state = st.value;
        }


        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            streetValue: this.props.profile.demographics.addresses[idx].street,
            cityValue: this.props.profile.demographics.addresses[idx].city,
            stateValue: state,
            zipValue: this.props.profile.demographics.addresses[idx].zip,
            typeValue: this.props.profile.demographics.addresses[idx].type,
            showAddressEditor: true
        })
    }

    handleSaveAddress = async () => {
        const street = document.getElementById("fldStreet").value.trim();
        const city = document.getElementById("fldCity").value.trim();
        const state = this.props.profile.stateList.find((x) => x.value === this.state.stateValue).label;
        const zip = document.getElementById("fldZip").value.trim();
        const country = "USA";

        if (this.state.editMode !== "Edit") {
            if (street && city && state && zip) {
                var primary = false;
                var type = this.state.typeValue;              
                const id = await addAddress(type, street, city, state, zip, country, primary);
                if (id) {
                    const address = { id: id, street: street, city: city, state: state, zip: zip, county: country, type: type, primary: false };
                    this.props.profile.demographics.addresses.push(address);
                    this.setState({ showAddressEditor: false });
                }
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (street && city && state && zip) {
                type = this.state.typeValue;
                primary = this.props.profile.demographics.addresses[idx].primary;
                await editAddress(id, type, street, city, state, zip, country, primary);
                const addr = { id: id, street: street, city: city, state: state, zip: zip, county: country, type: type, primary: primary };
                this.props.updateAddress(idx, addr);
                this.setState({ showAddressEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleDelete = async () => {
        if (this.state.deleteType === "Email") {
            this.props.deleteEmail(this.state.selectedIDX);
            await deleteEmail(this.state.selectedID);
        }
        if (this.state.deleteType === "Phone") {
            this.props.deletePhone(this.state.selectedIDX);
            await deletePhone(this.state.selectedID);
        }
        if (this.state.deleteType === "Address") {
            this.props.deleteAddress(this.state.selectedIDX);
            await deleteAddress(this.state.selectedID);
        }
        if (this.state.deleteType === "Family") {
            this.props.profile.familyMembers.splice(this.state.selectedIDX, 1);
            await deleteFamily(this.state.selectedID);
        }

        this.setState({
            showConfirmDelete: false,
            deleteType: "",
            selectedID: "",
            selectedIDX: ""
        })
    }

    handleSaveEmail = async () => {
        const email = document.getElementById("fldEmailAddress").value;
        if (this.state.editMode !== "Edit") {
            if (email && email.length > 0 && validateEmail(email)) {
                var primary = false;
                var type = this.state.typeValue;
                const id = await addEmail(type, email, primary);
                if (id) {
                    const eml = { id: id, address: email, emailType: 0, primary: false };
                    this.props.profile.demographics.emailAddresses.push(eml);
                    this.setState({ showEmailEditor: false });
                }
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (email && email.length > 0 && validateEmail(email)) {
                type = this.state.typeValue;
                primary = this.props.profile.demographics.emailAddresses[idx].primary;
                await editEmail(id, type, email, primary);
                this.props.updateEmail(idx, email, type);
                this.setState({ showEmailEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handleSavePhone = async () => {
        const number = document.getElementById("fldPhoneNumber").value;
        if (this.state.editMode !== "Edit") {
            if (number && number.length > 0) {
                var primary = false;
                var type = this.state.typeValue;
                const id = await addPhone(type, number, primary);
                if (id) {
                    const phone = { id: id, number: number, phoneType: 0, primary: false };
                    this.props.profile.demographics.phoneNumbers.push(phone);
                    this.setState({ showPhoneEditor: false });
                }
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (number && number.length > 0) {
                type = this.state.typeValue;
                primary = this.props.profile.demographics.phoneNumbers[idx].primary;
                await editPhone(id, type, number, primary);
                this.props.updatePhone(idx, number, type);
                this.setState({ showPhoneEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }

    handlePhoneChange = (e) => {
        const formattedVal = formatPhoneNumberInline(e.target.value);
        e.target.value = formattedVal;
    }

    handleStateChange = (e) => {
        this.setState({ stateValue: e.target.value });
    }

    handleNewZipChange = (e) => {
        if (e.target.value === '' || validateZip(e.target.value)) {
            this.setState({ zipValue: e.target.value })
        }
    };

    handleTypeChange = (e) => {
        this.setState({ typeValue: e.target.value });
    }
    handleDOBChange = (e) => {

        this.setState({ dobValue: new Date(e).toLocaleDateString() });

    }
    handleDeleteFamily = (id, idx) => {
        this.setState({
            deleteType: "Family",
            selectedID: id,
            selectedIDX: idx,
            showConfirmDelete: true
        })
    }

    handleAddFamily = () => {
        this.setState({
            editMode: "New",
            firstNameValue: "",
            middleNameValue: "",
            lastNameValue: "",
            typeValue: 0,
            genderValue: 0,
            dobValue: new Date(),
            showFamilyEditor: true
        })
    }

    handleEditFamily = (id, idx) => {

        this.setState({
            editMode: "Edit",
            selectedID: id,
            selectedIDX: idx,
            firstNameValue: this.props.profile.familyMembers[idx].firstName,
            middleNameValue: this.props.profile.familyMembers[idx].middleName,
            lastNameValue: this.props.profile.familyMembers[idx].lastName,
            typeValue: this.props.profile.familyMembers.[idx].type,
            genderValue: this.props.profile.familyMembers.[idx].gender,
            dobValue: this.props.profile.familyMembers[idx].dob,
            showFamilyEditor: true
        })
    }

    handleSaveFamily = async () => {
        const first = document.getElementById("fldFirstName").value.trim();
        const middle = document.getElementById("fldMiddleName").value.trim() ?? "";
        const last = document.getElementById("fldLastName").value.trim() ?? "";
        const type = this.state.typeValue;
        const gender = this.state.genderValue;
        const dob = this.state.dobValue;

        if (this.state.editMode !== "Edit") {
            if (first && last) {
                const id = await addFamily(first, middle, last, gender, type, dob);
                if (id) {
                    const fam = { id: id, firstName: first, middleName: middle, lastName: last, type: type, gender: gender, dob: dob };
                    this.props.profile.familyMembers.push(fam);
                    this.setState({ showFamilyEditor: false });
                }
            }
        }
        else {
            const id = this.state.selectedID;
            const idx = this.state.selectedIDX;
            if (first && last) {
                await editFamily(id, first, middle, last, gender, type, dob);
                const fam2 = { id: id, firstName: first, middleName: middle, lastName: last, type: type, gender: gender, dob: dob };
                this.props.updateFamily(idx, fam2);
                this.setState({ showFamilyEditor: false, editMode: "", selectedID: "", selectedIDX: "" });
            }
        }
    }


    render() {

        const phoneFormat = (str) => {
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');

            //Check if the input is of correct length
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3]
            };

            return null
        }
        const emailItem = (email, index) => {
            return (
                <SectionItem
                    key={email.id}
                    itemID={email.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.emailTypeList.find((x) => x.value === email.emailType).label}
                    primaryContent={email.address}
                    isPrimary={email.primary}
                    handleEdit={this.handleEditEmail}
                    handleDelete={this.handleDeleteEmail}
                >
                    <PublicAttribute {...this.props} attr={email.id} />
                </SectionItem>

            )
        }
        const phoneItem = (phone, index) => {
            return (
                <SectionItem
                    itemID={phone.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.phoneTypeList.find((x) => x.value === phone.phoneType).label}
                    primaryContent={phoneFormat(phone.number)}
                    isPrimary={phone.primary}
                    handleEdit={this.handleEditPhone}
                    handleDelete={this.handleDeletePhone}
                >
                    <PublicAttribute {...this.props} attr={phone.id} />
                </SectionItem>
            )
        }
        const addressItem = (address, index) => {
            return (
                <SectionItem
                    itemID={address.id}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.addressTypeList.find((x) => x.value === address.type).label}
                    primaryContent={<>{address.street}<br />{address.city + ", " + address.state + " " + address.zip}</>}
                    isPrimary={address.primary}
                    handleEdit={this.handleEditAddress}
                    handleDelete={this.handleDeleteAddress}
                >
                    <PublicAttribute {...this.props} attr={address.id} />
                </SectionItem>
            )
        }
        const familyItem = (family, index) => {
            return (
                <SectionItem
                    itemID={family.familyMemberID}
                    index={index}
                    dark={this.props.dark}
                    primaryChipLabel={this.props.profile.familyTypeList.find((x) => x.value === family.type).label}
                    primaryContent={family.firstName + " " + family.lastName}
                    handleEdit={this.handleEditFamily}
                    handleDelete={this.handleDeleteFamily}
                >
                    <PublicAttribute {...this.props} attr={family.familyMemberID} />
                </SectionItem>
            )
        }
        const Emails = (
            <Section dark={this.props.dark} title={"Email Addresses"} handleAdd={this.props.profile.demographics.emailAddresses.length > 0 && this.handleAddEmail} {...this.props}  publicAttribute="email">
                {this.props.profile.demographics.emailAddresses.length > 0
                    ?
                    this.props.profile.demographics.emailAddresses.map((email, index) => emailItem(email, index))
                    :
                    <div style={{ textAlign: "center"}}>
                        <Button variant="contained" onClick={this.handleAddEmail} color="secondary" sx={{ width: "250px" }}>Add Email Address</Button>
                    </div>
                }
            </Section>
        )
        const Phones = (
            <Section dark={this.props.dark} title={"Phone Numbers"} handleAdd={this.props.profile.demographics.phoneNumbers.length > 0 && this.handleAddPhone} {...this.props}  publicAttribute="phone">
                {this.props.profile.demographics.phoneNumbers.length > 0
                    ?
                    this.props.profile.demographics.phoneNumbers.map((phone, index) => phoneItem(phone, index))
                    :
                    <div style={{ textAlign: "center" }}>
                        <Button variant="contained" onClick={this.handleAddPhone} color="secondary" sx={{ width: "250px" }}>Add Phone Number</Button>
                    </div>
                }
            </Section>
        )
        const Addresses = (
            <Section dark={this.props.dark} title={"Mailing Addresses"} handleAdd={this.props.profile.demographics.addresses.length > 0 && this.handleAddAddress} {...this.props} publicAttribute="address">
                {this.props.profile.demographics.addresses.length > 0 ?
                    this.props.profile.demographics.addresses.map((address, index) => addressItem(address, index))
                    :
                    <div style={{ textAlign: "center"}}>
                        <Button variant="contained" onClick={this.handleAddAddress} color="secondary" sx={{ width: "250px" }}>Add Mailing Address</Button>
                    </div>
                }
            </Section>
        )


        const Family = (
            <Section dark={this.props.dark} title={"Immediate Family"} handleAdd={this.props.profile.familyMembers.length > 0 && this.handleAddFamily} {...this.props}  publicAttribute="family">
                {this.props.profile.familyMembers.length > 0 ?
                    this.props.profile.familyMembers.map((fam, index) => familyItem(fam, index))
                    :
                    <div style={{ textAlign: "center" }}>
                        <Button variant="contained" onClick={this.handleAddFamily} color="secondary" sx={{ width: "250px" }}>Add Family Member</Button>
                    </div>
                }
            </Section>)


        const deleteConfirmation = (<ModalDialog {...this.props} show={this.state.showConfirmDelete}
            title="Confirm Deletion"
            subTitle={this.state.deleteType + " will be deleted.  Proceed?"}
            handleClose={() => this.setState({ showConfirmDelete: false })}
            handleSave={this.handleDelete}
            fullWidth={false}
            maxWidth="sm"
            saveLabel="Delete" />)
        const emailEditor = (
            <ModalDialog {...this.props} show={this.state.showEmailEditor}
                title={this.state.editMode + " Email"}
                handleClose={() => this.setState({ showEmailEditor: false })}
                handleSave={this.handleSaveEmail}
                fullWidth={true}
                maxWidth="sm"
            >
                <SectionFieldGroup dark={this.props.dark}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="email"
                        cid="emailAddressF"
                        id="fldEmailAddress"
                        label="Email Address"
                        defaultValue={this.state.emailValue}
                    />
                    <SelectList
                        dark={this.props.dark}
                        key="etype"
                        label="Type"
                        value={this.state.typeValue}
                        onChange={this.handleTypeChange}
                        listItems={this.props.profile.emailTypeList}
                    />
                </SectionFieldGroup>
            </ModalDialog>
        )
        const phoneEditor = (
            <ModalDialog {...this.props} show={this.state.showPhoneEditor}
                title={this.state.editMode + " Phone"}
                handleClose={() => this.setState({ showPhoneEditor: false })}
                handleSave={this.handleSavePhone}
                fullWidth={true}
                maxWidth="sm"
            >
                <SectionFieldGroup dark={this.props.dark}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="phoneNumber"
                        cid="phoneNumberF"
                        id="fldPhoneNumber"
                        label="Phone Number"
                        defaultValue={this.state.phoneValue}
                        onChange={this.handlePhoneChange}
                    />
                    <SelectList
                        dark={this.props.dark}
                        key="etype"
                        label="Type"
                        value={this.state.typeValue}
                        onChange={this.handleTypeChange}
                        listItems={this.props.profile.phoneTypeList}
                    />
                </SectionFieldGroup>
            </ModalDialog>
        )
        const addressEditor = (
            <ModalDialog {...this.props} show={this.state.showAddressEditor}
                title={this.state.editMode + " Address"}
                handleClose={() => this.setState({ showAddressEditor: false })}
                handleSave={this.handleSaveAddress}
                fullWidth={true}
                maxWidth="sm"
            >
                <SectionFieldGroup dark={this.props.dark}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="street"
                        cid="streetF"
                        id="fldStreet"
                        label="Street"
                        defaultValue={this.state.streetValue}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="city"
                        cid="cityF"
                        id="fldCity"
                        label="City"
                        defaultValue={this.state.cityValue}
                    />
                    <SelectList
                        dark={this.props.dark}
                        key="state"
                        label="State"
                        value={this.state.stateValue}
                        onChange={this.handleStateChange}
                        listItems={this.props.profile.stateList}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="zip"
                        cid="zipF"
                        id="fldZip"
                        label="Zip"
                        defaultValue={this.state.zipValue}
                        inputProps={{ id: "fldZip", maxLength: 5 }}
                    />
                    <SelectList
                        dark={this.props.dark}
                        key="etype"
                        label="Type"
                        value={this.state.typeValue}
                        onChange={this.handleTypeChange}
                        listItems={this.props.profile.addressTypeList}
                    />
                </SectionFieldGroup>
            </ModalDialog>
        )
        const familyEditor = (
            <ModalDialog {...this.props} show={this.state.showFamilyEditor}
                title={this.state.editMode + " Family Member"}
                handleClose={() => this.setState({ showFamilyEditor: false })}
                handleSave={this.handleSaveFamily}
                fullWidth={true}
                maxWidth="sm"
            >
                <SectionFieldGroup dark={this.props.dark}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="firstName"
                        cid="firstNameF"
                        id="fldFirstName"
                        label="First Name"
                        defaultValue={this.state.firstNameValue}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="middleName"
                        cid="middleNameF"
                        id="fldMiddleName"
                        label="Middle Name"
                        defaultValue={this.state.middleNameValue}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="lastName"
                        cid="lastNameF"
                        id="fldLastName"
                        label="Last Name"
                        defaultValue={this.state.lastNameValue}
                    />
                    <SelectList
                        dark={this.props.dark}
                        key="etype"
                        label="Type"
                        value={this.state.typeValue}
                        onChange={this.handleTypeChange}
                        listItems={this.props.profile.familyTypeList}
                    />
                    <SelectList
                        dark={this.props.dark}
                        key="egender"
                        label="Gender"
                        value={this.state.genderValue}
                        onChange={this.handleGenderChange}
                        listItems={this.props.profile.genderList}
                    />
                    <DateSelector
                        dark={this.props.dark}
                        label="Date of Birth"
                        value={this.state.dobValue}
                        handleChange={this.handleDOBChange}
                    />
                </SectionFieldGroup>
            </ModalDialog>
        )
        return (
            <>
                <SectionGrid xs={12} md={6} height="calc(100% - 40px)">
                    {Emails}
                    {Phones}
                    {Addresses}
                    {this.props.appIdentifier === 'LS' && Family}
                </SectionGrid>
                {deleteConfirmation}
                {emailEditor}
                {phoneEditor}
                {addressEditor}
                {familyEditor}
            </>
        );
    }
}
