import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../Utilities/ModalDialog'
import { Post } from '../Posts/Post'

//Material UI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
//Data Services
import { getPost  } from '../../DataServices/PostService'
import { getPhotoThumb } from '../../DataServices/PhotoService'

export class Notifications extends Component {

  constructor(props) {
      super(props);
      this.state = {
          loading: true,
          selectedPost: null,
          showPost: false

      };
  }
    handleClick = async (id) => {
        const post = await getPost(id);

        if (post) {
            this.setState({
                selectedPost: post,
                showPost: true
            })
        }

    }
    handleDismiss = (event, item, index) => {
        event.stopPropagation();
        this.props.handleCancel(item, index);
    }
    //updatePostContent = async (idx, contentIdx, content) => {
    //    if (content && content) {
    //        if (content.type === 4) {
    //            content = await this.loadBlogSpecificContent(content);
    //        }

    //        const post = this.state.selectedPost;
    //        if (post && post.content)
    //            post.content[contentIdx] = content;
    //        this.setState({ selectedPost: post });
      

    //    }
    //}
    updatePostContent = async (idx, contentIdx, content) => {

            if (content && content) {
                if (content.type == 4) {
                    content = await this.loadBlogSpecificContent(content);
                }
                if (content.type == 8) {
                    content = await this.loadEventSpecificContent(content);
                }
                if (content.type == 12) {
                    content = await this.loadMarketSpecificContent(content);
                }

                const post = this.state.selectedPost;
                if (post && post.content)
                    post.content[contentIdx] = content;
                this.setState({ selectedPost: post });

            }
 
    }

    updateRepostContent = async (postIdx, postContentIdx, contentIdx, content) => {
        if (this.state.topic === "posts") {
            if (content && content) {
                if (content.type == 4) {
                    content = await this.loadBlogSpecificContent(content);
                }
                if (content.type == 8) {
                    content = await this.loadEventSpecificContent(content);
                }
                if (content.type == 12) {
                    content = await this.loadMarketSpecificContent(content);
                }
                const post = this.state.selectedPost;
                if (post && post.content)
                    post.content[postContentIdx].post.content[contentIdx] = content;
                this.setState({ selectedPost: post });


            }
        }
    }
    loadBlogSpecificContent = async (content) => {
        //get header image
        if (content.blog && content.blog.header && content.blog.header.headerImage && content.blog.header.headerImage.photoID) {
            const hthumb = await getPhotoThumb(content.blog.header.headerImage.photoID);
            if (hthumb) {
                content.blog.header.headerImage.thumb = hthumb;
            }
        }
        //get cover image
        if (content.blog && content.blog.header && content.blog.header.coverImage && content.blog.header.coverImage.photoID) {
            const cthumb = await getPhotoThumb(content.blog.header.coverImage.photoID);
            if (cthumb) {
                content.blog.header.coverImage.thumb = cthumb;
            }
        }
        //get author image
        if (content.blog && content.blog.header && content.blog.header.authorImage && content.blog.header.authorImage.photoID) {
            const athumb = await getPhotoThumb(content.blog.header.authorImage.photoID);
            if (athumb) {
                content.blog.header.authorImage.thumb = athumb;
            }
        }
        //load other content
        if (content.blog && content.blog.bodyRows && content.blog.bodyRows.length > 0) {
            for (var i = 0; i < content.blog.bodyRows.length; i++) {
                let row = content.blog.bodyRows[i];
                if (row.rowContent && row.rowContent.length > 0) {
                    for (var j = 0; j < row.rowContent.length; j++) {
                        let rcontent = row.rowContent[j];
                        if (rcontent.contentType == 1 && rcontent.photo && rcontent.photo.photoID) {
                            const contentThumb = await getPhotoThumb(rcontent.photo.photoID);
                            if (contentThumb) {
                                content.blog.bodyRows[i].rowContent[j].photo.thumb = contentThumb;
                            }

                        }
                    }
                }
            }
        }
        return content;
    }
    loadEventSpecificContent = async (content) => {
        //get header image
        if (content.event && content.event.coverPhoto && content.event.coverPhoto.photoID) {
            const hthumb = await getPhotoThumb(content.event.coverPhoto.photoID);
            if (hthumb) {
                content.event.coverPhoto.thumb = hthumb;
            }
        }

        //load other content
        if (content.event && content.event.bodyRows && content.event.bodyRows.length > 0) {
            for (var i = 0; i < content.event.bodyRows.length; i++) {
                let row = content.event.bodyRows[i];
                if (row.rowContent && row.rowContent.length > 0) {
                    for (var j = 0; j < row.rowContent.length; j++) {
                        let rcontent = row.rowContent[j];
                        if (rcontent.contentType == 1 && rcontent.photo && rcontent.photo.photoID) {
                            const contentThumb = await getPhotoThumb(rcontent.photo.photoID);
                            if (contentThumb) {
                                content.event.bodyRows[i].rowContent[j].photo.thumb = contentThumb;
                            }

                        }
                    }
                }
            }
        }
        return content;
    }
    loadMarketSpecificContent = async (content) => {
        //get header image
        if (content.marketItem && content.marketItem.primaryPhoto && content.marketItem.primaryPhoto.photoID) {
            const hthumb = await getPhotoThumb(content.marketItem.primaryPhoto.photoID);
            if (hthumb) {
                content.marketItem.primaryPhoto.thumb = hthumb;
            }
        }

        //load other content
        if (content.marketItem && content.marketItem.photos && content.marketItem.photos.length > 0) {
            for (var i = 0; i < content.marketItem.photos.length; i++) {
                if (content.marketItem.photos[i].photo && content.marketItem.photos[i].photo.photoID) {
                    const contentThumb = await getPhotoThumb(content.marketItem.photos[i].photo.photoID);
                    if (contentThumb) {
                        content.marketItem.photos[i].photo.thumb = contentThumb;
                    }
                }
            }
        }
        return content;
    }





    notification = (item, index) => {
        return (
            <>
                <ListItem button key={item.id} onClick={() => this.handleClick(item.referencedID)} sx={{ mb: 1 }}>

                    <ListItemAvatar>
                        <Avatar alt={item.referencedUser.firstName} src={"data:image/png;base64, " + item.referencedUser.avatarB64} className="avatarImageMedium" />
                    </ListItemAvatar>

                    <ListItemText
                        sx={{ color: this.props.dark ? "#ccc" : "#444" }}
                        secondary={item.created ?? ""}
                        secondaryTypographyProps={{ sx: { color: this.props.dark ? "#aaa" : "#666", fontSize: { xs: ".6rem", sm: ".6rem" } } }}
                        primaryTypographyProps={{ sx: { color: this.props.dark ? "#ddd" : "#333", fontSize: { xs: ".8rem", sm: "1rem" } } }}
                        primary={
                            <Box display="flex">
                                <Typography variant="body2" sx={{ fontWeight:600, fontSize: { xs: ".8rem", sm: ".9rem" }, display: 'inline', color: this.props.dark ? "#ccc" : "#555" }} >
                                    {item.message}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box sx={{ marginTop: "auto", marginBottom: "auto" }}>
                                    {item.type === 0 &&
                                        <>
                                            <Tooltip title="Join this group">
                                                <IconButton
                                                    key="invAccept"
                                                    aria-label="accept"
                                                    onClick={() => this.props.handleAccept(item, index)}
                                                    size="small"
                                                    sx={{ margin: "0px 4px", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}
                                                >
                                                    <ThumbUpIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Decline this group">
                                                <IconButton key="invDecline" aria-label="accept" onClick={() => this.handleDecline(item, index)} size="small" sx={{ margin: "0px 4px", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                                    <ThumbDownIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    }
                                    <Tooltip title="Dismiss">
                                        <IconButton key="invIgnore" aria-label="accept" onClick={(e) => this.handleDismiss(e, item, index)} size="small" sx={{ margin: "0px 4px", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                            <CloseIcon size="small" sx={{ fontSize: "16px" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        }
                    />
                </ListItem>
                <Divider component="li" />
            </>
        )
    }

    contents = () => {
        return (
            <ModalDialog
                show={this.props.show}
                handleClose={this.props.handleClose}
                fullWidth={true}
                maxWidth="md"
                hideScroll={true}
                closeLabel="Close"
                title="Notifications"
                dark={this.props.fullScreen ? true : this.props.dark}
                fullScreen={this.props.fullScreen}
                solid={this.props.fullScreen}
                fullHeight={this.props.fullScreen}
                noPad={this.props.fullScreen}
            >
                <Box sx={{ background: (theme) => this.props.dark ? theme.palette.item.dark : theme.palette.item.light, borderRadius: this.props.fullScreen ? 0 : "8px", minHeight: "300px", maxHeight: this.props.fullScreen ? "calc(100vh - 108px)" : "500px" }} className="prettyScroll">
                    {this.props.notifications.length > 0
                        ?
                        <List >
                            {this.props.notifications.map((item, index) => {
                                return this.notification(item, index);
                            })}
                        </List>
                        :
                        <span>No Notifications</span>
                    }
                </Box>
                {(this.state.selectedPost && this.state.showPost) && this.post()}
            </ModalDialog>
            )
    }

    post = () => {
        return (
            <ModalDialog
                show={this.state.showPost}
                handleClose={() => this.setState({ showPost: false })}
                fullWidth={true}
                maxWidth="lg"
                hideScroll={true}
                closeLabel="Close"
                dark={this.props.dark}
            >
                <Post
                    readOnly
                    dark={this.props.dark}
                    post={this.state.selectedPost}
                    idx={0}
                    user={this.props.user}
                    updatePostContent={this.updatePostContent}
                    updateRepostContent={this.updateRepostContent}
                />
            </ModalDialog>
        )
    }

    render() {
        return this.contents();
    };



}
