import React, { Component } from 'react';

//Custom Components
import { getPhoto } from '../../../DataServices/PhotoService'
import { PhotoViewer } from '../Photos/PhotoViewer';

//Material UI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';


export class Event extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPhotoViewer : false,
        }
    }

    handleClickPhoto = async (e, photo, idx) => {
        var ele = e.target;
        ele.style.cursor = 'wait';
        document.body.style.cursor = 'wait';
        if (!photo.fullPhoto) {
            photo = await getPhoto(photo.photoID);
            //this.updatePhoto(photo, idx);

        }

        this.setState({
            selectedPhoto: photo,
            selectedPhotoIDX: idx,
            openPhotoViewer: true
        })

        document.body.style.cursor = 'default';
        ele.style.cursor = 'default';

    }



    //Render funcs
    header = () => {
        if (this.props.event) {
            const event = this.props.event;
            let startDate, endDate;
            if (event.eventStart) {
                startDate = new Date(new Date(event.eventStart).getTime() - (new Date(event.eventStart).getTimezoneOffset() * 60000));
            }
            if (event.eventEnd) {
                endDate = new Date(new Date(event.eventEnd).getTime() - (new Date(event.eventEnd).getTimezoneOffset() * 60000));
            }

            return (
                <Box
                    style={{
                        padding: "60px",
                        textAlign: "left",
                        backgroundImage: this.props.event.coverPhoto && "linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('data:image/png;base64, " + this.props.event.coverPhoto.thumb + "')",
                        backgroundSize: 'cover',
                        backgroundPositionY: 'center',
                        minHeight: "300px",
                        paddingRight: "100px",
                
                    }}
                    sx={{
                        backgroundColor: theme => theme.palette.green.light
                    }}
                >
                    <Stack spacing={1} sx={{ maxWidth: "500px", marginLeft: "auto", marginRight: "auto" }}>
                        {event.title &&
                            <Typography variant="h3" align="center" sx={{ fontWeight: 500, color: "white" }}>
                                {event.title}
                            </Typography>
                        }
                        <Box display="block">
                            {event.hostedBy &&
                                <Typography variant="h6" align="center" sx={{ fontWeight: 500, color: "white" }}>
                                    {`Hosted by ${event.hostedBy}`}
                                </Typography>
                            }
                            {event.locationDesc &&
                                <Typography variant="h6" align="center" sx={{ paddingLeft: "6px", fontWeight: 500, color: "white" }}>
                                    {`${event.locationDesc}`}
                                </Typography>
                            }
                        </Box>
                        <Box display="block">
                        {event.eventStart &&
                                <Typography variant="subtitle1" align="center" sx={{ fontWeight: 500, color: "white" }}>
                                {new Date(event.eventStart).toDateString()}
                            </Typography>
                        }
                        {(event.eventStart && event.eventEnd) &&
                                <Typography variant="subtitle2" align="center" sx={{ paddingLeft:"16px", fontWeight: 500, color: "white" }}>
                            {`${new Date(startDate).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })} to ${new Date(endDate).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}` }
                            </Typography>
                            }

                        </Box>
                        <Button color="secondary" variant="link" sx={{ color: "white", borderColor:"white", mt:3}} >Register Now</Button>
                    </Stack>
                </Box>
            )
        }
        return null;
    }
    bodyrows = () => {
        if (this.props.event.bodyRows && this.props.event.bodyRows.length > 0) {
            return (
                <Stack spacing={2} sx={{ marginBottom: "24px", marginTop: "16px", fontSize: ".9rem", padding: "0px 32px", textAlign: "left" }}>
                    {
                        this.props.event.bodyRows.map((item, index) => {

                            if (item.numCols === 1) {
                                return (
                                    <Box key={item.id} display="flex" sx={{ padding: "0px 16px", color: (theme) => theme.palette.common.dimblack }}>
                                        {(item.rowContent && item.rowContent.length > 0 && item.rowContent[0].contentType === 0) &&

                                            <div dangerouslySetInnerHTML={{ __html: item.rowContent[0].textContent }} style={{ width: "100%" }} />

                                        }
                                        {(item.rowContent && item.rowContent.length > 0 && item.rowContent[0].contentType === 1 && item.rowContent[0].photo && item.rowContent[0].photo.thumb) &&
                                            <Stack>
                                                <Paper
                                                    component="img"
                                                    elevation={this.props.dark ? 0 : 8}
                                                    src={"data:image/png;base64, " + item.rowContent[0].photo.thumb}
                                                style={{ maxHeight: "120px", maxWidth: "300px", height: "auto", objectFit: "cover", borderRadius: "5px", cursor: "pointer" }}
                                                onClick={(e) => this.handleClickPhoto(e, item.rowContent[0].photo, index)}
                                                />
                                                {item.rowContent[0].textContent &&
                                                    <Box sx={{ width: "100%", textAlign: "center" }}>
                                                        <Typography variant="body2" style={{ paddingTop: "6px", fontSize: ".7rem" }}>
                                                            {item.rowContent[0].textContent}
                                                        </Typography>
                                                    </Box>
                                                }
                                            </Stack>
                                        }
                                    </Box>
                                )
                            }
                            else {
                                return (
                                    <Box key={item.id} display="flex">
                                        <Box sx={{ width: "50%", padding: "0px 16px", color: (theme) => theme.palette.common.dimblack }}>
                                            {(item.rowContent && item.rowContent.length > 0 && item.rowContent[0].contentType === 0) &&
                                                <div dangerouslySetInnerHTML={{ __html: item.rowContent[0].textContent }} style={{ width: "100%" }} />
                                            }
                                            {(item.rowContent && item.rowContent.length > 0 && item.rowContent[0].contentType === 1 && item.rowContent[0].photo && item.rowContent[0].photo.thumb) &&
                                                <Stack>
                                                    <Paper
                                                        component="img"
                                                        elevation={this.props.dark ? 0 : 8}
                                                        src={"data:image/png;base64, " + item.rowContent[0].photo.thumb}
                                                    style={{ marginLeft: "auto", marginRight: "auto", marginTop: "8px", marginBottom: "auto", maxHeight: "100%", minWidth: "250px", maxWidth: "300px", height: "auto", objectFit: "cover", borderRadius: "5px", cursor: "pointer" }}
                                                    onClick={(e) => this.handleClickPhoto(e, item.rowContent[0].photo, index)}
                                                    />
                                                    {item.rowContent[0].textContent &&
                                                        <Box sx={{ width: "100%", textAlign: "center" }}>
                                                            <Typography variant="body2" style={{ paddingTop: "6px", fontSize: ".7rem" }}>
                                                                {item.rowContent[0].textContent}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                </Stack>
                                            }
                                        </Box>
                                        <Box sx={{ width: "50%", padding: "0px 16px", color: (theme) => theme.palette.common.dimblack }}>
                                            {(item.rowContent && item.rowContent.length > 1 && item.rowContent[1].contentType === 0) &&
                                                <div dangerouslySetInnerHTML={{ __html: item.rowContent[1].textContent }} style={{ width: "100%" }} />
                                            }
                                            {(item.rowContent && item.rowContent.length > 1 && item.rowContent[1].contentType === 1 && item.rowContent[1].photo && item.rowContent[1].photo.thumb) &&
                                                <Stack>
                                                    <Paper
                                                        component="img"
                                                        elevation={this.props.dark ? 0 : 8}
                                                        src={"data:image/png;base64, " + item.rowContent[1].photo.thumb}
                                                    style={{ marginLeft: "auto", marginRight: "auto", marginTop: "8px", marginBottom: "auto", maxHeight: "100%", minWidth: "250px", maxWidth: "300px", height: "auto", objectFit: "cover", borderRadius: "5px", cursor: "pointer" }}
                                                    onClick={(e) => this.handleClickPhoto(e, item.rowContent[1].photo, index)}
                                                    />
                                                    {item.rowContent[1].textContent &&
                                                        <Box sx={{ width: "100%", textAlign: "center" }}>
                                                            <Typography variant="body2" style={{ paddingTop: "6px", fontSize: ".7rem" }}>
                                                                {item.rowContent[1].textContent}
                                                            </Typography>
                                                        </Box>
                                                    }

                                                </Stack>
                                            }
                                        </Box>

                                    </Box>
                                )
                            }
                        })
                    }
                </Stack>
            )
        }
        return null;
    }



    footer = (show) => {
        if (show) {
            return (
                <Box sx={{ height: "48px", background: (theme) => theme.palette.secondary.main }}>
                    <Box display="flex" sx={{ height: "100%", padding: "0px 16px" }}>
                        <Button size="small" key="newblogchannel" aria-label="newblogchannel" onClick={this.handleRegister} sx={{ color: (theme) => theme.palette.common.white, fontSize: ".8rem", borderRadius: "6px", margin: "6px 0px" }}>
                            Register
                        </Button>
                    </Box>
                </Box>
            )
        }
        return null;
    }
    photoViewer = () => {
        if (this.state.selectedPhoto) {
            return (
                <PhotoViewer
                    dark={this.props.dark}
                    open={this.state.openPhotoViewer}
                    photoID={this.state.selectedPhoto && this.state.selectedPhoto.photoID}
                    photo={this.state.selectedPhoto}
                    handleClose={() => this.setState({ openPhotoViewer: false })}
                    index={this.state.selectedPhotoIDX}
                    allowNext={false}
                    allowPrevious={false}
                    hideInteractions
                />
            )
        }
        else {
            return null;
        }

    }
    content = () => {
        return (
            <Box
                elevation={this.props.dark ? 0 : 8}
                sx={{
                    width: this.props.post ? "100%" :  {xs: "360px", sm:"450px", md: "672px", lg: "960px", xl:"1176px"},
                    maxWidth: "1176px",
                    minWidth: "360px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingBottom: "0px",
                    background: "white",

                }}
            >
                <Stack spacing={3}>
                    {this.header()}
                    {this.bodyrows()}
                    {this.footer(true)}
                    {this.photoViewer()}
                </Stack>
            </Box>
            )
    }

    render() {
        return this.content();
    }
}

