import React, { Component} from 'react';

//Custom Components
import { Spin } from '../Utilities/Spin'
import { ProfileBasic } from './ProfileBasic'
import { ProfileContacts } from './ProfileContacts'
import { ProfileInterests } from './ProfileInterests'
import { ProfileBucketList } from './ProfileBucketList'
import { ProfileStrengthsAndWeaknesses } from './ProfileStrengthsAndWeaknesses'
import { ProfileResponsibilities } from './ProfileResponsibilities'
import { ProfileLifeEvents } from './ProfileLifeEvents'
import { ProfileSiteOptions } from './ProfileSiteOptions'

//Material UI
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

//Data Services
import { getUserProfile, getProfileImage, removeStrength, addStrength, removeWeakness, addWeakness, getSiteOptions } from '../../DataServices/UserService'
import { getPhotoThumb } from '../../DataServices/PhotoService'

const a11yProps = (index, id) => {
    return {
        id: `simple-tab-${index}-${id}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`profile-tabpanel-${index}`}
            aria-labelledby={`profile-tab-${index}`}
            style={{ minWidth: "130px" }}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export class Profile extends Component {
    constructor(props) {
        super(props);
        this.props.setToken.bind(this);
        this.state = {
            userprofile: [],
            loading: true,
            tabValue: 0,
        };
    }

    componentDidMount() {
        this.getUserProfile();
        this.getSiteOptions();
    }

    //Get Data
    getUserProfile = async () => {
        const data = await getUserProfile(this.props.appIdentifier);
        if (data === "error") {
            this.props.setToken('');
            localStorage.clear();
        }
        else {
            this.setState({
                userprofile: data,
                loading: false
            });
            this.getProfileImage();
        }
    }
    getProfileImage = async () => {
        const data = await getProfileImage();
        if (data) {
            this.setState(
                state => {
                    state.userprofile.imageB64 = data.thumb;
                    state.userprofile.avatarB64 = data.thumb;
                    return state;
                }
            );
        }
    }
    getSiteOptions = async () => {
        const data = await getSiteOptions(this.props.user.userID);
        if (data) {
            this.setState({ siteOptions: data });
            if (data.coverPhotoID) {
                const thumb = await getPhotoThumb(data.coverPhotoID);
                if (thumb) {
                    this.setState(
                        state => {
                            state.siteOptions.coverPhoto = thumb;
                            return state;
                        }
                    );
                }
            }
        }
    }

    //Event Handlers-----------------
    handleTabChange = (event, newValue) => {
        this.setState({ tabValue: newValue });
    }
    handleHeadlineChange(data) {
        this.setState(
            prevState => {
                let uprofile = { ...prevState.userprofile };
                uprofile.demographics.personalHeadline = data;
                return { uprofile };
            }
        )
    }
    handleFirstNameChange(data) {
        this.setState(
            prevState => {
                let uprofile = { ...prevState.userprofile };
                uprofile.firstName = data;
                return { uprofile };
            }
        )
    }
    handleLastNameChange(data) {
        this.setState(
            prevState => {
                let uprofile = { ...prevState.userprofile };
                uprofile.lastName = data;
                return { uprofile };
            }
        )
    }
    handleUpdateProfile = (b64) => {
        this.setState(
            state => {
                state.userprofile.imageB64 = b64;
                return state;
            }
        );

        let user = this.props.user;
        user.avatar = b64;
        this.props.setUserInfo(user);

    }
    deleteEmail = (idx) => {
        this.setState(
            state => {
                state.userprofile.demographics.emailAddresses.splice(idx, 1);
                return state;
            }
        );
    }
    deletePhone = (idx) => {
        this.setState(
            state => {
                state.userprofile.demographics.phoneNumbers.splice(idx, 1);
                return state;
            }
        );
    }
    deleteAddress = (idx) => {
        this.setState(
            state => {
                state.userprofile.demographics.addresses.splice(idx, 1);
                return state;
            }
        );
    }
    updateEmail = (idx, email, type) => {
        this.setState(
            state => {
                state.userprofile.demographics.emailAddresses[idx].address = email;
                state.userprofile.demographics.emailAddresses[idx].emailType = type;
                return state;
            }
        );
    }
    updatePhone = (idx, phone, type) => {
        this.setState(
            state => {
                state.userprofile.demographics.phoneNumbers[idx].number = phone;
                state.userprofile.demographics.phoneNumbers[idx].phoneType = type;
                return state;
            }
        );
    }
    updateAddress = (idx, address) => {
        this.setState(
            state => {
                state.userprofile.demographics.addresses[idx].street = address.street;
                state.userprofile.demographics.addresses[idx].city = address.city;
                state.userprofile.demographics.addresses[idx].state = address.state;
                state.userprofile.demographics.addresses[idx].zip = address.zip;
                state.userprofile.demographics.addresses[idx].type = address.type;
                return state;
            }
        );
    }
    updateFamily = (idx, family) => {
        this.setState(
            state => {
                state.userprofile.familyMembers[idx].firstName = family.firstName;
                state.userprofile.familyMembers[idx].middleName = family.middleName;
                state.userprofile.familyMembers[idx].lastName = family.lastName;
                state.userprofile.familyMembers[idx].type = family.type;
                state.userprofile.familyMembers[idx].gender = family.gender;
                state.userprofile.familyMembers[idx].dob = family.dob;
                return state;
            }
        );
    }
    updateMyDob = (val) => {
        this.setState(
            state => {
                state.userprofile.importantDates.dob = val;
                return state;
            }
        );
    }
    updateMyWeddingAnniversary = (val) => {
        this.setState(
            state => {
                state.userprofile.importantDates.weddingAnniversary = val;
                return state;
            }
        );
    }
    updateMyJobAnniversary = (val) => {
        this.setState(
            state => {
                state.userprofile.importantDates.jobAnniversary = val;
                return state;
            }
        );
    }
    handlePersonalChange = (type, value) => {
        if (type === "sex") {
            this.setState(
                state => {
                    state.userprofile.demographics.sexualPreference = value;
                    return state;
                }
            );
        }
        if (type === "gender") {
            this.setState(
                state => {
                    state.userprofile.demographics.gender = value;
                    return state;
                }
            );
        }
        if (type === "relationship") {
            this.setState(
                state => {
                    state.userprofile.demographics.relationshipStatus = value;
                    return state;
                }
            );
        }
        if (type === "politics") {
            this.setState(
                state => {
                    state.userprofile.demographics.politicalSlant = value;
                    return state;
                }
            );
        }
        if (type === "religion") {
            this.setState(
                state => {
                    state.userprofile.demographics.religiousViews = value;
                    return state;
                }
            );
        }
        if (type === "social") {
            this.setState(
                state => {
                    state.userprofile.demographics.socialType = value;
                    return state;
                }
            );
        }

    }
    toggleSelected = async (val) => {
        if (this.state.userprofile.demographics.interests &&
            this.state.userprofile.demographics.interests.includes(val)) {
            this.setState(
                state => {
                    state.userprofile.demographics.interests.splice(this.state.userprofile.demographics.interests.indexOf(val), 1);
                    return state;
                }
            );
            await fetch('home/removeinterest?id=' + val, { method: 'POST' });
        }
        else {
            this.setState(
                state => {
                    state.userprofile.demographics.interests.push(val);
                    return state;
                }
            );
            await fetch('home/addinterest?interest=' + val, { method: 'POST' });
        }
    }
    addFavorite = async (item) => {
        this.setState(
            state => {
                state.userprofile.demographics.favorites.push(item);
                return state;
            }
        );
    }
    removeFavorite = async (item) => {
        this.setState(
            state => {
                state.userprofile.demographics.favorites.splice(this.state.userprofile.demographics.favorites.indexOf(item), 1);
                return state;
            }
        );
    }
    toggleSelectedTaste = async (type, item, selected) => {
        if (!selected) {
            if (type === 0) {
                this.setState(
                    state => {
                        state.userprofile.demographics.musicTastes.push(item);
                        return state;
                    }
                );
            }
            if (type === 1) {
                this.setState(
                    state => {
                        state.userprofile.demographics.movieTastes.push(item);
                        return state;
                    }
                );
            }
            if (type === 2) {
                this.setState(
                    state => {
                        state.userprofile.demographics.foodTastes.push(item);
                        return state;
                    }
                );
            }
            if (type === 3) {
                this.setState(
                    state => {
                        state.userprofile.demographics.drinkTastes.push(item);
                        return state;
                    }
                );
            }
            if (type === 4) {
                this.setState(
                    state => {
                        state.userprofile.demographics.readingTastes.push(item);
                        return state;
                    }
                );
            }
            await fetch('home/addtaste?type=' + type + '&tasteval=' + item, { method: 'POST' });
        }
        else {
            if (type === 0) {
                this.setState(
                    state => {
                        state.userprofile.demographics.musicTastes.splice(state.userprofile.demographics.musicTastes.indexOf(item), 1);
                        return state;
                    }
                );
            }
            if (type === 1) {
                this.setState(
                    state => {
                        state.userprofile.demographics.movieTastes.splice(state.userprofile.demographics.movieTastes.indexOf(item), 1);
                        return state;
                    }
                );
            }
            if (type === 2) {
                this.setState(
                    state => {
                        state.userprofile.demographics.foodTastes.splice(state.userprofile.demographics.foodTastes.indexOf(item), 1);
                        return state;
                    }
                );
            }
            if (type === 3) {
                this.setState(
                    state => {
                        state.userprofile.demographics.drinkTastes.splice(state.userprofile.demographics.drinkTastes.indexOf(item), 1);
                        return state;
                    }
                );
            }
            if (type === 4) {
                this.setState(
                    state => {
                        state.userprofile.demographics.readingTastes.splice(state.userprofile.demographics.readingTastes.indexOf(item), 1);
                        return state;
                    }
                );
            }
            await fetch('home/removetaste?type=' + type + '&value=' + item, { method: 'POST' });
        }

    }
    updateBucketList = (idx, item) => {
        this.setState(
            state => {
                state.userprofile.demographics.bucketList[idx] = item;
                return state;
            }
        );
    }
    updatePersonalGoal = (idx, item) => {
        this.setState(
            state => {
                state.userprofile.personalGoals[idx] = item;
                return state;
            }
        );
    }
    updateHabit = (idx, item) => {
        this.setState(
            state => {
                state.userprofile.demographics.badHabits[idx] = item;
                return state;
            }
        );
    }
    toggleSelectedStrength = async (item) => {
        if (this.state.userprofile.demographics.personalStrengths &&
            this.state.userprofile.demographics.personalStrengths.find((x) => x.strength === item.value)) {
            const itm = this.state.userprofile.demographics.personalStrengths.find((x) => x.strength === item.value);
            const id = itm.id;
            this.setState(
                state => {
                    state.userprofile.demographics.personalStrengths.splice(this.state.userprofile.demographics.personalStrengths.indexOf(itm), 1);
                    return state;
                }
            );
            await removeStrength(id);
        }
        else {
            const newItm = { strength: item.value, ranking: 0, impacts: "" };
            this.setState(
                state => {
                    state.userprofile.demographics.personalStrengths.push(newItm);
                    return state;
                }
            );
            const len = this.state.userprofile.demographics.personalStrengths.length;
            const id2 = await addStrength(item.value);
            if (id2) {
                this.setState(
                    state => {
                        state.userprofile.demographics.personalStrengths[len].id = id2;
                        return state;
                    }
                );
            }

        }
    }
    toggleSelectedWeakness = async (item) => {
        if (this.state.userprofile.demographics.personalWeaknesses &&
            this.state.userprofile.demographics.personalWeaknesses.find((x) => x.weakness === item.value)) {
            const itm = this.state.userprofile.demographics.personalWeaknesses.find((x) => x.weakness === item.value);
            const id = itm.id;
            this.setState(
                state => {
                    state.userprofile.demographics.personalWeaknesses.splice(this.state.userprofile.demographics.personalWeaknesses.indexOf(itm), 1);
                    return state;
                }
            );
            await removeWeakness(id);
        }
        else {
            const newItm = { weakness: item.value, ranking: 0, impacts: "" };
            const len = this.state.userprofile.demographics.personalWeaknesses.length;
            this.setState(
                state => {
                    state.userprofile.demographics.personalWeaknesses.push(newItm);
                    return state;
                }
            );

            const id2 = await addWeakness(item.value);
            if (id2) {
                this.setState(
                    state => {
                        state.userprofile.demographics.personalWeaknesses[len].id = id2;
                        return state;
                    }
                );
            }
        }
    }
    updateOccupation = (idx, item) => {
        this.setState(
            state => {
                state.userprofile.occupations[idx] = item;
                return state;
            }
        );
    }
    updateRole = (idx, item) => {
        this.setState(
            state => {
                state.userprofile.lifeRoles[idx] = item;
                return state;
            }
        );
    }
    updateEvent = (idx, item) => {
        this.setState(
            state => {
                state.userprofile.demographics.lifeEvents[idx] = item;
                return state;
            }
        );
    }
    updateEducation = (idx, item) => {
        this.setState(
            state => {
                state.userprofile.educationHistory[idx] = item;
                return state;
            }
        );
    }
    updateEmployment = (idx, item) => {
        this.setState(
            state => {
                state.userprofile.employmentHistory[idx] = item;
                return state;
            }
        );
    }
    updateSiteOptions = (siteOptions) => {
        this.setState({ siteOptions });
    }
    updatePublicAttributes = (ignore, profile) => {
        const userprofile = this.state.userprofile;
        userprofile.publicAttributes = profile.publicAttributes;
        this.setState({ userprofile });
    }

    //render funcs
    TabContainer = () => {
        return (
            <Box sx={{ width: '100%', height: { xs: "calc(100vh - 108px)", sm: '100%' }, minHeight: "400px", background: this.props.dark ? "#050505" : "#eee" }} className={this.props.dark && "dark"} >
                <Box sx={{ borderBottom: this.props.dark && "1px solid #151515", background: theme => this.props.dark ? "#151515" : theme.palette.secondary.main }} elevation={this.props.dark ? 0 : 8}>
                    <Tabs variant="scrollable" scrollButtons="auto" sx={{ minHeight: "36px", maxWidth: "fit-content", mx: "auto" }} allowScrollButtonsMobile indicatorColor="secondary" value={this.state.tabValue} onChange={(e, newValue) => this.handleTabChange(e, newValue)} aria-label="profile tabs">
                        <Tab label="Personal Info" {...a11yProps(0)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        <Tab label="Contact Info" {...a11yProps(1)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        {this.props.appIdentifier === 'LS' &&
                            <Tab label="Responsibilities" {...a11yProps(2)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        }
                        {this.props.appIdentifier === 'LS' &&
                            <Tab label="Strengths and Weaknesses" {...a11yProps(3)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        }
                        {this.props.appIdentifier === 'LS' &&
                            <Tab label="Interests" {...a11yProps(4)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        }
                        {this.props.appIdentifier === 'LS' &&
                            <Tab label="Background" {...a11yProps(5)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        }
                        {this.props.appIdentifier === 'LS' &&
                            <Tab label="Goals and Future Desires" {...a11yProps(6)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        }
                        {this.props.appIdentifier === 'LS' &&
                            <Tab label="User Site Options" {...a11yProps(7)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        }
                    
                    </Tabs>
                </Box>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={0}>
                    {this.profileBasic()}
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={1}>
                    {this.profileContact()}
                </TabPanel>
                {this.props.appIdentifier === 'LS' &&

                    <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={2}>
                        {this.profileResponsibilities()}
                    </TabPanel>
                }
                {this.props.appIdentifier === 'LS' &&
                    <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={3}>
                        {this.profileStrengths()}
                    </TabPanel>
                }
                {this.props.appIdentifier === 'LS' &&
                    <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={4}>
                        {this.profileInterests()}
                    </TabPanel>
                }
                {this.props.appIdentifier === 'LS' &&
                    <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={5}>
                        {this.profileLifeEvents()}
                    </TabPanel>
                }
                {this.props.appIdentifier === 'LS' &&
                    <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={6}>
                        {this.profileBucketList()}
                    </TabPanel>
                }
                {this.props.appIdentifier === 'LS' &&
                    <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={7}>
                        {this.userSiteOptions()}
                    </TabPanel>
                }
                
            </Box>
        )
    }
    profileBasic = () => {
        return (
            <ProfileBasic
                appIdentifier={this.props.appIdentifier}
                profile={this.state.userprofile}
                dark={this.props.dark}
                updateData={this.updatePublicAttributes}
                setUserInfo={this.props.setUserInfo}
                handleUpdateProfile={this.handleUpdateProfile}
                deleteEmail={this.deleteEmail}
                deletePhone={this.deletePhone}
                deleteAddress={this.deleteAddress}
                updateEmail={this.updateEmail}
                updatePhone={this.updatePhone}
                updateAddress={this.updateAddress}
                updateMyDob={this.updateMyDob}
                updateMyJobAnniversary={this.updateMyJobAnniversary}
                updateMyWeddingAnniversary={this.updateMyWeddingAnniversary}
                handlePersonalChange={this.handlePersonalChange}
                fullScreen={this.props.fullScreen}
            />
        )
    }
    profileContact = () => {
        return (
            <ProfileContacts
                appIdentifier={this.props.appIdentifier}
                profile={this.state.userprofile}
                updateData={this.updatePublicAttributes}
                dark={this.props.dark}
                setUserInfo={this.props.setUserInfo}
                deleteEmail={this.deleteEmail}
                deletePhone={this.deletePhone}
                deleteAddress={this.deleteAddress}
                updateEmail={this.updateEmail}
                updatePhone={this.updatePhone}
                updateAddress={this.updateAddress}
            />
        )
    }
    profileInterests = () => {
        return (
            <ProfileInterests
                appIdentifier={this.props.appIdentifier}
                profile={this.state.userprofile}
                updateData={this.updatePublicAttributes}
                dark={this.props.dark}
                toggleSelected={this.toggleSelected}
                addFavorite={this.addFavorite}
                removeFavorite={this.removeFavorite}
                toggleSelectedTaste={this.toggleSelectedTaste}
            />
        )
    }
    profileBucketList = () => {
        return (
            <ProfileBucketList
                appIdentifier={this.props.appIdentifier}
                profile={this.state.userprofile}
                updateData={this.updatePublicAttributes}
                dark={this.props.dark}
                updateBucketList={this.updateBucketList}
                updatePersonalGoal={this.updatePersonalGoal}
                updateHabit={this.updateHabit}
            />
        )
    } 
    profileStrengths = () => {
        return (
            <ProfileStrengthsAndWeaknesses
                appIdentifier={this.props.appIdentifier}
                profile={this.state.userprofile}
                updateData={this.updatePublicAttributes}
                dark={this.props.dark}
                toggleSelectedStrength={this.toggleSelectedStrength}
                toggleSelectedWeakness={this.toggleSelectedWeakness}
            />
        )
    } 
    profileResponsibilities = () => {
        return (
            <ProfileResponsibilities
                appIdentifier={this.props.appIdentifier}
                profile={this.state.userprofile}
                updateData={this.updatePublicAttributes}
                dark={this.props.dark}
                updateOccupation={this.updateOccupation}
                updateRole={this.updateRole}
            />
        )
    }
    profileLifeEvents = () => {
        return (
            <ProfileLifeEvents
                appIdentifier={this.props.appIdentifier}
                profile={this.state.userprofile}
                updateData={this.updatePublicAttributes}
                dark={this.props.dark}
                updateEvent={this.updateEvent}
                updateEducation={this.updateEducation}
                updateEmployment={this.updateEmployment}
            />
        )
    }
    userSiteOptions = () => {
        if (this.state.siteOptions) {
            return (
                <ProfileSiteOptions
                    appIdentifier={this.props.appIdentifier}
                    siteOptions={this.state.siteOptions}
                    dark={this.props.dark}
                    handleUpdateProfile={this.handleUpdateSiteOptions}
                    user={this.props.user}
                    updateSiteOptions={this.updateSiteOptions}
                />
            )
        }
        return null;


    }
    content = () => {
        let contents = this.state.loading
            ? <Spin open={this.state.loading} />
            :
            this.TabContainer()

        return (
            <Box dark={this.props.dark} sx={{ height: "calc(100vh - 40px)" }}>
                {contents}
            </Box>
        )
    }

    //main component render
    render() {
        return this.content();
    }
}
