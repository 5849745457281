import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import { PublicAttribute } from './PublicAttribute';

export class Section extends Component {

    buttonOffset = () => {
        return (
            <Box sx={{ width: "36px" }} />
            )
    }
    sliderOffset = () => {
        return (
            <Box sx={{ width: "90px" }} />
        )
    }
    content = () => {
        return (
            <Paper
                sx={{
                    display: "flex",
                    py: this.props.noPad ? 0 : { xs: 1, sm: 2 },
                    px: this.props.noPad ? 0 : { xs: 0, sm: 2 },
                    paddingTop: "8px",
                    background: theme => this.props.transparent ? "transparent" : (this.props.dark ? theme.palette.post.dark : "#fff"),
                    height: "100%",
                    border: this.props.dark ? "none" : "none",
                    width: this.props.fullWidth && "100%",
                    borderRadius: this.props.flat ? "0px" : { xs: 0, sm: 1},
                    boxShadow: this.props.flat ? "none" : (this.props.dark && "none"),
                }}
                style={{
                marginBottom: this.props.padBottom && "12px"
            }} elevation={this.props.elevation ? this.props.elevation : (this.props.dark ? 0 : 4)}>
                <Stack sx={{ width: "100%" }}>
                    {this.props.title &&
                        <Stack direction="row" sx={{ marginBottom: "8px" }}>
                            {this.props.handleEdit && this.buttonOffset()}
                            {this.props.handleSave && this.buttonOffset()}
                            {this.props.handleAdd && this.buttonOffset()}
                            {this.props.publicAttribute && <PublicAttribute {...this.props} attr={this.props.publicAttribute} />}
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 550,
                                    color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black,
                                    textAlign: "center",
                                    width: "100%",
                                    p: { xs: 0, sm: 1 },
                            }}>{this.props.title ? this.props.title : ""}</Typography>
                            {this.props.publicAttribute && this.sliderOffset()}
                            {this.props.handleAdd &&
                                <Tooltip title={this.props.itemType ? "Add new " + this.props.itemType : "Add new item"}>
                                    <IconButton onClick={this.props.handleAdd}>
                                        <AddIcon fontSize="small" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />
                                    </IconButton>
                                </Tooltip>
                            }
                            {(this.props.handleSave && this.props.enableSave) &&
                                <IconButton onClick={this.props.handleSave}>
                                    <SaveIcon fontSize="small" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />
                                </IconButton>
                            }
                            {this.props.handleEdit &&
                                <Tooltip title="Edit properties">
                                    <IconButton onClick={this.props.handleEdit}>
                                        <EditIcon fontSize="small" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Stack>
                    }
                    <Box sx={{ px: { xs: 0, sm: 1 }, width: "100%" }}>
                        <Stack sx={{ width: "100%" }}>
                            {this.props.children && this.props.children}
                        </Stack>
                    </Box>
                </Stack>
            </Paper>
        );
    }

    //main compontnet render
    render() {
        return this.content();
    }
}
